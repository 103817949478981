import React from 'react';
// Import UI Components
import { PopoverV2, Tag, Button } from '@geneui/components';
// Import Components
import { CopyToClipboard } from 'components';
// Import constants
import { LanguagesFlagsPaths } from 'constants/utils';
import { DateTimeFormat, l, StateModesLabels, DataTableColumnsCustomTypes } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion, getTemplateChannelIconWithLabel } = Helpers;

const DataTableColumnsConfigs = (t, cloneTemplate) => {
    return {
        columns: [
            {
                text: t(l.TemplateId),
                dataKey: 'TemplateId',
                sortable: true,
                colRenderer: (id) => <CopyToClipboard>{id}</CopyToClipboard>,
            },
            {
                text: t(l.Title),
                dataKey: 'Name',
                sortable: true,
                isPermanent: true,
            },
            {
                text: t(l.Channel),
                dataKey: 'DeliveryMethod',
                sortable: true,
                colRenderer: (type) => (
                    <div className="crm-template-type-label">{getTemplateChannelIconWithLabel(type)}</div>
                ),
            },
            {
                text: t(l.State),
                dataKey: 'IsUsed',
                sortable: true,
                colRenderer: (IsUsed) => (
                    <div className={`crm-used-or-not-used-badge-cnt crm-used-or-not-used-label-${IsUsed}`}>
                        {IsUsed ? t(l.Used) : t(l.NotUsed)}
                    </div>
                ),
            },
            {
                text: t(l.Languages),
                dataKey: 'ChildItemsInfo',
                sortable: false,
                isPermanent: true,
                colRenderer: (ChildItemsInfo, _index, { data }) => {
                    const langCount = ChildItemsInfo.length;
                    return langCount ? (
                        <PopoverV2
                            className={`lang-popover c-lang-p lang-count-${langCount < 3 ? langCount : 3}`}
                            position="right"
                            align="center"
                            Content={
                                <div className="option-container-wrap">
                                    {ChildItemsInfo.map(({ Language, TemplateId }, index) => {
                                        return (
                                            <div className="option-container" key={index}>
                                                <div className="option-left-elements">
                                                    <img
                                                        className="crm-lng-img"
                                                        src={LanguagesFlagsPaths[Language]}
                                                        alt={Language}
                                                    />
                                                </div>
                                                <div className="option-texts">
                                                    <p className="option-title ellipsis-text">{t(l[Language])}</p>
                                                </div>
                                                <div className="option-right-elements">
                                                    <Button
                                                        icon="bc-icon-clone"
                                                        appearance="minimal"
                                                        color="primary"
                                                        onClick={() => {
                                                            cloneTemplate({
                                                                DeliveryMethod: data.DeliveryMethod,
                                                                TemplateId,
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <span className="left-arrow" />
                                </div>
                            }
                            behave="toggle"
                        >
                            <Tag
                                name={`${langCount} ${t(l.Languages)}`}
                                cornerRadius="smooth-radius"
                                appearance="outline"
                            />
                        </PopoverV2>
                    ) : (
                        <Tag name={t(l.Default)} cornerRadius="smooth-radius" appearance="outline" />
                    );
                },
            },
            {
                text: t(l.User),
                dataKey: 'CreatedBy.Name',
                sortable: true,
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.CreatedDate),
                dataKey: 'CreatedDate',
                sortable: true,
                formatter: (CreatedDate) => {
                    return customMomentWithoutTimezoneConversion(CreatedDate).format(DateTimeFormat);
                },
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.ModifiedDate),
                dataKey: 'ModifiedDate',
                sortable: true,
                formatter: (ModifiedDate) => {
                    return customMomentWithoutTimezoneConversion(ModifiedDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Mode),
                dataKey: 'State',
                sortable: true,
                colRenderer: (state) => (
                    <div className={`crm-mode-badge-cnt crm-cj-mode-label-${state}`}>{t(StateModesLabels[state])}</div>
                ),
            },
        ],
    };
};

export { DataTableColumnsConfigs };
