/* eslint-disable react/prop-types */
import React from 'react';
// Import Components
import { ClientId, TemplateId, AwardId } from 'components';
// Import Constants
import {
    l,
    CommunicationTypeLabels,
    CommunicationStatusLabels,
    CommunicationPromotionTypeLabels,
    DateTimeFormat,
    DataTableColumnsCustomTypes,
    DataTablePagingOptions,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion } = Helpers;

const getComponent = (objectName, data) => {
    if (data.Channel === CommunicationTypeLabels.Bonus) {
        return <AwardId awardId={data?.ObjectId} awardName={objectName} />;
    } else {
        return <TemplateId templateId={data?.ObjectId} templateName={objectName} />;
    }
};

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.PlayerId),
                dataKey: 'ClientId',
                sortable: true,
                isPermanent: true,
                colRenderer: (clientId) => <ClientId clientId={clientId} />,
            },
            {
                text: t(l.ClientName),
                dataKey: 'ClientName',
                sortable: false,
                isPermanent: true,
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.SentDate),
                dataKey: 'SentDate',
                sortable: true,
                formatter: (SentDate) => {
                    return customMomentWithoutTimezoneConversion(SentDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Channel),
                dataKey: 'Channel',
                sortable: true,
                colRenderer: (channel) => t(CommunicationTypeLabels[channel]),
            },
            {
                text: t(l.Title),
                dataKey: 'ObjectName',
                colRenderer: (objectName, _, rowData) => getComponent(objectName, rowData?.data),
                sortable: false,
            },
            {
                text: t(l.Status),
                dataKey: 'Status',
                sortable: true,
                colRenderer: (Status) => (
                    <div className={`crm-communication-state-badge-cnt crm-communication-state-label-${Status}`}>
                        {t(CommunicationStatusLabels[Status])}
                    </div>
                ),
            },
            {
                text: t(l.Message),
                dataKey: 'Message',
                sortable: false,
            },
            {
                text: t(l.PromotionName),
                dataKey: 'PromotionName',
                sortable: false,
            },
            {
                text: t(l.PromotionType),
                dataKey: 'PromotionType',
                sortable: true,
                colRenderer: (PromotionType) => t(CommunicationPromotionTypeLabels[PromotionType]),
            },
        ],
    };
};
const CommunicationPagingOptionsShowAll = {
    true: DataTablePagingOptions,
    false: [
        {
            label: '1000',
            value: 1000,
            defaultSelected: true,
        },
    ],
};

export { DataTableColumnsConfigs, CommunicationPagingOptionsShowAll };
