import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isArray, values } from 'lodash';
import { useTranslation } from 'react-i18next';

const DateSelector = ({ options, onClick, data }) => {
    const { t } = useTranslation();

    const [activeItemsIndexes, setActiveItemsIndexes] = useState([]);

    const isItemActive = (index) => activeItemsIndexes.indexOf(index) !== -1;

    const onClickHandler = (index) => {
        if (isItemActive(index)) {
            const newActiveItemsIndexes = [...activeItemsIndexes];
            newActiveItemsIndexes.splice(activeItemsIndexes.indexOf(index), 1);
            setActiveItemsIndexes(newActiveItemsIndexes);
        } else {
            setActiveItemsIndexes([...activeItemsIndexes, index]);
        }
        onClick(index);
    };

    useEffect(() => {
        if (isArray(data)) {
            setActiveItemsIndexes(data);
        }
    }, [data]);

    return (
        <>
            {values(options).map((item, index) => (
                <button
                    className={classNames({ active: isItemActive(index) })}
                    key={index}
                    onClick={() => onClickHandler(index)}
                >
                    {t(item)}
                </button>
            ))}
        </>
    );
};

DateSelector.propTypes = {
    data: PropTypes.array.isRequired,
    options: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
};
export default memo(DateSelector);
