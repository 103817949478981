import React, { memo, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { isNil } from 'lodash';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import UI Components
import { ExtendedInput, Paper, SkeletonLoader, Alert } from '@geneui/components';
//Import Components
import { ResetSave } from 'components';
//Import Constants
import { l, AlertTypes } from 'constants/common';
// Import Services
import { UtilsHttpService } from 'services/http';

const { success } = AlertTypes;

const TestUserSection = () => {
    const { t } = useTranslation();

    const { doPostRequest, doGetRequest } = useRequest();
    const { showToaster } = useToaster();

    const [isTestUserLoading, setIsTestUserLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            phoneNumber: '',
            clientId: '',
            telegramChatId: '0',
        },
        onSubmit: (values, formik) => {
            return doPostRequest(updateTestConfigRequest.request, {
                requestBody: {
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    clientId: values.clientId,
                    telegramChatId: values.telegramChatId,
                },
                successCallback: () => {
                    formik.resetForm({
                        values: {
                            email: values.email,
                            phoneNumber: values.phoneNumber,
                            clientId: values.clientId,
                            telegramChatId: values.telegramChatId,
                        },
                    });
                    showToaster(success, t(l.TestUserSettingsSavedSuccessfully));
                },
            });
        },
    });

    const { values, dirty, isSubmitting, setFieldValue, submitForm, resetForm } = formik;

    const { getTestConfigRequest, updateTestConfigRequest } = useMemo(
        () => ({
            getTestConfigRequest: UtilsHttpService.getTestConfig(),
            updateTestConfigRequest: UtilsHttpService.updateTestConfig(),
        }),
        [],
    );

    const getTestUser = () => {
        setIsTestUserLoading(true);
        doGetRequest(getTestConfigRequest.request, {
            successCallback: ({ Email, PhoneNumber, ClientId, TelegramChatId }) => {
                resetForm({
                    values: {
                        email: Email,
                        phoneNumber: PhoneNumber,
                        clientId: !isNil(ClientId) ? ClientId.toString() : '',
                        telegramChatId: TelegramChatId.toString(),
                    },
                });
            },
        }).then(() => {
            setIsTestUserLoading(false);
        });
    };

    const emailChangeHandler = (e) => {
        setFieldValue('email', e.target.value);
    };

    const phoneNumberChangeHandler = (e) => {
        setFieldValue('phoneNumber', e.target.value);
    };

    const clientIdChangeHandler = (e) => {
        setFieldValue('clientId', e.target.value);
    };

    const telegramChatIdChangeHandler = (e) => {
        setFieldValue('telegramChatId', e.target.value);
    };

    const init = () => {
        getTestUser();
    };

    const cleanUp = () => {
        return () => {
            getTestConfigRequest.cancel('TestUserSection:getTestConfigRequest');
            updateTestConfigRequest.cancel('TestUserSection:getTestConfigRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <Paper className="card" paperDirection="column">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name ellipsis-text">{t(l.TestUser)}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <div className="content">
                <Alert title={t(l.TestUserDescription)} iconProps={{ type: 'bc-icon-info' }} />
                <div className="fields-btn-group">
                    <div className="input-container">
                        <SkeletonLoader isBusy={isTestUserLoading}>
                            <ExtendedInput
                                value={values.email}
                                onChange={emailChangeHandler}
                                disabled={isSubmitting}
                                placeholder={t(l.Email)}
                                labelAppearance="swap"
                            />
                        </SkeletonLoader>
                    </div>
                    <div className="input-container">
                        <SkeletonLoader isBusy={isTestUserLoading}>
                            <ExtendedInput
                                value={values.phoneNumber}
                                onChange={phoneNumberChangeHandler}
                                disabled={isSubmitting}
                                placeholder={t(l.PhoneNumber)}
                                labelAppearance="swap"
                            />
                        </SkeletonLoader>
                    </div>
                    <div className="input-container">
                        <SkeletonLoader isBusy={isTestUserLoading}>
                            <ExtendedInput
                                value={values.clientId}
                                type="number"
                                onChange={clientIdChangeHandler}
                                disabled={isSubmitting}
                                placeholder={t(l.ClientId)}
                                labelAppearance="swap"
                            />
                        </SkeletonLoader>
                    </div>
                    <div className="input-container">
                        <SkeletonLoader isBusy={isTestUserLoading}>
                            <ExtendedInput
                                value={values.telegramChatId}
                                type="number"
                                onChange={telegramChatIdChangeHandler}
                                disabled={isSubmitting}
                                placeholder={t(l.TelegramClientId)}
                                labelAppearance="swap"
                            />
                        </SkeletonLoader>
                    </div>
                    <div className="btn-group">
                        <ResetSave
                            hasAdditionalSettings={false}
                            resetButtonClickHandler={resetForm}
                            disableSave={!dirty || isSubmitting}
                            disableReset={!dirty || isSubmitting}
                            saveButtonClickHandler={submitForm}
                            isLoadingSave={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        </Paper>
    );
};

export default memo(TestUserSection);
