/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isUndefined, isFunction, isString } from 'lodash';
import classNames from 'classnames';
// Import Components
import CustomTab from './CustomTab';
import { AccessControl } from 'components';
// Import SCSS
import 'assets/scss/customTabs.scss';

const CustomTabs = ({ tabsConfig, activeTabKey, tabChangeHandler, customCssClassNames, isValid, isDisabled }) => {
    const [activeKey, setActiveKey] = useState(activeTabKey);
    const isOpened = useRef(new Set([activeTabKey]));

    const tabClickHandler = (key, isExecutedExternally = false) => {
        return () => {
            if (key === activeKey) return;

            isOpened.current.add(key);

            if (isFunction(tabChangeHandler) && !isExecutedExternally) {
                tabChangeHandler(key);
            } else {
                setActiveKey(key);
            }
        };
    };

    useEffect(() => {
        if (activeTabKey !== activeKey) {
            tabClickHandler(activeTabKey, true)();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabKey]);

    const getTabKey = (tabKey, i) => {
        return isUndefined(tabKey) ? i : tabKey;
    };

    return (
        <>
            {!isEmpty(tabsConfig) && (
                <div
                    className={
                        !isEmpty(customCssClassNames) && isString(customCssClassNames)
                            ? customCssClassNames
                            : 'crm-custom-tabs-cnt'
                    }
                >
                    <ul
                        className={classNames('crm-custom-tabs-btn-panel', {
                            disabled: isDisabled,
                        })}
                    >
                        {tabsConfig.map(
                            (
                                {
                                    tabKey,
                                    tabTitle,
                                    hasSwitcher,
                                    switchChangeHandler,
                                    switcherChecked,
                                    isDisabled,
                                    permissions,
                                    partnerSetting,
                                },
                                i,
                            ) => (
                                <AccessControl permissions={permissions} partnerSetting={partnerSetting} key={i}>
                                    <CustomTab
                                        tabKey={isDisabled ? null : tabKey}
                                        tabTitle={tabTitle}
                                        hasSwitcher={hasSwitcher}
                                        switchChangeHandler={switchChangeHandler}
                                        switcherChecked={switcherChecked}
                                        getTabKey={getTabKey}
                                        activeKey={activeKey}
                                        tabClickHandler={tabClickHandler}
                                        index={i}
                                        isValid={isEmpty(isValid) || isValid[i]}
                                        isDisabled={isDisabled}
                                    />
                                </AccessControl>
                            ),
                        )}
                        <div className="tab-switcher" />
                    </ul>
                    <div className="crm-custom-tabs-content-wrapper">
                        <div className="crm-custom-tabs-content">
                            {tabsConfig.map(({ tabKey, tabContent, permissions, partnerSetting }, i) => {
                                return (
                                    <AccessControl permissions={permissions} partnerSetting={partnerSetting} key={i}>
                                        <div
                                            className={classNames('crm-tab-content', {
                                                active: getTabKey(tabKey, i) === activeKey,
                                            })}
                                        >
                                            {isOpened.current.has(getTabKey(tabKey, i)) && tabContent}
                                        </div>
                                    </AccessControl>
                                );
                            })}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

CustomTabs.propTypes = {
    tabsConfig: PropTypes.arrayOf(
        PropTypes.shape({
            tabKey: PropTypes.string,
            tabTitle: PropTypes.element.isRequired,
            tabContent: PropTypes.element.isRequired,
            permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        }),
    ).isRequired,
    activeTabKey: PropTypes.string,
    tabChangeHandler: PropTypes.func,
    customCssClassNames: PropTypes.string,
    isValid: PropTypes.array.isRequired,
    isDisabled: PropTypes.bool,
};

CustomTabs.defaultProps = {
    activeTabKey: '0',
    customCssClassNames: '',
    isValid: [],
    isDisabled: false,
};

export default memo(CustomTabs);
