import React, { useEffect, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
// Import Services
import { Helpers } from 'services';
import { TemplateType, getTemplateValidationTranslatableErrorText } from 'services/template';
import { TemplateHttpService } from 'services/http';
// Import Actions
import { TemplateActions } from 'actions';
// Import Hooks
import { useRequest, useValidator, useAutocompleteRequest } from 'hooks';
// Import Constants
import { l } from 'constants/common';
// Import SCSS
import 'assets/scss/socialNotification.scss';

const { calculateTemplateBodyLengthWithRegEx } = Helpers;
const { changeTemplateValue } = TemplateActions;

const SMSContent = ({
    bodyPropertyName,
    templateData,
    templateLangKey,
    maxSMSPartsCount,
    handleChange,
    shortenerAndTrackerSettings,
    updateDynamicVariableOptions,
    updateInsertionHandler,
    shouldFocusOnMount,
}) => {
    const { t } = useTranslation();
    const templateBody = templateData?.[bodyPropertyName];

    const pageValidation = useSelector((state) => state.pageValidation);
    const pageValidationForLanguage = pageValidation[templateLangKey + bodyPropertyName];
    const dispatch = useDispatch();
    const validator = useValidator(getTemplateValidationTranslatableErrorText(t));

    const [bodyValue, setBodyValue] = useState(templateBody);

    const inputRef = useRef(null);

    const { doGetRequest } = useRequest();
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    const { getSMSPartsCount } = useMemo(
        () => ({
            getSMSPartsCount: TemplateHttpService.getSMSPartsCount,
        }),
        [],
    );

    const computedBody = useMemo(() => {
        if (isEmpty(shortenerAndTrackerSettings) || isEmpty(templateBody)) return '';
        return calculateTemplateBodyLengthWithRegEx(shortenerAndTrackerSettings, templateBody);
    }, [templateBody, shortenerAndTrackerSettings]);

    const bodyValidationState = useMemo(() => {
        let isValid = isNil(pageValidationForLanguage?.isValid) || pageValidationForLanguage?.isValid;
        let errorText;

        if (!isValid) {
            errorText = t(pageValidationForLanguage?.errorText, {
                fieldName: l.Body,
                count: pageValidationForLanguage?.paramValue,
            });
        }

        const isValidSMSParts =
            isNil(pageValidation[templateLangKey + 'SMSParts']?.isValid) ||
            pageValidation[templateLangKey + 'SMSParts']?.isValid;

        if (!isValidSMSParts) {
            if (isValid) {
                errorText = t(pageValidation[templateLangKey + 'SMSParts']?.errorText);
            }
            isValid = false;
        }

        return {
            isValid,
            errorText,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageValidation, templateLangKey]);

    const onChange = (e) => {
        const value = e.target.value;

        setBodyValue(value);
        handleChange(bodyPropertyName, value);
    };

    const onFocusHandler = () => {
        updateDynamicVariableOptions();
    };

    const insertTextAtCursor = (textToInsert) => {
        const { selectionStart } = inputRef.current;
        const firstPart = bodyValue.slice(0, selectionStart);
        const secondPart = bodyValue.slice(selectionStart, bodyValue.length);
        const resultValue = `${firstPart} {${textToInsert}} ${secondPart}`;
        inputRef.current.setSelectionRange(resultValue.length, resultValue.length);
        inputRef.current.focus();

        setBodyValue(resultValue);
        handleChange(bodyPropertyName, resultValue);
    };

    updateInsertionHandler(bodyPropertyName, insertTextAtCursor);

    useEffect(() => {
        validator({ [templateLangKey + 'SMSParts']: templateData.SMSParts });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData.SMSParts]);

    useEffect(() => {
        if (isEmpty(computedBody)) {
            dispatch(changeTemplateValue({ propertyName: 'SMSParts', value: 1, templateLangKey }));
        } else {
            doGetRequest(getAutocompleteRequest(getSMSPartsCount).request, {
                successCallback: (data) =>
                    dispatch(changeTemplateValue({ propertyName: 'SMSParts', value: data, templateLangKey })),
                queryString: { message: computedBody },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [computedBody]);

    useEffect(() => {
        if (shouldFocusOnMount) {
            onFocusHandler();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cleanUp = () => () => cancelRequest('SMSTemplateContent:getSMSPartsCount');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <div className="crm-create-template-page-c-c-content">
            <div className="crm-messaging-row">
                <div className="crm-messaging-row-head">
                    <p>{t(l.CharactersCountIn, { pageName: TemplateType.SMS })}</p>
                    <span>{computedBody.length}</span>
                    <p>{t(l.SMSParts)}:</p>
                    <span>{`${isNil(templateData.SMSParts) ? 1 : templateData.SMSParts} / ${maxSMSPartsCount}`}</span>
                </div>

                <div className={`crm-messaging-row-content ${bodyValidationState.isValid ? '' : 'error-color'}`}>
                    <ExtendedInput
                        ref={inputRef}
                        type="textarea"
                        value={bodyValue}
                        colorBorderOnError={true}
                        isValid={bodyValidationState.isValid}
                        errorText={bodyValidationState.errorText}
                        placeholder={t(l.TypeYourText)}
                        onChange={onChange}
                        onFocus={onFocusHandler}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={shouldFocusOnMount}
                    />
                </div>
            </div>
        </div>
    );
};

SMSContent.propTypes = {
    bodyPropertyName: PropTypes.string.isRequired,
    templateData: PropTypes.object.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    maxSMSPartsCount: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    updateFocusedInput: PropTypes.func.isRequired,
    shortenerAndTrackerSettings: PropTypes.array.isRequired,
    updateDynamicVariableOptions: PropTypes.func.isRequired,
    updateInsertionHandler: PropTypes.func.isRequired,
    shouldFocusOnMount: PropTypes.bool.isRequired,
};

SMSContent.defaultProps = {
    shouldFocusOnMount: true,
};

export default SMSContent;
