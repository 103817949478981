import React, { memo, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, first, isNil, noop, random, values } from 'lodash';
// Import Validation
import { yupSchemaValidate } from 'validators/service.validator';
import { CJVisualizationStepIconValidationSchema } from 'validators/schemas.validation';
// Import Components
import {
    CustomerJourneyVisualizationTemplateGroup,
    OptionList,
    StepsSetting,
    CustomerJourneyVisualizationSubStep,
} from 'components';
// Import Services
import { newVisualizationStepNameGenerator, getModalCollectedProperties } from 'services/customerJourney';
import { Helpers } from 'services';
// Import Hooks
import { ExtendedInput, Tabs, Tab, Button, Icon, Section } from '@geneui/components';
// Import Constants
import { l, CJModelRelationScopes } from 'constants/common';

const { itemsIsValid } = Helpers;

const minSubStepsCount = 1;

const CustomerJourneyVisualizationStep = forwardRef(
    (
        {
            elements,
            getUpdate,
            defaultValue,
            iconsData,
            generateSubStep,
            stepNameGenerator,
            deleteStep,
            isEnabledDeleteStep,
        },
        ref,
    ) => {
        const iconsDataList = useRef();

        const [tabActiveKey, setTabActiveKey] = useState('0');
        const [isVisiblePopover, setIsVisiblePopover] = useState(false);
        const [searchValue, setSearchValue] = useState('');
        const [iconsDataSearchedList, setIconsSearchedDataList] = useState();
        const [selectedIconData, setSelectedIconData] = useState();
        const [iconValidationState, setIconValidationState] = useState({ isValid: true, errorText: null });
        const [showSubStepSetting, setShowSubStepSetting] = useState(false);

        // updatable states
        const [iconValue, setIconValue] = useState(defaultValue?.icon);
        const [templateData, setTemplateData] = useState(defaultValue?.templates);
        const [subStepsData, setSubStepsData] = useState(defaultValue?.subSteps || []);

        // get update only blur
        const [name, setName] = useState(defaultValue?.name);
        const [description, setDescription] = useState(defaultValue?.description);

        useEffect(() => {
            getUpdate({
                name: name,
                description: description,
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [name, description]);

        useEffect(() => {
            let errorText = null;
            const isValid = yupSchemaValidate(CJVisualizationStepIconValidationSchema(), { icon: iconValue }, (e) => {
                errorText = t(e?.message?.label, { fieldName: t(e?.message?.fieldName) });
            });
            setIconValidationState({ isValid, errorText });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [iconValue]);

        useEffect(() => {
            const listData = iconsData?.data?.map((item) => ({
                classNameValue: item.label,
                className: <span>{item.label}</span>,
                value: item.value,
                icon: (
                    <span className="crm-option-icon-column">
                        <span style={{ fontFamily: iconsData?.fontFamily }}>
                            <span className={item.label}></span>
                        </span>
                    </span>
                ),
            }));
            iconsDataList.current = listData;
            setIconsSearchedDataList(listData);
            setSelectedIconData(
                isNil(listData) ? null : first(listData.filter((item) => item.value === defaultValue?.icon)),
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [iconsData]);

        const { t } = useTranslation();

        const nameChangeHandler = (e) => {
            setName(e.target.value);
        };

        const descriptionChangeHandler = (e) => {
            setDescription(e.target.value);
        };

        const nameBlurHandler = () => {
            const tmpName = stepNameGenerator(name);
            setName(tmpName);
        };

        const descriptionBlurHandler = () => {
            setDescription(description);
        };

        const popoverToggler = () => {
            setIsVisiblePopover(!isVisiblePopover);
        };

        const searchIconHandler = ({ target }) => {
            setSearchValue(target.value);
            setIconsSearchedDataList(() =>
                iconsDataList.current.filter((item) =>
                    item.classNameValue.toLowerCase().includes(target.value.toLowerCase()),
                ),
            );
        };

        const selectIconHandler = (data) => {
            setSelectedIconData(data);
            setIconValue(data?.value);
            setIsVisiblePopover(false);
        };

        const iconClearClickHandler = () => {
            selectIconHandler(null);
        };

        const getSelectedIconDataLabel = (selectedIconData) => {
            if (!isNil(selectedIconData?.className) && !isNil(selectedIconData?.icon)) {
                return (
                    <div className="option-icon-value">
                        {selectedIconData?.icon}
                        {selectedIconData?.className}
                    </div>
                );
            }
        };

        useEffect(() => {
            const isStepValid =
                itemsIsValid(values(templateData)) &&
                yupSchemaValidate(CJVisualizationStepIconValidationSchema(), { icon: iconValue }) &&
                itemsIsValid(subStepsData);
            getUpdate({
                isValid: isStepValid,
                icon: iconValue,
                templates: templateData,
                subSteps: subStepsData,
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [templateData, iconValue, subStepsData]);

        const getNewRenderKey = (item = { renderKey: -1 }) => {
            let tmpNewRenderKey = random(0, 10000);
            while (item?.renderKey === tmpNewRenderKey) {
                tmpNewRenderKey = random(0, 10000);
            }
            return tmpNewRenderKey;
        };

        const changeTemplateLanguages = (templatesValue, languages, partnerLanguages) => {
            return languages.reduce(
                (acc, language) => {
                    if (isNil(templatesValue[language])) {
                        acc[language] = {
                            name: '',
                            description: '',
                            language: { flagId: language, label: partnerLanguages[language]?.Name },
                        };
                    } else {
                        acc[language] = templatesValue[language];
                    }
                    return acc;
                },
                { defaultLang: templatesValue.defaultLang },
            );
        };

        useImperativeHandle(ref, () => ({
            changeLanguages(languages, partnerLanguages) {
                setTemplateData((prev) => {
                    return changeTemplateLanguages(prev, languages, partnerLanguages);
                });
                setSubStepsData((prev) => {
                    return prev.map((subStep) => {
                        return {
                            ...subStep,
                            renderKey: getNewRenderKey(),
                            templates: changeTemplateLanguages(subStep.templates, languages, partnerLanguages),
                        };
                    });
                });
            },
        }));

        const templateChangeHandler = (languageKey, data) => {
            setTemplateData((prev) => {
                return { ...prev, [languageKey]: { ...prev[languageKey], ...data } };
            });
        };

        const tabChangeHandler = (index) => {
            setTabActiveKey(`${index}`);
        };

        const addSubStepClickHandler = () => {
            setSubStepsData((prev) => {
                const result = cloneDeep(prev);
                const steps = cloneDeep(prev);
                steps.splice(0, 1);
                result.push(generateSubStep(result.length, steps));
                return result;
            });
        };

        const subStepUpdateHandler = (index) => {
            return (stepData) => {
                setSubStepsData((prev) => {
                    const result = cloneDeep(prev);
                    result[index] = { ...prev[index], ...stepData };
                    return result;
                });
            };
        };

        const subStepNameGenerator = (subSteps, index) => {
            return (name) => {
                const steps = cloneDeep(subSteps);
                steps.splice(index, 1);
                return newVisualizationStepNameGenerator(t, name, steps, l.NewSubStep);
            };
        };

        const getCollectedProperties = (oneLastBlock) => {
            return oneLastBlock ? getModalCollectedProperties(CJModelRelationScopes.All, oneLastBlock) : [];
        };

        const deleteSubStepHandler = (index) => {
            return (e) => {
                e.stopPropagation();
                if (index === +tabActiveKey) {
                    setTabActiveKey('0');
                } else if (index < +tabActiveKey) {
                    setTabActiveKey(`${tabActiveKey - 1}`);
                }
                setSubStepsData((prev) => {
                    const result = cloneDeep(prev);
                    result.splice(index, 1);
                    result.forEach((item, i) => {
                        item.key = i;
                    });
                    return result;
                });
            };
        };

        const subStepSettingClickHandler = () => {
            setShowSubStepSetting(true);
        };

        const subStepSettingCloseHandler = () => {
            setShowSubStepSetting(false);
        };

        const subStepSettingOkHandler = (data) => {
            setSubStepsData((prev) => {
                const tmpSubStepsData = cloneDeep(prev).reduce((acc, subStepData) => {
                    acc[subStepData.key] = subStepData;
                    return acc;
                }, {});

                return [
                    ...data.map((item) => {
                        const tmpSubStep = tmpSubStepsData[item.id];
                        tmpSubStep.key = item.index;
                        return tmpSubStep;
                    }),
                ];
            });
            setShowSubStepSetting(false);
        };

        const getSubStepsSettingsItems = (subStepsData) => {
            return subStepsData.map((item, index) => ({ index: index, name: item.name, id: item.key }));
        };

        return (
            <div className="crm-cj-visualization-step">
                <Section
                    text={
                        <p className="crm-cj-visualization-step-subtitle">{`${t(l.Step)} ${
                            defaultValue.key
                        } - ${name}`}</p>
                    }
                    actions={
                        <Button
                            icon="bc-icon-delete-2"
                            appearance="minimal"
                            onClick={deleteStep}
                            disabled={!isEnabledDeleteStep}
                        />
                    }
                >
                    <div>
                        <div className="crm-cj-visualization-step-block">
                            <div className="c-input-group-wrapper">
                                <ExtendedInput
                                    appearance="outline"
                                    inputSize="default"
                                    labelAppearance="swap"
                                    placeholder={t(l.Name)}
                                    onChange={nameChangeHandler}
                                    onBlur={nameBlurHandler}
                                    value={isNil(name) ? '' : name}
                                />
                                <ExtendedInput
                                    appearance="outline"
                                    inputSize="default"
                                    labelAppearance="swap"
                                    placeholder={t(l.Description)}
                                    onChange={descriptionChangeHandler}
                                    onBlur={descriptionBlurHandler}
                                    value={isNil(description) ? '' : description}
                                />
                            </div>
                            <div className="crm-cj-visualization-step-dropdown">
                                <OptionList
                                    optionLabel={getSelectedIconDataLabel(selectedIconData)}
                                    toggleHandler={popoverToggler}
                                    isVisible={isVisiblePopover}
                                    setIsVisible={setIsVisiblePopover}
                                    keys={['icon', 'className']}
                                    titles={[t(l.Icon), t(l.ClassName)]}
                                    selectedPropertyKey="value"
                                    list={iconsDataSearchedList}
                                    rowCount={iconsDataSearchedList?.length}
                                    searchHandler={searchIconHandler}
                                    optionValue={iconValue}
                                    optionSelectHandler={selectIconHandler}
                                    contentTop={45}
                                    contentWrapperClassName="cj-option-list-content-wrapper"
                                    clearable={true}
                                    onClear={iconClearClickHandler}
                                    searchValue={searchValue}
                                    isLoading={isNil(iconsDataSearchedList)}
                                    isLoadingList={isNil(iconsDataSearchedList)}
                                    isValid={iconValidationState.isValid}
                                    errorText={iconValidationState.errorText}
                                />
                            </div>
                        </div>
                        <CustomerJourneyVisualizationTemplateGroup
                            templates={templateData}
                            onChange={templateChangeHandler}
                        />

                        <Tabs
                            type="button"
                            activeKey={tabActiveKey}
                            actions={
                                <div className="substep-tabs-actions-wrapper">
                                    <button onClick={addSubStepClickHandler} className="add-translation-btn">
                                        <i className={'bc-icon-profit'} />
                                        <span>{t(l.AddSubStep)}</span>{' '}
                                    </button>
                                    <Button
                                        icon="bc-icon-settings"
                                        appearance="minimal"
                                        onClick={subStepSettingClickHandler}
                                    />
                                </div>
                            }
                        >
                            {subStepsData.map((subStep, index) => {
                                return (
                                    <Tab
                                        key={index}
                                        className={`${+tabActiveKey === index ? 'active' : ''} ${
                                            subStep.isValid ? '' : 'invalid-tab'
                                        }`}
                                        title={
                                            <button onClick={() => tabChangeHandler(index)}>
                                                <p>{subStep.name}</p>
                                                {subStepsData.length > minSubStepsCount && (
                                                    <Icon
                                                        type="bc-icon-clear-small"
                                                        onClick={deleteSubStepHandler(index)}
                                                    />
                                                )}
                                            </button>
                                        }
                                    >
                                        <CustomerJourneyVisualizationSubStep
                                            collectedProperties={getCollectedProperties(subStep.node)}
                                            node={subStep.node}
                                            elements={elements}
                                            option={subStep.option}
                                            key={`CustomerJourneyVisualizationSubStep_${subStep.renderKey}`}
                                            defaultValue={subStep}
                                            getUpdate={subStepUpdateHandler(index)}
                                            stepNameGenerator={subStepNameGenerator(subStepsData, index)}
                                        />
                                    </Tab>
                                );
                            })}
                        </Tabs>
                        {showSubStepSetting && (
                            <StepsSetting
                                isVisible={showSubStepSetting}
                                onCancel={subStepSettingCloseHandler}
                                onOk={subStepSettingOkHandler}
                                steps={getSubStepsSettingsItems(subStepsData)}
                                columnName="SubStep"
                            />
                        )}
                    </div>
                </Section>
            </div>
        );
    },
);

CustomerJourneyVisualizationStep.displayName = 'CustomerJourneyVisualizationStep';

CustomerJourneyVisualizationStep.propTypes = {
    defaultValue: PropTypes.shape({
        key: PropTypes.string.isRequired,
        templates: PropTypes.object,
        icon: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        subSteps: PropTypes.array,
    }),
    iconsData: PropTypes.shape({
        fontFamily: PropTypes.string,
        data: PropTypes.array,
    }),
    node: PropTypes.object,
    elements: PropTypes.array,
    option: PropTypes.object,
    stepNameGenerator: PropTypes.func.isRequired,
    collectedProperties: PropTypes.array,
    getUpdate: PropTypes.func,
    addTemplateValidationProperties: PropTypes.func,
    removeTemplateValidationProperties: PropTypes.func,
    generateSubStep: PropTypes.func,
    deleteStep: PropTypes.func,
    isEnabledDeleteStep: PropTypes.bool,
};

CustomerJourneyVisualizationStep.defaultProps = {
    defaultValue: {
        templates: {},
        name: '',
        description: '',
    },
    iconsData: {},
    collectedProperties: [],
    node: null,
    elements: null,
    option: null,
    getUpdate: noop,
    addTemplateValidationProperties: noop,
    removeTemplateValidationProperties: noop,
};

export default memo(CustomerJourneyVisualizationStep);
