export enum ABTestStatusEnum {
    Pending = 1,
    InProgress = 2,
    Failed = 3,
    Completed = 4,
    Stopped = 5,
    Calculating = 6,
}

export enum SegmentABMetricStatus {
    Pending = 1,
    InProgress = 2,
    Completed = 3,
    Failed = 4,
}

export const ABTestStatusLabels = {
    [ABTestStatusEnum.Pending]: 'Pending',
    [ABTestStatusEnum.InProgress]: 'InProgress',
    [ABTestStatusEnum.Failed]: 'Failed',
    [ABTestStatusEnum.Completed]: 'Completed',
    [ABTestStatusEnum.Stopped]: 'Stopped',
    [ABTestStatusEnum.Calculating]: 'Calculating',
};
