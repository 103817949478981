import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { isNil, noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { CJIntegerValidationSchema } from 'validators/schemas.validation';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
// Import Components
import CJMenuItemDropdown from './CJMenuItemDropDown';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText, getDoubleValue } from 'services/customerJourney';

const CJInteger = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const { CustomAttributes } = option;
    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
        },
        onSubmit: noop,
        validationSchema: CJIntegerValidationSchema(option),
    });
    const { values, setFieldValue, errors, submitForm } = formik;
    const { value } = values;

    useEffect(() => {
        getUpdate({ isValid: !errors?.value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    const onChangeHandler = ({ target }) => {
        const replacedValue = target?.value.replace('.', '');
        const tempValue = getDoubleValue(replacedValue);
        setFieldValue('value', tempValue);
        getUpdate({ value: tempValue });
    };

    useEffect(() => {
        submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isNil(CustomAttributes?.MenuItemId)) {
        return (
            <CJMenuItemDropdown
                defaultValue={defaultValue}
                option={option}
                getUpdate={getUpdate}
                isMultiselect={false}
                extendTargetWidth={true}
            />
        );
    } else {
        return (
            <ExtendedInput
                type="number"
                placeholder={t(CustomAttributes?.DisplayName)}
                label={t(CustomAttributes?.DisplayName)}
                labelAppearance="swap"
                onChange={onChangeHandler}
                value={value}
                isValid={!errors?.value}
                errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
            />
        );
    }
};

CJInteger.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJInteger.defaultProps = {
    defaultValue: { value: null },
};

export default memo(CJInteger);
