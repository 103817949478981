import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { isNil } from 'lodash';
// Import UI Components
import { BarChart, Icon, Tooltip } from '@geneui/components';
// Import Types
import { ABTestChartContainerProps } from './types';
// Import Constants
import { DangerConfidencePercentage, l } from 'constants/common';

const ABTestChartContainer: FC<ABTestChartContainerProps> = ({
    titleKey,
    pilotGroup,
    controlGroup,
    impactPercentage,
    confidencePercentage,
}) => {
    const { t } = useTranslation();

    const isDangerZone = confidencePercentage !== null && confidencePercentage < DangerConfidencePercentage;
    const impact = isNil(impactPercentage) ? t(l.NA) : `${impactPercentage}%`;
    const confidence = isNil(confidencePercentage) ? t(l.NA) : `${confidencePercentage}%`;

    return (
        <div className="details-section">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name">{t(l[`TestMetric${titleKey}`])}</div>
                            <Tooltip text={t(l[`TestMetric${titleKey}Description`])} position="bottom">
                                <Icon type="bc-icon-info" />
                            </Tooltip>
                        </div>
                    </div>
                </li>
            </ul>
            <div className="general-details-content">
                <BarChart
                    chart={{
                        type: 'column',
                    }}
                    series={[
                        { name: t(l.PilotGroup), color: '#BB3F3A', data: [pilotGroup] },
                        { name: t(l.ControlGroup), color: '#1473E6', data: [controlGroup] },
                    ]}
                    categories={['']}
                />
                <div className="percentage-info-section">
                    <div>
                        <p className="g-details-title">{t(l.ImpactPercentage)}</p>
                        <p
                            className={classNames('g-details-subtitle', {
                                'percentage-danger-zone': !isNil(impactPercentage) && isDangerZone,
                                'percentage-normal-zone': !isNil(impactPercentage) && !isDangerZone,
                            })}
                        >
                            {impact}
                        </p>
                    </div>
                    <div>
                        <p className="g-details-title">{t(l.ConfidencePercentage)}</p>
                        <p
                            className={classNames('g-details-subtitle', {
                                'percentage-danger-zone': !isNil(confidencePercentage) && isDangerZone,
                                'percentage-normal-zone': !isNil(confidencePercentage) && !isDangerZone,
                            })}
                        >
                            {confidence}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(ABTestChartContainer);
