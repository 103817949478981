//Import Components
import MenuItemInfoValue from './MenuItemInfoValue';
//Import Constants
import {
    l,
    ReportsResultStatus,
    DateTimeWithoutSecondsFormat,
    ExecutedReportParametersDataTypes,
} from 'constants/common';
//Import Services
import { Helpers } from 'services';
const { customMomentWithoutTimezoneConversion } = Helpers;

const downloadFileTypes = {
    xlsx: {
        fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        fileExtension: '.xlsx',
    },
    pdf: {
        fileType: 'application/pdf',
        fileExtension: '.pdf',
    },
    csv: {
        fileType: 'text/csv',
        fileExtension: '.csv',
    },
};

const InfoItemsConfig = (t) => {
    return {
        generalDetailsInfoItemsConfig: {
            title: t(l.GeneralDetails),
            keyValueItemsData: {
                version: {
                    label: t(l.Version),
                },
                user: {
                    label: t(l.User),
                },
                executionDate: {
                    label: t(l.ExecutionDate),
                    format: (executionDate) =>
                        customMomentWithoutTimezoneConversion(executionDate).format(DateTimeWithoutSecondsFormat),
                },
                executionDuration: {
                    label: t(l.ExecutionDuration),
                    format: (executionDuration) => {
                        return executionDuration < 1
                            ? t(l.ReportsResultGridTextLessThan)
                            : executionDuration.toFixed(2) + ' ' + t(l.Minute);
                    },
                },
                status: {
                    label: t(l.Status),
                    format: (status) => ReportsResultStatus[status],
                },
            },
        },
    };
};

const parametersInfoConfig = (t) => {
    return {
        parametersInfoItemsConfig: {
            Date: {
                dataType: ExecutedReportParametersDataTypes.Date,
                Component: ({ value }) =>
                    customMomentWithoutTimezoneConversion(value).format(DateTimeWithoutSecondsFormat),
            },
            Int: {
                dataType: ExecutedReportParametersDataTypes.Int,
                Component: ({ value }) => value ?? t(l.NA),
            },
            String: {
                dataType: ExecutedReportParametersDataTypes.String,
                Component: ({ value }) => value ?? t(l.NA),
            },
            MenuItem: {
                dataType: ExecutedReportParametersDataTypes.MenuItem,
                Component: MenuItemInfoValue,
            },
        },
    };
};

export { downloadFileTypes, InfoItemsConfig, parametersInfoConfig };
