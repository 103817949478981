import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const gtag = (...rest) => {
    window.dataLayer.push(rest);
};

const useGAPageView = (pageTitle) => {
    const location = useLocation();

    useEffect(() => {
        gtag('event', 'page_view', {
            page_path: location.pathname,
            page_title: pageTitle,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

const useGACustomEvent = () => {
    const logCustomEvent = useCallback((eventCategory, eventAction, eventLabel, eventValue) => {
        gtag('event', eventAction, {
            event_category: eventCategory,
            event_label: eventLabel,
            value: eventValue,
        });
    }, []);

    return { logCustomEvent };
};

export { useGAPageView, useGACustomEvent };
