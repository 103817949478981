import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty, noop } from 'lodash';
import { CJPartnerBonusIdValidationSchema } from 'validators/schemas.validation';
// Import Components
import { ButtonWithLoader, OptionList } from 'components';
// Import Services
import { BonusHttpService } from 'services/http';
import { Helpers } from 'services';
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Hooks
import { useRequest, useAutocompleteRequest } from 'hooks';
// Import Constants
import { DateTimeWithoutSecondsFormat, l, BonusTypesLabels, CustomerJourneyErrorCodes } from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;

const CJPartnerBonusId = ({ defaultValue, getUpdate, option, updateDependency, setBonusDetails }) => {
    const { t } = useTranslation();
    const { doPostRequest, doGetRequest } = useRequest();
    const { CustomAttributes } = option;
    const { SupportedBonusTypes = [] } = CustomAttributes;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [listData, setListData] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [selectedBonusData, setSelectedBonusData] = useState({
        Id: defaultValue?.value,
        Name: !isNil(defaultValue?.value) ? `${defaultValue.value}` : null,
    });
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);
    const [isBonusByIdRequestFailed, setIsBonusByIdRequestFailed] = useState(false);
    const [isBonusByIdInitFailed, setIsBonusByIdInitFailed] = useState(false);

    const { getAutocompleteRequest: getAutocompleteBonusListRequest, cancelRequest: cancelGetBonusList } =
        useAutocompleteRequest();
    const { getAutocompleteRequest: getAutocompleteBonusByIdRequest, cancelRequest: cancelGetBonusById } =
        useAutocompleteRequest();

    const { getBonusList, getBonusById } = useRef({
        getBonusList: BonusHttpService.getBonusList,
        getBonusById: BonusHttpService.getBonusById,
    }).current;

    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
            triggerType: defaultValue?.triggerType,
        },
        onSubmit: noop,
        validationSchema: CJPartnerBonusIdValidationSchema(option),
    });
    const { values, setFieldValue, setValues, errors, setFieldError, submitForm } = formik;
    const { value, triggerType } = values;

    useEffect(() => {
        getUpdate({ value, triggerType });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    // TODO: config
    const bonusListGetterMethod = (searchValue = '') => {
        setIsLoadingList(true);
        const getSearchValueType = (value) => {
            return /^( )*\d+( )*$/.test(value) ? 'Id' : 'Name';
        };

        const requestObj = getAutocompleteBonusListRequest(getBonusList);

        doPostRequest(requestObj.request, {
            requestBody: {
                Filters: [
                    { Name: 'TargetObject', Comparision: 0, Values: [0] },
                    { Name: getSearchValueType(searchValue), Comparision: 2, Values: [searchValue] },
                    { Name: 'Type', Comparision: 8, Values: [...SupportedBonusTypes] },
                ],
                Pageing: { PageSize: 100, PageNumber: 1 },
                Sorting: { Name: 'StartDate', Direction: 'desc' },
            },
            successCallback: (data) => {
                setListData(
                    !isNil(data?.Data)
                        ? data.Data?.map((item) => ({
                              ...item,
                              formattedStartDate: customMomentWithoutTimezoneConversion(item.StartDate).format(
                                  DateTimeWithoutSecondsFormat,
                              ),
                              formattedEndDate: customMomentWithoutTimezoneConversion(item.EndDate).format(
                                  DateTimeWithoutSecondsFormat,
                              ),
                              bonusType: BonusTypesLabels[item.Type],
                          }))
                        : [],
                );
                setIsLoadingList(false);
            },
            errorCallback: () => setIsLoadingList(false),
        });
    };

    const searchBonusHandler = ({ target }) => {
        setSearchValue(target.value);
        bonusListGetterMethod(target.value);
    };

    const init = ({ shouldRestoreSavedDataWhenBonusDetailsReloaded } = {}) => {
        submitForm();
        bonusListGetterMethod('');

        if (!isNil(defaultValue?.value) && !isEmpty(defaultValue.value.toString())) {
            setIsLoading(true);
            const getBonusByIdRequest = getAutocompleteBonusByIdRequest(getBonusById);
            setBonusDetails(
                new Promise((resolve, reject) => {
                    doGetRequest(getBonusByIdRequest.request, {
                        queryString: { bonusId: defaultValue.value },
                        successCallback: (responseData) => {
                            setIsBonusByIdRequestFailed(false);
                            updateDependency({ ...responseData, dependencyItemId: responseData.Id });
                            setSelectedBonusData(responseData);
                            setIsLoading(false);
                            resolve(responseData);
                        },
                        errorCallback: () => {
                            setIsBonusByIdRequestFailed(true);
                            setIsBonusByIdInitFailed(true);
                            setFieldError('value', CustomerJourneyErrorCodes.bonusDetailsGettingError);
                            setIsLoading(false);
                            reject();
                        },
                    });
                }),
                shouldRestoreSavedDataWhenBonusDetailsReloaded,
            );
        }
    };

    const cleanUp = () => {
        return () => {
            cancelGetBonusById('CJPartnerBonusId:getBonusById');
            cancelGetBonusList('CJPartnerBonusId:getBonusList');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    const popoverToggler = () => {
        setIsVisiblePopover(!isVisiblePopover);
    };

    const selectBonusHandler = (selectionData) => {
        const { shouldRestoreSavedDataWhenBonusDetailsReloaded, ...data } = selectionData;
        const selectedOldBonusId = selectedBonusData.Id;
        setIsLoading(true);
        setSelectedBonusData(data);

        const getBonusByIdRequest = getAutocompleteBonusByIdRequest(getBonusById);

        setBonusDetails(
            new Promise((resolve, reject) => {
                doGetRequest(getBonusByIdRequest.request, {
                    queryString: { bonusId: data.Id },
                    successCallback: (responseData) => {
                        setIsBonusByIdRequestFailed(false);
                        setValues({ value: responseData?.Id, triggerType: responseData?.TriggerType });
                        updateDependency({ ...responseData, dependencyItemId: responseData?.Id });
                        setIsLoading(false);
                        resolve(responseData);
                    },
                    errorCallback: () => {
                        setIsBonusByIdInitFailed(false);
                        setIsBonusByIdRequestFailed(true);

                        if (!isNil(selectedOldBonusId)) {
                            updateDependency({
                                dependencyItemId: selectedOldBonusId,
                                shouldRemoveDependency: true,
                            });
                        }

                        setFieldValue('value', '', false);
                        setFieldValue('triggerType', undefined, false);
                        setFieldError('value', CustomerJourneyErrorCodes.bonusDetailsGettingError);
                        setIsLoading(false);
                        reject();
                    },
                });
            }),
            shouldRestoreSavedDataWhenBonusDetailsReloaded,
        );

        setIsVisiblePopover(false);
    };

    const handleReloadBonusData = () => {
        if (isBonusByIdInitFailed) {
            init({ shouldRestoreSavedDataWhenBonusDetailsReloaded: true });
        } else {
            selectBonusHandler({ ...selectedBonusData, shouldRestoreSavedDataWhenBonusDetailsReloaded: true });
        }
    };

    return (
        <>
            <OptionList
                optionLabel={selectedBonusData?.Name}
                toggleHandler={popoverToggler}
                isVisible={isVisiblePopover}
                setIsVisible={setIsVisiblePopover}
                keys={['Name', 'formattedStartDate', 'formattedEndDate', 'bonusType', 'Id']}
                titles={[t(l.BonusName), t(l.StartDate), t(l.EndDate), t(l.Type), t(l.ID)]}
                selectedPropertyKey="Id"
                list={listData}
                rowCount={listData.length}
                searchHandler={searchBonusHandler}
                optionValue={value}
                optionSelectHandler={selectBonusHandler}
                contentTop={45}
                contentWrapperClassName="cj-option-list-content-wrapper"
                searchValue={searchValue}
                isLoading={isLoading}
                isLoadingList={isLoadingList}
                isValid={!errors.value}
                errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
            />
            {isBonusByIdRequestFailed && (
                <div>
                    <ButtonWithLoader
                        isLoading={isLoading}
                        color="primary"
                        defaultClassNames={classNames('crm-modal-reload-action-btn', {
                            'crm-modal-reload-action-loading': isLoading,
                        })}
                        onClick={handleReloadBonusData}
                    >
                        {t(l.ReloadData)}
                    </ButtonWithLoader>
                </div>
            )}
        </>
    );
};

CJPartnerBonusId.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
    updateDependency: PropTypes.func.isRequired,
    setBonusDetails: PropTypes.func,
};

CJPartnerBonusId.defaultProps = {
    defaultValue: { value: '' },
    setBonusDetails: noop,
};

export default memo(CJPartnerBonusId);
