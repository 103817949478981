import React, { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isNil, noop, values as objectValues } from 'lodash';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { ProviderHttpService } from 'services/http';
// Import UI Components
import { Alert, Button, ExtendedInput, SkeletonLoader } from '@geneui/components';
//Import Components
import { ResetSave } from 'components';
//Import Constants
import { l, AlertTypes, PushNotificationProviderKeys } from 'constants/common';

const { success } = AlertTypes;

const OneSignalSettingsContent = ({ isDefault, isLoading, initialData, onRefetchData, onSetDefault }) => {
    const { t } = useTranslation();

    const { doPostRequest } = useRequest();
    const { showToaster } = useToaster();

    const isInitialDataEmpty = objectValues(initialData).every((value) => isNil(value));

    const { saveOneSignalSettingRequest } = useMemo(
        () => ({
            saveOneSignalSettingRequest: ProviderHttpService.setOneSignalSetting(),
        }),
        [],
    );

    const formik = useFormik({
        initialValues: {
            appId: initialData.appId ?? '',
            appKey: initialData.appKey ?? '',
        },
        validationSchema: Yup.object({
            appId: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.AppId) })),
            appKey: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.AppKey) })),
        }),
        onSubmit: (values) => {
            doPostRequest(saveOneSignalSettingRequest.request, {
                requestBody: { appKey: values.appKey, appId: values.appId },
                successCallback: () => {
                    onRefetchData();
                    showToaster(success, t(l.OneSignalSettingsSavedSuccessfully));
                },
            });
        },
    });

    const { values, errors, dirty, isSubmitting, isValid, setFieldValue, submitForm, resetForm } = formik;

    useEffect(() => {
        if (initialData) {
            resetForm({
                values: {
                    appId: initialData.appId ?? '',
                    appKey: initialData.appKey ?? '',
                },
            });
        }
    }, [initialData, resetForm]);

    const oneSignalAppIdInputChangeHandler = (e) => {
        setFieldValue('appId', e.target.value);
    };

    const oneSignalAppKeyInputChangeHandler = (e) => {
        setFieldValue('appKey', e.target.value);
    };

    const onSetDefaultHandler = () => {
        onSetDefault(PushNotificationProviderKeys.OneSignal);
    };

    const cleanUp = () => {
        return () => {
            saveOneSignalSettingRequest.cancel('PushNotificationSettingsSection:saveOneSignalSettingRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <div className="crm-push-notification-providers-settings-content">
            <Alert closable={false} title={t(l.OneSignalProviderDescription)} iconProps={{ type: 'bc-icon-info' }} />
            <div className="fields-btn-group">
                <div className="input-container">
                    <SkeletonLoader isBusy={isLoading}>
                        <ExtendedInput
                            value={values.appKey}
                            onChange={oneSignalAppKeyInputChangeHandler}
                            disabled={isSubmitting}
                            placeholder={t(l.AppKey)}
                            isValid={!errors.appKey}
                            errorText={errors.appKey}
                            type="password"
                        />
                    </SkeletonLoader>
                </div>
                <div className="input-container">
                    <SkeletonLoader isBusy={isLoading}>
                        <ExtendedInput
                            value={values.appId}
                            onChange={oneSignalAppIdInputChangeHandler}
                            disabled={isSubmitting}
                            placeholder={t(l.AppId)}
                            isValid={!errors.appId}
                            errorText={errors.appId}
                        />
                    </SkeletonLoader>
                </div>
                <div className="push-notification-settings-content-action-buttons-container">
                    <Button
                        appearance="outline"
                        onClick={onSetDefaultHandler}
                        disabled={isDefault || isInitialDataEmpty}
                    >
                        {t(l.SetDefault)}
                    </Button>

                    <ResetSave
                        hasAdditionalSettings={false}
                        resetButtonClickHandler={resetForm}
                        disableSave={!isValid || !dirty || isSubmitting}
                        disableReset={!dirty || isSubmitting}
                        saveButtonClickHandler={submitForm}
                        isLoadingSave={isSubmitting}
                    />
                </div>
            </div>
        </div>
    );
};

OneSignalSettingsContent.propTypes = {
    isDefault: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    initialData: PropTypes.shape({
        appId: PropTypes.string.isRequired,
        appKey: PropTypes.string.isRequired,
    }),
    onRefetchData: PropTypes.func.isRequired,
    onSetDefault: PropTypes.func.isRequired,
};

OneSignalSettingsContent.defaultProps = {
    isDefault: false,
    isLoading: false,
    onRefetchData: noop,
    onSetDefault: noop,
};

export default memo(OneSignalSettingsContent);
