import React, { useEffect, memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { noop } from 'lodash';
// Import Hooks
import { useRequest, useGoToRoute } from 'hooks';
//Import UI Components
import { Modal, Button } from '@geneui/components';
//Import Components
import CJVisualizationInfo from 'components/CustomerJourney/CJVisualizationInfo';
import ActivityTimeLine from '../CustomerJourney/ActivityTimeLine';
import { AccessControl, CustomerJourneyGeneralDetails, CustomTabs } from 'components';
//Import Constants
import {
    DependencyObjectTypeLabels,
    ModalsActions,
    l,
    RealtimePromotionTypes,
    CustomerJourneyInfoModalTitleLabels,
    PERMISSIONS,
} from 'constants/common';
import { RoutesList } from 'routes';
// Import Services
import { CustomerJourneyHttpService, UtilsHttpService } from 'services/http';
//Import SCSS
import 'assets/scss/customerJourneyInfoModal.scss';

const { REALTIME_JOURNEY_CLONE, REALTIME_JOURNEY_PREDEFINED, REALTIME_ACTION_CLONE } = RoutesList;

const CustomerJourneyInfoModal = ({
    workflowId,
    handleClose,
    isVisible,
    isPredefined,
    hasVisualization,
    promotionType,
}) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingUsedIn, setIsLoadingUsedIn] = useState(false);
    const [generalInfoData, setGeneralInfoData] = useState({});
    const [usedIn, setUsedIn] = useState(null);

    const { goToRoute } = useGoToRoute();
    const { doGetRequest } = useRequest();

    const { getCustomerJourneyGeneralDetailsRequest, getObjectDependencies } = useMemo(
        () => ({
            getCustomerJourneyGeneralDetailsRequest: CustomerJourneyHttpService.getCustomerJourneyGeneralDetails(),
            getObjectDependencies: UtilsHttpService.getObjectDependencies(),
        }),
        [],
    );

    const getCustomerJourneyGeneralDetails = () => {
        setIsLoading(true);
        doGetRequest(getCustomerJourneyGeneralDetailsRequest.request, {
            queryString: { workflowId },
            successCallback: (Data) => {
                setGeneralInfoData(Data ?? {});
            },
        }).then(() => {
            setIsLoading(false);
        });
    };

    const getCustomerJourneyUsedIn = () => {
        setIsLoadingUsedIn(true);
        doGetRequest(getObjectDependencies.request, {
            queryString: { objectId: workflowId, dependencyType: DependencyObjectTypeLabels.Workflow },
            successCallback: (Data) => {
                setUsedIn(Data);
            },
        }).then(() => {
            setIsLoadingUsedIn(false);
        });
    };

    const cloneClickHandler = () => {
        const params = {
            workflowId,
        };
        const route = isPredefined
            ? REALTIME_JOURNEY_PREDEFINED
            : promotionType === +RealtimePromotionTypes.ActionCampaign
            ? REALTIME_ACTION_CLONE
            : REALTIME_JOURNEY_CLONE;

        goToRoute(route, params);
        handleClose(ModalsActions.CLONE);
    };

    const init = () => {
        getCustomerJourneyGeneralDetails();
        getCustomerJourneyUsedIn();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    const cleanUp = () => {
        return () => {
            getCustomerJourneyGeneralDetailsRequest.cancel(
                'CustomerJourneyInfoModal:getCustomerJourneyGeneralDetailsRequest',
            );
            getObjectDependencies.cancel('CustomerJourneyInfoModal:getObjectDependencies');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    const tabsConfig = [
        {
            tabTitle: t(l.GeneralInfo),
            tabKey: '0',
            tabContent: (
                <CustomerJourneyGeneralDetails
                    workflowId={workflowId}
                    isLoading={isLoading}
                    title={generalInfoData.Name}
                    description={generalInfoData.Description}
                    startDate={generalInfoData.Schedule?.StartDate}
                    endDate={generalInfoData.Schedule?.EndDate}
                    timeZone={generalInfoData.Schedule?.TimeZone}
                    mode={generalInfoData.State}
                    status={generalInfoData.Status}
                    User={generalInfoData.CreatorName}
                    createdDate={generalInfoData.CreatedDate}
                    lastModified={generalInfoData.ModifiedDate}
                    lastModifier={generalInfoData.ModifierName}
                    customFields={generalInfoData.CustomFields}
                    usedIn={usedIn}
                    isLoadingUsedIn={isLoadingUsedIn}
                />
            ),
        },
        {
            tabTitle: t(l.Timeline),
            tabKey: '1',
            tabContent: <ActivityTimeLine workflowId={workflowId} />,
        },
        ...(hasVisualization
            ? [
                  {
                      tabTitle: t(l.Visualization),
                      tabKey: '2',
                      tabContent: <CJVisualizationInfo workflowId={workflowId} />,
                  },
              ]
            : []),
    ];

    return (
        <Modal
            className="crm-customer-journey-info-modal"
            background="dark-background"
            position="center"
            title={
                <div className="segment-list-modal-head">
                    <span className="segment-list-modal-h-text">
                        {t(l[CustomerJourneyInfoModalTitleLabels[promotionType]])}
                    </span>
                    <div className="segment-list-modal-h-icons">
                        <AccessControl mandatoryPermissions={[PERMISSIONS.CreateWorkflow, PERMISSIONS.ViewWorkflow]}>
                            <Button
                                icon="bc-icon-clone"
                                appearance="minimal"
                                color="primary"
                                onClick={() => cloneClickHandler()}
                            />
                        </AccessControl>
                    </div>
                </div>
            }
            closable={false}
            onCancel={handleClose}
            closeOnClickOutside={true}
            visible={isVisible}
            size="content-size"
            footer={
                <Button appearance="minimal" onClick={handleClose}>
                    {t(l.Close)}
                </Button>
            }
        >
            <CustomTabs tabsConfig={tabsConfig} />
        </Modal>
    );
};

CustomerJourneyInfoModal.propTypes = {
    workflowId: PropTypes.number.isRequired,
    isVisible: PropTypes.bool,
    isPredefined: PropTypes.bool,
    handleClose: PropTypes.func,
    hasVisualization: PropTypes.bool,
    promotionType: PropTypes.number,
};

CustomerJourneyInfoModal.defaultProps = {
    handleClose: noop,
    isVisible: false,
    isPredefined: false,
    hasVisualization: false,
};

export default memo(CustomerJourneyInfoModal);
