/* eslint-disable react/prop-types */
import React from 'react';
import { first, isArray } from 'lodash';
// Import Constants
import { l } from 'constants/common';
// Import UI Components
import { Tag, Tooltip } from '@geneui/components';

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.Name),
                dataKey: 'Label',
                sortable: true,
            },
            {
                text: t(l.Filters),
                dataKey: 'Filters',
                sortable: true,
                isPermanent: true,
                colRenderer: (filters) => {
                    const filtersLength = filters.length;
                    let labels = '';
                    let labelsValues = '';

                    filters.forEach(({ Label, Values }, index) => {
                        const valueObj = first(Values);
                        labels += `${Label}${index !== filtersLength - 1 ? ', ' : '.'}`;
                        labelsValues += `${t(valueObj?.valueFieldKey)} = ${
                            isArray(valueObj.value)
                                ? valueObj.value.map((valueItem) => t(valueItem)).join(', ')
                                : valueObj.value
                        } `;
                    });

                    return (
                        <Tooltip title={t(l.Filters)} text={labelsValues}>
                            <Tag name={labels} appearance="minimal" cornerRadius="smooth-radius" />
                        </Tooltip>
                    );
                },
            },
        ],
    };
};

export { DataTableColumnsConfigs };
