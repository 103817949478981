import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, noop, isNil } from 'lodash';
// Import UI components
import { Button, Divider, Tooltip, Icon, SkeletonLoader } from '@geneui/components';
// Import Constants
import { l, AlertTypes, GACategoryEvents } from 'constants/common';
// Import Components
import { SingleInputModal } from 'components';
import ConfigureFilterTagModal from './ConfigureFilterTagModal';
// Import Services
import { UtilsHttpService } from 'services/http';
import { mapToTagListModel, resetFilterFields, createAddFilterTagDTO, isAlreadyExistFiltersTagName } from './service';
// Import Hooks
import { useGACustomEvent, useRequest, useToaster } from 'hooks';
// Import SCSS
import 'assets/scss/filterTag.scss';

const { error, success } = AlertTypes;
const { GAFiltersCategory } = GACategoryEvents;

const FilterTag = forwardRef(
    ({ filterHolderStateSetter, filtersTagKey, filterHolderRef, isFilterHolderDisabled }, ref) => {
        const { t } = useTranslation();
        const { doPostRequest, doGetRequest } = useRequest();
        const { showToaster } = useToaster();
        const { logCustomEvent } = useGACustomEvent();

        const [filtersTags, setFiltersTags] = useState([]);
        const [filtersTagsList, setFiltersTagsList] = useState([]);
        const [isVisibleSaveFilterTagModal, setIsVisibleSaveFilterTagModal] = useState(false);
        const [addFilterLoading, setAddFilterLoading] = useState(false);
        const [filtersTagListLoading, setFiltersTagListLoading] = useState(false);
        const [isVisibleConfigureFilterTagModal, setIsVisibleConfigureFilterTagModal] = useState(false);
        const [filterName, setFilterName] = useState();

        const { getFiltersTagGroup, addFiltersTagGroup } = useRef({
            getFiltersTagGroup: UtilsHttpService.getFiltersTagGroup(),
            addFiltersTagGroup: UtilsHttpService.addFiltersTagGroup(),
        }).current;

        const getFiltersTagsList = () => {
            setFiltersTagListLoading(true);
            doGetRequest(getFiltersTagGroup.request, {
                queryString: { filtersTagGroupName: filtersTagKey },
                successCallback: ({ Value }) => !isNil(Value) && setFiltersTagsList(Value),
            }).then(() => setFiltersTagListLoading(false));
        };

        // TODO: localization case bugs
        const init = () => {
            getFiltersTagsList();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(init, []);

        const onAddBtnClickHandler = () => {
            logCustomEvent(
                GAFiltersCategory.name,
                GAFiltersCategory.events[`${filtersTagKey}AddButton`],
                filtersTagKey,
                1,
            );
            filterHolderStateSetter(true);
        };

        const onSaveBtnClickHandler = () => {
            logCustomEvent(
                GAFiltersCategory.name,
                GAFiltersCategory.events[`${filtersTagKey}SaveButton`],
                filtersTagKey,
                1,
            );
            setIsVisibleSaveFilterTagModal(true);
        };

        const onSaveModalCancelHandler = () => {
            setIsVisibleSaveFilterTagModal(false);
            setFilterName();
        };

        const onManageBtnClickHandler = () => {
            logCustomEvent(
                GAFiltersCategory.name,
                GAFiltersCategory.events[`${filtersTagKey}SavedButton`],
                filtersTagKey,
                1,
            );
            setIsVisibleConfigureFilterTagModal(true);
        };

        const applyFiltersToFilterHolderAndFilterTag = (existingFilters = []) => {
            filterHolderRef.current.changeAndApplyFilters(
                resetFilterFields(filterHolderRef.current.filtersState, existingFilters),
            );

            setIsVisibleConfigureFilterTagModal(false);
        };

        const removeFilterHandler = (removedFilterIndex) => {
            const existingFilters = filtersTags.filter((_filter, index) => index !== removedFilterIndex);
            applyFiltersToFilterHolderAndFilterTag(existingFilters);
        };

        const onSaveFilterHandler = () => {
            setAddFilterLoading(true);
            doPostRequest(addFiltersTagGroup.request, {
                requestBody: createAddFilterTagDTO(filtersTagKey, filterName, filtersTags),
                successCallback: () => {
                    onSaveModalCancelHandler();
                    showToaster(success, t(l.FilterGroupWasSavedSuccessfully));
                    getFiltersTagsList();
                },
                errorCallback: (errorMessage) => {
                    showToaster(error, errorMessage);
                },
            }).then(() => {
                setAddFilterLoading(false);
            });
        };

        const cleanUp = () => {
            return () => {
                getFiltersTagGroup.cancel('getFiltersTagGroup');
                addFiltersTagGroup.cancel('addFiltersTagGroup');
            };
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(cleanUp, []);

        useImperativeHandle(ref, () => ({
            changeFilters: (filtersState) => setFiltersTags(mapToTagListModel(filtersState)),
        }));

        return (
            <>
                <div className="crm-filter-tag-container">
                    <div className="buttons-section">
                        <Button
                            disabled={isFilterHolderDisabled}
                            icon="bc-icon-add"
                            onClick={onAddBtnClickHandler}
                            size="medium"
                        >
                            {isEmpty(filtersTags) ? t(l.AddFilters) : ''}
                        </Button>

                        <Divider type="vertical" />
                    </div>
                    <div className="tags-section">
                        <div>
                            {filtersTags.map(({ label, value }, index) => {
                                const text = `${t(label)} = ${value}`;

                                if (!isEmpty(value.toString().replaceAll(',', ''))) {
                                    return (
                                        <Tooltip key={index} text={text} position="bottom">
                                            <div className="filter-tag">
                                                <p>{text}</p>
                                                <Icon
                                                    onClick={() => removeFilterHandler(index)}
                                                    type="bc-icon-cancel"
                                                />
                                            </div>
                                        </Tooltip>
                                    );
                                } else {
                                    return <SkeletonLoader key={index} isBusy={true} />;
                                }
                            })}
                        </div>
                    </div>
                    <div className="controls-section">
                        <Divider type="vertical" />
                        <Button
                            icon="bc-icon-save"
                            disabled={isEmpty(filtersTags) || isFilterHolderDisabled}
                            onClick={onSaveBtnClickHandler}
                            size="medium"
                            appearance="minimal"
                        />
                        <Button
                            icon="bc-icon-settings-bars"
                            disabled={isFilterHolderDisabled}
                            onClick={onManageBtnClickHandler}
                            size="medium"
                            appearance="minimal"
                        />
                    </div>
                </div>

                {isVisibleSaveFilterTagModal && (
                    <SingleInputModal
                        titleTextLabel={l.SaveFilters}
                        isModalVisible={isVisibleSaveFilterTagModal}
                        onCancel={onSaveModalCancelHandler}
                        onOk={onSaveFilterHandler}
                        inputName={filterName}
                        setInputName={setFilterName}
                        inputPlaceholderLabel={l.FilterName}
                        inputNoteLabel={l.WriteANameForTheFilter}
                        isLoading={addFilterLoading}
                        externalValidationFn={(value) => !isAlreadyExistFiltersTagName(value, filtersTagsList)}
                        externalValidationErrorMessage={t(l.FilterGroupNameAlreadyExistInFiltersGroupList, {
                            filterGroupName: filterName,
                        })}
                    />
                )}

                {isVisibleConfigureFilterTagModal && (
                    <ConfigureFilterTagModal
                        filtersTagKey={filtersTagKey}
                        isModalVisible={isVisibleConfigureFilterTagModal}
                        data={filtersTagsList}
                        isDataLoading={filtersTagListLoading}
                        onCancel={() => setIsVisibleConfigureFilterTagModal(false)}
                        getFiltersTagsList={getFiltersTagsList}
                        applyFiltersAction={applyFiltersToFilterHolderAndFilterTag}
                    />
                )}
            </>
        );
    },
);

FilterTag.displayName = 'FilterTag';

FilterTag.propTypes = {
    filterHolderRef: PropTypes.any.isRequired,
    filtersTagKey: PropTypes.string.isRequired,
    filterHolderStateSetter: PropTypes.func,
    isFilterHolderDisabled: PropTypes.bool,
};

FilterTag.defaultProps = {
    filterHolderStateSetter: noop,
    isFilterHolderDisabled: true,
};

export default FilterTag;
