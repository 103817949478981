/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isObject } from 'lodash';
// Import UI components
import { Modal, Icon } from '@geneui/components';
import { ConfirmationModal } from 'components';
import EditCreateContent from './EditCreateContent';
import ModalFooter from './ModalFooter';
import ManageSetContent from './ManageSetContent';
// Import constants
import { LabelsModalView, LabelsColors } from './configs';
import { l, ActionsIcons, ModalsClassNames, TargetTypeEnum, GACategoryEvents } from 'constants/common';
// Import services
import { UtilsHttpService } from 'services/http';
// Import hooks
import { useGACustomEvent, useRequest } from 'hooks';
// Import scss
import 'assets/scss/manageLabelsModal.scss';

const { manageLabelsView, setLabelView, editLabelView, createLabelView } = LabelsModalView;
const { GAFiltersCategory } = GACategoryEvents;

const LabelsModal = ({ isVisible, handleClose, objectType, view, objectId, selectedLabelId }) => {
    const { t } = useTranslation();
    const { doPostRequest, doDeleteRequest } = useRequest();
    const { logCustomEvent } = useGACustomEvent();
    const { colors } = LabelsColors;
    const defaultColor = colors[0].value;
    const defaultData = { ObjectCategoryId: 0, Name: '', Description: '', Color: defaultColor };

    const [labelsList, setLabelsList] = useState([]);
    const [labelData, setLabelData] = useState(defaultData);
    const [dataCount, setDataCount] = useState(0);

    const [modalView, setModalView] = useState(LabelsModalView[view]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState(defaultColor);
    const [ObjectCategoryId, setObjectCategoryId] = useState(0);

    const [deleteLabelLoading, setDeleteLabelLoading] = useState(false);
    const [isDisableSave, setIsDisableSave] = useState(true);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [tableIsLoaded, setTableIsLoaded] = useState(true);
    const [isRefresh, setIsRefresh] = useState(false);
    const [labelName, setLabelName] = useState('');

    const isExecuteCallback = useRef(false);

    const { deleteTagRequest, createTagRequest, createObjectTagRequest, updateTagRequest, getTagsListRequest } = useRef(
        {
            deleteTagRequest: UtilsHttpService.deleteTag(),
            createTagRequest: UtilsHttpService.createTag(),
            createObjectTagRequest: UtilsHttpService.createObjectTag(),
            updateTagRequest: UtilsHttpService.updateTag(),
            getTagsListRequest: UtilsHttpService.tagsList(),
        },
    ).current;

    function setDeleteModalOpen(data) {
        if (!isOpenDeleteModal) {
            setLabelName(data.Name);
            setObjectCategoryId(data.ObjectCategoryId);
        }
        setIsOpenDeleteModal(!isOpenDeleteModal);
    }

    function handleDeleteLabel() {
        deleteLabel(ObjectCategoryId);
    }

    const saveHandler = () => {
        if (ObjectCategoryId > 0) {
            updateLabel(ObjectCategoryId);
        } else {
            createLabel();
        }
    };

    const setLabelSaveHandler = () => {
        createObjectLabel(ObjectCategoryId, objectId);
        closeHandler();
    };

    const editHandler = (data) => {
        setData(data);
        setModalView(editLabelView);
    };

    const createHandler = () => {
        const objectTypeLabels = {
            [TargetTypeEnum.Segment]: 'createSegmentLabel',
            [TargetTypeEnum.Template]: 'createTemplateLabel',
            [TargetTypeEnum.Campaign]: 'createCampaignLabel',
            [TargetTypeEnum.Workflow]: 'createWorkflowLabel',
            [TargetTypeEnum.Report]: 'createReportLabel',
        };

        logCustomEvent(
            GAFiltersCategory.name,
            GAFiltersCategory.events[objectTypeLabels[objectType]],
            'create label',
            1,
        );
        setData(defaultData);
        setModalView(createLabelView);
    };

    const cancelHandler = () => {
        setData(defaultData);
        setModalView(LabelsModalView[view]);
    };

    const closeHandler = () => {
        setIsDisableSave(true);
        handleClose(isRefresh);
    };

    const setData = (data) => {
        setIsDisableSave(true);
        setLabelData(data);

        const { Name, Description, Color, ObjectCategoryId } = data;

        setName(Name);
        setDescription(Description);
        setColor(Color);
        setObjectCategoryId(ObjectCategoryId);
    };

    const getLabels = () => {
        setTableIsLoaded(true);
        doPostRequest(getTagsListRequest.request, {
            requestBody: {
                filters: [],
                pageing: {
                    PageSize: 1000,
                    PageNumber: 1,
                },
                sorting: {
                    Name: 'CreatedDate',
                    Direction: 'desc',
                },
            },
            successCallback: (Data) => {
                setLabelsList((isObject(Data) && Data.Data) || []);
                setDataCount(Data.Count);
            },
        }).then(() => setTableIsLoaded(false));
    };

    const createLabel = () => {
        doPostRequest(createTagRequest.request, {
            requestBody: {
                Name: name,
                Description: description,
                Color: color,
            },
            successCallback: () => {
                cancelHandler();
                getLabels();
            },
        });
    };

    const createObjectLabel = (objectCategoryId, objectId) => {
        isExecuteCallback.current = true;
        doPostRequest(createObjectTagRequest.request, {
            requestBody: {
                ObjectId: objectId,
                ObjectType: objectType,
                ObjectCategoryId: objectCategoryId,
            },
        }).then(() => handleClose(isExecuteCallback.current));
    };

    const updateLabel = (objectCategoryId) => {
        doPostRequest(updateTagRequest.request, {
            requestBody: {
                Name: name,
                Description: description,
                Color: color,
                ObjectCategoryId: objectCategoryId,
            },
            successCallback: () => {
                getLabels();
                cancelHandler();
                setIsRefresh(true);
            },
        });
    };

    const deleteLabel = (id) => {
        isExecuteCallback.current = true;
        setDeleteLabelLoading(true);
        doDeleteRequest(deleteTagRequest.request, {
            queryString: { id },
            successCallback: () => {
                getLabels();
                setDeleteModalOpen();
            },
        }).then(() => setDeleteLabelLoading(false));
    };

    const getContent = () => {
        switch (modalView) {
            case createLabelView:
            case editLabelView:
                return (
                    <div className="edit-create-label c-create-label">
                        <EditCreateContent
                            setName={setName}
                            setDescription={setDescription}
                            setColor={setColor}
                            setDisableSave={setIsDisableSave}
                            data={labelData}
                        />
                    </div>
                );
            case setLabelView:
            case manageLabelsView:
                return (
                    <ManageSetContent
                        tableIsLoaded={tableIsLoaded}
                        data={labelsList}
                        dataCount={dataCount}
                        objectType={objectType}
                        columns={modalView.columns}
                        createHandler={createHandler}
                        editHandler={editHandler}
                        setObjectCategoryId={setObjectCategoryId}
                        deleteHandler={setDeleteModalOpen}
                        setIsDisableSave={setIsDisableSave}
                        selectedLabelId={selectedLabelId}
                    />
                );
            default:
                break;
        }
    };

    const getFooter = () => {
        return (
            <ModalFooter
                isDisableSave={isDisableSave}
                closeHandler={modalView.isClose ? closeHandler : cancelHandler}
                saveHandler={modalView.isClose ? setLabelSaveHandler : saveHandler}
                isSaveVisible={modalView.isVisibleSave}
            />
        );
    };

    useEffect(() => {
        setModalView(LabelsModalView[view]);
    }, [view]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getLabels, []);

    return (
        <>
            <div className="crm-category-modal-wrap">
                <Modal
                    size={'content-size'}
                    title={
                        <>
                            {!modalView.isClose && <Icon type="bc-icon-arrow-back" onClick={cancelHandler} />}
                            {t(l[modalView.title])}
                        </>
                    }
                    closable={false}
                    visible={isVisible}
                    onCancel={handleClose}
                    closeOnClickOutside={true}
                    footer={getFooter()}
                >
                    <div className="manage-label-popup-content">{getContent()}</div>
                </Modal>
            </div>
            <ConfirmationModal
                isVisibleModal={isOpenDeleteModal}
                onOk={handleDeleteLabel}
                onCancel={setDeleteModalOpen}
                questionLabel={t(l.AreYouSureWantToDeleteLabel, {
                    labelName: `${labelName}`,
                })}
                titleText={t(l.DeleteLabel)}
                iconType={ActionsIcons.Delete}
                actionLabel={t(l.Delete)}
                className={ModalsClassNames.Delete}
                isLoading={deleteLabelLoading}
            />
        </>
    );
};

LabelsModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    objectType: PropTypes.number.isRequired,
    view: PropTypes.string.isRequired,
    objectId: PropTypes.number,
    selectedLabelId: PropTypes.number,
};

LabelsModal.defaultProps = {
    objectId: 0,
    selectedLabelId: 0,
};

export default memo(LabelsModal);
