/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Components
import { PlayersDataTable, DataTable, ButtonWithLoader, DownloadToolbar } from 'components';
// Import Constants
import { Icon, Tooltip } from '@geneui/components';
import { CampaignType, l } from 'constants/common';
import { PlayersColumns, TrackedLinksColumnsConfigs, ModalTypes } from './config';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { CommunicationHttpService } from 'services/http';
import { first, isNil, noop, isEmpty, sum } from 'lodash';

const CustomerJourneyTrackedLinksContent = forwardRef(
    (
        {
            data,
            templateId,
            workflowId,
            isLoading,
            linkType,
            setIsDisableDownload,
            downloadAllClickHandler,
            downloadClickHandler,
            isFileLoading,
            isDownloadAllLoading,
            isDisableDownload,
        },
        ref,
    ) => {
        const { t } = useTranslation();
        const { doPostRequest } = useRequest();

        const [isLoadedData, setIsLoadedData] = useState(false);
        const [playersData, setPlayersData] = useState([]);
        const [trackedLinksData, setTrackedLinksData] = useState([]);
        const [radioSelectedRow, setRadioSelectedRow] = useState();
        const isUnsubscribe = (trackedLinkId) =>
            data.find((r) => r.TrackLinkId === trackedLinkId).Url === ModalTypes.UnsubscribeLink;

        const { columns } = TrackedLinksColumnsConfigs(t);

        const { getWorkflowTrackLinkReportByIdRequest, getWorkflowUnsubscribedClientsReportRequest } = useRef({
            getWorkflowTrackLinkReportByIdRequest: CommunicationHttpService.getTrackLinkReportById(),
            getWorkflowUnsubscribedClientsReportRequest: CommunicationHttpService.getUnsubscribedClientsReport(),
        }).current;

        const radioSelectHandler = (trackedLinkId) => {
            if (isUnsubscribe(trackedLinkId)) {
                sendRequest(getWorkflowUnsubscribedClientsReportRequest.request, trackedLinkId);
            } else {
                sendRequest(getWorkflowTrackLinkReportByIdRequest.request, trackedLinkId);
            }
        };

        const sendRequest = (request = null, trackedLinkId) => {
            if (!isNil(request)) {
                setPlayersData([]);
                setIsLoadedData(true);
                const requestBody = {
                    CampaignId: workflowId,
                    CampaignType: CampaignType.WorkFlow,
                    TemplateId: templateId,
                    TrackLinkId: trackedLinkId,
                };
                doPostRequest(request, {
                    requestBody,
                    successCallback: (Data) => {
                        setPlayersData({ Data });
                        setIsDisableDownload(isEmpty(Data));
                    },
                }).then(() => {
                    setIsLoadedData(false);
                });
            }
        };

        const cleanUp = () => {
            return () => {
                // Cancel all async processes
                getWorkflowTrackLinkReportByIdRequest.cancel(
                    'CustomerJourneyTrackedLinksContent:getTrackLinkReportByIdRequest',
                );
                getWorkflowUnsubscribedClientsReportRequest.cancel(
                    'CustomerJourneyTrackedLinksContent:getWorkflowUnsubscribedClientsReportRequest',
                );
            };
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(cleanUp, []);

        useEffect(() => {
            let selectedRow = null;

            switch (linkType) {
                case ModalTypes.TrackedLink:
                    selectedRow = first(data);
                    break;
                case ModalTypes.UnsubscribeLink:
                    selectedRow = data.find((r) => r.Url === ModalTypes.UnsubscribeLink);
                    break;
                default:
            }
            setTrackedLinksData(
                data.map(({ Url, ClickedCount, TrackLinkId }) => {
                    return {
                        actionBar: false,
                        dragDisable: true,
                        hasHover: true,
                        data: {
                            Url,
                            ClickedCount,
                            TrackLinkId,
                        },
                    };
                }),
            );
            if (!isNil(selectedRow)) {
                setRadioSelectedRow({
                    data: selectedRow,
                });
            }

            setIsDisableDownload(isEmpty(playersData));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [data]);

        const getRadioSelectedRow = (row) => {
            setRadioSelectedRow(row);
            if (!isNil(row?.data)) {
                radioSelectHandler(row?.data.TrackLinkId);
            }
        };

        useImperativeHandle(ref, () => ({
            getRadioSelectedRow() {
                return radioSelectedRow?.data?.TrackLinkId;
            },
        }));

        const getTilte = () => {
            let title = null;
            switch (linkType) {
                case ModalTypes.TrackedLink:
                    title = t(l.TrackedLink);
                    break;
                case ModalTypes.UnsubscribeLink:
                    title = t(l.UnsubscribeLink);
                    break;
                default:
            }
            return title;
        };

        return (
            <div className="c-j-report-page-wrapper">
                <div className="c-j-report-page-inner">
                    <div className="c-j-report-page-table">
                        <div className="c-j-report-download">
                            <p className="tracked-link-title">{getTilte()}</p>
                            <ButtonWithLoader
                                isLoading={isDownloadAllLoading}
                                color="primary"
                                appearance="outline"
                                onClick={downloadAllClickHandler}
                                defaultClassNames="download-all"
                                isDisabled={
                                    !sum(
                                        trackedLinksData.map(({ data }) => {
                                            return data.ClickedCount;
                                        }),
                                    ) > 0
                                }
                            >
                                {t(l.DownloadAll)}
                            </ButtonWithLoader>
                        </div>
                        <DataTable
                            classNames="default-height"
                            rowKey="TrackLinkId"
                            columnKey="dataKey"
                            tableKey={'TrackLinks'}
                            withPagination={false}
                            withPageSelector={false}
                            isVisibleOnlyDataTable={true}
                            data={trackedLinksData}
                            dataCount={data?.length}
                            columns={columns}
                            isLoading={isLoading}
                            isHasRowRadioSelect={true}
                            rowActionBar={null}
                            getRadioSelectedRow={getRadioSelectedRow}
                            defaultRadioSelectedRow={radioSelectedRow}
                        />
                    </div>
                    <div className="c-j-report-page-table">
                        <div className="c-j-report-download">
                            <DownloadToolbar
                                downloadClickHandler={downloadClickHandler}
                                withCountDropdown={true}
                                withTypeSelector={false}
                                isFileLoading={isFileLoading}
                                isDisableDownload={isDisableDownload}
                            />
                            <Tooltip text={t(l.TooltipDownloadTypeInfo)} position="top">
                                <Icon type="bc-icon-info" />
                            </Tooltip>
                        </div>
                        <PlayersDataTable
                            isFileLoading={isFileLoading}
                            downloadClickHandler={downloadClickHandler}
                            isDisableDownload={isDisableDownload}
                            key="ClientId"
                            config={PlayersColumns}
                            isLoading={isLoadedData}
                            data={playersData}
                        />
                    </div>
                </div>
            </div>
        );
    },
);

CustomerJourneyTrackedLinksContent.displayName = 'CustomerJourneyTrackedLinksContent';

CustomerJourneyTrackedLinksContent.propTypes = {
    workflowId: PropTypes.string.isRequired,
    templateId: PropTypes.number.isRequired,
    data: PropTypes.array,
    linkType: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isFileLoading: PropTypes.bool.isRequired,
    isDownloadAllLoading: PropTypes.bool.isRequired,
    setIsDisableDownload: PropTypes.func,
    downloadAllClickHandler: PropTypes.func,
    downloadClickHandler: PropTypes.func,
    isDisableDownload: PropTypes.bool,
};

CustomerJourneyTrackedLinksContent.defaultProps = {
    data: [],
    setIsDisableDownload: noop,
    downloadAllClickHandler: noop,
    downloadClickHandler: noop,
    isDisableDownload: false,
};

export default memo(CustomerJourneyTrackedLinksContent);
