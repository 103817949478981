import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, findIndex, isEmpty, isNil, noop, sortBy } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classNames from 'classnames';
// Import UI components
import { Button } from '@geneui/components';
// Import Components
import Block from './Block';
// Import Services
import { getParentState } from './service';
// Import SCSS
import 'assets/scss/fromToBlocks.scss';

const FromToBlocks = forwardRef(
    (
        {
            leftBlockList,
            rightBlockList,
            leftBlockWithParent,
            rightBlockWithParent,
            getUpdate,
            leftBlockWithPosition,
            rightBlockWithPosition,
            leftBlockTitle,
            leftBlockCountsLabel,
            rightBlockTitle,
            rightBlockCountsLabel,
            hasInfoTooltip,
        },
        ref,
    ) => {
        const [leftBlockItems, setLeftBlockItems] = useState([]);
        const [rightBlockItems, setRightBlockItems] = useState([]);

        const [_leftBlockWithParent, setLeftBlockWithParent] = useState(false);
        const [_rightBlockWithParent, setRightBlockWithParent] = useState(false);
        const [leftListSelectedItemsCount, setLeftListSelectedItemsCount] = useState(0);
        const [rightListSelectedItemsCount, setRightListSelectedItemsCount] = useState(0);

        const hasParent = useRef();
        const allParentRows = useRef();
        const initialData = useRef({});
        const leftBlockRef = useRef();
        const rightBlockRef = useRef();

        const initIdTypeModel = (item) => {
            const tmpObj = { ...item, id: item.id.toString() };
            if (!isNil(item.childrenList)) {
                item.childrenList = item.childrenList.map((row) => ({ ...row, id: row.id.toString() }));
            }
            return tmpObj;
        };

        const isHasParent = (initialValue, blockList) => {
            let tmpRightHasParent = initialValue;

            if (!isEmpty(blockList)) {
                tmpRightHasParent = false;
                blockList.forEach((item) => {
                    if (!isNil(item.childrenList)) {
                        tmpRightHasParent = true;
                    }
                });
            }
            return tmpRightHasParent;
        };

        const getAllParentRows = (rightHasParent, leftHasParent, rightBlockList, leftBlockList) => {
            let tmpAllParentRows = [];
            if (rightHasParent) {
                tmpAllParentRows = [
                    ...tmpAllParentRows,
                    ...rightBlockList.map((item) => ({ ...item, childrenList: [] })),
                ];
            }
            if (leftHasParent) {
                tmpAllParentRows = [
                    ...tmpAllParentRows,
                    ...leftBlockList.map((item) => ({ ...item, childrenList: [] })),
                ];
            }
            return tmpAllParentRows;
        };

        const getInitialBlockList = (blockWithParent, hasParent, blockList, allParentRows) => {
            if (blockWithParent) {
                const tmpBlockList = cloneDeep(blockList);
                allParentRows.forEach((item) => {
                    const findResult = findIndex(tmpBlockList, { id: item.id });
                    if (findResult === -1) {
                        tmpBlockList.push(item);
                    }
                });
                return sortParentList(tmpBlockList);
            } else {
                let tmpBlockList = [];
                blockList.forEach((item) => {
                    if (hasParent) {
                        if (!isNil(item.childrenList)) {
                            tmpBlockList = [...tmpBlockList, ...item.childrenList];
                        }
                    } else {
                        tmpBlockList = [...tmpBlockList, item];
                    }
                });
                return tmpBlockList;
            }
        };

        const init = (initRightBlockList = rightBlockList, initLeftBlockList = leftBlockList) => {
            const _rightBlockList = initRightBlockList.map(initIdTypeModel);
            const _leftBlockList = initLeftBlockList.map(initIdTypeModel);

            const tmpRightHasParent = isHasParent(rightBlockWithParent, _rightBlockList);
            const tmpLeftHasParent = isHasParent(leftBlockWithParent, _leftBlockList);

            const tmpRightBlockWithParent = rightBlockWithParent && tmpRightHasParent;
            const tmpLeftBlockWithParent = leftBlockWithParent && tmpLeftHasParent;

            const tmpAllParentRows = getAllParentRows(
                tmpRightHasParent,
                tmpLeftHasParent,
                _rightBlockList,
                _leftBlockList,
            );

            hasParent.current = tmpRightHasParent || tmpLeftHasParent;
            allParentRows.current = tmpAllParentRows;

            setRightBlockWithParent(tmpRightBlockWithParent);
            setLeftBlockWithParent(tmpLeftBlockWithParent);

            initialData.current.rightBlockItems = getInitialBlockList(
                tmpRightBlockWithParent,
                tmpRightHasParent,
                _rightBlockList,
                tmpAllParentRows,
                rightBlockWithPosition,
            );
            initialData.current.leftBlockItems = getInitialBlockList(
                tmpLeftBlockWithParent,
                tmpLeftHasParent,
                _leftBlockList,
                tmpAllParentRows,
                leftBlockWithPosition,
            );

            setLeftBlockItems(cloneDeep(initialData.current.leftBlockItems));
            setRightBlockItems(cloneDeep(initialData.current.rightBlockItems));
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(init, []);

        useImperativeHandle(ref, () => ({
            reset() {
                leftBlockRef.current.resetSearch();
                rightBlockRef.current.resetSearch();
                setLeftBlockItems(initialData.current.leftBlockItems);
                setRightBlockItems(initialData.current.rightBlockItems);
            },
            setInitialBlocksData(initRightBlockList, initLeftBlockList) {
                init(initRightBlockList, initLeftBlockList);
            },
        }));

        const getGroupedUpdatableData = (listItems) => {
            return groupRowList(listItems, allParentRows.current).filter((item) => item.childrenList.length > 0);
        };

        useEffect(() => {
            let tmpLeftBlockList = cloneDeep(leftBlockItems);
            let tmpRightBlockItems = cloneDeep(rightBlockItems);

            if (hasParent.current) {
                if (!_leftBlockWithParent) {
                    tmpLeftBlockList = getGroupedUpdatableData(tmpLeftBlockList);
                }

                if (!_rightBlockWithParent) {
                    tmpRightBlockItems = getGroupedUpdatableData(tmpRightBlockItems);
                }
            }

            getUpdate({ leftBlockList: tmpLeftBlockList, rightBlockList: tmpRightBlockItems });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [leftBlockItems, rightBlockItems]);

        const groupByWithParent = (listItems) => {
            return listItems.reduce((acc, item) => {
                (acc[item.parentId] = acc[item.parentId] || []).push(item);
                return acc;
            }, {});
        };

        const getLeftBlockListUpdate = (blockItems, selectedItemsCount) => {
            setLeftListSelectedItemsCount(selectedItemsCount);
            setLeftBlockItems(blockItems);
        };

        const getRightBlockListUpdate = (blockItems, selectedItemsCount) => {
            setRightListSelectedItemsCount(selectedItemsCount);
            setRightBlockItems(blockItems);
        };

        const openParentsStates = (listItems) => {
            let tmpRowList = [];
            listItems.forEach((item) => {
                tmpRowList = [...tmpRowList, ...item.childrenList];
            });
            return tmpRowList;
        };

        const getCheckedList = (listItems) => {
            return listItems.filter((item) => item.isChecked);
        };

        const getUncheckedList = (listItems) => {
            return listItems.filter((item) => !item.isChecked);
        };

        const clearParentStates = (item) => {
            item.childrenList = [];
            item.isChecked = false;
            item.isVisible = true;
        };

        const sortParentList = (list) => {
            const sortPropName = 'name';

            const tmpList = cloneDeep(list).map((item) => {
                item.childrenList = sortBy(item.childrenList, [sortPropName]);
                return item;
            });

            const sortedList = sortBy(tmpList, [sortPropName]); // TODO maybe remove divided case when sorted first time and sorted after move

            return sortedList;
        };

        const groupRowList = (blockList, list, isSetParentState = false) => {
            const groupByList = groupByWithParent(blockList);
            const tmpList = cloneDeep(list);
            tmpList.forEach(clearParentStates);
            Object.keys(groupByList).forEach((id) => {
                const findResult = findIndex(tmpList, { id: id });
                tmpList[findResult].childrenList = groupByList[id];
                if (isSetParentState) {
                    tmpList[findResult].isChecked = getParentState(tmpList[findResult].childrenList);
                }
            });
            return sortParentList(tmpList); // sort when using with parent
        };

        const setIndexMap = (item, index) => ({ ...item, index: index });

        const moveToFrom = (
            fromList,
            toList,
            setFromList,
            setToList,
            fromBlockWithParent,
            toBlockWithParent,
            fromBlockWithPosition,
            toBlockWithPosition,
        ) => {
            let tmpFromBlockList = fromList;
            let tmpToBlockList = toList;

            if (fromBlockWithParent) {
                tmpFromBlockList = openParentsStates(fromList);
            }
            if (toBlockWithParent) {
                tmpToBlockList = openParentsStates(toList);
            }

            tmpToBlockList = [
                ...tmpToBlockList,
                ...getCheckedList(tmpFromBlockList).map((row) => ({ ...row, isChecked: false })),
            ].map((row) => ({
                ...row,
                isVisible: true,
            }));

            tmpFromBlockList = getUncheckedList(tmpFromBlockList).map((row) => ({
                ...row,
                isChecked: false,
                isVisible: true,
            }));

            if (fromBlockWithParent) {
                tmpFromBlockList = groupRowList(tmpFromBlockList, fromList);
            } else if (fromBlockWithPosition) {
                tmpFromBlockList = tmpFromBlockList.map(setIndexMap);
            }

            if (toBlockWithParent) {
                tmpToBlockList = groupRowList(tmpToBlockList, toList, true);
            } else if (toBlockWithPosition) {
                tmpToBlockList = tmpToBlockList.map(setIndexMap);
            }

            setFromList(tmpFromBlockList);
            setToList(tmpToBlockList);
            leftBlockRef.current.resetSearch();
            rightBlockRef.current.resetSearch();
        };

        const moveLeftToRight = () => {
            return moveToFrom(
                leftBlockItems,
                rightBlockItems,
                setLeftBlockItems,
                setRightBlockItems,
                _leftBlockWithParent,
                _rightBlockWithParent,
                leftBlockWithPosition,
                rightBlockWithPosition,
            );
        };

        const moveRightToLeft = () => {
            return moveToFrom(
                rightBlockItems,
                leftBlockItems,
                setRightBlockItems,
                setLeftBlockItems,
                _rightBlockWithParent,
                _leftBlockWithParent,
                rightBlockWithPosition,
                leftBlockWithPosition,
            );
        };

        return (
            <DndProvider backend={HTML5Backend} context={window}>
                <div className="drag-and-drop-wrapper">
                    <Block
                        key="from"
                        blockList={leftBlockItems}
                        withParent={_leftBlockWithParent}
                        getUpdate={getLeftBlockListUpdate}
                        withPosition={leftBlockWithPosition}
                        title={leftBlockTitle}
                        countsLabel={leftBlockCountsLabel}
                        ref={leftBlockRef}
                        hasInfoTooltip={hasInfoTooltip}
                    />
                    <div key="arrows" className="drag-and-drop-tween-arrows">
                        <Button
                            cornerRadius="round"
                            flexibility="content-size"
                            className={classNames('d-a-d-button c-icon', {
                                'd-a-d-button-active': leftListSelectedItemsCount > 0,
                            })}
                            onClick={moveLeftToRight}
                        >
                            <i className="icon bc-icon-above" />
                        </Button>

                        <Button
                            cornerRadius="round"
                            flexibility="content-size"
                            className={classNames('d-a-d-button c-icon', {
                                'd-a-d-button-active': rightListSelectedItemsCount > 0,
                            })}
                            onClick={moveRightToLeft}
                        >
                            <i className="icon bc-icon-below" />
                        </Button>
                    </div>
                    <Block
                        key="to"
                        blockList={rightBlockItems}
                        withParent={_rightBlockWithParent}
                        getUpdate={getRightBlockListUpdate}
                        withPosition={rightBlockWithPosition}
                        title={rightBlockTitle}
                        countsLabel={rightBlockCountsLabel}
                        ref={rightBlockRef}
                        hasInfoTooltip={hasInfoTooltip}
                    />
                </div>
            </DndProvider>
        );
    },
);

FromToBlocks.displayName = 'FromToBlocks';

FromToBlocks.propTypes = {
    leftBlockList: PropTypes.array.isRequired,
    rightBlockList: PropTypes.array.isRequired,
    leftBlockWithParent: PropTypes.bool,
    rightBlockWithParent: PropTypes.bool,
    getUpdate: PropTypes.func,
    leftBlockWithPosition: PropTypes.bool,
    rightBlockWithPosition: PropTypes.bool,
    leftBlockTitle: PropTypes.string,
    leftBlockCountsLabel: PropTypes.string,
    rightBlockTitle: PropTypes.string,
    rightBlockCountsLabel: PropTypes.string,
    hasInfoTooltip: PropTypes.bool,
};

FromToBlocks.defaultProps = {
    leftBlockWithPosition: false,
    rightBlockWithPosition: false,
    leftBlockWithParent: false,
    rightBlockWithParent: false,
    getUpdate: noop,
    leftBlockTitle: '',
    leftBlockCountsLabel: '',
    rightBlockTitle: '',
    rightBlockCountsLabel: '',
    hasInfoTooltip: false,
};

export default FromToBlocks;
