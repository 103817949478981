// Import services
import { SegmentHttpService } from 'services/http';

const getSegmentClientCount = () => {
    const getSegmentClientCountRequest = SegmentHttpService.getSegmentClientCount();
    return {
        request: (segmentId, callBack, errorCallBack) => {
            getSegmentClientCountRequest
                .request({ segmentId })
                .then((response) => {
                    const { HasError, AlertMessage, Data } = response;
                    if (!HasError) {
                        callBack(Data);
                    } else {
                        errorCallBack(AlertMessage);
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    // TODO: need add error handling
                });
        },
        cancel: getSegmentClientCountRequest.cancel,
    };
};

const getCampaignsBySegment = () => {
    const getCampaignsBySegmentRequest = SegmentHttpService.getCampaignsBySegment();
    return {
        request: (segmentId, callBack, errorCallBack) => {
            getCampaignsBySegmentRequest
                .request({ segmentId })
                .then((response) => {
                    const { HasError, AlertMessage, Data } = response;
                    if (!HasError) {
                        callBack(Data);
                    } else {
                        errorCallBack(AlertMessage);
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    // TODO: need add error handling
                });
        },
        cancel: getCampaignsBySegmentRequest.cancel,
    };
};

const getStaticSegmentTop200Client = () => {
    const getStaticSegmentTop200ClientRequest = SegmentHttpService.getStaticSegmentTop200Client();
    return {
        request: (segmentId, callBack, errorCallBack) => {
            getStaticSegmentTop200ClientRequest
                .request(null, { segmentId })
                .then((response) => {
                    const { HasError, Data, AlertMessage } = response;
                    if (!HasError) {
                        const resultData = Data?.Data?.map((elem) =>
                            Data?.Columns.reduce((accumulator, element, index) => {
                                accumulator[element.ColumnName] = elem[index];
                                return accumulator;
                            }, {}),
                        );

                        callBack({
                            Data: resultData,
                            Columns: Data?.Columns,
                        });
                    } else {
                        errorCallBack(AlertMessage);
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error);
                });
        },
        cancel: getStaticSegmentTop200ClientRequest.cancel,
    };
};

const getSegmentById = () => {
    const getSegmentByIdRequest = SegmentHttpService.getSegmentById();
    return {
        request: (segmentId, callBack, errorCallBack) => {
            getSegmentByIdRequest
                .request({ segmentId: segmentId })
                .then((response) => {
                    const { HasError, AlertMessage, Data } = response;
                    if (!HasError) {
                        callBack(Data);
                    } else {
                        errorCallBack(AlertMessage);
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error.message);
                    // TODO: need add error handling
                });
        },
        cancel: getSegmentByIdRequest.cancel,
    };
};

const getNonCustomerSegmentPreview = () => {
    const getNonCustomerSegmentPreviewRequest = SegmentHttpService.getNonCustomerSegmentPreview();
    return {
        request: (segmentId, callBack, errorCallBack) => {
            getNonCustomerSegmentPreviewRequest
                .request({ segmentId: segmentId })
                .then((response) => {
                    const { HasError, AlertMessage, Data } = response;
                    if (!HasError) {
                        const resultData = Data?.Data?.map((elem, _index) =>
                            Data?.Columns.reduce((accumulator, element, index) => {
                                accumulator[element.ColumnName] = elem[index];
                                return accumulator;
                            }, {}),
                        );

                        callBack({
                            Data: resultData,
                            Columns: Data?.Columns,
                            Name: Data.Name,
                            SegmentId: Data.SegmentId,
                        });
                    } else {
                        errorCallBack(AlertMessage);
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.log(error.message);
                    // TODO: need add error handling
                });
        },
        cancel: getNonCustomerSegmentPreviewRequest.cancel,
    };
};

export {
    getSegmentClientCount,
    getCampaignsBySegment,
    getStaticSegmentTop200Client,
    getSegmentById,
    getNonCustomerSegmentPreview,
};
