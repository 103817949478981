import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, noop, first, last, isObject, cloneDeep, isBoolean } from 'lodash';
// Import UI Components
import { Button, Dropdown, Tooltip } from '@geneui/components';
// Import Components
import CJCascadeDropdown from '../CJCascadeDropdown';
// Import Constants
import {
    l,
    CJModelRelationScopes,
    CJArgumentValuesTypes,
    CJReferenceInArgumentType,
    CJFormulaInArgumentType,
    excludeRightValueContentVisibilitiesOperators,
} from 'constants/common';
import { formsTypes, operatorsLabels, formulaToReferenceMapToUIModel } from '../config';
// Import Services
import { getMatchedProperties, getFormContent, mapPropertiesToCascadeDropdownModel } from 'services/customerJourney';

const CJFilter = ({
    index,
    properties,
    filter,
    getUpdate,
    onRemove,
    onClone,
    isRemoveDisabled,
    option,
    node,
    elements,
}) => {
    const { t } = useTranslation();

    const [isValid, setIsValid] = useState(null);

    const [property, setProperty] = useState();
    const [propertyValue, setPropertyValue] = useState();
    const [operator, setOperator] = useState();
    const [rightValue, setRightValue] = useState();
    const [rightValueContent, setRightValueContent] = useState({});
    const [operators, setOperators] = useState([]);
    const [leftProperties, setLeftProperties] = useState([]);
    const [rightValueProperties, setRightValueProperties] = useState([]);
    const [isReferenceActive, setIsReferenceActive] = useState(false);

    const getOption = (parentIndex, optionIndex) => {
        return properties[parentIndex]?.properties.filter(({ Hidden }) => !Hidden)[optionIndex];
    };

    const getOptionByProperty = (property) => {
        if (!isNil(property?.parentValue) && !isNil(property?.value) && !isNil(properties)) {
            const parentPropertyIndex = properties.findIndex((item) => item.id === property.parentValue);
            if (parentPropertyIndex !== -1 && !isNil(properties[parentPropertyIndex]?.properties)) {
                const propertyIndex = properties[parentPropertyIndex].properties.findIndex(
                    (item) => item.Name === property.value,
                );
                if (propertyIndex !== -1) {
                    return properties[parentPropertyIndex].properties[propertyIndex];
                }
            }

            const selectedElement = elements.find((el) => el.id === property.parentValue);
            if (!isNil(selectedElement?.data?.metaData?.Properties)) {
                const tmpProperty = selectedElement.data.metaData.Properties.find((p) => p.Name === property.value);
                if (!isNil(tmpProperty)) {
                    return tmpProperty;
                }
            }

            return null; // TODO: need case review
        }
        return null; // TODO: need case review
    };

    const propertyChangeHandler = ({
        label,
        value,
        optionIndex,
        parentLabel,
        parentValue,
        parentIndex,
        logicFunction,
    }) => {
        const option = getOption(parentIndex, optionIndex);

        const selectedFunctionReturnType = option.Functions.find((item) => item.value === logicFunction)?.returnType;
        const tmpOperators = selectedFunctionReturnType?.Operators?.map((operator) => ({
            label: t(operatorsLabels[operator]),
            value: operator,
        }));

        setOperators(tmpOperators);

        setPropertyValue({
            ...option,
            BaseTypes: selectedFunctionReturnType?.BaseTypes,
            CustomAttributes: selectedFunctionReturnType?.CustomAttributes,
            Validation: selectedFunctionReturnType?.Validation,
        });

        const _operator = first(tmpOperators)?.value;
        setOperator(_operator);

        setIsReferenceActive(false);

        const _property = { label, value, optionIndex, parentLabel, parentValue, parentIndex, logicFunction };

        setProperty(_property);

        getUpdate({
            property: _property,
            operator: _operator,
            rightValue: null,
        });
        setIsValid(null);

        setRightValue(null);
        const content = cloneDeep(getFormContent(selectedFunctionReturnType?.BaseTypes, formsTypes));
        setRightValueContent({ Component: content.Component, optionType: content.optionType });

        if (option?.ModelRelationScope !== CJModelRelationScopes.None) {
            const optionType = last(first(selectedFunctionReturnType?.BaseTypes).split('.'));
            const matchedProperties = getMatchedProperties(optionType, properties);
            setRightValueProperties(mapPropertiesToCascadeDropdownModel(matchedProperties));
        }
    };

    const operatorChangeHandler = ({ value }) => {
        setOperator(value);
        setIsReferenceActive(false);

        getUpdate({
            operator: value,
            rightValue: null,
        });

        setIsValid(null);

        setRightValue(null);
        const content = cloneDeep(getFormContent(propertyValue?.BaseTypes, formsTypes));
        setRightValueContent({ Component: content.Component, optionType: content.optionType });
    };

    const rightValueChangeHandler = (option, formConfig, argumentInType = CJArgumentValuesTypes.StaticInArgument) => {
        return (value, paramArgumentInType = argumentInType) => {
            // filters validation checking and update validation
            if (!isNil(value?.isValid) && isBoolean(value.isValid)) {
                setIsValid((prev) => ({ ...prev, right: value.isValid }));
                return;
            }

            let data = value;
            if (paramArgumentInType === CJArgumentValuesTypes.ReferenceInArgument) {
                data = { blockId: value?.parentValue, propertyName: value?.value, logicFunction: value?.logicFunction };
            }
            const tmpValue = formConfig.mapToApiModel(data, paramArgumentInType, option);
            setRightValue(tmpValue);
            getUpdate({
                rightValue: tmpValue,
            });
        };
    };

    const getRightValueContent = () => {
        if (!isNil(rightValueContent?.Component)) {
            const { Component, optionType } = rightValueContent;
            const dynamicProps = {};

            if (optionType === formsTypes.WfDateTime.optionType || optionType === formsTypes.WfGuid.optionType) {
                dynamicProps.data = rightValueProperties;
                dynamicProps.node = node;
            }

            return (
                <Component
                    getUpdate={rightValueChangeHandler(propertyValue, formsTypes[optionType])}
                    option={{
                        ...propertyValue,
                        ModelRelationScope: option?.ModelRelationScope,
                        Required: true,
                        SelfModelTypeName: option?.SelfModelTypeName,
                        SelfModelProperties: option?.SelfModelProperties,
                    }}
                    defaultValue={formsTypes[optionType].mapToUIModel(rightValue)}
                    elements={elements}
                    fromFilter={true}
                    {...dynamicProps}
                />
            );
        }

        return <></>;
    };

    const init = () => {
        setLeftProperties(mapPropertiesToCascadeDropdownModel(properties));

        const { property: consumedProperty, operator: consumedOperator, rightValue: consumedRightValue } = filter;

        if (isNil(consumedProperty?.parentValue || consumedProperty?.value)) return;

        setProperty(consumedProperty);

        const tmpProperty = getOptionByProperty(consumedProperty);
        const selectedFunctionReturnType = tmpProperty?.Functions?.find(
            (item) => item.value === consumedProperty?.logicFunction,
        )?.returnType;

        const tmpOperators = selectedFunctionReturnType?.Operators?.map((operator) => ({
            label: t(operatorsLabels[operator]),
            value: operator,
        }));
        setOperators(tmpOperators);
        setOperator(consumedOperator);

        const content = getFormContent(selectedFunctionReturnType?.BaseTypes, formsTypes);

        setPropertyValue({
            ...tmpProperty,
            BaseTypes: selectedFunctionReturnType?.BaseTypes,
            CustomAttributes: selectedFunctionReturnType?.CustomAttributes,
            Validation: selectedFunctionReturnType?.Validation,
        });
        setRightValueContent({ Component: content.Component, optionType: content.optionType });
        setRightValue(consumedRightValue);

        // ? if (option?.ModelRelationScope !== CJModelRelationScopes.None) {
        // TODO: remove duplicate code from property change handler
        const optionType = last(first(selectedFunctionReturnType?.BaseTypes)?.split('.'));
        const matchedProperties = getMatchedProperties(optionType, properties);
        setRightValueProperties(mapPropertiesToCascadeDropdownModel(matchedProperties));

        if (
            isObject(consumedRightValue) &&
            (consumedRightValue?.$type === CJReferenceInArgumentType ||
                consumedRightValue?.$type === CJFormulaInArgumentType) &&
            content?.onlyPrimitive !== true
        ) {
            setIsReferenceActive(true);
        }
    };

    const referenceActiveClickHandler = () => {
        if (isReferenceActive) {
            rightValueChangeHandler(propertyValue, formsTypes[rightValueContent.optionType])(null);
        } else {
            rightValueChangeHandler(
                propertyValue,
                formsTypes.FilterCondition,
                CJArgumentValuesTypes.ReferenceInArgument,
            )(null);
        }
        setIsReferenceActive(!isReferenceActive);
    };

    useEffect(() => {
        let tmpIsValid = true;
        if (!isNil(isValid)) {
            tmpIsValid =
                tmpIsValid &&
                (isNil(isValid?.left) ? true : isValid.left) &&
                (isNil(isValid?.right) ? true : isValid.right);
        }
        getUpdate({ isValid: tmpIsValid });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    return (
        <div className="crm-cj-filter-condition-block">
            <div>
                <span>{`${t(l.Filter)} ${index + 1}`}</span>
                <Button onClick={onClone} icon="bc-icon-clone" appearance="minimal" />
                <Button disabled={isRemoveDisabled} onClick={onRemove} icon="bc-icon-delete-2" appearance="minimal" />
            </div>

            <div className="crm-cj-filter-condition-inputs">
                <div className="crm-cj-filter-condition-inputs-left-content">
                    <CJCascadeDropdown
                        value={property}
                        data={leftProperties}
                        onChange={propertyChangeHandler}
                        option={{
                            ModelRelationScope: option?.ModelRelationScope,
                            BaseTypes: getOptionByProperty(property)?.BaseTypes,
                            SelfModelTypeName: option?.SelfModelTypeName,
                            SelfModelProperties: option?.SelfModelProperties,
                        }}
                        node={node}
                        elements={elements}
                        onValidationChange={(isValid) => {
                            setIsValid((prev) => ({ ...prev, left: isValid }));
                        }}
                    />
                </div>

                {!isNil(property) && (
                    <>
                        <div className="crm-cj-filter-condition-operator">
                            <Dropdown
                                isMultiSelect={false}
                                disabled={false}
                                hasSearch={true}
                                inputSize="default"
                                appearance="outline"
                                placeholder={t(l.Operator)}
                                label={t(l.Operator)}
                                labelAppearance="swap"
                                data={operators}
                                noDataText={t(l.NoDataFound)}
                                value={operator}
                                onChange={operatorChangeHandler}
                            />
                        </div>
                        {!excludeRightValueContentVisibilitiesOperators.includes(operator) && (
                            <div className="crm-cj-filter-condition-inputs-right-content">
                                {isReferenceActive || isNil(formsTypes[rightValueContent.optionType]) ? (
                                    <CJCascadeDropdown
                                        data={rightValueProperties}
                                        value={formulaToReferenceMapToUIModel(rightValue)}
                                        elements={elements}
                                        onChange={rightValueChangeHandler(
                                            propertyValue,
                                            formsTypes.FilterCondition,
                                            CJArgumentValuesTypes.ReferenceInArgument,
                                        )}
                                        onValidationChange={(isValid) => {
                                            setIsValid((prev) => ({ ...prev, right: isValid }));
                                        }}
                                        option={{
                                            ModelRelationScope: option?.ModelRelationScope,
                                            BaseTypes: getOptionByProperty(property)?.BaseTypes,
                                            SelfModelTypeName: option?.SelfModelTypeName,
                                            SelfModelProperties: option?.SelfModelProperties,
                                        }}
                                        node={node}
                                    />
                                ) : (
                                    getRightValueContent()
                                )}
                                {!isNil(formsTypes[rightValueContent.optionType]) &&
                                    formsTypes[rightValueContent.optionType].onlyPrimitive !== true && (
                                        <Tooltip text={t(l.CJBlockFilterReferenceDescription)}>
                                            <Button
                                                icon={isReferenceActive ? 'bc-icon-unlink' : 'bc-icon-link'}
                                                onClick={referenceActiveClickHandler}
                                                appearance="minimal"
                                            />
                                        </Tooltip>
                                    )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

CJFilter.propTypes = {
    index: PropTypes.number.isRequired,
    filter: PropTypes.shape({
        property: PropTypes.string.isRequired,
        operator: PropTypes.string.isRequired,
        rightValue: PropTypes.any.isRequired,
    }),
    node: PropTypes.object.isRequired,
    elements: PropTypes.array.isRequired,
    option: PropTypes.object,
    properties: PropTypes.array,
    getUpdate: PropTypes.func,
    onRemove: PropTypes.func,
    onClone: PropTypes.func,
    isRemoveDisabled: PropTypes.bool,
};

CJFilter.defaultProps = {
    filter: {
        property: '',
        operator: '',
        rightValue: null,
    },
    properties: [],
    getUpdate: noop,
    onRemove: noop,
    onClone: noop,
    isRemoveDisabled: false,
};

export default memo(CJFilter);
