import { useCallback } from 'react';
import Axios from 'axios';
import { isFunction, noop } from 'lodash';
// Import hooks
import { useToaster } from 'hooks';
// Import constants
import { AlertTypes } from 'constants/common';

const useRequest = () => {
    const { showToaster } = useToaster();

    const doRequest = useCallback(
        (requestFn, params, args) => {
            if (!isFunction(requestFn)) {
                // eslint-disable-next-line no-console
                console.error(`${requestFn} should be a function`);
            } else {
                const {
                    successCallback = noop,
                    errorCallback = noop,
                    catchCallback = noop,
                    isShowError = true,
                } = params;

                return new Promise((resolve) => {
                    const resolveResponseObject = (response) => {
                        const { HasError, AlertMessage, Data } = response;

                        if (!HasError) {
                            successCallback(Data, AlertMessage);
                        } else {
                            isShowError && showToaster(AlertTypes.error, AlertMessage);
                            errorCallback(AlertMessage);
                        }

                        resolve(response);
                    };

                    requestFn(...args)
                        .then(
                            (response) => {
                                if (response instanceof Blob) {
                                    if (response.type !== 'application/json') {
                                        successCallback(response, null);
                                        resolve(response);
                                    } else {
                                        response.text().then((responseJSON) => {
                                            resolveResponseObject(JSON.parse(responseJSON));
                                        });
                                    }
                                } else {
                                    resolveResponseObject(response);
                                }
                            },
                            (error) => {
                                // eslint-disable-next-line no-console
                                console.error(error.message);
                                if (!Axios.isCancel(error)) {
                                    showToaster(AlertTypes.error, error.message);
                                    errorCallback(error.message);
                                }
                                resolve({ HasError: true, AlertMessage: error.message, AlertType: AlertTypes.danger });
                            },
                        )
                        .catch((error) => {
                            // Please do not touch any state of app in catch block!
                            // eslint-disable-next-line no-console
                            console.error(error.message);
                            catchCallback(error);
                        });
                });
            }
        },
        [showToaster],
    );

    const doPostRequest = useCallback(
        (requestFn, params) => {
            const { requestBody, queryString = {} } = params;
            const args = [requestBody, queryString];
            return doRequest(requestFn, params, args);
        },
        [doRequest],
    );

    const doGetRequest = useCallback(
        (requestFn, params) => {
            const { queryString = {} } = params;
            const args = [queryString];
            return doRequest(requestFn, params, args);
        },
        [doRequest],
    );

    const doDeleteRequest = useCallback(
        (requestFn, params) => {
            const { queryString = {} } = params;
            const args = [queryString];
            return doRequest(requestFn, params, args);
        },
        [doRequest],
    );

    return { doPostRequest, doGetRequest, doDeleteRequest };
};

export default useRequest;
