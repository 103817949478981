import React, { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Col, Popover, Row, Tag } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

interface Props {
    generalDetails: {
        goal: string;
        segmentSplitEndDate: string;
        kpiNames: string[];
        endDate: string;
        controlGroup: number;
        startDate: string;
        pilotGroup: number;
    };
}

const ABTestGeneralDetails: FC<Props> = ({ generalDetails }) => {
    const { t } = useTranslation();

    const { goal, segmentSplitEndDate, kpiNames, endDate, controlGroup, startDate, pilotGroup } = generalDetails;

    const [isOpenKPIs, setIsOpenKPIs] = useState<boolean>(false);

    const kPIsClickHandler = () => setIsOpenKPIs((prev) => !prev);

    const getPopoverContent = () => (
        <div className="used-in-content">
            <div className="tagList-bc c-tagList-bc">
                {kpiNames.map((title, index) => {
                    return (
                        <Tag
                            key={index}
                            name={title}
                            cornerRadius="smooth-radius"
                            appearance="minimal"
                            flexibility="compact"
                        />
                    );
                })}
            </div>
        </div>
    );

    return (
        <div className="campaign-report-ab-test-general-details-container">
            <div className="details-section">
                <ul className="module-header cr-position-radius p-top s-big">
                    <li className="left-content">
                        <div className="left-inner">
                            <div className="module-title ellipsis-text" title="">
                                <div className="crm-statics-segment-data-table-name">{t(l.GeneralDetails)}</div>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="ab-test-general-details-content">
                    <Row>
                        <Col size={3} className="ab-test-general-details-column-info-item">
                            <p className="g-details-title">{t(l.Goal)}</p>
                            <p className="g-details-subtitle">{goal}</p>
                        </Col>
                        <Col size={3} className="ab-test-general-details-column-info-item">
                            <p className="g-details-title">{t(l.KPIs)}</p>
                            <p className="g-details-subtitle">
                                <Popover
                                    className="used-in-popover"
                                    isOpen={isOpenKPIs}
                                    extendTargetWidth={false}
                                    Content={getPopoverContent()}
                                >
                                    <button onClick={kPIsClickHandler} className="used-in-button">
                                        {kpiNames.length}
                                        <i className={`bc-icon-arrow-${isOpenKPIs ? 'up' : 'down'}`}></i>
                                    </button>
                                </Popover>
                            </p>
                        </Col>
                        <Col size={3} className="ab-test-general-details-column-info-item">
                            <p className="g-details-title">{t(l.ControlGroup)}</p>
                            <p className="g-details-subtitle">{controlGroup}%</p>
                        </Col>
                        <Col size={3} className="ab-test-general-details-column-info-item">
                            <p className="g-details-title">{t(l.PilotGroup)}</p>
                            <p className="g-details-subtitle">{pilotGroup}%</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col size={3} className="ab-test-general-details-column-info-item">
                            <p className="g-details-title">{t(l.StartDate)}</p>
                            <p className="g-details-subtitle">{startDate}</p>
                        </Col>
                        <Col size={3} className="ab-test-general-details-column-info-item">
                            <p className="g-details-title">{t(l.SegmentSplitEndDate)}</p>
                            <p className="g-details-subtitle">{segmentSplitEndDate}</p>
                        </Col>
                        <Col size={3} className="ab-test-general-details-column-info-item">
                            <p className="g-details-title">{t(l.EndDate)}</p>
                            <p className="g-details-subtitle">{endDate}</p>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default memo(ABTestGeneralDetails);
