import React from 'react';
import { RoutesList } from 'routes';
// Import Constants
import {
    l,
    CampaignType,
    Widgets,
    DateToISOStringFormat,
    CustomerJourneyStatusLabels,
    PromotionTypeLabels,
    CommunicationType,
    CommunicationTypeLabels,
    PromoConditionLabels,
} from 'constants/common';
import { DashboardWidgetsIcons } from 'constants/utils';
// Import Services
import { Helpers } from 'services';
import { DashboardHttpService } from 'services/http';
// Import SCSS
import 'assets/scss/dashboard.scss';
// Import UI Components
import { Icon } from '@geneui/components';
import { TittleLink } from 'components';

const { customMoment, getMultiselectDropdownValueAndMap } = Helpers;

const { REALTIME_JOURNEY_VIEW, CAMPAIGN_REPORT } = RoutesList;

const KPIWidgetsConfigs = [
    {
        hasDatePicker: true,
        title: l.DKPIActiveUsers,
        tooltip: l.DKPIActiveUsersToolTip,
        className: 'DKPIActiveUsers',
        gaEventNameKey: 'DKPIActiveUsers',
        withPercentage: false,
        id: Widgets.ActiveUsers,
        widgetImg: DashboardWidgetsIcons.activeUsersWidgetImg,
        request: DashboardHttpService.getDashboardActiveUsers,
    },
    {
        hasDatePicker: true,
        title: l.DKPIRetentionRate,
        tooltip: l.DKPIRetentionRateToolTip,
        className: 'DKPIRetentionRate',
        gaEventNameKey: 'DKPIRetentionRate',
        withPercentage: true,
        id: Widgets.RetentionRate,
        widgetImg: DashboardWidgetsIcons.retentionRateWidgetImg,
        request: DashboardHttpService.getRetentionRates,
    },
    {
        hasDatePicker: true,
        title: l.DKPIConversionRate,
        tooltip: l.DKPIConversionRateToolTip,
        className: 'DKPIConversionRate',
        gaEventNameKey: 'DKPIConversionRate',
        withPercentage: true,
        id: Widgets.ConversionRate,
        widgetImg: DashboardWidgetsIcons.conversionRateWidgetImg,
        request: DashboardHttpService.getConversionRates,
    },
    {
        hasDatePicker: true,
        title: l.DKPIMoneyRetentionRate,
        tooltip: l.DKPIMoneyRetentionRateToolTip,
        className: 'DKPIMoneyRetentionRate',
        gaEventNameKey: 'DKPIMoneyRetentionRate',
        withPercentage: true,
        id: Widgets.MoneyRetentionRate,
        widgetImg: DashboardWidgetsIcons.moneyRetentionRateWidgetImg,
        request: DashboardHttpService.getDollarRetentionRates,
    },
    {
        hasDatePicker: false,
        title: l.DKPIDAUMAU,
        tooltip: l.DKPIDAUMAUToolTip,
        className: 'DKPIDAUMAU',
        gaEventNameKey: 'DKPIDAUMAU',
        queryString: {
            startDate: customMoment().add(-1, 'months').format(DateToISOStringFormat),
            endDate: customMoment().format(DateToISOStringFormat),
        },
        withPercentage: true,
        id: Widgets.DAU_MAU,
        widgetImg: DashboardWidgetsIcons.dauMauWidgetImg,
        request: DashboardHttpService.getDAURates,
    },
    {
        hasDatePicker: false,
        title: l.DKPIDAUWAU,
        tooltip: l.DKPIDAUWAUToolTip,
        className: 'DKPIDAUWAU',
        gaEventNameKey: 'DKPIDAUWAU',
        queryString: {
            startDate: customMoment().add(-7, 'days').format(DateToISOStringFormat),
            endDate: customMoment().format(DateToISOStringFormat),
        },
        withPercentage: true,
        id: Widgets.DAU_WAU,
        widgetImg: DashboardWidgetsIcons.dauWauWidgetImg,
        request: DashboardHttpService.getDAURates,
    },
    {
        hasDatePicker: true,
        hasCurrency: true,
        title: l.DKPIARPU,
        tooltip: l.DKPIARPUToolTip,
        className: 'DKPIARPU',
        gaEventNameKey: 'DKPIARPU',
        withPercentage: false,
        id: Widgets.ARPU,
        widgetImg: DashboardWidgetsIcons.arpuWidgetImg,
        request: DashboardHttpService.getDashboardActiveClientsAverageRevenue,
    },
    {
        hasDatePicker: true,
        title: l.DKPIReactivationRate,
        tooltip: l.DKPIReactivationRateToolTip,
        className: 'DKPIReactivationRate',
        gaEventNameKey: 'DKPIReactivationRate',
        withPercentage: true,
        id: Widgets.ReactivationRate,
        widgetImg: DashboardWidgetsIcons.reactivationRateWidgetImg,
        request: DashboardHttpService.getDashboardReactivationRate,
    },
    {
        hasDatePicker: true,
        hasCurrency: true,
        title: l.DKPICLV,
        tooltip: l.DKPICLVToolTip,
        className: 'DKPICLV',
        gaEventNameKey: 'DKPICLV',
        withPercentage: false,
        id: Widgets.CustomerLifetimeValue,
        widgetImg: DashboardWidgetsIcons.clvWidgetImg,
        request: DashboardHttpService.getDashboardCustomerLifetimeValue,
    },
];

const datePickerConfigs = {
    initStartDate: customMoment().add(-7, 'days').startOf('day'),
    initEndDate: customMoment().startOf('day'),
    min: '01/01/2000',
    max: '01/01/2050',
};

const campaignsInActionColumnsConfigs = (t) => {
    return {
        campaignsInActionColumns: [
            {
                text: t(l.Name),
                dataKey: 'Title',
                sortable: true,
                // eslint-disable-next-line react/prop-types
                colRenderer: ({ Type, ObjectId, Name, HasBonus, Status }) => {
                    const path = `${Type}` === CampaignType.WorkFlow ? REALTIME_JOURNEY_VIEW : CAMPAIGN_REPORT;
                    const queryParams =
                        `${Type}` === CampaignType.WorkFlow ? { workflowId: ObjectId } : { campaignId: ObjectId };
                    const isClickable = !(
                        Status === +CustomerJourneyStatusLabels.Invalid ||
                        Status === +CustomerJourneyStatusLabels.NonStarted
                    );

                    return (
                        <div className="crm-list-column-title-icon-components">
                            <TittleLink isClickable={isClickable} path={path} name={Name} queryParams={queryParams} />
                            {HasBonus && <Icon type="bc-icon-send-bonus-block" />}
                        </div>
                    );
                },
            },
            {
                text: t(l.User),
                dataKey: 'CreatorName',
                sortable: true,
            },
            {
                text: t(l.PromotionType),
                dataKey: 'Type',
                sortable: true,
                colRenderer: (Type) => <div>{PromotionTypeLabels[Type]}</div>,
            },
            {
                text: t(l.Condition),
                dataKey: 'ConditionType',
                sortable: true,
                colRenderer: (status) => <div>{t(PromoConditionLabels[status])}</div>,
            },
        ],
    };
};

const customerRelationshipMetricsColumnsConfigs = (t) => {
    return {
        customerRelationshipMetricsColumns: [
            {
                text: t(l.Description),
                dataKey: 'Name',
                sortable: true,
            },
            {
                text: t(l.Metrics),
                dataKey: 'Value',
                sortable: true,
            },
        ],
    };
};

const getStartDate = (day = 0) => customMoment().add(day, 'day').startOf('day').format(DateToISOStringFormat);
const getEndDate = (day = 0) => customMoment().add(day, 'day').endOf('day').format(DateToISOStringFormat);

const campaignsSectionRadioGroupOptionsConfigs = (t) => ({
    campaignsSectionRadioGroupOptions: [
        {
            label: t(l.Yesterday),
            value: {
                startDate: getStartDate(-1),
                endDate: getEndDate(-1),
                toString() {
                    return l.Yesterday;
                },
            },
        },
        {
            label: t(l.Today),
            value: {
                startDate: getStartDate(),
                endDate: getEndDate(),
                toString() {
                    return l.Today;
                },
            },
        },
        {
            label: t(l.Tomorrow),
            value: {
                startDate: getStartDate(1),
                endDate: getEndDate(1),
                toString() {
                    return l.Tomorrow;
                },
            },
        },
    ],
});

const RFMChartRadioGroupOptionsConfigs = (t) => {
    return {
        RFMChartRadioGroupOptions: [
            {
                label: t(l.Sport),
                value: 1,
            },
            {
                label: t(l.Casino),
                value: 2,
            },
        ],
    };
};

const parentRadioGroupOptionsConfigs = (t) => {
    return {
        parentRadioGroupOptions: [
            {
                label: t(l.Today),
                value: {
                    startDate: customMoment().add(-1, 'days').startOf('day').toDate(),
                    endDate: customMoment().startOf('day').toDate(),
                    toString() {
                        return l.Today;
                    },
                },
            },
            {
                label: t(l.Last7Days),
                value: {
                    startDate: customMoment().add(-7, 'days').startOf('day').toDate(),
                    endDate: customMoment().startOf('day').toDate(),
                    toString() {
                        return l.Last7Days;
                    },
                },
            },
            {
                label: t(l.Last30Days),
                value: {
                    startDate: customMoment().add(-30, 'days').startOf('day').toDate(),
                    endDate: customMoment().startOf('day').toDate(),
                    toString() {
                        return l.Last30Days;
                    },
                },
            },
        ],
    };
};

const depositCumulativeChartDataTimeIntervalConfigs = (t) => {
    return {
        depositCumulativeChartDataTimeInterval: [
            { label: t(l.Hour), value: 0 },
            { label: t(l.Day), value: 1 },
            { label: t(l.Week), value: 2 },
            { label: t(l.Month), value: 3 },
            { label: t(l.Year), value: 4 },
        ],
    };
};

const getCommunicationChartOptions = (t) => {
    const excludeCommunicationList = [CommunicationTypeLabels.Bonus, CommunicationTypeLabels.FastexNotification];
    const Statuses = [
        {
            label: t(l.Success),
            value: 1,
        },
        {
            label: t(l.Failed),
            value: 0,
        },
    ];
    const CommunicationTypeFiltered = CommunicationType.reduce((acc, type) => {
        if (excludeCommunicationList.includes(type.value)) return acc;

        acc.push({
            label: t(type.label),
            value: type.value,
        });

        return acc;
    }, []);
    const channel = getMultiselectDropdownValueAndMap(CommunicationTypeFiltered);
    const status = getMultiselectDropdownValueAndMap(Statuses);

    return {
        communicationTypes: CommunicationTypeFiltered,
        communicationStatuses: Statuses,
        defaultChannels: channel,
        defaultStatuses: status,
    };
};

export {
    KPIWidgetsConfigs,
    datePickerConfigs,
    campaignsInActionColumnsConfigs,
    customerRelationshipMetricsColumnsConfigs,
    campaignsSectionRadioGroupOptionsConfigs,
    RFMChartRadioGroupOptionsConfigs,
    depositCumulativeChartDataTimeIntervalConfigs,
    parentRadioGroupOptionsConfigs,
    getCommunicationChartOptions,
};
