import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { cloneDeep, isEmpty, keys, noop } from 'lodash';
// Import UI components
import { Icon, Tooltip } from '@geneui/components';
// Import components
import { ConfirmationModal } from 'components';
// Import containers
import { UIHashComparisonContainer } from '../UIHashContainers';
// Import actions
import { DynamicSegmentActions } from 'actions';
// Import services
import { UtilsHttpService } from 'services/http';
import { getFiltersElementView, getFiltersAddDataView } from './service';
// Import constants
import { l, ModalsClassNames, ActionsIcons, SegmentFilterApplicationColumnId } from 'constants/common';

const { deleteFiltersItem } = DynamicSegmentActions;

const FilterGroupItem = ({
    item,
    index,
    withoutActions,
    handleChangeActiveList,
    resetActiveList,
    setReadOnly,
    readOnly,
    editingFilter,
    setEditingFilter,
    additionalData,
    setClientsCount,
    tabActiveIndex,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [updatedAdditionalData, setUpdateAdditionalData] = useState(null);
    const dynamicSegmentFiltersData = useSelector((state) => state.dynamicSegment);

    const { getMenuItemsById, getDataById } = useRef({
        getMenuItemsById: UtilsHttpService.getMenuItemsById(),
        getDataById: UtilsHttpService.getDataById(),
    }).current;

    const filtersGroupItemMemo = useMemo(() => {
        const filtersElementView = getFiltersElementView(t, item, updatedAdditionalData || additionalData);

        return (
            <div
                className={classNames('crm-filters-group-item', {
                    'crm-editable-item': item.comparison.hashKey === editingFilter && readOnly,
                })}
            >
                <ConfirmationModal
                    isVisibleModal={isOpenDeleteModal}
                    onOk={handelDeleteItem}
                    onCancel={setDeleteModalOpen}
                    questionLabel={t(l.DoYouWantToDeleteThisFilterItemFromDynamicSegmentFilterContainer)}
                    titleText={t(l.DeleteFilterFromDynamicSegmentFilterContainer)}
                    iconType={ActionsIcons.Delete}
                    actionLabel={t(l.Delete)}
                    className={ModalsClassNames.Delete}
                />

                <Tooltip text={filtersElementView} position="bottom">
                    <p>{filtersElementView}</p>
                </Tooltip>

                {!withoutActions && (
                    <div className="crm-filters-group-item__icons">
                        <Icon
                            type={`${
                                item.comparison.hashKey === editingFilter && readOnly
                                    ? 'bc-icon-cancel-bonus'
                                    : 'bc-icon-edit'
                            } ${item.comparison.hashKey === editingFilter ? 'is-editing-filter' : ''}`}
                            disabled={item.comparison.hashKey !== editingFilter && readOnly}
                            onClick={handleEditItem}
                        />
                        <Icon
                            type="bc-icon-trash"
                            onClick={setDeleteModalOpen}
                            disabled={item.comparison.hashKey !== editingFilter && readOnly}
                        />
                    </div>
                )}
            </div>
        );
    }, [
        additionalData,
        updatedAdditionalData,
        item.comparison.colorUI,
        item.comparison.comparison,
        item,
        readOnly,
        editingFilter,
        isOpenDeleteModal,
    ]);

    useEffect(() => {
        const { comparison, Value, viewType } = item;
        if (withoutActions || !additionalData) {
            const filteredAddData = getFiltersAddDataView(
                comparison,
                Value,
                viewType,
                getMenuItemsById,
                getDataById,
                t,
                dynamicSegmentFiltersData,
            );
            if (filteredAddData instanceof Promise) {
                if (comparison.elementType === 1) {
                    filteredAddData.then((res) => {
                        const data = res.Data[0];
                        if (JSON.stringify(data) !== JSON.stringify(additionalData)) {
                            item.setAdditionalData({ value: data.Id, label: data.Name });
                            setUpdateAdditionalData({ value: data.Id, label: data.Name });
                        }
                    });
                } else {
                    filteredAddData.then((data) => {
                        if (JSON.stringify(data) !== JSON.stringify(additionalData)) {
                            const resultAddData = data.map((el) => {
                                return {
                                    value: el.Id,
                                    label: el.Name,
                                };
                            });
                            item.setAdditionalData(resultAddData);
                            setUpdateAdditionalData(resultAddData);
                        }
                    });
                }
            } else {
                item.setAdditionalData(filteredAddData);
                setUpdateAdditionalData(filteredAddData);
            }
        } else {
            item.setAdditionalData(additionalData);
            setUpdateAdditionalData(additionalData);
        }
        /*eslint react-hooks/exhaustive-deps:0*/
    }, [additionalData, item]);

    useEffect(() => {
        if (
            item.comparison.applicationColumnId === SegmentFilterApplicationColumnId &&
            !isEmpty(dynamicSegmentFiltersData) &&
            additionalData
        ) {
            const tmpAdditionalData = cloneDeep(item.additionalData);
            tmpAdditionalData.forEach((item) => {
                const value = item.dataValue ?? item.value;
                if (keys(dynamicSegmentFiltersData).includes(value)) {
                    item.label = dynamicSegmentFiltersData[value].name;
                }
            });
            setUpdateAdditionalData(tmpAdditionalData);
        }
    }, [tabActiveIndex, dynamicSegmentFiltersData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    function cleanUp() {
        return () => {
            getMenuItemsById.cancel('FilterGroupItem:getMenuItemsById');
            getDataById.cancel('FilterGroupItem:getDataById');
        };
    }

    function setDeleteModalOpen() {
        setIsOpenDeleteModal(!isOpenDeleteModal);
    }

    function handelDeleteItem() {
        const { parentHashKey } = item;
        dispatch(deleteFiltersItem({ parentHashKey, index, id: tabActiveIndex }));
        setClientsCount({ Total: 0, Count: 0 });
        setDeleteModalOpen();
        if (readOnly) {
            resetActiveList();
            setEditingFilter(null);
        }
    }

    function handleEditItem() {
        const { hashKey, displayName } = item.comparison;
        setReadOnly(true);
        if (hashKey !== editingFilter) {
            setEditingFilter(hashKey);
            handleChangeActiveList(hashKey, displayName, index);
        } else {
            resetActiveList();
            setEditingFilter(null);
        }
    }

    return <>{filtersGroupItemMemo}</>;
};

FilterGroupItem.propTypes = {
    item: PropTypes.instanceOf(UIHashComparisonContainer),
    index: PropTypes.number,
    withoutActions: PropTypes.bool,
    readOnly: PropTypes.bool,
    handleChangeActiveList: PropTypes.func,
    resetActiveList: PropTypes.func,
    setReadOnly: PropTypes.func,
    setEditingFilter: PropTypes.func,
    editingFilter: PropTypes.string,
    additionalData: PropTypes.any,
    setClientsCount: PropTypes.func,
    tabActiveIndex: PropTypes.number.isRequired,
};

FilterGroupItem.defaultProps = {
    withoutActions: false,
    editingFilter: '',
    resetActiveList: noop,
    setEditingFilter: noop,
    readOnly: false,
    setReadOnly: noop,
    handleChangeActiveList: noop,
};

export default FilterGroupItem;
