import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isNil, last, noop, toString } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Components
import { AccessControl } from 'components';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';
import StopABTestButtonWithConfirmationModal from 'components/StopABTestButtonWithConfirmationModal';
// Import Hooks
import { useGACustomEvent } from 'hooks';
// Import Constants
import { ABTestStatusEnum } from 'constants/abTest';
import {
    StateModesLabels,
    CampaignStatusLabels,
    l,
    PERMISSIONS,
    ActionsIcons,
    GACategoryEvents,
    PARTNER_SETTINGS,
} from 'constants/common';

const { GADetailsCategory } = GACategoryEvents;

type PermissionsProps = Record<string, string | string[] | Record<string, string | string[]>>;

const CampaignActionsButtons = ({
    data,
    unArchiveCampaignAction,
    archiveCampaignAction,
    deleteCampaignAction,
    cloneCampaignAction,
    editCampaignAction,
    editCampaignAction2,
    viewCampaignReportAction,
    infoCampaignAction,
    stopSendCampaignAction,
    changeModeCampaignAction,
    stopABTestCampaignAction,
}) => {
    const { State, Status, IsNonMarketing, ArchivedDate, ABTests, CampaignId, NextRunDate } = data;
    const latestABTest = last(ABTests);
    // TODO: Create a type for latestABTest
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { Status: ABTestStatus, StartDate, AnalyticsPeriod } = latestABTest || ({} as any);
    const status = toString(Status);

    const abTestStartDate = StartDate || NextRunDate;

    const { t } = useTranslation();
    const { logCustomEvent } = useGACustomEvent();

    const isArchive = !isNil(ArchivedDate);
    const isLive = State === StateModesLabels.Live;
    const isDraft = State === StateModesLabels.Draft;
    const isCompleted = status === CampaignStatusLabels.Completed;
    const isStopped = status === CampaignStatusLabels.Stopped;
    const isInProgress = status === CampaignStatusLabels.InProgress;
    const isIdle = status === CampaignStatusLabels.Idle;

    const isVisibleStopABTestButton = () => ABTestStatus === ABTestStatusEnum.InProgress;
    const isVisibleDelete = () => !isArchive && !isCompleted && !isStopped && !isInProgress && !isIdle;
    const isVisibleEdit = () => !isArchive && !isCompleted && !isStopped && !isInProgress && !ABTests?.length;
    const isVisibleEditWithStepper = () => !isArchive && !isCompleted && !isStopped && !isInProgress;
    const isVisibleChangeMode = () => !isArchive && !isCompleted && !isStopped && !isInProgress && !isLive;
    const isVisibleArchive = () => (!isArchive && isCompleted) || (!isArchive && isStopped);
    const isVisibleStopSend = () => !isArchive && !isCompleted && !isStopped && !isDraft;

    let permissionsProps: PermissionsProps = { permissions: PERMISSIONS.EditCampaign };
    if (IsNonMarketing) {
        permissionsProps = { mandatoryPermissions: [PERMISSIONS.EditCampaign, PERMISSIONS.ManageNonMarketingCampaign] };
    }

    const onInfoAction = () => {
        logCustomEvent(
            GADetailsCategory.name,
            GADetailsCategory.events.campaignInfoActionButton,
            'campaign info action button',
            1,
        );
        infoCampaignAction(data);
    };

    const onReportAction = () => {
        logCustomEvent(
            GADetailsCategory.name,
            GADetailsCategory.events.campaignReportActionButton,
            'campaign report action button',
            1,
        );
        viewCampaignReportAction(data);
    };

    return (
        <>
            {isVisibleStopABTestButton() && (
                <AccessControl partnerSetting={PARTNER_SETTINGS.IsAllowABTest}>
                    <Tooltip text={t(l.DeleteCampaign)}>
                        <StopABTestButtonWithConfirmationModal
                            buttonType="icon"
                            campaignId={CampaignId}
                            abTestStatus={ABTestStatus}
                            abTestStartDate={abTestStartDate}
                            analyticsPeriod={AnalyticsPeriod}
                            onStopCompleted={stopABTestCampaignAction}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleDelete() && (
                <AccessControl permissions={PERMISSIONS.DeleteCampaign}>
                    <Tooltip text={t(l.DeleteCampaign)}>
                        <Button
                            icon={ActionsIcons.Delete}
                            appearance="minimal"
                            color="primary"
                            flexibility="default"
                            children={undefined}
                            onClick={() => deleteCampaignAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleEdit() && (
                <AccessControl {...permissionsProps}>
                    <Tooltip text={t(l.EditCampaign)}>
                        <Button
                            icon={ActionsIcons.Edit}
                            appearance="minimal"
                            color="primary"
                            flexibility="default"
                            children={undefined}
                            onClick={() => editCampaignAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleEditWithStepper() && (
                <AccessControl {...permissionsProps} partnerSetting={PARTNER_SETTINGS.IsAllowABTest}>
                    <Tooltip text={t(l.EditWithStepper)}>
                        <Button
                            icon={ActionsIcons.Edit}
                            appearance="minimal"
                            color="primary"
                            flexibility="default"
                            children={undefined}
                            onClick={() => editCampaignAction2(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isArchive && (
                <Tooltip text={t(l.UnarchiveCampaign)}>
                    <Button
                        icon={ActionsIcons.UnArchive}
                        appearance="minimal"
                        color="primary"
                        flexibility="default"
                        children={undefined}
                        onClick={() => unArchiveCampaignAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleArchive() && (
                <Tooltip text={t(l.ArchiveCampaign)}>
                    <Button
                        icon={ActionsIcons.Archive}
                        appearance="minimal"
                        color="primary"
                        flexibility="default"
                        children={undefined}
                        onClick={() => archiveCampaignAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleChangeMode() && (
                <Tooltip text={t(l.ChangeCampaignMode)}>
                    <Button
                        icon={ActionsIcons.TransferBold}
                        appearance="minimal"
                        color="primary"
                        flexibility="default"
                        children={undefined}
                        onClick={() => changeModeCampaignAction(data)}
                    />
                </Tooltip>
            )}
            {!isDraft && (
                <Tooltip text={t(l.CampaignReport)}>
                    <Button
                        icon={ActionsIcons.ViewReport}
                        appearance="minimal"
                        color="primary"
                        flexibility="default"
                        children={undefined}
                        onClick={onReportAction}
                    />
                </Tooltip>
            )}
            {isVisibleStopSend() && (
                <AccessControl permissions={PERMISSIONS.ExecuteCampaign}>
                    <Tooltip text={t(l.StopCampaign)}>
                        <Button
                            icon={ActionsIcons.StatusStop}
                            appearance="minimal"
                            color="primary"
                            flexibility="default"
                            children={undefined}
                            onClick={() => stopSendCampaignAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            <AccessControl permissions={PERMISSIONS.CreateCampaign}>
                <Tooltip text={t(l.CloneCampaign)}>
                    <Button
                        icon={ActionsIcons.Clone}
                        appearance="minimal"
                        color="primary"
                        flexibility="default"
                        children={undefined}
                        onClick={() => cloneCampaignAction(data)}
                    />
                </Tooltip>
            </AccessControl>
            {infoCampaignAction && (
                <Tooltip text={t(l.CampaignInfo)}>
                    <Button
                        icon={ActionsIcons.Stop}
                        appearance="minimal"
                        color="primary"
                        flexibility="default"
                        children={undefined}
                        onClick={onInfoAction}
                    />
                </Tooltip>
            )}
        </>
    );
};

CampaignActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    unArchiveCampaignAction: PropTypes.func,
    archiveCampaignAction: PropTypes.func,
    deleteCampaignAction: PropTypes.func,
    cloneCampaignAction: PropTypes.func,
    viewCampaignReportAction: PropTypes.func,
    stopSendCampaignAction: PropTypes.func,
    changeModeCampaignAction: PropTypes.func,
    editCampaignAction: PropTypes.func,
    editCampaignAction2: PropTypes.func,
    infoCampaignAction: PropTypes.func,
    stopABTestCampaignAction: PropTypes.func,
};

CampaignActionsButtons.defaultProps = {
    unArchiveCampaignAction: noop,
    archiveCampaignAction: noop,
    deleteCampaignAction: noop,
    cloneCampaignAction: noop,
    editCampaignAction: noop,
    editCampaignAction2: noop,
    viewCampaignReportAction: noop,
    stopSendCampaignAction: noop,
    changeModeCampaignAction: noop,
    stopABTestCampaignAction: noop,
};

export default memo(CampaignActionsButtons);
