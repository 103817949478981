import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { cloneDeep, first, isEmpty, isString, isUndefined, noop } from 'lodash';
import { List } from 'react-virtualized';
// Import UI Components
import { Tooltip, ExtendedInput, BusyLoader, Option, Empty, Checkbox, Switcher } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const CheckboxColumnKey = 'checkbox';

const OptionList = ({
    columns,
    value,
    valueKey,
    isLoading,
    list,
    listWidth,
    listHeight,
    listRowHeight,
    rowCount,
    emptySize,
    isMultiselect,
    contentTop,
    onChange,
    defaultSearchValue,
    onSearch,
    showSelected,
    onChangeShowSelected,
}) => {
    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState(
        columns
            .filter((x) => x.filterable)
            .reduce((acc, item) => {
                acc[item.key] = defaultSearchValue[item.key] ?? '';
                return acc;
            }, {}),
    );

    const rowRenderer = (item) => {
        if (!isUndefined(item)) {
            let tmpColumns = columns;
            if (isMultiselect) {
                tmpColumns = [{ key: CheckboxColumnKey }, ...columns];
            }
            return tmpColumns.map((column, index) => {
                const columnItemKey = column.key;

                if (isMultiselect && columnItemKey === CheckboxColumnKey) {
                    return (
                        <Checkbox
                            key={`${item[valueKey]}_${index}`}
                            checked={value.includes(item[valueKey])}
                            onChange={(e) => {
                                e.preventDefault();
                                onClickHandler(item, value.includes(item[valueKey]));
                            }}
                        />
                    );
                }

                return (
                    <Tooltip
                        key={`${item[valueKey]}_${index}`}
                        title={isString(columnItemKey) ? item[columnItemKey] : null}
                    >
                        <span className={`crm-option-column-${index} ellipsis-text`}>{item[columnItemKey]}</span>
                    </Tooltip>
                );
            });
        }
        return <></>;
    };

    const onClickHandler = (listItem, currentState) => {
        if (isMultiselect) {
            if (currentState) {
                onChange(value.filter((item) => item !== listItem[valueKey]));
            } else {
                onChange([...value, listItem[valueKey]]);
            }
        } else {
            onChange([listItem[valueKey]]);
        }
    };

    const filterChangeHandler = (e, column) => {
        const { value } = e.target;
        setSearchValue((prev) => {
            const result = cloneDeep(prev);
            result[column.key] = value;
            return result;
        });
    };

    useEffect(() => {
        onSearch(searchValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    return (
        <div className="crm-option-list-content" style={{ top: `${contentTop}px` }}>
            {!(columns.length === 1 && !first(columns).filterable) && (
                <Option
                    title={columns.map((column, index) => (
                        <div key={column.key} className="crm-option-list-header-item">
                            {column.filterable ? (
                                <ExtendedInput
                                    canClear={true}
                                    disabled={showSelected}
                                    placeholder={column.label}
                                    value={searchValue[column.key]}
                                    label={column.label}
                                    labelAppearance="swap"
                                    onChange={(e) => filterChangeHandler(e, column)}
                                />
                            ) : (
                                <span className={`crm-option-header-column-${index}`}>{column.label}</span>
                            )}
                        </div>
                    ))}
                    border="bottom"
                />
            )}

            {isMultiselect &&
                columns.reduce((acc, item) => {
                    acc = acc || item.filterable;
                    return acc;
                }, false) && (
                    <Switcher
                        disabled={isLoading}
                        label={t(l.ShowSelected)}
                        checked={showSelected}
                        onChange={() => {
                            onChangeShowSelected(!showSelected);
                        }}
                    />
                )}

            <div style={{ height: `${listHeight}px` }}>
                <BusyLoader isBusy={isLoading}>
                    {!isEmpty(list) ? (
                        <List
                            width={listWidth}
                            height={listHeight}
                            rowCount={rowCount}
                            rowHeight={listRowHeight}
                            rowRenderer={({ index, style }) => {
                                return (
                                    <Option
                                        style={style}
                                        key={list[index][valueKey]}
                                        leftCustomElement={rowRenderer(list[index])}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onClickHandler(list[index], value.includes(list[index][valueKey]));
                                        }}
                                        active={value.includes(list[index][valueKey])}
                                    />
                                );
                            }}
                        />
                    ) : (
                        <Empty title={t(l.NoDataToDisplay)} size={emptySize} />
                    )}
                </BusyLoader>
            </div>
        </div>
    );
};

OptionList.propTypes = {
    columns: PropTypes.array,
    value: PropTypes.array,
    valueKey: PropTypes.string,
    isLoading: PropTypes.bool,
    list: PropTypes.array,
    listWidth: PropTypes.number,
    listHeight: PropTypes.number,
    listRowHeight: PropTypes.number,
    rowCount: PropTypes.number,
    emptySize: PropTypes.number,
    contentTop: PropTypes.number,
    isMultiselect: PropTypes.bool,
    onChange: PropTypes.func,
    onSearch: PropTypes.func,
    defaultSearchValue: PropTypes.string,
    showSelected: PropTypes.bool,
    onChangeShowSelected: PropTypes.func,
};

OptionList.defaultProps = {
    columns: [],
    value: [],
    valueKey: 'value',
    isLoading: false,
    listWidth: 200,
    listHeight: 240,
    listRowHeight: 40,
    rowCount: 0,
    emptySize: 'big',
    contentTop: 50,
    isMultiselect: false,
    onChange: noop,
    onSearch: noop,
    defaultSearchValue: {},
    showSelected: false,
    onChangeShowSelected: noop,
};

export default memo(OptionList);
