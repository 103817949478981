import { keys, isNil } from 'lodash';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { l, TimeFormatWithoutSeconds } from 'constants/common';

const { customMomentWithoutTimezoneConversion, customMoment } = Helpers;

const DynamicDateCustomValues = [
    { label: l.Today, value: 2, count: 0, isDay: false },
    { label: l.Yesterday, value: 3, count: 1, isDay: false },
    { label: l.ThreeDaysAgo, value: 4, count: 3, isDay: false },
    { label: l.AWeekAgo, value: 5, count: 7, isDay: false },
    { label: l.LastMonth, value: 6, count: 30, isDay: false },
    { label: l.Today, value: 7, count: 0, isDay: true },
    { label: l.Yesterday, value: 8, count: 1, isDay: true },
    { label: l.ThreeDaysAgo, value: 9, count: 3, isDay: true },
    { label: l.AWeekAgo, value: 10, count: 7, isDay: true },
    { label: l.LastMonth, value: 11, count: 30, isDay: true },
    // { label: l.LastYear, value: 12, count: 365 },  TODO : need fix merge results
    // { label: l.LastThirtyMonths, value: 13, count: 900 },
    { label: l.FirstDayOfTheCurrentMonth, value: 14, count: 0, isDay: false },
    { label: l.FirstDayOfThePreviousMonth, value: 15, count: 0, isDay: false },
];

const MergeResultValues = {
    2: { label: l.Today, value: 0 },
    3: { label: l.OneDaysAgo, value: -1 },
    4: { label: l.ThreeDaysAgo, value: -3 },
    5: { label: l.AWeekAgo, value: -7 },
    6: { label: l.LastMonth, value: -30 },
    7: { label: l.Today, value: 0 },
    8: { label: l.OneDaysAgo, value: -1 },
    9: { label: l.ThreeDaysAgo, value: -3 },
    10: { label: l.AWeekAgo, value: -7 },
    11: { label: l.LastMonth, value: -30 },
    // 12: { label: l.LastYear, value: 0 },
    // 13: { label: l.LastThirtyMonths, value: 0 },
    14: { label: l.FirstDayOfTheCurrentMonth, value: 0 },
    15: { label: l.FirstDayOfThePreviousMonth, value: 0 },
};

function generateMergeResultText(count, type, customValue, timeData, t) {
    const time = `${timeData && timeData.hasTime && !customValue ? ` (${timeData.value})` : ''}`;
    let date = '';
    if (count === 0) {
        date = l.Today;
        return date + time;
    }
    if (type === 'add' && count > 0) {
        date = customValue
            ? t(l.InDaysAtThisMoment, { count: Math.abs(count) })
            : t(l.InDays, { count: Math.abs(count) });
        return date + time;
    }
    date = customValue
        ? t(l.DaysAgoAtThisMoment, { count: Math.abs(count) })
        : t(l.DaysAgo, { count: Math.abs(count) });
    return date + time;
}

function generateFirstDayOfTheMonthtText(daysCount, typeValue, timeValue, month, t) {
    const date = new Date();
    let days = 0;
    if (typeValue !== 1) {
        days = typeValue === 3 ? -+daysCount : +(+daysCount);
    }

    const count = Math.round(
        (date.getTime() -
            new Date(
                date.getFullYear(),
                date.getMonth() + +month,
                1 + days,
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
            ).getTime()) /
            86400000, // one day
    );
    if (count >= 0) {
        return `${t(l.DaysAgo, { count: Math.abs(count) })}${timeValue?.hasTime ? ` (${timeValue.value})` : ''}`;
    } else {
        return `${t(l.InDays, { count: Math.abs(count) })}${timeValue?.hasTime ? ` (${timeValue.value})` : ''}`;
    }
}

const DynamicDateOperationTypes = {
    1: 'None',
    2: 'Add',
    3: 'Subtract',
    None: 1,
    Add: 2,
    Subtract: 3,
};

const DynamicDateTypeValues = [
    { label: l.None, value: DynamicDateOperationTypes.None },
    { label: l.Add, value: DynamicDateOperationTypes.Add },
    { label: l.Subtract, value: DynamicDateOperationTypes.Subtract },
];

const LossAmountRadioDateTypeValues = [
    { label: l.Exact, value: 1, boolean: false },
    { label: l.Equivalent, value: 2, boolean: true },
];

function setDefaultTime(hasTime, data, callBack, fieldLabel) {
    if (hasTime) {
        const timeValue = customMomentWithoutTimezoneConversion(data).format(TimeFormatWithoutSeconds);
        callBack(fieldLabel, { hasTime: true, value: timeValue });
    }
}

function setFormDataObject(hasTime) {
    return {
        Operation: 0,
        Data: {
            HasTime: hasTime,
            Value: '',
            ValueType: 0,
        },
        Argument: {
            Value: null,
            ValueType: 0,
        },
    };
}

function setFormLossAmountDataObject(currencyCode, isEquivalent, originalValue) {
    return {
        CurrencyCode: currencyCode ? currencyCode.value : currencyCode,
        IsEquivalent: isEquivalent,
        OriginalValue: +originalValue,
    };
}

function getCurrentDate() {
    return customMoment().format(TimeFormatWithoutSeconds);
}

const monthsAndDays = {
    Jan: 31,
    Feb: 29,
    Mar: 31,
    Apr: 30,
    May: 31,
    Jun: 30,
    Jul: 31,
    Aug: 31,
    Sep: 30,
    Oct: 31,
    Nov: 30,
    Dec: 31,
};

function dynamicInitialDataCreate(hasTime) {
    const data = setFormDataObject(hasTime);
    data.Data.Value = null;
    data.Data.ValueType = 1;
    data.Argument.Value = '0.00:00:00';
    return data;
}

function dateDataSetter(
    data,
    setTabType,
    setFieldValue,
    fieldsLabels = {
        mergeResult: 'mergeResult',
        customValue: 'customValue',
        typeValue: 'typeValue',
        daysCount: 'daysCount',
        time: 'time',
        date: 'date',
    },
) {
    const { Data, Operation, Argument } = data;
    const { ValueType, Value, HasTime } = Data;
    const daysCountValue = Argument.Value;
    if (ValueType === 0) {
        setTabType('1');
        setDefaultTime(HasTime, Value, setFieldValue, fieldsLabels.time);
        setFieldValue(fieldsLabels.date, Value, true);
    } else {
        setTabType('2');
        setDefaultTime(HasTime, Value, setFieldValue, fieldsLabels.time);
        setFieldValue(
            fieldsLabels.customValue,
            DynamicDateCustomValues.find((customValue) => customValue.value === ValueType),
            false,
        );
        setFieldValue(
            fieldsLabels.typeValue,
            DynamicDateTypeValues.find((typeValue) => typeValue.value === Operation + 1),
            false,
        );
        setFieldValue(
            fieldsLabels.daysCount,
            daysCountValue ? daysCountValue.slice(0, daysCountValue.indexOf('.')) : 0,
            false,
        );
    }
}

function cascadeFormValidationSetter(validation, keyValueList) {
    const resultData = {};
    keys(keyValueList).forEach((key, i) => {
        if (!isNil(validation[key].isValid) || i === 0) {
            resultData[key] = keyValueList[key];
        }
    });
    return resultData;
}

const defaultValidationScenariosPerElementType = {};

const decimalRegex = /^\d{1,}(\.\d{0,9})?$/;

export {
    dynamicInitialDataCreate,
    monthsAndDays,
    getCurrentDate,
    setFormLossAmountDataObject,
    setFormDataObject,
    setDefaultTime,
    DynamicDateCustomValues,
    cascadeFormValidationSetter,
    MergeResultValues,
    generateMergeResultText,
    DynamicDateTypeValues,
    LossAmountRadioDateTypeValues,
    dateDataSetter,
    defaultValidationScenariosPerElementType,
    decimalRegex,
    generateFirstDayOfTheMonthtText,
    DynamicDateOperationTypes,
};
