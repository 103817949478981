import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { noop, isEmpty, head, last } from 'lodash';
// Import Constants
import { l, GoalDropdownOptions, GoalOptions } from 'constants/common';
// Import UI Components
import { Modal, Dropdown, ExtendedInput, Button } from '@geneui/components';
// Import Components
import { ButtonWithLoader } from 'components';
// Import SCSS
import 'assets/scss/campaignModal.scss';

const CampaignAddGoalModal = ({ data, isVisibleModal, onCancel, onOk, isLoading }) => {
    const { t } = useTranslation();
    const [selectedGoalValue, setSelectedGoalValue] = useState('');
    const [goalCount, setGoalCount] = useState(1);

    const formik = useFormik({
        initialValues: { goalTypeValue: selectedGoalValue, goalCountValue: goalCount },
        onSubmit: noop,
        validationSchema: Yup.object({
            goalCountValue: Yup.string()
                .required(t(l.CannotBeEmpty, { fieldName: t(l.goalCountValue) }))
                .matches(/^[+]?[1-9]\d*$/, t(l.MustContainsOnlyNumber, { fieldName: l.goalCountValue })),
            goalTypeValue: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.goalTypeValue) })),
        }),
    });

    const { values, errors, touched, setFieldValue, setFieldTouched, validateForm, submitForm } = formik;

    const goalTypeOnChangeHandler = (e) => {
        setFieldValue('goalTypeValue', e.value, true).then(() => setFieldTouched('goalTypeValue'));
        setFieldTouched('goalTypeValue');
        setSelectedGoalValue(e.value);
    };

    const countOnChangeHandler = (e) => {
        const { value } = e.target;
        if (value.includes('.') || value[0] === '0') return;
        setFieldValue('goalCountValue', e.target.value, true).then(() => setFieldTouched('goalCountValue'));
        setFieldTouched('goalCountValue');
        setGoalCount(value);
    };

    const handleSetGoal = () => {
        submitForm().then(() =>
            validateForm(values).then((formErrors) => {
                if (isEmpty(formErrors)) {
                    onOk(selectedGoalValue, goalCount);
                }
            }),
        );
    };

    const init = () => {
        const goal = data?.GoalName;

        if (goal) {
            const goalValue = GoalOptions[head(goal.split(' = '))];
            const goalCount = last(goal.split(' = '));
            setSelectedGoalValue(goalValue);
            setGoalCount(goalCount);
            formik.values.goalCountValue = goalCount;
            formik.values.goalTypeValue = goalValue;
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    return (
        <Modal
            size="content-size"
            background="dark-background"
            position="center"
            title={t(l.SetGoal)}
            className="crm-modal crm-set-goal-modal"
            appearance="default"
            closable={false}
            closeOnClickOutside={true}
            visible={isVisibleModal}
            onCancel={onCancel}
            footer={
                <>
                    <Button color="default" appearance={'minimal'} onClick={onCancel}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader
                        isLoading={isLoading}
                        color="primary"
                        onClick={handleSetGoal}
                        isDisabled={selectedGoalValue === '' || goalCount === ''}
                    >
                        {t(l.Set)}
                    </ButtonWithLoader>
                </>
            }
        >
            <>
                <Dropdown
                    placeholder={t(l.GoalType)}
                    labelAppearance="swap"
                    isMultiSelect={false}
                    disabled={false}
                    hasSearch={true}
                    inputSize="default"
                    appearance="outline"
                    data={GoalDropdownOptions}
                    searchPlaceholderText="Search"
                    noDataText={t(l.NoDataFound)}
                    value={selectedGoalValue}
                    onChange={goalTypeOnChangeHandler}
                    errorText={touched.goalTypeValue && errors.goalTypeValue}
                />

                <ExtendedInput
                    title={t(l.Count)}
                    label={t(l.Count)}
                    placeholder={t(l.Count)}
                    labelAppearance="swap"
                    pattern="[0-9]*"
                    type="number"
                    min={1}
                    onChange={countOnChangeHandler}
                    value={goalCount}
                    isValid={!(touched.goalCountValue && errors.goalCountValue)}
                    errorText={touched.goalCountValue && errors.goalCountValue}
                />
            </>
        </Modal>
    );
};

CampaignAddGoalModal.propTypes = {
    isVisibleModal: PropTypes.bool.isRequired,
    isOnLiveMode: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
};

export default memo(CampaignAddGoalModal);
