import React, { memo, useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
// Import Components
import TemplateInfoTab from './TemplateInfoTab';
import { DisplayModeTabs, DeviceTabs } from 'components';
// Import UI Components
import { BusyLoader, Tabs, Tab, Tooltip } from '@geneui/components';
// Import Services
import { UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import Actions
import { PartnerLanguageAction } from 'actions';
// Import Constants
import { DeviceTypes, l, TemplateTypesLabels, DisplayModeTypes, DeviceTabInfos, ModeTabInfos } from 'constants/common';
import { TemplateType } from 'services/template';

const { getPartnerLanguageList } = PartnerLanguageAction;

const TemplateInfo = ({
    defaultTemplateId,
    defaultBody,
    defaultSubject,
    childItemsInfo,
    getTemplateInfo,
    deliveryMethod,
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { doGetRequest } = useRequest();

    const [activeDeviceTab, setActiveDeviceTab] = useState(DeviceTypes.FULL_SCREEN);
    const [displayModeTab, setDisplayModeTab] = useState(DisplayModeTypes.LIGHT_MODE);
    const [activeLanguageTab, setActiveLanguageTab] = useState(defaultTemplateId);
    const [urlTypesData, setUrlTypesData] = useState([]);

    const [isVisibleSubjectField, setIsVisibleSubjectField] = useState(false);

    const [isRequestedTabsData, setIsRequestedTabsData] = useState(
        childItemsInfo.reduce(
            (accumulator, item) => ({
                ...accumulator,
                [item.TemplateId]: false,
            }),
            {},
        ),
    );

    const { getPartnerLanguage, getPopUpComparisons } = useMemo(
        () => ({
            getPartnerLanguage: UtilsHttpService.getPartnerLanguage(),
            getPopUpComparisons: UtilsHttpService.getPopUpComparisons(),
        }),
        [],
    );

    const partnerLanguages = useSelector((state) => state.partnerLanguages);

    const childItemsAdditionalInfo = useMemo(() => {
        if (isEmpty(partnerLanguages)) return [];

        return childItemsInfo.map((item) => {
            const name = `${item.Language.toUpperCase()} - ${partnerLanguages[item.Language]?.Name}`;
            return {
                ...item,
                languageCode: item.Language.toUpperCase(),
                languageName: name,
                tooltipText: name,
            };
        });
    }, [childItemsInfo, partnerLanguages]);

    const { dataTabs } = useRef({
        dataTabs: childItemsInfo.reduce(
            (accumulator, item) => ({
                ...accumulator,
                [item.TemplateId]: { subject: null, body: null, isBusy: true },
            }),
            {},
        ),
    }).current;

    const [tabsData, setTabsData] = useState(dataTabs);

    const handleTabChange = (templateId) => {
        setActiveLanguageTab(+templateId);

        if (+templateId === +defaultTemplateId) return;

        if (!isRequestedTabsData[templateId]) {
            setIsRequestedTabsData({ ...isRequestedTabsData, [templateId]: true });
            getTemplateInfo(templateId).then((data) => {
                if (deliveryMethod === TemplateTypesLabels[TemplateType.PushNotification]) {
                    const { message_title: messageTitle, message_body: messageBody } = JSON.parse(data.Body);
                    dataTabs[templateId] = { subject: messageTitle, body: messageBody, isBusy: false };
                } else {
                    dataTabs[templateId] = { subject: data.Subject, body: data.Body, isBusy: false };
                }
                setTabsData({ ...dataTabs });
            });
        }
    };

    function getDefaultTemplateSubject() {
        return deliveryMethod === TemplateTypesLabels[TemplateType.PushNotification]
            ? JSON.parse(defaultBody).message_title
            : defaultSubject;
    }

    function getDefaultTemplateBody() {
        return deliveryMethod === TemplateTypesLabels[TemplateType.PushNotification]
            ? JSON.parse(defaultBody).message_body
            : defaultBody;
    }

    const init = () => {
        setIsVisibleSubjectField(deliveryMethod !== TemplateTypesLabels[TemplateType.SMS]);
        isEmpty(partnerLanguages) && getPartnerLanguageList(dispatch, getPartnerLanguage.request);

        if (deliveryMethod === TemplateTypesLabels[TemplateType.DynamicPopUp]) {
            doGetRequest(getPopUpComparisons.request, {
                successCallback: (Data) => {
                    const popUpNotificationUrlComparisons = Data.map(({ Id, Name }) => ({ value: Id, label: Name }));
                    setUrlTypesData(popUpNotificationUrlComparisons);
                },
            });
        }
    };

    const cleanUp = () => {
        return () => {
            getPartnerLanguage.cancel('TemplateInfo:getPartnerLanguage');
            getPopUpComparisons.cancel('TemplateInfo:getPopUpComparisons');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <div className="wrap-tabs-holder-info">
            <div className="wrap-tabs-holder-info-inner">
                {deliveryMethod !== TemplateTypesLabels.Viber ||
                    (deliveryMethod !== TemplateTypesLabels.Telegram && (
                        <div className="inner-wrap-tabs-holder-info">
                            <DeviceTabs activeTab={activeDeviceTab} setActiveTab={setActiveDeviceTab} />
                        </div>
                    ))}
                {deliveryMethod !== TemplateTypesLabels.Telegram && (
                    <div className="display-mode-tabs-wrapper">
                        <DisplayModeTabs activeTab={displayModeTab} setActiveTab={setDisplayModeTab} />
                    </div>
                )}
            </div>
            <Tabs type="basic" position="top" fixedSize={false} onChange={handleTabChange}>
                <Tab
                    key={defaultTemplateId}
                    title={
                        <Tooltip text={t(l.Default)}>
                            <span>{t(l.Default)}</span>
                        </Tooltip>
                    }
                >
                    <TemplateInfoTab
                        isSubjectVisible={isVisibleSubjectField}
                        subject={getDefaultTemplateSubject()}
                        body={getDefaultTemplateBody()}
                        activeTab={activeDeviceTab}
                        setActiveTab={setActiveDeviceTab}
                        deliveryMethod={deliveryMethod}
                        configs={{
                            bodyConfig: DeviceTabInfos[activeDeviceTab],
                            modeConfig: ModeTabInfos[displayModeTab],
                            urlTypesData,
                        }}
                    />
                </Tab>

                {childItemsAdditionalInfo.map((childItem) => (
                    <Tab
                        key={childItem.TemplateId}
                        title={
                            <Tooltip text={childItem.tooltipText}>
                                <span>
                                    {activeLanguageTab === childItem.TemplateId
                                        ? childItem.languageName
                                        : childItem.languageCode}
                                </span>
                            </Tooltip>
                        }
                    >
                        <BusyLoader isBusy={tabsData[childItem.TemplateId].isBusy} type="spinner" spinnerSize="medium">
                            <TemplateInfoTab
                                isSubjectVisible={isVisibleSubjectField}
                                subject={tabsData[childItem.TemplateId].subject}
                                body={tabsData[childItem.TemplateId].body}
                                activeTab={activeDeviceTab}
                                setActiveTab={setActiveDeviceTab}
                                deliveryMethod={deliveryMethod}
                                configs={{
                                    bodyConfig: DeviceTabInfos[activeDeviceTab],
                                    modeConfig: ModeTabInfos[displayModeTab],
                                    urlTypesData,
                                }}
                            />
                        </BusyLoader>
                    </Tab>
                ))}
            </Tabs>
        </div>
    );
};

TemplateInfo.propTypes = {
    defaultTemplateId: PropTypes.number.isRequired,
    defaultBody: PropTypes.string.isRequired,
    defaultSubject: PropTypes.string.isRequired,
    getTemplateInfo: PropTypes.func.isRequired,
    childItemsInfo: PropTypes.array,
    deliveryMethod: PropTypes.number.isRequired,
};

TemplateInfo.defaultProps = {
    childItemsInfo: [],
};

export default memo(TemplateInfo);
