import React from 'react';
import { isNil, isObject } from 'lodash';
// Import Services
import { Helpers } from 'services';
import { TemplateHttpService, BonusHttpService } from 'services/http';
// Import Components
import { ClientId } from 'components';
// Import Constants
import {
    l,
    DateTimeFormat,
    GenderLabels,
    BonusTypeLabels,
    BonusAcceptanceTypeLabels,
    BonusResultTypeLabels,
    FilterComparisions,
    FilterHolderFields,
    DataTableColumnsCustomTypes,
    CampaignLogStatusLabels,
    CommunicationType,
    CommunicationStatus,
    PERMISSIONS,
    AlertTypes,
} from 'constants/common';

const { Equals, Between, In, Like } = FilterComparisions;
const { textInput, rangeDate, multiSelect, multiSelectAutocomplete } = FilterHolderFields;

const { customMomentWithoutTimezoneConversion } = Helpers;

const getRequestBody = (searchValue, sortColumn) => {
    return {
        Filters: [{ Name: 'Name', Comparision: Like, Values: [searchValue] }],
        Pageing: { PageSize: 100, PageNumber: 1 },
        Sorting: { Name: sortColumn, Direction: 'desc' },
    };
};

const dateTimeFormat = (date) => {
    return isNil(date) ? '' : customMomentWithoutTimezoneConversion(date).format(DateTimeFormat);
};

const CampaignActivityTableConfig = (t) => {
    return {
        columns: [
            {
                text: t(l.ExecuteId),
                dataKey: 'CampaignExecuteId',
                sortable: true,
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.StartDate),
                dataKey: 'StartDate',
                sortable: true,
                formatter: (StartDate) => {
                    return dateTimeFormat(StartDate);
                },
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.EndDate),
                dataKey: 'EndDate',
                sortable: true,
                formatter: (EndDate) => {
                    return dateTimeFormat(EndDate);
                },
            },
            {
                text: t(l.Status),
                dataKey: 'Status',
                sortable: false,
            },
        ],
        defaultSorting: { name: 'StartDate', direction: 'desc' },
    };
};

const PlayersColumns = (t, withClientIdRenderer = true) => {
    return [
        {
            text: t(l.Country),
            dataKey: 'Country',
            sortable: false,
        },
        {
            text: t(l.Currency),
            dataKey: 'Currency',
            sortable: false,
        },
        {
            text: t(l.ClientId),
            dataKey: 'ClientId',
            sortable: false,
            colRenderer: (clientId) => (withClientIdRenderer ? <ClientId clientId={clientId} /> : clientId),
        },
        {
            text: t(l.Email),
            dataKey: 'Email',
            sortable: false,
        },
        {
            text: t(l.Gender),
            dataKey: 'Gender',
            sortable: false,
            colRenderer: (Gender) => <div> {GenderLabels[Gender]} </div>,
        },
        {
            text: t(l.FirstName),
            dataKey: 'FirstName',
            sortable: false,
        },
        {
            text: t(l.LastName),
            dataKey: 'LastName',
            sortable: false,
        },
        {
            text: t(l.Login),
            dataKey: 'Login',
            sortable: false,
        },
        {
            text: t(l.MobilePhone),
            dataKey: 'MobilePhone',
            sortable: false,
        },
    ];
};

const CampaignBonusReceivedPlayersTableConfig = (t) => {
    return {
        columns: [
            {
                text: t(l.BonusType),
                dataKey: 'Bonustype',
                sortable: false,
                colRenderer: (Bonustype) => <div>{BonusTypeLabels[Bonustype]}</div>,
            },
            {
                text: t(l.BonusSum),
                dataKey: 'Amount',
                sortable: false,
            },
            ...PlayersColumns(t),
            {
                text: t(l.CreatedDate),
                dataKey: 'CreatedDate',
                sortable: false,
                formatter: (CreatedDate) => {
                    return dateTimeFormat(CreatedDate);
                },
            },
            {
                text: t(l.Status),
                dataKey: 'Status',
                sortable: false,
            },
            {
                text: t(l.WinAmount),
                dataKey: 'WinAmount',
                sortable: false,
            },
            {
                text: t(l.AcceptanceType),
                dataKey: 'AcceptanceType',
                sortable: false,
                colRenderer: (AcceptanceType) => <div> {BonusAcceptanceTypeLabels[AcceptanceType]} </div>,
            },
            {
                text: t(l.IsUsed),
                dataKey: 'IsUsed',
                sortable: false,
                colRenderer: (IsUsed) => <div> {`${IsUsed}`} </div>,
            },
            {
                text: t(l.ResultType),
                dataKey: 'ResultType',
                sortable: false,
                colRenderer: (ResultType) => <div> {BonusResultTypeLabels[ResultType]} </div>,
            },
        ],
    };
};

const CampaignActivityOpenedMailPlayersTableConfig = (t) => {
    return {
        columns: [
            ...PlayersColumns(t),
            {
                text: t(l.OpenDate),
                dataKey: 'OpenDate',
                sortable: false,
                formatter: (OpenDate) => {
                    return dateTimeFormat(OpenDate);
                },
            },
        ],
    };
};

const CampaignActivityClickedLinkPlayersTableConfig = (t) => {
    return {
        columns: [
            ...PlayersColumns(t),
            {
                text: t(l.ClickedDate),
                dataKey: 'ClickDate',
                sortable: false,
                formatter: (ClickDate) => {
                    return dateTimeFormat(ClickDate);
                },
            },
        ],
    };
};

const CampaignActivityReceivedPlayersTableConfig = (t) => {
    return {
        columns: [
            ...PlayersColumns(t),
            {
                text: t(l.SentDate),
                dataKey: 'SendDate',
                sortable: false,
                formatter: (sentDate) => {
                    return dateTimeFormat(sentDate);
                },
            },
            {
                text: t(l.Status),
                dataKey: 'Status',
                sortable: false,
            },
        ],
    };
};

const TrackedLinksClickPlayersConfig = (t) => {
    return {
        columns: [
            ...PlayersColumns(t, false),
            {
                text: t(l.PrimaryPhone),
                dataKey: 'PrimaryPhone',
                sortable: false,
            },
        ],
    };
};
const activityMetricsTabsKey = {
    ReceivedPlayers: 'ReceivedPlayers',
    PlayersWhoOpenedMail: 'PlayersWhoOpenedMail',
    PlayersWhoClickedTheLink: 'PlayersWhoClickedTheLink',
};

const activityMetricsTabsKeyConfig = [
    activityMetricsTabsKey.ReceivedPlayers,
    activityMetricsTabsKey.PlayersWhoOpenedMail,
    activityMetricsTabsKey.PlayersWhoClickedTheLink,
];

const activityMetricsTabsActivityKPIs = {
    [activityMetricsTabsKey.PlayersWhoClickedTheLink]: 'ClickCount',
    [activityMetricsTabsKey.PlayersWhoOpenedMail]: 'OpenCount',
    [activityMetricsTabsKey.ReceivedPlayers]: 'SendCount',
};

const CampaignLiveMonitoringConfig = {
    newPageSize: 10000,
    comparatorPropName: 'CampaignLogId',
    sorting: { Name: 'CampaignLogId', Direction: 'desc' },
    paging: { PageSize: 100, PageNumber: 1 },
    getStatusInterval: 5000,
    campaignIdFilterName: 'CampaignId',
    campaignLogIdFilterName: 'CampaignLogId',
};

const CampaignLogStatusTypes = [
    { label: 'Succes', value: CampaignLogStatusLabels.Succes },
    { label: 'Failed', value: CampaignLogStatusLabels.Failed },
];

const CampaignLiveMonitorFilterConfig = [
    {
        type: rangeDate.type,
        labelAppearance: 'swap',
        valueFieldKey: 'CreatedDate',
        comparision: Between,
        popoverAlign: 'start',
        displayName: 'CreatedDate',
        value: rangeDate.defaultValue,
        withTime: true,
    },
    {
        type: textInput.type,
        labelAppearance: 'swap',
        valueFieldKey: 'CampaignExecuteResultId',
        comparision: Equals,
        displayName: 'ExecutionId',
        value: textInput.defaultValue,
    },
    {
        type: textInput.type,
        labelAppearance: 'swap',
        valueFieldKey: 'ClientId',
        comparision: Equals,
        displayName: 'ClientId',
        value: textInput.defaultValue,
    },
    {
        type: multiSelect.type,
        labelAppearance: 'swap',
        valueFieldKey: 'Status',
        isMultiSelect: true,
        comparision: In,
        disabled: false,
        hasSearch: true,
        displayName: 'Status',
        data: CampaignLogStatusTypes,
        value: multiSelect.defaultValue,
    },
];

const CommunicationListFilterConfig = {
    defaultInputsData: {
        defaultInputsDataIds: {
            TemplateId: 4,
            BonusId: 5,
        },
        defaultInputsDataList: [
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ClientId',
                comparision: Equals,
                displayName: l.PlayerId,
                value: '',
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'SentDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.SentDate,
                value: [null, null],
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Channel',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Channel,
                value: [],
                data: CommunicationType,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Status',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Status,
                value: [],
                data: CommunicationStatus,
            },
            {
                type: multiSelectAutocomplete.type,
                labelAppearance: 'swap',
                valueFieldKey: 'TemplateId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Template,
                value: [],
                data: [],
                getRequest: TemplateHttpService.getTemplateList,
                getData(requestObj, searchValue) {
                    return new Promise((resolve, reject) => {
                        requestObj
                            .request(getRequestBody(searchValue, 'CreatedDate'))
                            .then((response) => {
                                const { HasError, AlertMessage, Data } = response;
                                if (!HasError) {
                                    resolve(
                                        isObject(Data)
                                            ? Data.Data.map((item) => ({ label: item.Name, value: item.TemplateId }))
                                            : [],
                                    );
                                } else {
                                    reject({ AlertType: AlertTypes.error, AlertMessage: AlertMessage });
                                }
                            })
                            .catch((error) => {
                                reject({ AlertType: AlertTypes.danger, AlertMessage: error });
                            });
                    });
                },
            },
            {
                type: multiSelectAutocomplete.type,
                labelAppearance: 'swap',
                valueFieldKey: 'BonusId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Award,
                value: [],
                data: [],
                permission: PERMISSIONS.ViewAwards,
                getRequest: BonusHttpService.getBonusList,
                getData(requestObj, searchValue) {
                    return new Promise((resolve, reject) => {
                        requestObj
                            .request(getRequestBody(searchValue, 'Id'), {
                                isIncludeExpired: true,
                            })
                            .then((response) => {
                                const { HasError, AlertMessage, Data } = response;
                                if (!HasError) {
                                    resolve(
                                        isObject(Data)
                                            ? Data.Data.map((item) => ({ label: item.Name, value: item.Id }))
                                            : [],
                                    );
                                } else {
                                    reject({ AlertType: AlertTypes.error, AlertMessage: AlertMessage });
                                }
                            })
                            .catch((error) => {
                                reject({ AlertType: AlertTypes.danger, AlertMessage: error });
                            });
                    });
                },
            },
        ],
    },
};

const getCommunicationsRequestBody = (name, value) => {
    return {
        Filters: [{ Name: name, Comparision: FilterComparisions.In, Values: value }],
        Pageing: { PageSize: 20, PageNumber: 1 },
        Sorting: { Name: 'SentDate', Direction: 'desc' },
    };
};

export {
    dateTimeFormat,
    CampaignActivityTableConfig,
    CampaignBonusReceivedPlayersTableConfig,
    CampaignActivityOpenedMailPlayersTableConfig,
    CampaignActivityClickedLinkPlayersTableConfig,
    CampaignActivityReceivedPlayersTableConfig,
    TrackedLinksClickPlayersConfig,
    activityMetricsTabsKey,
    activityMetricsTabsKeyConfig,
    CampaignLiveMonitoringConfig,
    CampaignLiveMonitorFilterConfig,
    getCommunicationsRequestBody,
    CommunicationListFilterConfig,
    activityMetricsTabsActivityKPIs,
};
