import {
    CRMRequestService,
    CRMRequestDownloadLinkService,
    postCancelableRequest,
    deleteCancelableRequest,
    getCancelableRequest,
} from './http.config';

const CampaignHttpService = {
    getCampaigntList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/List',
        });
    },
    getCampaignById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { isClone: false },
            endpointPath: ({ campaignId, isClone }) => `/Campaign/Get?id=${campaignId}&isClone=${isClone}`,
        });
    },
    getCampaignPreviewById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `Campaign/Preview?id=${campaignId}`,
        });
    },
    getCampaignBonusesReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/GetCampaignBonusesReport',
        });
    },
    getCampaignBonusInfo: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            defaultParams: { campaignType: 0 },
            endpointPath: ({ campaignId, startDate, endDate, campaignType }) =>
                `/Campaign/GetCampaignBonusInfo?campaignId=${campaignId}&startDate=${startDate}&endDate=${endDate}&campaignType=${campaignType}`,
        });
    },
    getCampaignEmailActivity: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/GetTrackActionData',
        });
    },
    getCampaignEmailsReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/GetCampaignEmailsReport',
        });
    },
    getCampaignEmailTrackInfoOpen: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/GetDataEmailTrackInfoOpen',
        });
    },
    getCampaignEmailTrackInfoClick: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/GetDataEmailTrackInfoClick',
        });
    },
    getCampaignEmailTrackInfo: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ CampaignId }) => `/Campaign/GetCampaignEmailTrackInfo?campaignId=${CampaignId}`,
        });
    },
    getCampaignABTestResult: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ CampaignId }) => `/Campaign/GetABTestResult?campaignId=${CampaignId}`,
        });
    },
    getCampaignBonusChartsData: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/GetBonusChartsData',
        });
    },
    getCampaignEmailChartsData: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/GetEmailChartsData',
        });
    },
    getCampaignTemplatesLinkTrackData: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Campaign/ListTemplatesLinkTrackData',
        });
    },
    getCampaignStatus: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `/Campaign/GetCampaignStatus?id=${campaignId}`,
        });
    },
    getCampaignLogList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/CampaignLog/List',
        });
    },
    deleteCampaign: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `/Campaign/Delete?id=${campaignId}`,
        });
    },
    deleteCampaigns: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Campaign/Delete/`,
        });
    },
    archiveCampaign: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `Campaign/Archive?id=${campaignId}`,
        });
    },
    unarchiveCampaign: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `/Campaign/unArchive?id=${campaignId}`,
        });
    },
    archiveCampaigns: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Campaign/Archive`,
        });
    },
    unarchiveCampaigns: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Campaign/unArchive`,
        });
    },
    stopSendCampaigns: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `Campaign/Stop?id=${campaignId}`,
        });
    },
    changeModeCampaign: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `TargetState/Create`,
        });
    },
    getCampaignIdGoalState: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `/Campaign/GetCampaignIdGoalState?campaignId=${campaignId}`,
        });
    },
    rerunCampaign: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Campaign/Rerun`,
        });
    },
    listOfClientsClickedTemplateLink: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId, trackedLinkId }) =>
                `/Campaign/ListofClientsClickedTemplatesLink?trackedLinkId=${trackedLinkId}&campaignId=${campaignId}`,
        });
    },
    createCampaign: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `Campaign/Create`,
        });
    },
    editCampaign: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `Campaign/Update?id=${campaignId}`,
        });
    },
    getCampainEffectivityData: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId, date }) =>
                `/Campaign/GetCampainEffectivityData?campaignId=${campaignId}&date=${date}`,
        });
    },
    getCampaignGeneralDetails: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId, topRows }) =>
                `/Campaign/GetCampaignGeneralDetails?campaignId=${campaignId}&topRows=${topRows}`,
        });
    },
    setCampaignGoal: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/CampaignGoal/Create',
        });
    },
    deleteCampaignGoal: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ id }) => `/CampaignGoal/Delete?id=${id}`,
        });
    },
    downloadTrackLogByCampaignId: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            defaultParams: { isClone: false },
            endpointPath: ({ campaignId, documentType }) =>
                `/EmailTrackInfo/DownloadTrackLogByCampaignId?campaignId=${campaignId}&DocumentType=${documentType}`,
        });
    },
    downloadCampaignLogs: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            defaultParams: { isClone: false },
            endpointPath: ({ campaignId, isDownload }) =>
                `/EmailTrackInfo/DownloadCampaignLogs?campaignId=${campaignId}&isDownload=${isDownload}`,
        });
    },
    downloadEmailReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: ({ isDownload }) => `Campaign/DownloadEmailReport?isDownload=${isDownload}`,
        });
    },
    downloadEmailClickReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: ({ isDownload }) => `Campaign/DownloadEmailClickReport?isDownload=${isDownload}`,
        });
    },
    downloadEmailOpenReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: ({ isDownload }) => `Campaign/DownloadEmailOpenReport?isDownload=${isDownload}`,
        });
    },
    downloadBonusReport: () => {
        return postCancelableRequest({
            requestService: CRMRequestDownloadLinkService,
            endpointPath: ({ isDownload }) => `Campaign/DownloadBonusReport?isDownload=${isDownload}`,
        });
    },
};

export default CampaignHttpService;
