import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { noop, cloneDeep, isEmpty, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
// Import Components
import { Scheduler } from 'components';
// Import Constants
import {
    ScheduleModalViews,
    TimeFormatWithoutSeconds,
    MonthDayYearFormat,
    DefaultDatesDict,
    l,
    CustomerJourneyGroupBlockTypes,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';
import { resultScheduleDataCreator, isSchedulerUnitPairsInvalid } from 'services/schedular';
import { UtilsHttpService } from 'services/http';
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Validation
import { CJScheduleValidationSchema } from 'validators/schemas.validation';
// Import Hooks
import { useRequest } from 'hooks';

const { notRepeatView } = ScheduleModalViews;
const { customMoment, getPlusOneDayDate } = Helpers;

const CJSchedule = ({ defaultValue, getUpdate, option, node }) => {
    const { settings } = useSelector((state) => state.header);
    const { ActivePartner } = settings;
    const { doPostRequest } = useRequest();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: !isEmpty(defaultValue)
            ? { ...defaultValue }
            : {
                  startDate: customMoment().format(MonthDayYearFormat),
                  endDate: customMoment(getPlusOneDayDate()).format(MonthDayYearFormat),
                  startTime: customMoment().format(TimeFormatWithoutSeconds),
                  datesDict: cloneDeep(DefaultDatesDict),
                  selectedDateSelector: notRepeatView,
                  modalView: notRepeatView,
                  timeZone: ActivePartner.TimeZone,
                  isActiveSchedule: option?.Required,
              },
        onSubmit: noop,
        validationSchema: CJScheduleValidationSchema(option, node),
    });

    const { values, setFieldValue, submitForm, errors, setValues } = formik;
    const { startDate, endDate, startTime, datesDict, selectedDateSelector, modalView, timeZone, isActiveSchedule } =
        values;

    const [isVisibleRecCount, setIsVisibleRecCount] = useState(false);
    const [recurrenceData, setRecurrenceData] = useState({});

    const { getScheduleRunDates } = useRef({
        getScheduleRunDates: UtilsHttpService.getScheduleRunDates(),
    }).current;

    const isHourlyScheduleEnabled = useMemo(() => {
        return node?.data?.metaData?.Options?.reduce((isHourlyEnabled, option) => {
            if (option?.CustomAttributes?.ScheduleHourly) {
                isHourlyEnabled = true;
            }

            return isHourlyEnabled;
        }, false);
    }, [node]);

    const setDictData = (data, viewType) => {
        const obj = { ...datesDict };
        obj[viewType] = data;
        setFieldValue('datesDict', obj);
    };

    useEffect(() => {
        setFieldValue('selectedDateSelector', modalView);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalView]);

    const setModalView = (value) => {
        setFieldValue('modalView', value);
    };

    const startDateChangeHandler = (value) => {
        const fieldsValues = { ...values, startDate: value };

        if (modalView === notRepeatView) {
            fieldsValues.endDate = value;
        }

        setValues(fieldsValues, true);
    };

    const startTimeChangeHandler = (e) => {
        const { value } = e.target;
        const match = value.match(/[0-9]?[0-9]?:?[0-9]?[0-9]?/g);
        let matchedValue = match[0] || match[1] || '';

        if (matchedValue.length === 2) {
            matchedValue += ':';
        }
        setFieldValue('startTime', matchedValue, true);
    };

    const endDateChangeHandler = (value) => {
        setFieldValue('endDate', value, true);
    };

    const timeZoneChangeHandler = (value) => {
        setFieldValue('timeZone', value, true);
    };

    const setSelectedDateSelector = (value) => {
        setFieldValue('selectedDateSelector', value, true);
    };

    const getRecCount = (cron, startDate, endDate, timeZone) => {
        if (!isNil(cron)) {
            doPostRequest(getScheduleRunDates.request, {
                requestBody: { cronExpression: cron, startDate: startDate, endDate: endDate, timeZone: timeZone },
                successCallback: (Data) => setRecurrenceData(Data),
            });
            setIsVisibleRecCount(true);
        }
    };

    const getCroneTimes = () => {
        const { StartDate, EndDate, CronSchedule, TimeZone } = resultScheduleDataCreator(
            startTime,
            startDate,
            endDate,
            selectedDateSelector,
            datesDict,
            timeZone,
        );

        getUpdate({
            startDate: StartDate,
            endDate: EndDate,
            cronSchedule: CronSchedule,
            timeZone,
            isActiveSchedule,
        });

        if (isSchedulerUnitPairsInvalid(datesDict[modalView])) {
            setRecurrenceData({});
            return;
        }

        getRecCount(CronSchedule, StartDate, EndDate, TimeZone);
    };

    const handleScheduleOnOffChange = () => {
        setFieldValue('isActiveSchedule', !isActiveSchedule, true);
    };

    useEffect(() => {
        submitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getCroneTimes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, startTime, datesDict, selectedDateSelector, timeZone, isActiveSchedule]);

    const respectiveScheduleInfoMessage = useMemo(() => {
        switch (CustomerJourneyGroupBlockTypes[node.data.metaData.Group]) {
            case CustomerJourneyGroupBlockTypes.condition:
                return t(l.CJConditionSchedulerOffInfoMessage);
            case CustomerJourneyGroupBlockTypes.target:
                return t(l.CJTargetSchedulerOffInfoMessage);
            case CustomerJourneyGroupBlockTypes.action:
                return t(l.CJActionSchedulerOffInfoMessage);
            default:
                return '';
        }
    }, [node.data.metaData.Group, t]);

    return (
        <>
            <div className={classNames('scheduler-modal-wrap', { 'disable-scheduler': !isActiveSchedule })}>
                <Scheduler
                    dictData={datesDict}
                    view={modalView}
                    startDate={startDate}
                    endDate={endDate}
                    startTime={startTime}
                    selectedDateSelector={selectedDateSelector}
                    viewMode="fullView"
                    setView={setModalView}
                    setDictData={setDictData}
                    setStartTime={startTimeChangeHandler}
                    setEndDate={endDateChangeHandler}
                    setStartDate={startDateChangeHandler}
                    startDateErrorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.startDate)}
                    endDateErrorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.endDate)}
                    startTimeErrorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.startTime)}
                    recurrenceData={recurrenceData}
                    isVisibleRecCount={isVisibleRecCount}
                    timeZone={timeZone}
                    setTimeZone={timeZoneChangeHandler}
                    setSelectedDateSelector={setSelectedDateSelector}
                    withSwitcher={!option?.Required}
                    isActiveSchedule={isActiveSchedule}
                    setIsActiveSchedule={handleScheduleOnOffChange}
                    schedulerOffInfoMessage={respectiveScheduleInfoMessage}
                    schedulerValidationErrorText={errors?.datesDict?.label}
                    isHourlyEnabled={isHourlyScheduleEnabled}
                />
            </div>
        </>
    );
};

CJSchedule.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
    node: PropTypes.object.isRequired,
};

export default memo(CJSchedule);
