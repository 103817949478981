import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
// Import hooks
import { useListBaseRequestBody, useGoToRoute, useRequest } from 'hooks';
// Import services
import { SegmentHttpService } from 'services/http';
// Import components
import PredefinedListMenu from './PredefinedListMenu';
import { SegmentModal } from 'components/SegmentModal';
// Import constants
import { SegmentTypesLabels, ModalsActions } from 'constants/common';
import { PredefinedListMenuRequestModel } from './constants';
import { RoutesList } from 'routes';

const PredefinedSegmentListMenu = ({
    handleClose,
    predefinedInfoModalVisibleState,
    setPredefinedInfoModalVisibleState,
}) => {
    const { goToRoute } = useGoToRoute();
    const { doPostRequest } = useRequest();
    const [selectedInfoItem, setSelectedInfoItem] = useState(null);
    const { Filters, Paging, Sorting } = PredefinedListMenuRequestModel.segments;
    const [baseRequestBody] = useListBaseRequestBody(Filters, Paging, Sorting);

    const handleInfoItemClick = (item) => {
        setSelectedInfoItem(item);
        setPredefinedInfoModalVisibleState(true);
    };

    const handleListItemClick = (item) => {
        if (item.Type === SegmentTypesLabels.Dynamic) {
            goToRoute(RoutesList.SEGMENT_DYNAMIC_PREDEFINED, { segmentId: item.SegmentId });
        } else {
            goToRoute(RoutesList.SEGMENT_COMPOSITE_CLONE, { segmentId: item.SegmentId });
        }
        handleClose();
    };

    const modalCloseHandler = (action) => {
        if (action !== ModalsActions.CLOSE) {
            handleClose();
        }
        setPredefinedInfoModalVisibleState(false);
    };

    const { getSegmentListRequest } = useRef({ getSegmentListRequest: SegmentHttpService.getSegmentList() }).current;

    const getPredefinedSegmentList = () => {
        return doPostRequest(getSegmentListRequest.request, { requestBody: baseRequestBody });
    };

    const destroyComponent = () => {
        return () => {
            setSelectedInfoItem(null);
            setPredefinedInfoModalVisibleState(false);
            getSegmentListRequest.cancel();
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(destroyComponent, []);

    return (
        <>
            {predefinedInfoModalVisibleState && !isNil(selectedInfoItem) && (
                <>
                    <SegmentModal
                        segmentId={selectedInfoItem.SegmentId}
                        segmentType={selectedInfoItem.Type}
                        handleClose={modalCloseHandler}
                        isVisible={predefinedInfoModalVisibleState}
                        isPredefined={true}
                    />
                </>
            )}

            <PredefinedListMenu
                infoItemClickHandler={handleInfoItemClick}
                listItemClickHandler={handleListItemClick}
                getList={getPredefinedSegmentList}
            />
        </>
    );
};

PredefinedSegmentListMenu.propTypes = {
    handleClose: PropTypes.func,
    predefinedInfoModalVisibleState: PropTypes.bool.isRequired,
    setPredefinedInfoModalVisibleState: PropTypes.func.isRequired,
};

PredefinedSegmentListMenu.defaultProps = {
    handleClose: noop,
};

export default memo(PredefinedSegmentListMenu);
