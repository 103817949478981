import { useCallback, useEffect, useMemo, useState } from 'react';
import { cloneDeep, isEmpty, isNil, keys, lowerCase, noop, unionBy } from 'lodash';
// Import Services
import { UtilsHttpService } from 'services/http';
import { subscribe } from 'services/event';
// Import Hooks
import { useRequest, useAutocompleteRequest } from 'hooks';
// Import Constants
import { MenuItemTypes, GlobalEvents, SubscriberSources } from 'constants/common';

const AutoCompleteFlagValue = 1;

let menuItemsConfig = {};
let menuItems = {};

const UseMenuItem = (menuItemId, doInitialRequest = true, url = null) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingRestore, setIsLoadingRestore] = useState(false);
    const [isLoadingConfig, setIsLoadingConfig] = useState(true);

    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();
    const { getAutocompleteRequest: getAutocompleteRequestSearch, cancelRequest: cancelRequestSearch } =
        useAutocompleteRequest();
    const byUrl = !isNil(url);

    const [config, setConfig] = useState(menuItemsConfig[byUrl ? url : menuItemId]);

    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [convertedSearchData, setConvertedSearchData] = useState([]);
    const [convertedData, setConvertedData] = useState([]);

    const { doGetRequest, doPostRequest } = useRequest();

    const { getMenuItemsConfig, getByPath, postByPath } = useMemo(
        () => ({
            getByPath: UtilsHttpService.getByPath,
            postByPath: UtilsHttpService.postByPath,
            getMenuItemsConfig: UtilsHttpService.getMenuItemsConfig(),
        }),
        [],
    );

    const clearMenuItems = () => {
        menuItems = {};
        menuItemsConfig = {};
    };

    const init = (menuItemConfig) => {
        subscribe(GlobalEvents.PartnerChanged, SubscriberSources.MenuItem, clearMenuItems);

        search({}, true, menuItemConfig);
    };

    const createUrlConfig = () => {
        const keyColumnName = 'Id';

        const idColumn = {
            name: keyColumnName,
            filterable: false,
            isName: false,
            isId: true,
            isKey: true,
            orderKey: 0,
        };

        const nameColumn = {
            name: 'Name',
            filterable: false,
            isName: true,
            isId: false,
            isKey: false,
            orderKey: 1,
            idColumn: idColumn,
        };
        const groups = [[idColumn, nameColumn]];

        const option = {
            requestPath: url,
            keyGroupNameColumn: nameColumn,
            groups: groups,
            params: groups
                .flat()
                .filter((groupItem) => groupItem.filterable)
                .reduce((acc, item) => {
                    acc[item.name] = item;
                    return acc;
                }, {}),
        };
        menuItemsConfig[url] = {
            type: 0,
            option: option,
            isAutocomplete: false,
            keyColumnName: keyColumnName,
        };
        return menuItemsConfig[url];
    };

    useEffect(() => {
        if (isEmpty(menuItemsConfig)) {
            doGetRequest(getMenuItemsConfig.request, {
                successCallback: (data) => {
                    menuItemsConfig = data.reduce((acc, item) => {
                        let option;
                        let keyColumnName;
                        if (item.Type === MenuItemTypes.MultiField) {
                            const tmpOption = JSON.parse(item.OptionsJson);
                            let keyGroupNameColumn;
                            const groups = tmpOption.Groups.map((group) => {
                                let groupIdColumn;
                                let groupNameColumn;
                                const result = group.map((groupItem) => {
                                    const tmpGroupItem = {
                                        name: groupItem.Name,
                                        filterable: !!groupItem.Filterable,
                                        isName: !!groupItem.IsName,
                                        isId: !!groupItem.IsId,
                                        isKey: !!groupItem.IsKey,
                                        orderKey: groupItem.OrderKey,
                                    };
                                    if (tmpGroupItem.isKey) {
                                        keyColumnName = tmpGroupItem.name;
                                    }
                                    if (tmpGroupItem.isId) {
                                        groupIdColumn = tmpGroupItem;
                                    } else if (tmpGroupItem.isName) {
                                        groupNameColumn = tmpGroupItem;
                                    }

                                    return tmpGroupItem;
                                });
                                if (!isNil(groupNameColumn) && !isNil(groupIdColumn)) {
                                    groupNameColumn.idColumn = groupIdColumn;
                                    if (isNil(keyGroupNameColumn) && groupIdColumn.name === keyColumnName) {
                                        keyGroupNameColumn = groupNameColumn;
                                    }
                                }
                                return result;
                            });
                            option = {
                                requestPath: tmpOption.Endpoint,
                                groups: groups,
                                keyGroupNameColumn: keyGroupNameColumn,
                                params: groups
                                    .flat()
                                    .filter((groupItem) => groupItem.filterable)
                                    .reduce((acc, item) => {
                                        acc[item.name] = item;
                                        return acc;
                                    }, {}),
                            };
                        } else {
                            keyColumnName = 'Id';

                            const idColumn = {
                                name: keyColumnName,
                                filterable: (item.Attributes & AutoCompleteFlagValue) > 0,
                                isName: false,
                                isId: true,
                                isKey: true,
                                orderKey: 0,
                            };

                            const nameColumn = {
                                name: 'Name',
                                filterable: true,
                                isName: true,
                                isId: false,
                                isKey: false,
                                orderKey: 1,
                                idColumn: idColumn,
                            };
                            const groups = [[idColumn, nameColumn]];

                            option = {
                                requestPath: 'OptionsList/GetMenuItems',
                                keyGroupNameColumn: nameColumn,
                                groups: groups,
                                params: groups
                                    .flat()
                                    .filter((groupItem) => groupItem.filterable)
                                    .reduce((acc, item) => {
                                        acc[item.name] = item;
                                        return acc;
                                    }, {}),
                            };
                        }
                        acc[item.Id] = {
                            type: item.Type,
                            option: option,
                            isAutocomplete: (item.Attributes & AutoCompleteFlagValue) > 0,
                            keyColumnName: keyColumnName,
                        };
                        return acc;
                    }, {});

                    const tmpConfig = byUrl ? createUrlConfig() : menuItemsConfig[menuItemId];
                    setConfig(cloneDeep(tmpConfig));
                    setIsLoadingConfig(false);
                    init(tmpConfig);
                },
            });
        } else {
            setIsLoadingConfig(false);
            const tmpConfig = byUrl ? menuItemsConfig[url] ?? createUrlConfig() : menuItemsConfig[menuItemId];
            setConfig(cloneDeep(tmpConfig));
            init(tmpConfig);
        }

        return () => {
            cancelRequest();
            cancelRequestSearch();
            getMenuItemsConfig.cancel('useMenuItem: getMenuItemsConfig');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const convertToLabelValue = (data) => {
        return isNil(data) ? [] : data.map(({ Id, Name }) => ({ label: Name, value: Id }));
    };

    useEffect(() => {
        if (!isNil(config?.isAutocomplete) && config.isAutocomplete) {
            menuItems[menuItemId] = unionBy(menuItems[menuItemId] ?? [], data, config.keyColumnName);
        }
        setConvertedData(() => convertToLabelValue(data));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, config]);

    useEffect(() => {
        setConvertedSearchData(() => convertToLabelValue(searchData));
    }, [searchData]);

    const restore = useCallback(
        (ides = [], menuItemConfig = config) => {
            return new Promise((resolve) => {
                if (!isNil(menuItemConfig)) {
                    if (!isNil(menuItems[menuItemId])) {
                        const filteredData = menuItems[menuItemId].filter((menuItem) =>
                            ides.includes(menuItem[menuItemConfig.keyColumnName]),
                        );

                        if (filteredData.length === ides.length && !isEmpty(ides)) {
                            setData(() => filteredData);
                            setSearchData(() => filteredData);
                            resolve(filteredData);
                            return;
                        }
                    }

                    const requestBody = menuItemConfig.isAutocomplete ? { Ides: ides } : {};

                    setIsLoadingRestore(() => true);

                    const successCallback = (data) => {
                        setData((prev) => {
                            return isEmpty(ides) ? data : unionBy(prev, data, menuItemConfig.keyColumnName);
                        });
                        setSearchData(() => data);
                        setIsLoadingRestore(() => false);
                        resolve(data);
                    };

                    if (menuItemConfig.type !== MenuItemTypes.MultiField) {
                        const requestObj = getAutocompleteRequest(postByPath);

                        doPostRequest(requestObj.request, {
                            queryString: { url: menuItemConfig.option.requestPath, queryParams: { menuItemId } },
                            requestBody: requestBody.Ides ?? [],
                            successCallback,
                        });
                    } else {
                        const requestObj = getAutocompleteRequest(getByPath);

                        doGetRequest(requestObj.request, {
                            queryString: { url: menuItemConfig.option.requestPath, queryParams: requestBody },
                            successCallback,
                        });
                    }
                } else {
                    resolve([]);
                    return;
                }
            });
        },
        [config, doGetRequest, doPostRequest, getAutocompleteRequest, getByPath, postByPath, menuItemId],
    );

    const search = (searchValue, init = false, menuItemConfig = config) => {
        // for developers
        if (!isNil(menuItemConfig)) {
            if (
                (init && !menuItemConfig.isAutocomplete && isNil(menuItems[byUrl ? url : menuItemId])) ||
                (init && doInitialRequest && menuItemConfig.isAutocomplete) ||
                (!init && (menuItemConfig.isAutocomplete || isNil(menuItems[byUrl ? url : menuItemId])))
            ) {
                const requestBody = init
                    ? {}
                    : keys(menuItemConfig.option.params).reduce((acc, item) => {
                          if (!isEmpty(searchValue[item])) {
                              const param = menuItemConfig.option.params[item];
                              if (!isNil(param.idColumn) && param.idColumn.filterable) {
                                  const regexMatched = searchValue[item].match(/^( )*(?<id>(-)?\d+)( )*$/);
                                  if (!isNil(regexMatched?.groups?.id)) {
                                      acc[param.idColumn.name] = +regexMatched.groups.id;
                                  } else {
                                      acc[item] = searchValue[item];
                                  }
                              } else {
                                  acc[item] = searchValue[item];
                              }
                          }
                          return acc;
                      }, {});
                setIsLoading(() => true);

                const successCallback = (data) => {
                    if (init && !menuItemConfig.isAutocomplete) {
                        menuItems[byUrl ? url : menuItemId] = data;
                    }
                    setData((prev) => {
                        return unionBy(prev, data, menuItemConfig.keyColumnName);
                    });
                    setSearchData(() => data);
                    setIsLoading(() => false);
                };

                if (menuItemConfig.type !== MenuItemTypes.MultiField && !byUrl) {
                    requestBody.menuItemId = menuItemId;
                    const requestObj = getAutocompleteRequestSearch(postByPath);

                    const { Id, ...queryParamsWithoutId } = requestBody;

                    doPostRequest(requestObj.request, {
                        queryString: { url: menuItemConfig.option.requestPath, queryParams: queryParamsWithoutId },
                        requestBody: Id ? [Id] : [],
                        successCallback,
                    });
                } else {
                    const requestObj = getAutocompleteRequestSearch(getByPath);

                    doGetRequest(requestObj.request, {
                        queryString: { url: menuItemConfig.option.requestPath, queryParams: requestBody },
                        successCallback,
                    });
                }
            } else if (init) {
                if (!isNil(menuItems[byUrl ? url : menuItemId])) {
                    const tmpData = menuItems[byUrl ? url : menuItemId];
                    setData(tmpData);
                    setSearchData(() => tmpData);
                    setIsLoading(() => false);
                }
            } else {
                setSearchData(() => data.filter((item) => lowerCase(item.Name).includes(lowerCase(searchValue?.Name))));
            }
        }
    };

    return {
        data,
        convertedData,
        searchData,
        convertedSearchData,
        isLoading,
        isLoadingRestore,
        isLoadingConfig,
        search: (searchValue) => {
            search(searchValue);
        },
        config,
        restore: byUrl ? noop : restore,
    };
};

export { UseMenuItem };
