import { BlockEditorIcons } from 'constants/utils';
import juice from 'juice';
import { noop } from 'lodash';
const c = {};

const opt = {
    cmdOpenImport: 'gjs-open-import-template',
    cmdTglImages: 'gjs-toggle-images',
    componentOutline: 'core:component-outline',
    cmdInlineHtml: 'gjs-get-inlined-html',
    cmtTglImagesLabel: 'Toggle Images',
    cmdBtnMoveLabel: 'Move',
    cmdBtnUndoLabel: 'Undo',
    cmdBtnRedoLabel: 'Redo',
    cmdBtnDesktopLabel: 'Desktop',
    cmdBtnTabletLabel: 'Tablet',
    cmdBtnMobileLabel: 'Mobile',
    modalTitleImport: 'Import template',
    modalTitleExport: 'Export template',
    modalLabelImport: '',
    modalLabelExport: '',
    modalBtnImport: 'Import',
    codeViewerTheme: 'hopscotch',
    openBlocksBtnTitle: c.openBlocksBtnTitle || '',
    openLayersBtnTitle: c.openLayersBtnTitle || '',
    openSmBtnTitle: c.openSmBtnTitle || '',
    openTmBtnTitle: c.openTmBtnTitle || '   ',
    expTplBtnTitle: c.expTplBtnTitle || 'View Code',
    fullScrBtnTitle: c.fullScrBtnTitle || 'FullScreen',
    swichtVwBtnTitle: c.swichtVwBtnTitle || 'View Components',
    categoryLabel: c.categoryLabel || '',
    importPlaceholder: '',
    defaultTemplate: '', // Default template in case the canvas is empty
    inlineCss: 1,
    cellStyle: {
        padding: 0,
        margin: 0,
        'vertical-align': 'top',
    },
    tableStyle: {
        height: '150px',
        margin: '0 auto 10px auto',
        padding: '5px 5px 5px 5px',
        width: '100%',
    },
    sect100BlkLabel: '1 Section',
    sect50BlkLabel: '2 Section',
    sect30BlkLabel: '1/3 Section',
    sect37BlkLabel: '3/7 Section',
    buttonBlkLabel: 'Button',
    dividerBlkLabel: 'Divider',
    textBlkLabel: 'Text',
    textSectionBlkLabel: 'Text Section',
    imageBlkLabel: 'Image',
    quoteBlkLabel: 'Quote',
    linkBlkLabel: 'Link',
    linkBlockBlkLabel: 'Link Block',
    gridItemsBlkLabel: 'Grid Items',
    listItemsBlkLabel: 'List Items',
    assetsModalTitle: c.assetsModalTitle || 'Select image',
    blockLabel: 'Custom Code',
    buttonLabel: 'Save',
    modalTitle: 'Insert your code',
    placeholderScript: `<div style="pointer-events: none; padding: 10px;">
      <svg viewBox="0 0 24 24" style="height: 30px; vertical-align: middle;">
        <path d="M13 14h-2v-4h2m0 8h-2v-2h2M1 21h22L12 2 1 21z"></path>
        </svg>
      Custom code with <i>&lt;script&gt;</i> can't be rendered on the canvas
    </div>`,
    customStyleManager: [
        {
            name: 'General',
            buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom'],
            properties: [
                {
                    name: 'Alignment',
                    property: 'float',
                    type: 'radio',
                    defaults: 'none',
                    list: [
                        { value: 'none', className: 'fa fa-times' },
                        { value: 'left', className: 'fa fa-align-left' },
                        { value: 'right', className: 'fa fa-align-right' },
                    ],
                },
                { property: 'position', type: 'select' },
            ],
        },
        {
            name: 'Dimension',
            open: false,
            buildProps: ['width', 'flex-width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
            properties: [
                {
                    id: 'flex-width',
                    type: 'integer',
                    name: 'Width',
                    units: ['px', '%'],
                    property: 'flex-basis',
                    toRequire: 1,
                },
                {
                    property: 'margin',
                    properties: [
                        { name: 'Top', property: 'margin-top' },
                        { name: 'Right', property: 'margin-right' },
                        { name: 'Bottom', property: 'margin-bottom' },
                        { name: 'Left', property: 'margin-left' },
                    ],
                },
                {
                    property: 'padding',
                    properties: [
                        { name: 'Top', property: 'padding-top' },
                        { name: 'Right', property: 'padding-right' },
                        { name: 'Bottom', property: 'padding-bottom' },
                        { name: 'Left', property: 'padding-left' },
                    ],
                },
            ],
        },
        {
            name: 'Typography',
            open: false,
            buildProps: [
                'font-family',
                'font-size',
                'font-weight',
                'letter-spacing',
                'color',
                'line-height',
                'text-align',
                'text-decoration',
                'text-shadow',
            ],
            properties: [
                { name: 'Font', property: 'font-family' },
                { name: 'Weight', property: 'font-weight' },
                { name: 'Font color', property: 'color' },
                {
                    property: 'text-align',
                    type: 'radio',
                    defaults: 'left',
                    list: [
                        { value: 'left', name: 'Left', className: 'fa fa-align-left' },
                        { value: 'center', name: 'Center', className: 'fa fa-align-center' },
                        { value: 'right', name: 'Right', className: 'fa fa-align-right' },
                        { value: 'justify', name: 'Justify', className: 'fa fa-align-justify' },
                    ],
                },
                {
                    property: 'text-decoration',
                    type: 'radio',
                    defaults: 'none',
                    list: [
                        { value: 'none', name: 'None', className: 'fa fa-times' },
                        { value: 'underline', name: 'underline', className: 'fa fa-underline' },
                        { value: 'line-through', name: 'Line-through', className: 'fa fa-strikethrough' },
                    ],
                },
                {
                    property: 'text-shadow',
                    properties: [
                        { name: 'X position', property: 'text-shadow-h' },
                        { name: 'Y position', property: 'text-shadow-v' },
                        { name: 'Blur', property: 'text-shadow-blur' },
                        { name: 'Color', property: 'text-shadow-color' },
                    ],
                },
            ],
        },
        {
            name: 'Decorations',
            open: false,
            buildProps: ['opacity', 'background-color', 'border-radius', 'border', 'box-shadow', 'background'],
            properties: [
                {
                    type: 'slider',
                    property: 'opacity',
                    defaults: 1,
                    step: 0.01,
                    max: 1,
                    min: 0,
                },
                {
                    property: 'border-radius',
                    properties: [
                        { name: 'Top', property: 'border-top-left-radius' },
                        { name: 'Right', property: 'border-top-right-radius' },
                        { name: 'Bottom', property: 'border-bottom-left-radius' },
                        { name: 'Left', property: 'border-bottom-right-radius' },
                    ],
                },
                {
                    property: 'box-shadow',
                    properties: [
                        { name: 'X position', property: 'box-shadow-h' },
                        { name: 'Y position', property: 'box-shadow-v' },
                        { name: 'Blur', property: 'box-shadow-blur' },
                        { name: 'Spread', property: 'box-shadow-spread' },
                        { name: 'Color', property: 'box-shadow-color' },
                        { name: 'Shadow type', property: 'box-shadow-type' },
                    ],
                },
                {
                    property: 'background',
                    properties: [
                        { name: 'Image', property: 'background-image' },
                        { name: 'Repeat', property: 'background-repeat' },
                        { name: 'Position', property: 'background-position' },
                        { name: 'Attachment', property: 'background-attachment' },
                        { name: 'Size', property: 'background-size' },
                    ],
                },
            ],
        },
        // {
        //     name: 'Extra',
        //     open: false,
        //     buildProps: ['transition', 'perspective', 'transform'],
        //     properties: [
        //         {
        //             property: 'transition',
        //             properties: [
        //                 { name: 'Property', property: 'transition-property' },
        //                 { name: 'Duration', property: 'transition-duration' },
        //                 { name: 'Easing', property: 'transition-timing-function' },
        //             ],
        //         },
        //         {
        //             property: 'transform',
        //             properties: [
        //                 { name: 'Rotate X', property: 'transform-rotate-x' },
        //                 { name: 'Rotate Y', property: 'transform-rotate-y' },
        //                 { name: 'Rotate Z', property: 'transform-rotate-z' },
        //                 { name: 'Scale X', property: 'transform-scale-x' },
        //                 { name: 'Scale Y', property: 'transform-scale-y' },
        //                 { name: 'Scale Z', property: 'transform-scale-z' },
        //             ],
        //         },
        //     ],
        // },
        // {
        //     name: 'Flex',
        //     open: false,
        //     properties: [
        //         {
        //             name: 'Flex Container',
        //             property: 'display',
        //             type: 'select',
        //             defaults: 'block',
        //             list: [
        //                 { value: 'block', name: 'Disable' },
        //                 { value: 'flex', name: 'Enable' },
        //             ],
        //         },
        //         {
        //             name: 'Flex Parent',
        //             property: 'label-parent-flex',
        //             type: 'integer',
        //         },
        //         {
        //             name: 'Direction',
        //             property: 'flex-direction',
        //             type: 'radio',
        //             defaults: 'row',
        //             list: [
        //                 {
        //                     value: 'row',
        //                     name: 'Row',
        //                     className: 'icons-flex icon-dir-row',
        //                     title: 'Row',
        //                 },
        //                 {
        //                     value: 'row-reverse',
        //                     name: 'Row reverse',
        //                     className: 'icons-flex icon-dir-row-rev',
        //                     title: 'Row reverse',
        //                 },
        //                 {
        //                     value: 'column',
        //                     name: 'Column',
        //                     title: 'Column',
        //                     className: 'icons-flex icon-dir-col',
        //                 },
        //                 {
        //                     value: 'column-reverse',
        //                     name: 'Column reverse',
        //                     title: 'Column reverse',
        //                     className: 'icons-flex icon-dir-col-rev',
        //                 },
        //             ],
        //         },
        //         {
        //             name: 'Justify',
        //             property: 'justify-content',
        //             type: 'radio',
        //             defaults: 'flex-start',
        //             list: [
        //                 {
        //                     value: 'flex-start',
        //                     className: 'icons-flex icon-just-start',
        //                     title: 'Start',
        //                 },
        //                 {
        //                     value: 'flex-end',
        //                     title: 'End',
        //                     className: 'icons-flex icon-just-end',
        //                 },
        //                 {
        //                     value: 'space-between',
        //                     title: 'Space between',
        //                     className: 'icons-flex icon-just-sp-bet',
        //                 },
        //                 {
        //                     value: 'space-around',
        //                     title: 'Space around',
        //                     className: 'icons-flex icon-just-sp-ar',
        //                 },
        //                 {
        //                     value: 'center',
        //                     title: 'Center',
        //                     className: 'icons-flex icon-just-sp-cent',
        //                 },
        //             ],
        //         },
        //         {
        //             name: 'Align',
        //             property: 'align-items',
        //             type: 'radio',
        //             defaults: 'center',
        //             list: [
        //                 {
        //                     value: 'flex-start',
        //                     title: 'Start',
        //                     className: 'icons-flex icon-al-start',
        //                 },
        //                 {
        //                     value: 'flex-end',
        //                     title: 'End',
        //                     className: 'icons-flex icon-al-end',
        //                 },
        //                 {
        //                     value: 'stretch',
        //                     title: 'Stretch',
        //                     className: 'icons-flex icon-al-str',
        //                 },
        //                 {
        //                     value: 'center',
        //                     title: 'Center',
        //                     className: 'icons-flex icon-al-center',
        //                 },
        //             ],
        //         },
        //         {
        //             name: 'Flex Children',
        //             property: 'label-parent-flex',
        //             type: 'integer',
        //         },
        //         {
        //             name: 'Order',
        //             property: 'order',
        //             type: 'integer',
        //             defaults: 0,
        //             min: 0,
        //         },
        //         {
        //             name: 'Flex',
        //             property: 'flex',
        //             type: 'composite',
        //             properties: [
        //                 {
        //                     name: 'Grow',
        //                     property: 'flex-grow',
        //                     type: 'integer',
        //                     defaults: 0,
        //                     min: 0,
        //                 },
        //                 {
        //                     name: 'Shrink',
        //                     property: 'flex-shrink',
        //                     type: 'integer',
        //                     defaults: 0,
        //                     min: 0,
        //                 },
        //                 {
        //                     name: 'Basis',
        //                     property: 'flex-basis',
        //                     type: 'integer',
        //                     units: ['px', '%', ''],
        //                     unit: '',
        //                     defaults: 'auto',
        //                 },
        //             ],
        //         },
        //         {
        //             name: 'Align',
        //             property: 'align-self',
        //             type: 'radio',
        //             defaults: 'auto',
        //             list: [
        //                 {
        //                     value: 'auto',
        //                     name: 'Auto',
        //                 },
        //                 {
        //                     value: 'flex-start',
        //                     title: 'Start',
        //                     className: 'icons-flex icon-al-start',
        //                 },
        //                 {
        //                     value: 'flex-end',
        //                     title: 'End',
        //                     className: 'icons-flex icon-al-end',
        //                 },
        //                 {
        //                     value: 'stretch',
        //                     title: 'Stretch',
        //                     className: 'icons-flex icon-al-str',
        //                 },
        //                 {
        //                     value: 'center',
        //                     title: 'Center',
        //                     className: 'icons-flex icon-al-center',
        //                 },
        //             ],
        //         },
        //     ],
        // },
    ],
    baseCss: ` 
    * {
    box-sizing: border-box;
    }
    html, body, [data-gjs-type=wrapper] {
    min-height: 100%;
    }
    body {
    margin: 0;
    height: 100%;
    background-color: #fff;
    font-family: "Open Sans", sans-serif ;
    }
    [data-gjs-type=wrapper] {
    overflow: auto;
    overflow-x: hidden;
    }
    
    * ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1)
    }
    
    * ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2)
    }
    
    * ::-webkit-scrollbar {
    width: 10px
    }
    
    .gjs-hovered{
        background: rgba(0, 0, 0, 0.03);
    }
    
    #wrapper > table tr {
    outline: none;
    }
    
    #wrapper table {
    border-collapse: separate;
    border-spacing: 5px;
    }
    .gjs-selected {
    outline: 1px solid #3b97e3 !important;
    }
    .gjs-plh-image {
    outline: 1px solid #ffca6f;
    margin: 3px;
    }
    html .gjs-selected-parent {
        outline: 2px solid #1473e6 !important;
    }
    `,
};

function createBlocks(blockManager) {
    let tableStyleStr = '';
    let cellStyleStr = '';

    const tableStyle = opt.tableStyle || {};
    const cellStyle = opt.cellStyle || {};

    for (const prop in tableStyle) {
        tableStyleStr += `${prop}: ${tableStyle[prop]}; `;
    }

    for (const prop in cellStyle) {
        cellStyleStr += `${prop}: ${cellStyle[prop]}; `;
    }

    blockManager.getAll().reset();
    blockManager.add('sect100', {
        label: opt.sect100BlkLabel,
        category: opt.categoryLabel,
        attributes: { class: 'gjs-fonts gjs-f-b1' },
        content: `<table style="${tableStyleStr}">
            <tr>
            <td style="${cellStyleStr}"></td>
            </tr>
            </table>`,
    });

    blockManager.add('sect50', {
        label: opt.sect50BlkLabel,
        category: opt.categoryLabel,
        attributes: { class: 'gjs-fonts gjs-f-b2' },
        content: `<table style="${tableStyleStr}">
            <tr>
            <td style="${cellStyleStr} width: 50%"></td>
            <td style="${cellStyleStr} width: 50%"></td>
            </tr>
            </table>`,
    });

    blockManager.add('sect30', {
        label: opt.sect30BlkLabel,
        category: opt.categoryLabel,
        attributes: { class: 'gjs-fonts gjs-f-b3' },
        content: `<table style="${tableStyleStr}">
            <tr>
            <td style="${cellStyleStr} width: 33.3333%"></td>
            <td style="${cellStyleStr} width: 33.3333%"></td>
            <td style="${cellStyleStr} width: 33.3333%"></td>
            </tr>
            </table>`,
    });

    blockManager.add('sect37', {
        label: opt.sect37BlkLabel,
        category: opt.categoryLabel,
        attributes: { class: 'gjs-fonts gjs-f-b37' },
        content: `<table style="${tableStyleStr}">
            <tr>
            <td style="${cellStyleStr} width:30%"></td>
            <td style="${cellStyleStr} width:70%"></td>
            </tr>
            </table>`,
    });

    blockManager.add('button', {
        label: opt.buttonBlkLabel,
        category: opt.categoryLabel,
        content: '<a class="button">Button</a>',
        attributes: { class: 'gjs-fonts gjs-f-button' },
    });

    blockManager.add('divider', {
        label: opt.dividerBlkLabel,
        category: opt.categoryLabel,
        content: `<table style="width: 100%; margin-top: 10px; margin-bottom: 10px;">
            <tr>
            <td class="divider"></td>
            </tr>
        </table>
        <style>
        .divider {
            background-color: rgba(0, 0, 0, 0.1);
            height: 1px;
        }
        </style>`,
        attributes: { class: 'gjs-fonts gjs-f-divider' },
    });

    blockManager.add('text', {
        label: opt.textBlkLabel,
        category: opt.categoryLabel,
        attributes: { class: 'gjs-fonts gjs-f-text' },
        content: '<p class="paragraph">Insert your text here</p>',
    });

    blockManager.add('text-sect', {
        label: opt.textSectionBlkLabel,
        category: opt.categoryLabel,
        content:
            '<h1 class="heading">Insert title here</h1><p class="paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>',
        attributes: { class: 'gjs-fonts gjs-f-h1p' },
    });

    blockManager.add('image', {
        label: opt.imageBlkLabel,
        category: opt.categoryLabel,
        attributes: { class: 'gjs-fonts gjs-f-image' },

        content: {
            type: 'image',
            style: { color: 'black' },
            activeOnRender: 1,
            src: BlockEditorIcons.imageBlockPath,
        },
    });

    blockManager.add('quote', {
        label: opt.quoteBlkLabel,
        category: opt.categoryLabel,
        content:
            '<blockquote class="quote">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore ipsum dolor sit</blockquote>',
        attributes: { class: 'fa fa-quote-right' },
    });

    blockManager.add('link', {
        label: opt.linkBlkLabel,
        category: opt.categoryLabel,
        attributes: { class: 'fa fa-link' },
        content: {
            type: 'link',
            content: 'Link',
            style: { color: '#3b97e3' },
        },
    });

    blockManager.add('link-block', {
        label: opt.linkBlockBlkLabel,
        category: opt.categoryLabel,
        attributes: { class: 'fa fa-link-block' },
        content: {
            type: 'link',
            editable: false,
            droppable: true,
            style: {
                display: 'inline-block',
                padding: '5px',
                'min-height': '50px',
                'min-width': '50px',
            },
        },
    });

    const gridItem = `<table class="grid-item-card">
            <tr>
            <td class="grid-item-card-cell">
                <img class="grid-item-image" src="http://placehold.it/250x150/78c5d6/fff/" alt="Image"/>
                <table class="grid-item-card-body">
                <tr>
                    <td class="grid-item-card-content">
                    <h1 class="card-title">Title here</h1>
                    <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                    </td>
                </tr>
                </table>
            </td>
            </tr>
        </table>`;

    blockManager.add('grid-items', {
        label: opt.gridItemsBlkLabel,
        category: opt.categoryLabel,
        content: `<table class="grid-item-row">
            <tr>
            <td class="grid-item-cell2-l">${gridItem}</td>
            <td class="grid-item-cell2-r">${gridItem}</td>
            </tr>
        </table>`,
        attributes: { class: 'fa fa-th' },
    });

    const listItem = `<table class="list-item">
            <tr>
            <td class="list-item-cell">
                <table class="list-item-content">
                <tr class="list-item-row">
                    <td class="list-cell-left">
                    <img class="list-item-image" src="http://placehold.it/150x150/78c5d6/fff/" alt="Image"/>
                    </td>
                    <td class="list-cell-right">
                    <h1 class="card-title">Title here</h1>
                    <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                    </td>
                </tr>
                </table>
            </td>
            </tr>
        </table>`;

    blockManager.add('list-items', {
        label: opt.listItemsBlkLabel,
        category: opt.categoryLabel,
        content: listItem + listItem,
        attributes: { class: 'fa fa-th-list' },
    });

    blockManager.add('custom-code', {
        label: opt.blockLabel,
        category: opt.categoryLabel,
        activate: true,
        select: true,
        content: { type: 'custom-code' },
        attributes: { class: 'fa bc-icon-code-hide' },
        // ...blockCustomCode
    });
}

const getInlineHtml = (editor) => {
    const tmpHtml = editor.getHtml() + `<style>${editor.getCss()}</style>`;
    return juice(tmpHtml);
};

function createCommands(editorCommands) {
    // editorCommands.add('undo', {
    //     run(editor, sender) {
    //         sender.set('active', 0);
    //         if (editor.UndoManager.hasUndo()) {
    //             editor.UndoManager.undo(1);
    //         }
    //     },
    // });
    // editorCommands.add('redo', {
    //     run(editor, sender) {
    //         sender.set('active', 0);
    //         editor.UndoManager.redo(1);
    //     },
    // });

    const appendToContent = (target, content) => {
        if (content instanceof HTMLElement) {
            target.appendChild(content);
        } else if (content) {
            target.insertAdjacentHTML('beforeend', content);
        }
    };

    // Add the custom code command
    editorCommands.add('custom-code:open-modal', {
        key: 'custom-code-plugin__code',

        run(editor, _sender, opts = {}) {
            this.editor = editor;
            this.options = opts;
            this.target = opts.target || editor.getSelected();
            const target = this.target;

            if (target && target.get('editable')) {
                this.showCustomCode(target);
            }
        },

        stop(editor) {
            editor.Modal.close();
        },

        /**
         * Method which tells how to show the custom code
         * @param  {Component} target
         */
        showCustomCode(target) {
            const { editor, options } = this;
            const title = options.title || opt.modalTitle;
            const content = this.getContent();
            const code = target.get('custom-code-plugin__code') || '';
            editor.Modal.open({ title, content })
                .getModel()
                .once('change:open', () => editor.stopCommand(this.id));
            this.getCodeViewer().setContent(code);
        },

        /**
         * Custom pre-content. Can be a simple string or an HTMLElement
         */
        getPreContent: noop,

        /**
         * Custom post-content. Can be a simple string or an HTMLElement
         */
        getPostContent: noop,

        /**
         * Get all the content for the custom code
         * @return {HTMLElement}
         */
        getContent() {
            const { editor } = this;
            const content = document.createElement('div');
            const codeViewer = this.getCodeViewer();
            const pfx = editor.getConfig('stylePrefix');
            content.className = `${pfx}custom-code`;
            appendToContent(content, this.getPreContent());
            content.appendChild(codeViewer.getElement());
            appendToContent(content, this.getPostContent());
            appendToContent(content, this.getContentActions());
            codeViewer.refresh();
            setTimeout(() => codeViewer.focus(), 0);

            return content;
        },

        /**
         * Get the actions content. Can be a simple string or an HTMLElement
         * @return {HTMLElement|String}
         */
        getContentActions() {
            const { editor } = this;
            const btn = document.createElement('button');
            const pfx = editor.getConfig('stylePrefix');
            btn.innerHTML = opt.buttonLabel;
            btn.className = `${pfx}btn-prim ${pfx}btn-import__custom-code`;
            btn.onclick = () => this.handleSave();

            return btn;
        },

        /**
         * Handle the main save task
         */
        handleSave() {
            const { editor, target } = this;
            const code = this.getCodeViewer().getContent();
            target.set('custom-code-plugin__code', code);
            editor.Modal.close();
        },

        /**
         * Return the code viewer instance
         * @return {CodeViewer}
         */
        getCodeViewer() {
            const { editor } = this;

            if (!this.codeViewer) {
                this.codeViewer = editor.CodeManager.createViewer({
                    codeName: 'htmlmixed',
                    theme: 'hopscotch',
                    readOnly: 0,
                    // ...codeViewOptions,
                });
            }

            return this.codeViewer;
        },

        // ...commandCustomCode,
    });
}

function addFont(editor) {
    const styleManager = editor.StyleManager;
    const fontProperty = styleManager.getProperty('typography', 'font-family');
    const list = fontProperty.get('list');
    fontProperty.set('list', [{ value: '"Open Sans", sans-serif', name: 'Open Sans' }, ...list]);
    fontProperty.set('defaults', '"Open Sans", sans-serif');
    styleManager.render();
}

function createTypes(editor, opts = {}) {
    const dc = editor.DomComponents;
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const { toolbarBtnCustomCode } = opts;
    let timedInterval;

    dc.addType('script', {
        view: {
            onRender() {
                const isCC = this.model.closestType('custom-code');
                isCC && (this.el.innerHTML = '');
            },
        },
    });

    dc.addType('custom-code', {
        model: defaultModel.extend(
            {
                defaults: {
                    ...defaultModel.prototype.defaults,
                    name: 'Custom Code',
                    editable: true,
                    ...opts.propsCustomCode,
                },

                /**
                 * Initilize the component
                 */
                init() {
                    this.listenTo(this, `change:${'custom-code-plugin__code'}`, this.onCustomCodeChange);
                    const initialCode = this.get('custom-code-plugin__code') || opts.placeholderContent;
                    !this.components().length && this.components(initialCode);
                    const toolbar = this.get('toolbar');
                    const id = 'custom-code';

                    // Add the custom code toolbar button if requested and it's not already in
                    if (toolbarBtnCustomCode && !toolbar.filter((tlb) => tlb.id === id).length) {
                        toolbar.unshift({
                            id,
                            command: 'custom-code:open-modal',
                            label: `<svg viewBox="0 0 24 24">
                <path d="M14.6 16.6l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4m-5.2 0L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4z"></path>
              </svg>`,
                            ...toolbarBtnCustomCode,
                        });
                    }
                },

                /**
                 * Callback to launch on 'custom-code-plugin__code' change
                 */
                onCustomCodeChange() {
                    this.components(this.get('custom-code-plugin__code'));
                },
            },
            {
                /**
                 * The component can be used only if requested explicitly via `type` property
                 */
                isComponent() {
                    return false;
                },
            },
        ),

        view: defaultType.view.extend({
            events: {
                dblclick: 'onActive',
            },

            init() {
                this.listenTo(this.model.components(), 'add remove reset', this.onComponentsChange);
                this.onComponentsChange();
            },

            /**
             * Things to do once inner components of custom code are changed
             */
            onComponentsChange() {
                timedInterval && clearInterval(timedInterval);
                timedInterval = setTimeout(() => {
                    const { model } = this;
                    const content = model.get('custom-code-plugin__code') || '';
                    let droppable = 1;

                    // Avoid rendering codes with scripts
                    if (content.indexOf('<script') >= 0) {
                        this.el.innerHTML = opts.placeholderScript;
                        droppable = 0;
                    }

                    model.set({ droppable });
                }, 0);
            },

            onActive() {
                const target = this.model;
                this.em.get('Commands').run('custom-code:open-modal', { target });
            },
        }),
    });
}

function getStylesModule() {
    return opt.customStyleManager;
}

export { createBlocks, createCommands, getStylesModule, createTypes, addFont, getInlineHtml, opt };
