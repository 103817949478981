import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { noop, first, isEmpty, isNil, keys, cloneDeep } from 'lodash';
// Import Hooks
import { useRequest, useMenuItem } from 'hooks';
// Import UI Components
import {
    Modal,
    ExtendedInput,
    Button,
    DatePickerInput,
    BusyLoader,
    Alert,
    SkeletonLoader,
    Dropdown,
} from '@geneui/components';
// Import Components
import { ButtonWithLoader, CustomTabs, ValidatableDropdown, CurrencyAutoFillSection } from 'components';
// Import Services
import { AwardHttpService, UtilsHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Constants
import { l, PageTypes, BonusTypesLabels, DateFormat, MenuItemIds } from 'constants/common';
import { datePickerConfigs, getStartDate, getEndDate } from './config';
// Import Styles
import styles from './AwardCEModal.module.scss';

const { getCurrencyLabel, customMomentWithoutTimezoneConversion } = Helpers;

// TODO: need rewrite props logic
const AwardCEModal = ({
    type,
    bonusType,
    openedModalData,
    getTableRefreshFn,
    setIsEditModalOpenedState,
    setIsCEAwardModalOpenedState,
}) => {
    const { Settings } = useSelector((state) => state.userData);
    const currencyId = Settings.CurrencyId;

    const totalCurrencyRates = useSelector((state) => state.currencyRates);
    const currencyRates = useMemo(() => totalCurrencyRates[currencyId], [totalCurrencyRates, currencyId]);
    const {
        convertedData: BalanceTypesData,
        isLoading: isLoadingBalanceTypesData,
        isLoadingConfig,
    } = useMenuItem(MenuItemIds.BalanceTypes);
    const { data: currencyData, isLoading: isLoadingCurrencyData } = useMenuItem(MenuItemIds.Currency);

    const { data: wonderWheelsData, isLoading: isWonderWheelsDataLoading } = useMenuItem(
        BonusTypesLabels[bonusType] === BonusTypesLabels.WonderWheelSpin ? MenuItemIds.WonderWheel : undefined,
    );

    const { t } = useTranslation();
    const { doPostRequest, doGetRequest } = useRequest();

    const [currencies, setCurrencies] = useState([]);
    const [wallets, setWallets] = useState([]);
    const [marketplaces, setMarketplaces] = useState([]);
    const [isLoadingMarketplaces, setIsLoadingMarketplaces] = useState(true);
    const [isLoadingNftVoucherCollections, setIsLoadingNftVoucherCollections] = useState();
    const [nftVoucherCollectionsList, setNftVoucherCollectionsList] = useState([]);
    const [nftVoucherDropdownData, setNftVoucherDropdownData] = useState([]);

    const [date, setDate] = useState(
        type === PageTypes.edit
            ? [openedModalData?.StartDate, openedModalData?.EndDate]
            : [datePickerConfigs.initStartDate, datePickerConfigs.initEndDate],
    );
    const [activeTabKey, setActiveTabKey] = useState(0);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const { createAwardRequest, editAwardRequest, getNftMarketplacesRequest, getNftVoucherCollectionsListRequest } =
        useMemo(
            () => ({
                createAwardRequest: AwardHttpService.createAward(),
                editAwardRequest: AwardHttpService.editAward(),
                getNftMarketplacesRequest: UtilsHttpService.getNftMarketplaces(),
                getNftVoucherCollectionsListRequest: UtilsHttpService.getNftVoucherCollectionsList(),
            }),
            [],
        );

    const wonderWheelsDataDropdownModel = useMemo(
        () => wonderWheelsData.map(({ Name, Id }) => ({ label: `${Name} (${Id})`, value: Id })),
        [wonderWheelsData],
    );

    const formik = useFormik({
        initialValues: {
            inputValue: type === PageTypes.edit ? openedModalData?.Name : '',
            currencyInputs: [],
            descriptionValue: type === PageTypes.edit ? openedModalData?.Description : '',
            balanceType: type === PageTypes.edit ? openedModalData?.BalanceType : null,
        },
        onSubmit: noop,
        validationSchema: Yup.object({
            inputValue: Yup.string()
                .required(t(l.CannotBeEmpty, { fieldName: t(l.Name) }))
                .matches(/^[A-Za-z0-9]/, t(l.FirstCharacterCantBeASymbol, { fieldName: l.Value }))
                .min(3, t(l.MustContainMinimumCharacters, { fieldName: l.Value, count: 3 }))
                .max(50, t(l.MustContainMaximumCharacters, { fieldName: l.Value, count: 50 })),
            descriptionValue: Yup.string()
                .nullable()
                .max(250, t(l.MustContainMaximumCharacters, { fieldName: l.Value, count: 250 })),
            currencyInputs: Yup.array().of(
                Yup.object().shape({
                    min: Yup.string()
                        .required(t(l.CannotBeEmpty, { fieldName: t(l.Min) }))
                        .matches(/^\d{1,}(\.\d{0,9})?$/, t(l.MinCurrencyIsNotValid, { fieldName: l.Min }))
                        .test('min', t(l.MinMustBeSmallerThanMax, { label: t(l.Min) }), function (value) {
                            const { max } = this.parent;
                            return !value || !max || +value <= +max;
                        }),
                    max: Yup.string()
                        .required(t(l.CannotBeEmpty, { fieldName: t(l.Max) }))
                        .matches(/^\d{1,}(\.\d{0,9})?$/, t(l.MaxCurrencyIsNotValid, { fieldName: l.Max }))
                        .test('max', t(l.MaxMustBeBiggerThanMin, { label: t(l.Max) }), function (value) {
                            const { min } = this.parent;
                            return !value || !min || +value >= +min;
                        })
                        .test('max', t(l.MaxMustBeBiggerThanZero, { label: t(l.Max) }), function (value) {
                            return value > 0;
                        }),
                }),
            ),
            nftFrom:
                BonusTypesLabels.NFTBonus === BonusTypesLabels[bonusType]
                    ? Yup.number()
                          .required(t(l.CannotBeEmpty, { fieldName: t(l.From) }))
                          .moreThan(0, t(l.MustBeBiggerThan, { fieldName: l.From, count: 0 }))
                          .test('nftFromTest', t(l.FromMustBeSmallerThanTo), function (value) {
                              const { nftTo } = this.parent;
                              return isNil(nftTo) || isNil(value) || +value < +nftTo;
                          })
                          .nullable()
                    : Yup.number().nullable(),
            nftTo:
                BonusTypesLabels.NFTBonus === BonusTypesLabels[bonusType]
                    ? Yup.number()
                          .required(t(l.CannotBeEmpty, { fieldName: t(l.To) }))
                          .moreThan(0, t(l.MustBeBiggerThan, { fieldName: l.To, count: 0 }))
                          .test('nftToTest', t(l.ToMustBeBiggerThanFrom), function (value) {
                              const { nftFrom } = this.parent;
                              return isNil(nftFrom) || isNil(value) || +value > +nftFrom;
                          })
                          .nullable()
                    : Yup.number().nullable(),
            balanceType: ![
                BonusTypesLabels.LoyaltyPoint,
                BonusTypesLabels.NFTBonus,
                BonusTypesLabels.FrenchFreeBet,
                BonusTypesLabels.WonderWheelSpin,
                BonusTypesLabels.NftVoucherBonus,
            ].includes(BonusTypesLabels[bonusType])
                ? Yup.number()
                      .required(t(l.CannotBeEmpty, { fieldName: t(l.Wallet) }))
                      .nullable()
                : Yup.number().nullable(),
            marketplace:
                BonusTypesLabels[bonusType] === BonusTypesLabels.NFTBonus
                    ? Yup.number()
                          .required(t(l.CannotBeEmpty, { fieldName: t(l.Marketplace) }))
                          .nullable()
                    : Yup.number().nullable(),
            wonderWheel:
                BonusTypesLabels[bonusType] === BonusTypesLabels.WonderWheelSpin
                    ? Yup.number()
                          .required(t(l.CannotBeEmpty, { fieldName: t(l.WonderWheelBonus) }))
                          .nullable()
                    : Yup.number().nullable(),
            nftVoucherCollectionId:
                BonusTypesLabels[bonusType] === BonusTypesLabels.NftVoucherBonus
                    ? Yup.number()
                          .required(t(l.CannotBeEmpty, { fieldName: t(l.NftVoucherCollection) }))
                          .nullable()
                    : Yup.number().nullable(),
        }),
    });

    const {
        values,
        errors,
        touched,
        setFieldValue,
        setValues,
        setFieldTouched,
        validateForm,
        submitForm,
        handleBlur,
        isValid,
    } = formik;

    const inputValueChangeHandler = (value) => {
        setFieldValue('inputValue', value, false)
            .then(() => setFieldTouched('inputValue'))
            .then(() => submitForm());
    };

    const handleSave = () => {
        submitForm().then(() =>
            validateForm(values).then((formErrors) => {
                if (isEmpty(formErrors)) {
                    onOk();
                }
            }),
        );
    };

    const dateInputChangeHandler = (value) => {
        setDate([getStartDate(value[0]), getEndDate(value[1])]);
    };

    const descriptionValueChangeHandler = (value) => {
        setFieldValue('descriptionValue', value.target.value, false).then(() => submitForm());
    };

    const nftValuesChangeHandler = (e, fieldName) => {
        const inputValue = e.target.value;
        setFieldValue(fieldName, inputValue).then(() => submitForm());
    };

    function handleMinCurrencyChange(e, id) {
        const inputValue = e.target?.value.replace('-', '');
        const result = values.currencyInputs.map((item) => {
            if (item?.currency === id) {
                return { ...item, min: inputValue };
            }
            return { ...item };
        });
        setFieldValue('currencyInputs', result).then(() => submitForm());
    }

    function handleMaxCurrencyChange(e, id) {
        const inputValue = e.target?.value.replace('-', '');
        const result = values.currencyInputs.map((item) => {
            if (item?.currency === id) {
                return { ...item, max: inputValue };
            }
            return { ...item };
        });
        setFieldValue('currencyInputs', result).then(() => submitForm());
    }

    const dropdownChangeHandler = (e) => {
        setFieldValue('balanceType', e?.value ?? '').then(() => submitForm());
    };

    const marketplaceDropdownChangeHandler = (e) => {
        setFieldValue('marketplace', e?.value ?? '').then(() => submitForm());
    };

    const cancelHandler = () => {
        setFieldValue('inputValue', '', false).then(() => setFieldTouched('inputValue'));
        setFieldValue('descriptionValue', '', false);
        setIsCEAwardModalOpenedState(false);
        setIsEditModalOpenedState(false);
    };

    const getMinMaxErrorMessages = (field) => {
        return keys(errors).includes('currencyInputs') ? errors.currencyInputs[activeTabKey]?.[field] : '';
    };

    const getMinMaxTuoched = (field) => {
        return keys(touched).includes('currencyInputs') ? touched.currencyInputs[activeTabKey]?.[field] : '';
    };

    const getNftErrorMessages = (field) => {
        return errors[field] ?? '';
    };

    const tabChangeHandler = (tabKey) => {
        setActiveTabKey(tabKey);
    };

    const getEditModalCurrencyInputs = () => {
        if (type === PageTypes.edit) {
            if (
                [
                    BonusTypesLabels.LoyaltyPoint,
                    BonusTypesLabels.WonderWheelSpin,
                    BonusTypesLabels.NftVoucherBonus,
                ].includes(BonusTypesLabels[bonusType])
            ) {
                return openedModalData.BonusDetails.map((item) => {
                    if ([BonusTypesLabels.WonderWheelSpin].includes(BonusTypesLabels[bonusType])) {
                        setFieldValue('wonderWheel', item.Id.toString());
                    }
                    if ([BonusTypesLabels.NftVoucherBonus].includes(BonusTypesLabels[bonusType])) {
                        setFieldValue('nftVoucherCollectionId', item.Id);
                    }
                    return {
                        currency: item.CurrencyCode,
                        min: +item.MinAmount,
                        max: +item.MaxAmount,
                        isValid: true,
                    };
                });
            } else {
                const openedModalDataBonusDetails = openedModalData.BonusDetails.reduce((acc, val) => {
                    acc[val.CurrencyCode] = val;
                    return acc;
                }, {});

                return currencyData.map((item) => {
                    const tmpVal = openedModalDataBonusDetails[item.Id] ?? null;
                    return {
                        currency: tmpVal?.CurrencyCode ?? item?.Id,
                        min: tmpVal?.MinAmount ? +tmpVal?.MinAmount : '',
                        max: tmpVal?.MaxAmount ? +tmpVal?.MaxAmount : '',
                        isValid: !isNil(tmpVal),
                    };
                });
            }
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (!isLoadingBalanceTypesData && !isLoadingConfig) {
            setWallets(cloneDeep(BalanceTypesData));
        }
    }, [isLoadingBalanceTypesData, isLoadingConfig, BalanceTypesData]);

    const init = () => {
        if (BonusTypesLabels.NFTBonus !== BonusTypesLabels[bonusType]) {
            let currenciesData = [];
            if (BonusTypesLabels.LoyaltyPoint === BonusTypesLabels[bonusType]) {
                currenciesData = [{ Id: l.LP }];
            } else if (BonusTypesLabels.WonderWheelSpin === BonusTypesLabels[bonusType]) {
                currenciesData = [{ Id: l.SPIN }];
            } else if (BonusTypesLabels.NftVoucherBonus === BonusTypesLabels[bonusType]) {
                currenciesData = [{ Id: l.NFTV }];
            } else {
                if (!isNil(currencyData)) {
                    currenciesData = currencyData;
                }
            }

            setCurrencies(currenciesData);
            if (type === PageTypes.edit) {
                setFieldValue('currencyInputs', getEditModalCurrencyInputs());
            } else {
                setFieldValue(
                    'currencyInputs',
                    currenciesData.map(({ Id }) => {
                        return { currency: Id, min: '', max: '', isValid: false };
                    }),
                ).then(() => {
                    submitForm();
                });
            }
        }
    };

    const getIsValidCurrencyInputsValues = () => {
        const data = values.currencyInputs;
        if (keys(errors).includes('currencyInputs')) {
            data.forEach((item, index) => {
                if (keys(errors.currencyInputs).includes(`${index}`)) {
                    if (isNil(errors.currencyInputs[index])) {
                        item.isValid = true;
                    } else {
                        item.isValid = false;
                    }
                } else {
                    item.isValid = true;
                }
            });
        } else {
            data.forEach((item) => {
                item.isValid = true;
            });
        }
        return data;
    };

    const setCurrencyInputsValidation = () => {
        setFieldValue('currencyInputs', getIsValidCurrencyInputsValues(), false);
    };

    const isValidTabs = () => {
        return getIsValidCurrencyInputsValues().map((item) => {
            return item.isValid;
        });
    };

    const onOk = () => {
        setIsSaveLoading(true);
        const requestBody = {
            Name: values?.inputValue,
            Description: values?.descriptionValue,
            StartDate: date[0],
            EndDate: date[1],
            Type: BonusTypesLabels[bonusType],
            BalanceType: values?.balanceType ?? null,
        };

        if (type === PageTypes.edit) {
            requestBody.Id = openedModalData?.Id;
        }

        if (BonusTypesLabels.NFTBonus !== BonusTypesLabels[bonusType]) {
            requestBody.BonusDetails = values?.currencyInputs?.map((item) => {
                return {
                    CurrencyCode: item.currency,
                    MinAmount: item.min,
                    MaxAmount: item.max,
                    ...(BonusTypesLabels.WonderWheelSpin === BonusTypesLabels[bonusType] && { Id: values.wonderWheel }),
                    ...(BonusTypesLabels.NftVoucherBonus === BonusTypesLabels[bonusType] && {
                        Id: values.nftVoucherCollectionId,
                    }),
                };
            });
        } else {
            requestBody.BonusDetails = [
                {
                    From: values?.nftFrom,
                    To: values?.nftTo,
                    Marketplace: values?.marketplace,
                },
            ];
        }

        doPostRequest((type === PageTypes.edit ? editAwardRequest : createAwardRequest).request, {
            requestBody: requestBody,
            successCallback: () => {
                type === PageTypes.edit ? setIsEditModalOpenedState(false) : setIsCEAwardModalOpenedState(false);
                getTableRefreshFn();
            },
        }).then(() => {
            setIsSaveLoading(false);
        });
    };

    const cleanUp = () => {
        return () => {
            createAwardRequest.cancel('AwardCEModal:createAwardRequest');
            editAwardRequest.cancel('AwardCEModal:editAwardRequest');
            getNftMarketplacesRequest.cancel('AwardCEModal:getNftMarketplacesRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(setCurrencyInputsValidation, [
        // eslint-disable-next-line react-hooks/exhaustive-deps
        values?.currencyInputs[activeTabKey]?.min,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        values?.currencyInputs[activeTabKey]?.max,
    ]);

    useEffect(() => {
        if (BonusTypesLabels.NFTBonus === BonusTypesLabels[bonusType]) {
            const bonusDetails = first(openedModalData?.BonusDetails ?? []);
            setValues((prev) => ({ ...prev, nftFrom: bonusDetails?.From, nftTo: bonusDetails?.To }));
            doGetRequest(getNftMarketplacesRequest.request, {
                successCallback: (data) => {
                    setMarketplaces((data ?? []).map((item) => ({ value: item.Value, label: item.DisplayName })));
                    setFieldValue('marketplace', bonusDetails?.Marketplace);
                    submitForm();
                },
            }).then(() => setIsLoadingMarketplaces(false));
        }
        if (BonusTypesLabels.NftVoucherBonus === BonusTypesLabels[bonusType]) {
            setIsLoadingNftVoucherCollections(true);
            doGetRequest(getNftVoucherCollectionsListRequest.request, {
                successCallback: (data) => {
                    setNftVoucherCollectionsList(
                        data.reduce((acc, val) => {
                            acc[val.CollectionId] = val;
                            return acc;
                        }, {}),
                    );
                    setNftVoucherDropdownData(
                        data.map((item) => ({ label: `${item.Name}(${item.CollectionId})`, value: item.CollectionId })),
                    );
                },
            }).then(() => setIsLoadingNftVoucherCollections(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, [currencyData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    const getTabContent = (id) => {
        const currencyValue = values.currencyInputs.find((item) => item.currency === id);
        return (
            <div className="currency-min-max-section">
                <div className="min-max-currency">
                    <ExtendedInput
                        autocomplete="off"
                        value={currencyValue?.min}
                        onBlur={handleBlur}
                        onChange={(e) => handleMinCurrencyChange(e, id)}
                        type="number"
                        name="min"
                        placeholder={t(l.Min)}
                        label={getCurrencyLabel(
                            t(l.Min),
                            currencyValue?.min,
                            currencyValue?.currency,
                            currencyId,
                            currencyRates,
                        )}
                        labelAppearance="swap"
                        isValid={!(getMinMaxTuoched('min') && getMinMaxErrorMessages('min'))}
                        errorText={getMinMaxTuoched('min') && getMinMaxErrorMessages('min')}
                    />
                    <ExtendedInput
                        autocomplete="off"
                        value={currencyValue?.max}
                        onBlur={handleBlur}
                        onChange={(e) => handleMaxCurrencyChange(e, id)}
                        type="number"
                        name="max"
                        placeholder={t(l.Max)}
                        label={getCurrencyLabel(
                            t(l.Max),
                            currencyValue?.max,
                            currencyValue?.currency,
                            currencyId,
                            currencyRates,
                        )}
                        labelAppearance="swap"
                        isValid={!(getMinMaxTuoched('max') && getMinMaxErrorMessages('max'))}
                        errorText={getMinMaxTuoched('max') && getMinMaxErrorMessages('max')}
                    />
                </div>
            </div>
        );
    };

    const tabsConfig = currencies.map((item, index) => {
        return {
            tabTitle: item?.Id,
            tabKey: index,
            tabContent: getTabContent(item?.Id),
        };
    });

    const onAutoFillClickHandler = (data) => {
        const { autoFillMinValue, autoFillMaxValue, selectedCurrency } = data;
        const result = values.currencyInputs.map((currency) => {
            const tmpCurrency = { ...currency };
            tmpCurrency.min = totalCurrencyRates[tmpCurrency.currency][selectedCurrency].Rate * +autoFillMinValue;
            tmpCurrency.max = totalCurrencyRates[tmpCurrency.currency][selectedCurrency].Rate * +autoFillMaxValue;
            return tmpCurrency;
        });
        setFieldValue('currencyInputs', result).then(() => submitForm());
    };

    const currenciesDropdownData = useMemo(() => {
        return currencies.map(({ Id, Name }) => ({ label: Name, value: Id }));
    }, [currencies]);

    const isAutoFillSectionVisible = useMemo(
        () =>
            [BonusTypesLabels.CorrectionBonus, BonusTypesLabels.CashBonus, BonusTypesLabels.FrenchFreeBet].includes(
                BonusTypesLabels[bonusType],
            ),
        [bonusType],
    );

    return (
        <Modal
            withPortal={true}
            background="dark-background"
            position="center"
            size="content-size"
            className="configuration-create-award-modal"
            title={t(l[`${type.charAt(0).toUpperCase() + type.slice(1)}AwardingTitle`], {
                bonusType: type === PageTypes.edit ? t(BonusTypesLabels[openedModalData?.Type]) : t(bonusType),
            })}
            closable={false}
            closeOnClickOutside={true}
            visible={true}
            onCancel={cancelHandler}
            footer={
                <>
                    <Button color="default" appearance="minimal" onClick={cancelHandler}>
                        {t(l.Cancel)}
                    </Button>
                    <ButtonWithLoader
                        color="primary"
                        onClick={handleSave}
                        isLoading={isSaveLoading}
                        isDisabled={!isValid}
                    >
                        {t(l.Save)}
                    </ButtonWithLoader>
                </>
            }
        >
            {BonusTypesLabels.FrenchFreeBet === BonusTypesLabels[bonusType] && (
                <div className="french-free-bet-alert-holder">
                    <Alert
                        closable={false}
                        title={t(l.FrenchFreeBetInfoMessage)}
                        iconProps={{ type: 'bc-icon-info' }}
                    />
                </div>
            )}
            <div className="awarding-chanel-row">
                <ExtendedInput
                    placeholder={t(l.Name)}
                    value={values.inputValue}
                    onChange={(e) => inputValueChangeHandler(e.target.value)}
                    onBlur={handleBlur}
                    isValid={!(touched.inputValue && errors.inputValue)}
                    errorText={touched.inputValue && errors.inputValue}
                    labelAppearance="swap"
                />
                <DatePickerInput.WithRange
                    onChange={dateInputChangeHandler}
                    value={date}
                    min={datePickerConfigs.min}
                    max={datePickerConfigs.max}
                    label={t(l.BonusProgramPeriod)}
                    labelAppearance="swap"
                />
            </div>

            {BonusTypesLabels.WonderWheelSpin === BonusTypesLabels[bonusType] && (
                <div style={{ display: 'flex', width: '100%', gap: '30px', marginBottom: '30px', marginTop: '30px' }}>
                    <Dropdown
                        label={t(l.WonderWheelBonus)}
                        placeholder={t(l.WonderWheelBonus)}
                        labelAppearance="swap"
                        data={wonderWheelsDataDropdownModel}
                        value={values?.wonderWheel}
                        isLoading={isWonderWheelsDataLoading}
                        onChange={(e) => setFieldValue('wonderWheel', e.value)}
                        isValid={!errors.wonderWheel}
                        errorText={errors.wonderWheel}
                    />
                </div>
            )}
            <div className="award-description">
                <ExtendedInput
                    labelAppearance="swap"
                    type="textarea"
                    inputSize="small"
                    placeholder={t(l.Description)}
                    value={values.descriptionValue}
                    onChange={descriptionValueChangeHandler}
                    isValid={!(touched.descriptionValue && errors.descriptionValue)}
                    errorText={touched.descriptionValue && errors.descriptionValue}
                />
            </div>
            {BonusTypesLabels.NftVoucherBonus === BonusTypesLabels[bonusType] && (
                <div className={styles.container}>
                    <Dropdown
                        data={nftVoucherDropdownData}
                        label={t(l.NftVoucherCollection)}
                        placeholder={t(l.NftVoucherCollection)}
                        labelAppearance="swap"
                        value={values?.nftVoucherCollectionId}
                        isLoading={isLoadingNftVoucherCollections}
                        onChange={(e) => setFieldValue('nftVoucherCollectionId', e.value)}
                        isValid={!errors.nftVoucherCollectionId}
                        errorText={errors.nftVoucherCollectionId}
                    />
                    {values.nftVoucherCollectionId && !isEmpty(nftVoucherCollectionsList) && (
                        <div className="award-key-value-wrapper">
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.Price)}</span>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {nftVoucherCollectionsList[values.nftVoucherCollectionId].Price}
                                </span>
                            </div>

                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.Slug)}</span>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {nftVoucherCollectionsList[values.nftVoucherCollectionId].Slug}
                                </span>
                            </div>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.Currency)}</span>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {nftVoucherCollectionsList[values.nftVoucherCollectionId].Currency}
                                </span>
                            </div>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.Count)}</span>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {nftVoucherCollectionsList[values.nftVoucherCollectionId].Count}
                                </span>
                            </div>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.RewardDate)}</span>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {customMomentWithoutTimezoneConversion(
                                        nftVoucherCollectionsList[values.nftVoucherCollectionId].RewardDate,
                                    ).format(DateFormat)}
                                </span>
                            </div>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.ExpirationDate)}</span>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {isNil(nftVoucherCollectionsList[values.nftVoucherCollectionId].ExpireDate)
                                        ? t(l.NA)
                                        : customMomentWithoutTimezoneConversion(
                                              nftVoucherCollectionsList[values.nftVoucherCollectionId].ExpireDate,
                                          ).format(DateFormat)}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {BonusTypesLabels.NFTBonus === BonusTypesLabels[bonusType] ? (
                <>
                    <div className="title-wrapper">
                        <p className="title-wrapper-text">{t(l.Marketplace)}</p>
                        <span className="title-wrapper-line" />
                    </div>
                    <div className="crm-marketplace-dropdown">
                        <SkeletonLoader isBusy={isLoadingMarketplaces}>
                            {!isLoadingMarketplaces && (
                                <ValidatableDropdown
                                    isMultiSelect={false}
                                    disabled={false}
                                    hasSearch={false}
                                    inputSize="default"
                                    placeholder={t(l.Marketplace)}
                                    appearance="outline"
                                    data={marketplaces}
                                    searchPlaceholderText={t(l.Search)}
                                    labelAppearance="swap"
                                    noDataText={t(l.NoDataFound)}
                                    onChange={marketplaceDropdownChangeHandler}
                                    isValid={!errors.marketplace}
                                    errorText={errors.marketplace}
                                    value={values?.marketplace}
                                    clearable={true}
                                />
                            )}
                        </SkeletonLoader>
                    </div>
                    <div className="title-wrapper">
                        <p className="title-wrapper-text">{t(l.Settings)}</p>
                        <span className="title-wrapper-line" />
                    </div>
                    <div className="nft-bonus-setting-container">
                        <ExtendedInput
                            autocomplete="off"
                            value={values?.nftFrom}
                            onChange={(e) => nftValuesChangeHandler(e, 'nftFrom')}
                            type="number"
                            name="from"
                            placeholder={t(l.From)}
                            labelAppearance="swap"
                            isValid={!getNftErrorMessages('nftFrom')}
                            errorText={getNftErrorMessages('nftFrom')}
                        />

                        <ExtendedInput
                            autocomplete="off"
                            value={values?.nftTo}
                            onChange={(e) => nftValuesChangeHandler(e, 'nftTo')}
                            type="number"
                            name="to"
                            placeholder={t(l.To)}
                            labelAppearance="swap"
                            isValid={!getNftErrorMessages('nftTo')}
                            errorText={getNftErrorMessages('nftTo')}
                        />
                    </div>
                </>
            ) : (
                <>
                    {![
                        BonusTypesLabels.LoyaltyPoint,
                        BonusTypesLabels.FrenchFreeBet,
                        BonusTypesLabels.WonderWheelSpin,
                        BonusTypesLabels.NftVoucherBonus,
                    ].includes(BonusTypesLabels[bonusType]) && (
                        <>
                            <div className="title-wrapper">
                                <p className="title-wrapper-text">{t(l.Wallet)}</p>
                                <span className="title-wrapper-line" />
                            </div>
                            <SkeletonLoader isBusy={isLoadingBalanceTypesData}>
                                {!isLoadingBalanceTypesData && (
                                    <ValidatableDropdown
                                        isMultiSelect={false}
                                        disabled={false}
                                        hasSearch={true}
                                        inputSize="default"
                                        placeholder={t(l.Wallet)}
                                        appearance="outline"
                                        data={wallets}
                                        searchPlaceholderText={t(l.Search)}
                                        labelAppearance="swap"
                                        noDataText={t(l.NoDataFound)}
                                        onChange={dropdownChangeHandler}
                                        isValid={!errors.balanceType}
                                        errorText={errors.balanceType}
                                        value={values?.balanceType}
                                        clearable={true}
                                        wrapperClassName="crm-wallets-dropdown"
                                    />
                                )}
                            </SkeletonLoader>
                            <Alert
                                closable={false}
                                title={t(l.AvailableBonusTypeAlert)}
                                iconProps={{ type: 'bc-icon-info' }}
                            />
                        </>
                    )}

                    <div className="title-wrapper">
                        <p className="title-wrapper-text">
                            {BonusTypesLabels.LoyaltyPoint === BonusTypesLabels[bonusType]
                                ? t(l.Settings)
                                : t(l.SettingsPerCurrency)}
                        </p>
                        <span className="title-wrapper-line" />
                    </div>
                    {isAutoFillSectionVisible && (
                        <>
                            <CurrencyAutoFillSection
                                dropdownData={currenciesDropdownData}
                                onOk={onAutoFillClickHandler}
                                alertMessage={t(l.CurrencyAutoFillInfoAlertCreate)}
                                withMinMax={true}
                            />
                        </>
                    )}
                    <BusyLoader
                        isBusy={
                            ![BonusTypesLabels.LoyaltyPoint, BonusTypesLabels.WonderWheelSpin].includes(
                                BonusTypesLabels[bonusType],
                            ) && isLoadingCurrencyData
                        }
                        type="spinner"
                        spinnerSize="medium"
                    >
                        <div className="currency-tabs">
                            <CustomTabs
                                tabsConfig={tabsConfig}
                                activeTabKey={activeTabKey}
                                tabChangeHandler={tabChangeHandler}
                                isValid={isValidTabs()}
                            />
                        </div>
                    </BusyLoader>
                </>
            )}
        </Modal>
    );
};

AwardCEModal.propTypes = {
    type: PropTypes.string.isRequired,
    bonusType: PropTypes.string.isRequired,
    getTableRefreshFn: PropTypes.func.isRequired,
    setIsEditModalOpenedState: PropTypes.func.isRequired,
    setIsCEAwardModalOpenedState: PropTypes.func.isRequired,
    openedModalData: PropTypes.any,
};
AwardCEModal.defaultProps = {
    openedModalData: {},
    setIsEditModalOpenedState: noop,
};
export default memo(AwardCEModal);
