import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { isEmpty, isNil, noop } from 'lodash';
import { CJBonusAmountInputValidationSchema } from 'validators/schemas.validation';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
// Import Services
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
import { Helpers } from 'services';

const { getCurrencyLabel } = Helpers;

const BonusAmountInput = ({ bonusDetail, setAmountData, index, isRequired, defaultAmountValue }) => {
    const { Settings } = useSelector((state) => state.header.settings);

    const currencyRates = useSelector((state) => state.currencyRates[Settings.CurrencyId]);

    const { MinAmount, MaxAmount, CurrencyCode } = bonusDetail;
    const { t } = useTranslation();
    const formik = useFormik({
        initialValues: {
            amount: defaultAmountValue,
        },
        onSubmit: noop,
        validationSchema: CJBonusAmountInputValidationSchema(bonusDetail, isRequired),
    });

    const { values, setFieldValue, errors, submitForm } = formik;
    const { amount } = values;

    useEffect(() => {
        if (defaultAmountValue !== amount) {
            setFieldValue('amount', defaultAmountValue, true);
        } else {
            submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultAmountValue]);

    const amountChangeHandler = ({ target }) => {
        const replacedValue = target?.value.replace('-', '');
        const tempValue = isNil(replacedValue) || isEmpty(replacedValue) ? null : replacedValue;

        setAmountData(index, {
            CurrencyCode,
            OriginalValue: +tempValue,
        });
        setFieldValue('amount', tempValue, true);
    };

    return (
        <>
            <ExtendedInput
                type="number"
                value={amount}
                placeholder={`${CurrencyCode} ${MinAmount} - ${MaxAmount}`}
                label={getCurrencyLabel(
                    `${CurrencyCode} ${MinAmount} - ${MaxAmount}`,
                    amount,
                    CurrencyCode,
                    Settings.CurrencyId,
                    currencyRates,
                )}
                onChange={amountChangeHandler}
                appearance="outline"
                labelAppearance="swap"
                isValid={!errors.amount}
                errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.amount)}
            />
        </>
    );
};

BonusAmountInput.propTypes = {
    bonusDetail: PropTypes.shape({
        CurrencyCode: PropTypes.string,
        Id: PropTypes.number,
        MaxAmount: PropTypes.number,
        MinAmount: PropTypes.number,
        PartnerBonusId: PropTypes.number,
    }).isRequired,
    setAmountData: PropTypes.func.isRequired,
    amountValue: PropTypes.string,
    defaultAmountValue: PropTypes.string,
    index: PropTypes.number.isRequired,
    isRequired: PropTypes.bool,
};

BonusAmountInput.defaultTypes = {
    amountValue: '',
    defaultAmountValue: '',
    isRequired: false,
};
export default memo(BonusAmountInput);
