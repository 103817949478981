import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty, noop } from 'lodash';
import { useFormik } from 'formik';
import { CJTemplateIdValidationSchema } from 'validators/schemas.validation';
// Import Components
import { OptionList } from 'components';
// Import Services
import { TemplateHttpService } from 'services/http';
import { Helpers } from 'services';
import { getCustomerJourneyFormFieldTranslatableErrorText } from 'services/customerJourney';
// Import Hooks
import { useRequest, useAutocompleteRequest } from 'hooks';
// Import Constants
import { DateTimeWithoutSecondsFormat, l } from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;

const CJTemplateId = ({ defaultValue, getUpdate, option }) => {
    const { t } = useTranslation();
    const { doPostRequest, doGetRequest } = useRequest();
    const { getAutocompleteRequest, cancelRequest } = useAutocompleteRequest();

    const { CustomAttributes } = option;
    const { DeliveryMethod = 7 } = CustomAttributes;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [listData, setListData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedTemplateData, setSelectedTemplateData] = useState({ TemplateId: defaultValue?.value });
    const [isVisiblePopover, setIsVisiblePopover] = useState(false);
    const formik = useFormik({
        initialValues: {
            value: defaultValue?.value,
        },
        onSubmit: noop,
        validationSchema: CJTemplateIdValidationSchema(option),
    });
    const { values, setFieldValue, errors, submitForm } = formik;
    const { value } = values;
    const { getTemplateList, getTemplateById } = useRef({
        getTemplateList: TemplateHttpService.getTemplateList,
        getTemplateById: TemplateHttpService.getTemplate(),
    }).current;

    useEffect(() => {
        getUpdate({ value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const templateListGetterMethod = (searchValue = '') => {
        setIsLoadingList(true);

        const requestObj = getAutocompleteRequest(getTemplateList);

        doPostRequest(requestObj.request, {
            requestBody: {
                Filters: [
                    { Name: 'State', Comparision: 0, Values: [1] },
                    { Name: 'DeliveryMethod', Comparision: 8, Values: [DeliveryMethod] },
                    { Name: 'Name', Comparision: 2, Values: [searchValue] },
                ],
                Pageing: { PageSize: 100, PageNumber: 1 },
                Sorting: { Name: 'CreatedDate', Direction: 'desc' },
            },
            successCallback: (data) => {
                setListData(
                    !isNil(data?.Data)
                        ? data.Data?.map((item) => ({
                              ...item,
                              formattedCreatedDate: customMomentWithoutTimezoneConversion(item.CreatedDate).format(
                                  DateTimeWithoutSecondsFormat,
                              ),
                              formattedChildInfo: item.ChildItemsInfo.length
                                  ? item.ChildItemsInfo.map(({ Language }) => Language.toUpperCase()).join(' ')
                                  : t(l.Default),
                          }))
                        : [],
                );
                setIsLoadingList(false);
            },
            errorCallback: () => setIsLoadingList(false),
        });
    };

    const searchTemplateHandler = ({ target }) => {
        setSearchValue(target.value);
        templateListGetterMethod(target.value);
    };

    const init = () => {
        submitForm();
        templateListGetterMethod('');

        if (!isNil(defaultValue?.value) && !isEmpty(defaultValue.value.toString())) {
            setIsLoading(true);
            doGetRequest(getTemplateById.request, {
                queryString: { templateId: defaultValue.value },
                successCallback: (Data) => {
                    const { TemplateId, Name } = Data;
                    setSelectedTemplateData({ TemplateId, Name });
                },
            }).then(() => {
                setIsLoading(false);
            });
        }
    };

    const cleanUp = () => {
        return () => {
            getTemplateById.cancel('CJTemplateId:getTemplateById');
            cancelRequest('CJTemplateId:getTemplateList');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    const popoverToggler = () => {
        setIsVisiblePopover(!isVisiblePopover);
    };

    const selectTemplateHandler = (data) => {
        setSelectedTemplateData(data);
        setFieldValue('value', data.TemplateId);
        setIsVisiblePopover(false);
    };

    return (
        <OptionList
            optionLabel={selectedTemplateData?.Name}
            toggleHandler={popoverToggler}
            isVisible={isVisiblePopover}
            setIsVisible={setIsVisiblePopover}
            keys={['Name', 'formattedChildInfo', 'formattedCreatedDate']}
            titles={[t(l.TemplateName), t(l.Language), t(l.CreatedDate)]}
            selectedPropertyKey="TemplateId"
            list={listData}
            rowCount={listData.length}
            searchHandler={searchTemplateHandler}
            optionValue={value}
            optionSelectHandler={selectTemplateHandler}
            contentTop={45}
            contentWrapperClassName="cj-option-list-content-wrapper"
            searchValue={searchValue}
            isLoading={isLoading}
            isLoadingList={isLoadingList}
            isValid={!errors.value}
            errorText={getCustomerJourneyFormFieldTranslatableErrorText(t, errors?.value)}
        />
    );
};

CJTemplateId.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
};

CJTemplateId.defaultProps = {
    defaultValue: { value: null },
};

export default memo(CJTemplateId);
