import React, { memo, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import UI Components
import { ExtendedInput, Paper, Dropdown, SkeletonLoader } from '@geneui/components';
//Import Components
import { ResetSave } from 'components';
//Import Constants
import { l, AlertTypes } from 'constants/common';
// Import Services
import { UtilsHttpService } from 'services/http';

const { success } = AlertTypes;

const WebsiteSection = () => {
    const { t } = useTranslation();

    const { doPostRequest, doGetRequest } = useRequest();
    const { showToaster } = useToaster();

    const [siteBuilderTypes, setSiteBuilderTypes] = useState([]);
    const [isSiteBuilderTypesLoading, setIsSiteBuilderTypesLoading] = useState(false);
    const [isSiteSettingsLoading, setIsSiteSettingsLoading] = useState(true);

    const formik = useFormik({
        initialValues: {
            siteUrl: '',
            siteBuilder: null,
        },
        validationSchema: Yup.object({
            siteUrl: Yup.string()
                .required(t(l.CannotBeEmpty, { fieldName: t(l.Url) }))
                .matches(/^http(s)?:\/\/([\w-]+.)+[\w-]+(\/[\w\- ./?%&=])?$/gi, t(l.EnterValidUrl))
                .nullable(),
        }),
        onSubmit: (values, formik) => {
            return doPostRequest(updatePartnerSiteSettingRequest.request, {
                requestBody: { SiteUrl: values.siteUrl, SiteBuilder: values.siteBuilder },
                successCallback: () => {
                    formik.resetForm({
                        values: {
                            siteUrl: values.siteUrl,
                            siteBuilder: values.siteBuilder,
                        },
                    });
                    showToaster(success, t(l.SiteBuilderWasCreatedSuccessfully));
                },
            });
        },
    });

    const { values, errors, dirty, isSubmitting, isValid, setFieldValue, submitForm, resetForm } = formik;

    const { getSiteBuilderTypesRequest, getPartnerSiteSettingRequest, updatePartnerSiteSettingRequest } = useMemo(
        () => ({
            getSiteBuilderTypesRequest: UtilsHttpService.getSiteBuilderTypes(),
            getPartnerSiteSettingRequest: UtilsHttpService.getPartnerSiteSetting(),
            updatePartnerSiteSettingRequest: UtilsHttpService.updatePartnerSiteSetting(),
        }),
        [],
    );

    const getSiteBuilderTypes = () => {
        setIsSiteBuilderTypesLoading(true);
        doGetRequest(getSiteBuilderTypesRequest.request, {
            successCallback: (data) => {
                setSiteBuilderTypes(
                    data.map(({ Id, Name }) => {
                        return {
                            value: +Id,
                            label: Name,
                        };
                    }),
                );
            },
        }).then(() => setIsSiteBuilderTypesLoading(false));
    };

    const getSiteSetting = () => {
        setIsSiteSettingsLoading(true);
        doGetRequest(getPartnerSiteSettingRequest.request, {
            successCallback: ({ SiteUrl, SiteBuilder }) => {
                resetForm({
                    values: { siteUrl: SiteUrl, siteBuilder: SiteBuilder },
                });
            },
        }).then(() => {
            setIsSiteSettingsLoading(false);
        });
    };

    const urlInputChangeHandler = (value) => {
        setFieldValue('siteUrl', value);
    };

    const siteBuilderTypeChangeHandler = (e) => {
        setFieldValue('siteBuilder', e.value);
    };

    const init = () => {
        getSiteBuilderTypes();
        getSiteSetting();
    };

    const cleanUp = () => {
        return () => {
            getSiteBuilderTypesRequest.cancel('WebsiteSection:getSiteBuilderTypesRequest');
            getPartnerSiteSettingRequest.cancel('WebsiteSection:getPartnerSiteSettingRequest');
            updatePartnerSiteSettingRequest.cancel('WebsiteSection:updatePartnerSiteSettingRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <Paper className="card" paperDirection="column">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name ellipsis-text">{t(l.Website)}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <div className="content">
                <div className="fields-btn-group">
                    <div className="site-builder-input">
                        <SkeletonLoader isBusy={isSiteSettingsLoading}>
                            {!isSiteSettingsLoading && (
                                <ExtendedInput
                                    value={values.siteUrl}
                                    onChange={(e) => urlInputChangeHandler(e.target?.value)}
                                    disabled={isSubmitting}
                                    placeholder={t(l.SiteUrl)}
                                    labelAppearance="swap"
                                    isValid={!errors.siteUrl}
                                    errorText={errors.siteUrl}
                                />
                            )}
                        </SkeletonLoader>
                    </div>
                    <div className="site-builder-dropdown">
                        <SkeletonLoader isBusy={isSiteBuilderTypesLoading}>
                            {!isSiteBuilderTypesLoading && (
                                <Dropdown
                                    isMultiSelect={false}
                                    disabled={isSubmitting}
                                    hasSearch={false}
                                    inputSize="default"
                                    placeholder={t(l.SiteBuilderType)}
                                    appearance="outline"
                                    labelAppearance="swap"
                                    data={siteBuilderTypes}
                                    onChange={siteBuilderTypeChangeHandler}
                                    value={values.siteBuilder}
                                    noDataText={t(l.NoDataFound)}
                                />
                            )}
                        </SkeletonLoader>
                    </div>
                    <div className="btn-group">
                        <ResetSave
                            hasAdditionalSettings={false}
                            resetButtonClickHandler={resetForm}
                            disableSave={!isValid || !dirty || isSubmitting}
                            disableReset={!dirty || isSubmitting}
                            saveButtonClickHandler={submitForm}
                            isLoadingSave={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        </Paper>
    );
};

export default memo(WebsiteSection);
