import Axios from 'axios';
import { isNil, isObject, keys, noop } from 'lodash';
import { AuthAction, MaintenanceAction } from 'actions';
import store from 'store';
// Import Constants
import { DefaultLangId } from 'constants/common';
// Import services
import { Helpers } from 'services';

const { replaceDomainFromEnvironmentURL } = Helpers;

const BackofficeRequestService = Axios.create({
    baseURL: process.env.REACT_APP_BACKOFFICE_URL, // TODO: refactor lang to var
    timeout: 20000,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
});

const CRMRequestServiceMetaData = {
    baseURL: process.env.REACT_APP_CRM_URL,
    timeout: 200000,
    headers: {
        'Content-Type': 'application/json',
    },
};

const CRMRequestServiceDownloadMetaData = {
    baseURL: process.env.REACT_APP_CRM_DOWNLOAD_URL,
    timeout: 200000,
    headers: {
        'Content-Type': 'application/json',
    },
};

const CRMRequestService = Axios.create({ ...CRMRequestServiceMetaData });
const CRMRequestDownloadLinkService = Axios.create({ ...CRMRequestServiceDownloadMetaData });
const CRMRequestServiceDownload = Axios.create({ ...CRMRequestServiceDownloadMetaData, responseType: 'blob' });

const CRMGalleryRequestService = Axios.create({
    baseURL: process.env.REACT_APP_CRM_GALLERY_URL,
    timeout: 200000,
    headers: {
        'Content-Type': 'application/json',
    },
});

const setRequestBaseURLInterceptor = (request) => {
    request.baseURL = replaceDomainFromEnvironmentURL(request.baseURL);
    return request;
};

const setRequestBaseURLInterceptorErrHandler = (error) => {
    return Promise.reject(error);
};

const setRequestHeadersInterceptor = (request) => {
    const state = store.getState();
    const { accessToken } = state.auth;
    if (accessToken) {
        request.headers.Authentication = accessToken;
    }
    return request;
};

const setRequestHeadersBackofficeInterceptor = (request) => {
    return request;
};

const CRMBaseURLInterceptor = (request) => {
    const state = store.getState();
    const { Settings } = state.userData;
    request.baseURL += `${Settings?.Language ?? DefaultLangId}/`;
    return request;
};

const setRequestHeadersInterceptorErrHandler = (error) => {
    // need handle error case
    return Promise.reject(error);
};

const responseInterceptor = (response) => {
    const { headers } = response;
    const token = headers.authentication;
    const maintenanceStartDate = headers['x-crm-maintenance-startdate'];
    const maintenanceEndDate = headers['x-crm-maintenance-enddate'];
    const activePartnerId = headers['x-crm-activepartnerid'];

    if (!isNil(token)) {
        store.dispatch(AuthAction.updateToken(token));
    }

    // We update maintenance state from requests, where we have received ['x-crm-activepartnerid'] header
    if (!isNil(activePartnerId)) {
        const { maintenance } = store.getState();
        const shouldUpdateMaintenance =
            maintenanceStartDate !== maintenance.startDate || maintenanceEndDate !== maintenance.endDate;

        if (shouldUpdateMaintenance) {
            if (isNil(maintenanceStartDate) || isNil(maintenanceEndDate)) {
                store.dispatch(MaintenanceAction.clearMaintenanceSettings());
            } else {
                store.dispatch(
                    MaintenanceAction.setMaintenanceSettings({
                        startDate: maintenanceStartDate,
                        endDate: maintenanceEndDate,
                    }),
                );
            }
        }
    }

    return response;
};

const responseInterceptorErrHandler = (error) => {
    // Unauthorized 401
    // Forbidden 403

    try {
        const { status } = error.response;

        if (status === 401) {
            store.dispatch(AuthAction.doLogout());
        }
    } catch {
        // TODO: should redirected to the no internet page.
        // eslint-disable-next-line no-console
        console.log('Network error');
    }

    return Promise.reject(error);
};

const responseDTOParserInterceptor = (response) => {
    const { data, headers } = response;
    return {
        data,
        headers,
    };
};

const responseDTOParserInterceptorErrHandler = (error) => {
    // need handle error case
    return Promise.reject(error);
};

const CRMResponseDTOParserInterceptor = (response) => {
    return response.data;
};

const CRMResponseDTOParserInterceptorErrHandler = (error) => {
    // need handle error case
    return Promise.reject(error);
};

// Request interceptors ===========================================
BackofficeRequestService.interceptors.request.use(setRequestBaseURLInterceptor, setRequestBaseURLInterceptorErrHandler);
CRMGalleryRequestService.interceptors.request.use(setRequestBaseURLInterceptor, setRequestBaseURLInterceptorErrHandler);
CRMRequestService.interceptors.request.use(setRequestBaseURLInterceptor, setRequestBaseURLInterceptorErrHandler);
CRMRequestDownloadLinkService.interceptors.request.use(
    setRequestBaseURLInterceptor,
    setRequestBaseURLInterceptorErrHandler,
);
CRMRequestServiceDownload.interceptors.request.use(
    setRequestBaseURLInterceptor,
    setRequestBaseURLInterceptorErrHandler,
);

// Request interceptors ===========================================
BackofficeRequestService.interceptors.request.use(
    setRequestHeadersBackofficeInterceptor,
    setRequestHeadersInterceptorErrHandler,
);
CRMGalleryRequestService.interceptors.request.use(setRequestHeadersInterceptor, setRequestHeadersInterceptorErrHandler);
CRMRequestService.interceptors.request.use(setRequestHeadersInterceptor, setRequestHeadersInterceptorErrHandler);
CRMRequestService.interceptors.request.use(CRMBaseURLInterceptor, setRequestHeadersInterceptorErrHandler);
CRMRequestDownloadLinkService.interceptors.request.use(
    setRequestHeadersInterceptor,
    setRequestHeadersInterceptorErrHandler,
);
CRMRequestDownloadLinkService.interceptors.request.use(CRMBaseURLInterceptor, setRequestHeadersInterceptorErrHandler);

CRMRequestServiceDownload.interceptors.request.use(
    setRequestHeadersInterceptor,
    setRequestHeadersInterceptorErrHandler,
);
CRMRequestServiceDownload.interceptors.request.use(CRMBaseURLInterceptor, setRequestHeadersInterceptorErrHandler);

// Response interceptors ===========================================
BackofficeRequestService.interceptors.response.use(responseInterceptor, responseInterceptorErrHandler);
BackofficeRequestService.interceptors.response.use(
    responseDTOParserInterceptor,
    responseDTOParserInterceptorErrHandler,
);
CRMGalleryRequestService.interceptors.response.use(responseInterceptor, responseInterceptorErrHandler);
CRMGalleryRequestService.interceptors.response.use(
    CRMResponseDTOParserInterceptor,
    CRMResponseDTOParserInterceptorErrHandler,
);

CRMRequestService.interceptors.response.use(responseInterceptor, responseInterceptorErrHandler);
CRMRequestService.interceptors.response.use(CRMResponseDTOParserInterceptor, CRMResponseDTOParserInterceptorErrHandler);
CRMRequestDownloadLinkService.interceptors.response.use(responseInterceptor, responseInterceptorErrHandler);
CRMRequestDownloadLinkService.interceptors.response.use(
    CRMResponseDTOParserInterceptor,
    CRMResponseDTOParserInterceptorErrHandler,
);

CRMRequestServiceDownload.interceptors.response.use(responseInterceptor, responseInterceptorErrHandler);
CRMRequestServiceDownload.interceptors.response.use(
    CRMResponseDTOParserInterceptor,
    CRMResponseDTOParserInterceptorErrHandler,
);

const generateRequestObj = (requestService, endpointPath, defaultParams) => {
    const cancelTokenSource = Axios.CancelToken.source();
    // eslint-disable-next-line no-console
    if (!isObject(requestService)) console.log("requestService isn't Object");
    // eslint-disable-next-line no-console
    if (!isObject(endpointPath)) console.log("endpointPath isn't String");
    if (!isObject(defaultParams)) defaultParams = {};

    return {
        cancelTokenSource,
        request: noop,
    };
};

const getEncodedQueryParams = (queryParams) => {
    return keys(queryParams).reduce((acc, key) => {
        acc[key] = encodeURIComponent(queryParams[key]);
        return acc;
    }, {});
};

const getEndpointPath = (endpointPath, queryParams, uriEncoding) => {
    return endpointPath(uriEncoding ? getEncodedQueryParams(queryParams) : queryParams);
};

const getCancelableRequest = ({ requestService, endpointPath, defaultParams = {}, uriEncoding = true }) => {
    const requestObj = generateRequestObj(requestService, endpointPath, defaultParams);
    let request = requestObj.request;
    const { cancel, token } = requestObj.cancelTokenSource;

    request = (params = {}) =>
        requestService.get(getEndpointPath(endpointPath, { ...defaultParams, ...params }, uriEncoding), {
            cancelToken: token,
        });

    return {
        cancel,
        request,
    };
};

const postCancelableRequest = ({ requestService, endpointPath, defaultParams = {}, uriEncoding = true }) => {
    const requestObj = generateRequestObj(requestService, endpointPath, defaultParams);
    let request = requestObj.request;
    const { cancel, token } = requestObj.cancelTokenSource;

    request = (requestBody, params = {}) =>
        requestService.post(getEndpointPath(endpointPath, { ...defaultParams, ...params }, uriEncoding), requestBody, {
            cancelToken: token,
        });

    return {
        cancel,
        request,
    };
};

const deleteCancelableRequest = ({ requestService, endpointPath, defaultParams, uriEncoding = true }) => {
    const requestObj = generateRequestObj(requestService, endpointPath, defaultParams);
    let request = requestObj.request;
    const { cancel, token } = requestObj.cancelTokenSource;

    request = (params = {}) =>
        requestService.delete(getEndpointPath(endpointPath, { ...defaultParams, ...params }, uriEncoding), {
            cancelToken: token,
        });

    return {
        cancel,
        request,
    };
};

export {
    BackofficeRequestService,
    CRMRequestService,
    CRMGalleryRequestService,
    CRMRequestServiceDownload,
    CRMRequestDownloadLinkService,
    getCancelableRequest,
    postCancelableRequest,
    deleteCancelableRequest,
    replaceDomainFromEnvironmentURL,
};
