import React, { Suspense, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { isString } from 'lodash';
// Import Hooks
import { useLocalization, useRequest } from 'hooks';
// Import UI Components
import { Toaster, BusyLoader } from '@geneui/components';
// Import Components
import { Header, BMENewsModal } from 'components';
import MainAppRoutes from './MainAppRoutes';
// Import Actions
import { AuthAction, UserDataAction, HeaderAction, PartnerSettingsDataAction } from 'actions';
// Import Services
import { UtilsHttpService } from 'services/http';
import { subscribe } from 'services/event';
// Import Constants
import { AuthProvidersTypes, DefaultLangId, GlobalEvents, SubscriberSources } from 'constants/common';
import { BrowserStorageKeys } from 'constants/browserStorage';
// Import SCSS
import 'assets/scss/defaults.scss';

const MainApp = () => {
    const dispatch = useDispatch();
    const { doLogout } = AuthAction;
    const { setUserData, clearUserData } = UserDataAction;
    const { setSettingsSucceeded, setAppUrl } = HeaderAction;
    const { getPartnerSettingsSuccess, getPartnerSettingsFailed, setPartnerCurrencies } = PartnerSettingsDataAction;

    const [setLocalization] = useLocalization();
    const { url } = useRouteMatch();

    const [isDataReady, setIsDataReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { doGetRequest } = useRequest();
    const { getPartnerSettingsRequest, getUserInfoRequest, getPartnerCurrenciesRequest } = useMemo(
        () => ({
            getPartnerSettingsRequest: UtilsHttpService.getPartnerSettings(),
            getUserInfoRequest: UtilsHttpService.getUserInfo(),
            getPartnerCurrenciesRequest: UtilsHttpService.getPartnerCurrencies(),
        }),
        [],
    );

    const getUserInfoHandler = () => {
        doGetRequest(getUserInfoRequest.request, {
            successCallback: (data) => {
                // TODO: change LangId to Language
                const { Settings, ActivePartner, UserSession } = data;
                const LangId = Settings.Language;
                data.LangId = isString(LangId) ? LangId : DefaultLangId;
                const Timezone = ActivePartner.TimeZone;

                localStorage.setItem(BrowserStorageKeys.timezone, Timezone);

                if (UserSession?.ProviderId === AuthProvidersTypes.Password) {
                    localStorage.setItem(BrowserStorageKeys.isCRMSignIn, true);
                } else {
                    localStorage.removeItem(BrowserStorageKeys.isCRMSignIn);
                }

                dispatch(setUserData(data));
                dispatch(setSettingsSucceeded(data));
                setLocalization(LangId || DefaultLangId, () => setIsDataReady(true));
                setIsLoading(false);
            },
            errorCallback: () => {
                // TODO: handle error
                dispatch(clearUserData());
                dispatch(doLogout());
            },
        });
    };

    const getPartnerSettingsHandler = () => {
        if (!isLoading) {
            doGetRequest(getPartnerSettingsRequest.request, {
                successCallback: (data) => {
                    dispatch(getPartnerSettingsSuccess(data));
                },
                errorCallback: (data) => {
                    dispatch(getPartnerSettingsFailed(data));
                },
            });
        }
    };

    const getPartnerCurrenciesHandler = () => {
        doGetRequest(getPartnerCurrenciesRequest.request, {
            successCallback: (data) => {
                dispatch(setPartnerCurrencies(data));
            },
        });
    };

    const init = () => {
        getUserInfoHandler();
        getPartnerCurrenciesHandler();
        dispatch(setAppUrl(url));
        subscribe(GlobalEvents.PartnerChanged, SubscriberSources.UserInfo, getUserInfoHandler);
        subscribe(GlobalEvents.PartnerChanged, SubscriberSources.PartnerCurrencies, getPartnerCurrenciesHandler);
        subscribe(GlobalEvents.UserSettingsChange, SubscriberSources.UserInfo, getUserInfoHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getPartnerSettingsHandler, [isLoading]);
    return (
        <div>
            <div className="crm-wrapper">
                <>
                    {!(isLoading || !isDataReady) && <Header setIsLoading={setIsLoading} isLoading={isLoading} />}
                    <div className="crm-content">
                        {isLoading || !isDataReady ? (
                            <BusyLoader
                                isBusy
                                type="spinner"
                                spinnerSize="big"
                                className="crm-full-width crm-justify-content-center"
                            />
                        ) : (
                            <Suspense
                                fallback={
                                    <BusyLoader
                                        isBusy
                                        type="spinner"
                                        spinnerSize="big"
                                        className="crm-full-width crm-justify-content-center"
                                    />
                                }
                            >
                                <MainAppRoutes />
                            </Suspense>
                        )}
                    </div>
                    <Toaster notificationIcon={true} />
                </>
            </div>
            <BMENewsModal isReady={isDataReady && !isLoading} />
        </div>
    );
};

export default memo(MainApp);
