import React, { memo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isUndefined, isArray, isEqual, isEmpty, first, isObject, has, isNil } from 'lodash';
// Import UI Components
import { Modal, Button, BusyLoader } from '@geneui/components';
// Import Components
import { FromToBlocks } from 'components';
// Import Constants
import { l } from 'constants/common';
// Import Services
import { UtilsHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import SCSS
import 'assets/scss/columnsChooser.scss';

const ColumnsChooser = ({ tableName, modalOpenedState, setModalOpenedState, onSave, possibleColumns }) => {
    const { t } = useTranslation();
    const { doPostRequest, doGetRequest } = useRequest();

    // Segment fetch part
    const { saveDataTabelConfig, getDataTabelConfig } = useRef({
        saveDataTabelConfig: UtilsHttpService.saveDataTabelConfig(),
        getDataTabelConfig: UtilsHttpService.getDataTabelConfig(),
    }).current;

    const [leftList, setLeftList] = useState([]);
    const [rightList, setRightList] = useState([]);
    const [data, setData] = useState({});
    const [selectedColumns, setSelectedColumns] = useState();
    const [isSaveActive, setIsSaveActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingTextLabel, setLoadingTextLabel] = useState(l.Loading);

    const fromToBlocksRef = useRef();

    const findColumnByDataKey = (dataKey) => {
        return first(
            possibleColumns.filter((column) => {
                return column.dataKey === dataKey;
            }),
        );
    };

    const mapToColumnAPIModel = (list) => {
        if (!isArray(list)) return;

        return list.map(({ id, index }) => {
            return {
                Name: id,
                Position: index,
            };
        });
    };

    const mapToBlockModelFromRemote = (list = []) => {
        return list
            .filter(({ Name }) => {
                return !isUndefined(Name);
            })
            .map(({ Name, Position }) => {
                const column = findColumnByDataKey(Name);
                return {
                    name: isObject(column) && has(column, 'text') ? column.text : Name,
                    id: Name,
                    isPermanent: isObject(column) && has(column, 'isPermanent') ? column.isPermanent : false,
                    isChecked: false,
                    isVisible: true,
                    index: Position,
                };
            });
    };

    const mapToBlockModelFromLocal = (enabledList, list = []) => {
        return list
            .filter(({ text, dataKey }) => {
                return (
                    !isUndefined(text) &&
                    enabledList.filter((enabledColumn) => enabledColumn.id === dataKey).length === 0
                );
            })
            .map(({ text, dataKey, isPermanent }, index) => {
                return { name: text, id: dataKey, isPermanent, isChecked: false, isVisible: true, index: index };
            });
    };

    const mapSelectedToDataTable = (list = []) => {
        return list.map((item) => {
            return item.Name;
        });
    };

    const getCurrentColumnsUpdate = (newData) => {
        setData(newData);
        setSelectedColumns(mapToColumnAPIModel(newData.rightBlockList));
    };

    useEffect(() => {
        if (!isArray(selectedColumns)) return;
        setIsSaveActive(!isEqual(mapToColumnAPIModel([...rightList]), selectedColumns));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColumns]);

    const setBlocksValues = (left, right) => {
        if (isEmpty(right)) {
            setLeftList([]);
            setRightList(left);
        } else {
            setLeftList(left);
            setRightList(right);
        }
    };

    const onSaveHandler = () => {
        setLoadingTextLabel(l.Saving);
        setIsLoading(true);
        setIsSaveActive(false);

        const requestBody = {
            TabelName: tableName,
            EnabledColumns: selectedColumns,
        };
        doPostRequest(saveDataTabelConfig.request, {
            requestBody,
            successCallback: () => {
                setModalOpenedState(false);
                onSave(mapSelectedToDataTable(selectedColumns));
                setBlocksValues(data.leftBlockList, data.rightBlockList);
                setIsLoading(false);
            },
        });
    };

    const onResetHandler = () => {
        fromToBlocksRef.current.reset();
    };

    const onCancelHandler = () => {
        setModalOpenedState(false);
    };

    const getCustomFooter = () => {
        return (
            <>
                <Button appearance="minimal" onClick={onCancelHandler}>
                    {t(l.Close)}
                </Button>
                <Button disabled={!isSaveActive} appearance="minimal" onClick={onResetHandler}>
                    {t(l.Reset)}
                </Button>

                <Button disabled={!isSaveActive} onClick={onSaveHandler}>
                    {t(l.Save)}
                </Button>
            </>
        );
    };

    const init = () => {
        doGetRequest(getDataTabelConfig.request, {
            queryString: { tableName },
            successCallback: (Data) => {
                let { EnabledColumns } = Data;

                if (!isArray(EnabledColumns)) {
                    EnabledColumns = possibleColumns;
                }

                const filtredEnabledColumns = mapToBlockModelFromRemote(EnabledColumns);
                const filtredPossibleColumns = mapToBlockModelFromLocal(filtredEnabledColumns, possibleColumns);

                setBlocksValues(filtredPossibleColumns, filtredEnabledColumns);
                onSave(mapSelectedToDataTable(mapToColumnAPIModel(filtredEnabledColumns)));
            },
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    return (
        <div>
            {modalOpenedState && (
                <Modal
                    title={t(l.ColumnsChooser)}
                    className="d-a-d-popup"
                    background="dark-background"
                    closable={true}
                    position="center"
                    onCancel={onCancelHandler}
                    cancelText={t(l.Reset)}
                    okText={t(l.Save)}
                    closeOnClickOutside={true}
                    visible={modalOpenedState}
                    size="content-size"
                    footer={getCustomFooter()}
                >
                    {isLoading && (isNil(leftList) || isNil(rightList)) ? (
                        <BusyLoader isBusy type="spinner" loadingText={t(loadingTextLabel)} />
                    ) : (
                        <FromToBlocks
                            leftBlockList={leftList}
                            rightBlockList={rightList}
                            getUpdate={getCurrentColumnsUpdate}
                            leftBlockTitle={t(l.Possible)}
                            rightBlockTitle={t(l.Current)}
                            rightBlockWithPosition={true}
                            ref={fromToBlocksRef}
                        />
                    )}
                </Modal>
            )}
        </div>
    );
};

ColumnsChooser.propTypes = {
    tableName: PropTypes.string.isRequired,
    modalOpenedState: PropTypes.bool.isRequired,
    setModalOpenedState: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    possibleColumns: PropTypes.array.isRequired,
};

export default memo(ColumnsChooser);
