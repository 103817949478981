import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
// Import Components
import HTMLEditors from './HTMLEditors';
import DynamicVariables from './DynamicVariables';
// Import UI Components
import { Dropdown, ExtendedInput, SkeletonLoader } from '@geneui/components';
// Import Constants
import { DynamicPopUpDeviceTypesLabels, l, TemplateEditorModes, TemplateInputTypes } from 'constants/common';
// Import Hooks
import { useBind, useFieldValidity, useInputInsertion, useRequest } from 'hooks';
// Import Services
import { TemplateType } from 'services/template';
import { UtilsHttpService } from 'services/http';

const DeviceTypes = [
    { value: DynamicPopUpDeviceTypesLabels.Both, label: l.Both },
    { value: DynamicPopUpDeviceTypesLabels.Desktop, label: l.Desktop },
    { value: DynamicPopUpDeviceTypesLabels.Mobile, label: l.Mobile },
];

// temporarily hardcoded value, it shows sub content name for the same template type, it enables possibility to have different contents for the same template
const templateFieldWithConfig = 'Body';

const DynamicPopUpContent = ({
    templateData,
    handleChange,
    handleUIConfigChange,
    templateLangKey,
    getAssets,
    addAsset,
    dynamicVariables,
}) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();

    const urlTemplateData = templateData?.[TemplateInputTypes.Url];
    const bodyValue = templateData?.[TemplateInputTypes.Body];
    const uiConfig = templateData?.UIConfig;

    const [url, setUrl] = useState(urlTemplateData ?? '');
    const [urlTypesData, setUrlTypesData] = useState([]);
    const [isLoadingComparisons, setIsLoadingComparisons] = useState(true);

    const { getPopUpComparisons } = useMemo(
        () => ({
            getPopUpComparisons: UtilsHttpService.getPopUpComparisons(),
        }),
        [],
    );

    const translatedDeviceTypes = useMemo(() => DeviceTypes.map((type) => ({ ...type, label: t(type.label) })), [t]);
    const radioGroupOptions = useMemo(
        () => TemplateEditorModes.DynamicPopUp.map((option) => ({ ...option, label: t(option.label) })),
        [t],
    );

    const bodyValidationState = useFieldValidity(TemplateInputTypes.Body, l.Body, templateLangKey);

    const { insertionHandler, updateInsertionHandler, insertionOptions, setInsertionOptions } = useInputInsertion();

    const handleBodyChange = useBind(handleChange, TemplateInputTypes.Body);
    const handleBodyUIConfigChange = useBind(handleUIConfigChange, templateFieldWithConfig);
    const updateBodyInsertionHandler = useBind(updateInsertionHandler, TemplateInputTypes.Body);
    const handleUrlChange = useBind(handleChange, TemplateInputTypes.Url);
    const handleUrlTypeChange = useBind(handleChange, TemplateInputTypes.UrlType);
    const handleDeviceTypeChange = useBind(handleChange, TemplateInputTypes.DeviceType);

    const urlTypeChangeHandler = (e) => handleUrlTypeChange(e.value, 0);
    const urlChangeHandler = (e) => {
        setUrl(e.target.value);
        handleUrlChange(e.target.value);
    };
    const deviceTypesChangeHandler = (e) => handleDeviceTypeChange(e.value, 0);

    const updateDynamicVariableBodyOptions = useCallback(
        (hintText) => {
            setInsertionOptions({
                channel: TemplateType.DynamicPopUp,
                hintText,
                inputFilterKey: '',
                focusedInputKey: TemplateInputTypes.Body,
            });
        },
        [setInsertionOptions],
    );

    const updateDynamicVariableUrlOptions = useCallback(() => {
        setInsertionOptions({
            channel: TemplateType.DynamicPopUp,
            hintText: l.DynamicVariablesAreNotSupported,
            inputFilterKey: '',
            focusedInputKey: TemplateInputTypes.Url,
        });
    }, [setInsertionOptions]);

    useEffect(() => setUrl(urlTemplateData), [urlTemplateData]);

    const init = () => {
        doGetRequest(getPopUpComparisons.request, {
            successCallback: (Data) => {
                const popUpNotificationUrlComparisons = Data.map(({ Id, Name }) => ({ value: Id, label: Name }));
                setUrlTypesData(popUpNotificationUrlComparisons);
                if (isEmpty(templateData?.[TemplateInputTypes.UrlType])) {
                    handleUrlTypeChange(DeviceTypes[0].value, 0);
                }
            },
        }).then(() => {
            setIsLoadingComparisons(false);
        });

        if (isEmpty(templateData?.[TemplateInputTypes.DeviceType])) {
            handleDeviceTypeChange(DeviceTypes[0].value, 0);
        }
    };

    const cleanUp = () => {
        return () => {
            getPopUpComparisons.cancel('DynamicPopUpContent:getPopUpComparisons');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <div>
            <DynamicVariables
                dynamicVariables={dynamicVariables}
                clickHandler={insertionHandler}
                insertionOptions={insertionOptions}
            />
            <div className="dynamic-pop-up-url-section">
                <div className="dynamic-pop-up-url-type">
                    {isNil(urlTypesData) || isEmpty(urlTypesData) || isLoadingComparisons ? (
                        <SkeletonLoader isBusy={true} />
                    ) : (
                        <Dropdown
                            value={templateData?.[TemplateInputTypes.UrlType]}
                            onChange={urlTypeChangeHandler}
                            data={urlTypesData}
                            labelAppearance="swap"
                            placeholder={t(l.UrlType)}
                        />
                    )}
                </div>
                <ExtendedInput
                    value={url}
                    onChange={urlChangeHandler}
                    labelAppearance="swap"
                    placeholder={t(l.Url)}
                    className="dynamic-pop-up-url-input"
                    onFocus={updateDynamicVariableUrlOptions}
                />
                <div className="dynamic-pop-up-device-type">
                    <Dropdown
                        value={templateData?.[TemplateInputTypes.DeviceType]}
                        onChange={deviceTypesChangeHandler}
                        data={translatedDeviceTypes}
                        labelAppearance="swap"
                        placeholder={t(l.DeviceType)}
                    />
                </div>
            </div>
            <HTMLEditors
                tabOptions={radioGroupOptions}
                htmlText={bodyValue}
                handleChange={handleBodyChange}
                config={uiConfig?.[templateFieldWithConfig]}
                handleConfigChange={handleBodyUIConfigChange}
                templateLangKey={templateLangKey}
                getAssets={getAssets}
                addAsset={addAsset}
                validationState={bodyValidationState}
                dynamicVariables={dynamicVariables}
                templateType={TemplateType.DynamicPopUp}
                bodyPropertyName={TemplateInputTypes.Body}
                updateInsertionHandler={updateBodyInsertionHandler}
                onEditorFocus={updateDynamicVariableBodyOptions}
                isInitialFocus={true}
            />
        </div>
    );
};

DynamicPopUpContent.propTypes = {
    templateData: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleUIConfigChange: PropTypes.func.isRequired,
    templateLangKey: PropTypes.string.isRequired,
    getAssets: PropTypes.func,
    addAsset: PropTypes.func,
    dynamicVariables: PropTypes.arrayOf(PropTypes.object),
};

export default DynamicPopUpContent;
