import React, { memo, useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty, first, isNil } from 'lodash';
// Import Services
import { Helpers } from 'services';
// Import Hooks
import { useMenuItem } from 'hooks';
// Import Components
import { CJPartnerBonusId, BonusAmountsWrapper } from './index';
import { CurrencyAutoFillSection } from 'components';
// Import UI Components
import { BusyLoader, Dropdown } from '@geneui/components';
// Import Constants
import { l, BonusTypesLabels, BonusTriggerTypeEnum } from 'constants/common';

const { getBonusSupportedCurrencies } = Helpers;

const CJBonus = ({ defaultValue, getUpdate, option, updateDependency }) => {
    const { t } = useTranslation();
    const [savedDataForRestoreBonus, setSavedDataForRestoreBonus] = useState({});
    const [bonusDetails, setBonusDetails] = useState([]);
    const [bonusType, setBonusType] = useState();
    const defaultBonusId = defaultValue?.bonusId;
    const [bonusId, setBonusId] = useState(defaultBonusId);
    const [triggerType, setTriggerType] = useState(defaultValue?.triggerType);
    const [isLoadingBonusDetails, setIsLoadingBonusDetails] = useState(false);
    const [supportedCurrencies, setSupportedCurrencies] = useState([]);
    const [bonusAmountsList, setBonusAmountsList] = useState([...defaultValue?.amounts]);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const { convertedData: currenciesDropdownData, isLoading: isLoadingCurrencyData } = useMenuItem(104);
    const { partnerCurrencies } = useSelector((state) => state.partnerSettings);
    const totalCurrencyRates = useSelector((state) => state.currencyRates);

    useEffect(() => {
        if (isNil(defaultValue.wallet)) {
            setSelectedWallet(null);
        } else {
            const walletIsSupported = !!partnerCurrencies.find(
                (currency) => currency.CurrencyId === defaultValue.wallet && currency.IsWalletAutocreatable,
            );
            setSelectedWallet(walletIsSupported ? defaultValue.wallet : null); // TODO discuss the case when the selected wallet is no longer supported, now we just set primary wallet
        }
    }, [defaultValue, partnerCurrencies]);

    useEffect(() => {
        if (bonusDetails && (defaultBonusId !== bonusId || (defaultBonusId === bonusId && bonusId === ''))) {
            const tmpAmountList =
                bonusDetails?.map(({ CurrencyCode, MaxAmount, MinAmount }) => {
                    return { CurrencyCode, OriginalValue: MinAmount === MaxAmount ? MinAmount : null };
                }) || [];

            getUpdate({
                bonusId: bonusId,
                triggerType: triggerType,
                amounts: tmpAmountList,
                useSecondaryCurrency: false,
            });
            setBonusAmountsList(tmpAmountList);
            setSelectedWallet(null);
        }
    }, [bonusDetails, bonusId, defaultBonusId, getUpdate, triggerType]);

    const supportedWallets = useMemo(() => {
        return partnerCurrencies.filter((el) => el.IsWalletAutocreatable);
    }, [partnerCurrencies]);

    const bonusAmountListChangeHandler = useCallback(
        (index, value) => {
            const tmpBonusAmountsList = [...bonusAmountsList];
            tmpBonusAmountsList[index] = value;
            setBonusAmountsList(tmpBonusAmountsList);

            getUpdate({
                bonusId: bonusId,
                triggerType: triggerType,
                amounts: tmpBonusAmountsList,
                wallet: selectedWallet,
            });
        },
        [bonusAmountsList, bonusId, getUpdate, selectedWallet, triggerType],
    );

    const restoreBonusAmountsList = useCallback(
        (newBonusDetails = []) => {
            const tmpAmountList =
                savedDataForRestoreBonus.bonusAmountsList?.filter((list) =>
                    newBonusDetails.some(({ CurrencyCode }) => CurrencyCode === list.CurrencyCode),
                ) || [];
            setBonusAmountsList(tmpAmountList);
            setSavedDataForRestoreBonus({});
        },
        [savedDataForRestoreBonus.bonusAmountsList],
    );

    const setPartnerBonusDetails = useCallback(
        (promise, shouldRestoreSavedDataWhenBonusDetailsReloaded) => {
            setIsLoadingBonusDetails(true);
            promise
                .then((data) => {
                    const newBonusDetails = data?.BonusDetails ?? [];
                    setBonusDetails(newBonusDetails);
                    setBonusType(data?.Type);
                    setSupportedCurrencies(getBonusSupportedCurrencies(data));

                    if (
                        shouldRestoreSavedDataWhenBonusDetailsReloaded &&
                        data.Id === savedDataForRestoreBonus.bonusId
                    ) {
                        restoreBonusAmountsList(newBonusDetails);
                    }
                })
                .catch(() => {
                    if (!isNil(bonusId) && bonusId !== savedDataForRestoreBonus.bonusId) {
                        setSavedDataForRestoreBonus({ bonusId, bonusAmountsList });
                    }
                    setBonusType();
                    setBonusDetails([]);
                    setSupportedCurrencies([]);
                    setBonusAmountsList([]);
                })
                .finally(() => {
                    setIsLoadingBonusDetails(false);
                });
        },
        [bonusAmountsList, bonusId, restoreBonusAmountsList, savedDataForRestoreBonus.bonusId],
    );

    const partnerBonusIdChangeHandler = useCallback(({ value, triggerType }) => {
        setBonusId(value);
        setTriggerType(triggerType);
    }, []);

    const changeWalletHandler = (item) => {
        setSelectedWallet(item.value);
        const secondaryCurrencyOriginalValue = first(
            bonusAmountsList?.filter(
                ({ CurrencyCode }) => CurrencyCode === item.value || bonusType === BonusTypesLabels.FreeSpin,
            ),
        )?.OriginalValue;

        const tmpBonusAmountsList =
            bonusDetails
                ?.filter(
                    ({ CurrencyCode }) =>
                        isNil(item.value) || CurrencyCode === item.value || bonusType === BonusTypesLabels.FreeSpin,
                )
                .map(({ CurrencyCode, MaxAmount, MinAmount }) => {
                    return {
                        CurrencyCode,
                        OriginalValue:
                            !isNil(item.value) &&
                            (CurrencyCode === item.value || bonusType === BonusTypesLabels.FreeSpin)
                                ? secondaryCurrencyOriginalValue
                                : MinAmount === MaxAmount
                                ? MinAmount
                                : null,
                    };
                }) || [];
        setBonusAmountsList([...tmpBonusAmountsList]);

        getUpdate({
            bonusId: bonusId,
            triggerType: triggerType,
            amounts: [...tmpBonusAmountsList],
            wallet: item.value,
        });
    };

    const onAutoFillClickHandler = (data) => {
        const { amount, selectedCurrency } = data;
        setBonusAmountsList((prev) => {
            return prev.map((element) => {
                element.OriginalValue = totalCurrencyRates[element.CurrencyCode][selectedCurrency].Rate * amount;
                return element;
            });
        });
    };

    const secondaryCurrenciesDropdownData = useMemo(() => {
        if (isEmpty(partnerCurrencies)) {
            return [{ label: t(l.PrimaryWallet), value: null }];
        } else {
            const tmpResult = partnerCurrencies
                .filter((currency) => {
                    return currency.IsWalletAutocreatable;
                })
                .map((currency) => {
                    return {
                        label: currency.CurrencyId,
                        value: currency.CurrencyId,
                    };
                });
            tmpResult.unshift({ label: t(l.PrimaryWallet), value: null });
            return tmpResult;
        }
    }, [partnerCurrencies, t]);

    const isVisibleWalletSelection = useMemo(() => {
        const hasSupportedWallets = !isEmpty(supportedWallets);

        const isNotSingleWalletBonus = ![
            BonusTypesLabels.LoyaltyPoint,
            BonusTypesLabels.NFTBonus,
            BonusTypesLabels.TradingPlan,
            BonusTypesLabels.LoyaltyBenefit,
        ].includes(bonusType);

        const isAutoBonusTriggerType = !isNil(triggerType) && triggerType !== BonusTriggerTypeEnum.Manual;

        const isFreeSpin = bonusType === BonusTypesLabels.FreeSpin;

        const hasWalletWithSupportedCurrency = supportedWallets.some((supportedWallet) =>
            supportedCurrencies.includes(supportedWallet.CurrencyId),
        );

        return (
            bonusId &&
            hasSupportedWallets &&
            isNotSingleWalletBonus &&
            (isAutoBonusTriggerType || isFreeSpin || hasWalletWithSupportedCurrency)
        );
    }, [supportedWallets, bonusType, triggerType, bonusId, supportedCurrencies]);

    return (
        <>
            <div className="cj-bonus-container">
                <CJPartnerBonusId
                    defaultValue={{ value: defaultBonusId, triggerType: defaultValue?.triggerType }}
                    getUpdate={partnerBonusIdChangeHandler}
                    option={option}
                    updateDependency={updateDependency}
                    setBonusDetails={setPartnerBonusDetails}
                />
                {isVisibleWalletSelection && (
                    <Dropdown
                        label={l.Wallet}
                        labelAppearance="swap"
                        value={selectedWallet}
                        onChange={changeWalletHandler}
                        data={secondaryCurrenciesDropdownData}
                        hasSearch={true}
                        placeholder={l.Wallet}
                        className="wallet-dropdown"
                    />
                )}
            </div>
            <BusyLoader spinnerSize="medium" isBusy={isLoadingBonusDetails}>
                {bonusAmountsList.length > 1 && (
                    <CurrencyAutoFillSection
                        isLoading={isLoadingCurrencyData}
                        dropdownData={currenciesDropdownData}
                        onOk={onAutoFillClickHandler}
                        alertMessage={t(l.CurrencyAutoFillInfoAlert)}
                    />
                )}
                <div className="crm-cj-edit-modal-bonus-cmp-fields">
                    <BonusAmountsWrapper
                        bonusDetails={bonusDetails?.filter(
                            ({ CurrencyCode }) =>
                                isNil(selectedWallet) ||
                                CurrencyCode === selectedWallet ||
                                bonusType === BonusTypesLabels.FreeSpin,
                        )}
                        bonusAmountListChangeHandler={bonusAmountListChangeHandler}
                        bonusAmountsList={bonusAmountsList}
                        isRequired={option?.Required}
                    />
                </div>
            </BusyLoader>
        </>
    );
};

CJBonus.propTypes = {
    defaultValue: PropTypes.object,
    getUpdate: PropTypes.func.isRequired,
    option: PropTypes.object.isRequired,
    optionsSharedState: PropTypes.object.isRequired,
    updateDependency: PropTypes.func.isRequired,
};

CJBonus.defaultProps = {
    defaultValue: { amounts: [], bonusId: '' },
};

export default memo(CJBonus);
