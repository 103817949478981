import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useGACustomEvent, useRequest } from 'hooks';
// Import Services
import { DashboardHttpService } from 'services/http';
import { getStartDate, getEndDate } from 'services/dashboard';
import { Helpers } from 'services';
// Import UI Components
import { BarChart, DatePickerInput, BusyLoader, Empty, ModuleTitle } from '@geneui/components';
// Import Configs
import { datePickerConfigs } from './config';
// Import Constants
import { GACategoryEvents, l } from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;
const { GADateFilterCategory } = GACategoryEvents;
let getDashboardOnBoardActivityObj;

const CustomerOnBoardActivityChart = ({ parentDate }) => {
    const { doGetRequest } = useRequest();
    const { t } = useTranslation();
    const { logCustomEvent } = useGACustomEvent();

    const [isLoading, setIsLoading] = useState(false);
    const [customerOnBoardActivityData, setCustomerOnBoardActivityDataData] = useState(null);
    const [date, setDate] = useState(parentDate);

    const { getDashboardOnBoardActivityRequest } = useRef({
        getDashboardOnBoardActivityRequest: DashboardHttpService.getDashboardOnBoardActivity,
    }).current;

    const getDashboardOnBoardActivity = (date) => {
        setIsLoading(true);

        if (!isNil(getDashboardOnBoardActivityObj)) {
            getDashboardOnBoardActivityObj.cancel('CustomerOnBoardActivityChart:getDashboardOnBoardActivityRequest');
        }
        getDashboardOnBoardActivityObj = getDashboardOnBoardActivityRequest();
        doGetRequest(getDashboardOnBoardActivityObj.request, {
            queryString: { startDate: date[0], endDate: date[1] },
            successCallback: ({ DashboardOnBoardActivityList, SportEventsSeries }) => {
                const categories = [];
                const firstDepositCounts = [];
                const registrationCounts = [];
                const secondDepositCounts = [];
                const thirdDepositCounts = [];
                DashboardOnBoardActivityList.forEach((element) => {
                    firstDepositCounts.push(element['FirstDepositCount']);
                    registrationCounts.push(element['RegistrationCount']);
                    secondDepositCounts.push(element['SecondDepositCount']);
                    thirdDepositCounts.push(element['ThirdDepositCount']);
                    categories.push(customMomentWithoutTimezoneConversion(element['Date']).format('MMM DD'));
                });

                setCustomerOnBoardActivityDataData({
                    series: [
                        {
                            name: SportEventsSeries.find((r) => r.field === 'RegistrationCount').name,
                            data: registrationCounts,
                        },
                        {
                            name: SportEventsSeries.find((r) => r.field === 'FirstDepositCount').name,
                            data: firstDepositCounts,
                        },
                        {
                            name: SportEventsSeries.find((r) => r.field === 'SecondDepositCount').name,
                            data: secondDepositCounts,
                        },
                        {
                            name: SportEventsSeries.find((r) => r.field === 'ThirdDepositCount').name,
                            data: thirdDepositCounts,
                        },
                    ],
                    categories: categories,
                });
            },
        }).then(() => setIsLoading(false));
    };

    const getDate = (date) => [getStartDate(date[0]), getEndDate(date[1])];

    const handleDatePickerChange = (e) => {
        const date = getDate(e);

        logCustomEvent(
            GADateFilterCategory.name,
            GADateFilterCategory.events.newCustomersDailyMetrics,
            'new customers metrics date',
            `${date[0]} - ${date[1]}`,
        );
        setDate(date);
    };

    useEffect(() => {
        getDashboardOnBoardActivity(date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date[0], date[1]]);

    const cleanUp = () => {
        return () => {
            getDashboardOnBoardActivityObj.cancel('WidgetChooser:getDashboardOnBoardActivityRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    useEffect(() => {
        setDate(getDate(parentDate));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentDate]);

    return (
        <>
            <div className="charts-wrap-inner-head">
                <ModuleTitle title={t(l.DailyMetricsForNewCustomers)} />

                <div className="campaign-metrics-wrap-c-h-d">
                    <DatePickerInput.WithRange
                        onChange={handleDatePickerChange}
                        value={date}
                        label={t(l.TimePeriod)}
                        labelAppearance="swap"
                        min={datePickerConfigs.min}
                        max={datePickerConfigs.max}
                    />
                </div>
            </div>
            {isLoading ? (
                <BusyLoader spinnerSize="big" isBusy={true} />
            ) : isEmpty(customerOnBoardActivityData?.categories) ? (
                <Empty title={t(l.NoDataToDisplay)} />
            ) : (
                <div className="charts-wrap-inner-content">
                    <BarChart
                        series={customerOnBoardActivityData?.series}
                        categories={customerOnBoardActivityData?.categories}
                        plotOptions={{
                            column: {
                                borderRadius: 4,
                            },
                        }}
                        tooltip={{
                            pointFormat:
                                '<ul style="color:{series.color}"><li>{series.name}: <span>{point.y}</span></li></ul>',
                        }}
                    />
                </div>
            )}
        </>
    );
};

CustomerOnBoardActivityChart.propTypes = {
    parentDate: PropTypes.array.isRequired,
};

export default CustomerOnBoardActivityChart;
