import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isArray, isNil } from 'lodash';
// Import configs
import { customerRelationshipMetricsColumnsConfigs, datePickerConfigs } from './config';
// Import Hooks
import { useGACustomEvent, useRequest } from 'hooks';
// Import Services
import { DashboardHttpService } from 'services/http';
import { getStartDate, getEndDate } from 'services/dashboard';
// Import UI Components
import { DatePickerInput, ModuleTitle } from '@geneui/components';
import { DataTable } from 'components';
// Import Constants
import { GACategoryEvents, l } from 'constants/common';

let getCampaignsGeneralInfoObj;
const { GADateFilterCategory } = GACategoryEvents;

const CustomerRelationshipMetricsList = ({ parentDate }) => {
    const { doGetRequest } = useRequest();
    const { t } = useTranslation();
    const { logCustomEvent } = useGACustomEvent();

    const { customerRelationshipMetricsColumns } = customerRelationshipMetricsColumnsConfigs(t);

    const [isLoadingRelationshipMetrics, setIsLoadingRelationshipMetrics] = useState(false);
    const [customerRelationshipMetricsData, setCustomerRelationshipMetricsData] = useState([]);
    const [date, setDate] = useState(parentDate);

    const { getCampaignsGeneralInfoRequest } = useRef({
        getCampaignsGeneralInfoRequest: DashboardHttpService.getCampaignsGeneralInfo,
    }).current;

    const getCustomerRelationshipMetricsColumns = () => {
        return customerRelationshipMetricsColumns;
    };

    const getCampaignsGeneralInfo = (date) => {
        setIsLoadingRelationshipMetrics(true);

        if (!isNil(getCampaignsGeneralInfoObj)) {
            getCampaignsGeneralInfoObj.cancel('CustomerRelationshipMetricsList:getCampaignsGeneralInfoRequest');
        }

        getCampaignsGeneralInfoObj = getCampaignsGeneralInfoRequest();
        doGetRequest(getCampaignsGeneralInfoObj.request, {
            queryString: { startDate: getStartDate(date[0]), endDate: getEndDate(date[1]) },
            successCallback: (Data) => {
                setCustomerRelationshipMetricsData(Data);
            },
        }).then(() => setIsLoadingRelationshipMetrics(false));
    };

    const handleDatePickerChange = (e) => {
        const date = [getStartDate(e[0]), getEndDate(e[1])];

        logCustomEvent(
            GADateFilterCategory.name,
            GADateFilterCategory.events.customerRelationshipDateChange,
            'relationship metrics date change',
            `${date[0]} - ${date[1]}`,
        );
        setDate(date);
    };

    useEffect(() => {
        getCampaignsGeneralInfo(date);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date[0], date[1]]);

    const getCampaignsGeneralInfoTableRows = () => {
        if (isArray(customerRelationshipMetricsData)) {
            return customerRelationshipMetricsData.map(({ Name, Value }, index) => {
                return {
                    hasHover: true,
                    dragDisable: true,
                    data: {
                        index,
                        isRowOpend: false,
                        Name,
                        Value,
                    },
                };
            });
        }
        return [];
    };

    useEffect(() => {
        setDate(parentDate);
    }, [parentDate]);

    return (
        <div className="campaign-action-metrics-wrap-column">
            <div className="campaign-metrics-wrap-column-head">
                <ModuleTitle title={t(l.CustomerRelationshipMetrics)} />

                <div className="campaign-metrics-wrap-c-h-d">
                    <DatePickerInput.WithRange
                        onChange={handleDatePickerChange}
                        value={date}
                        label={t(l.TimePeriod)}
                        labelAppearance="swap"
                        min={datePickerConfigs.min}
                        max={datePickerConfigs.max}
                    />
                </div>
            </div>
            <div className="campaign-metrics-wrap-column-content">
                <DataTable
                    columnKey="dataKey"
                    rowKey="index"
                    data={getCampaignsGeneralInfoTableRows()}
                    dataCount={getCampaignsGeneralInfoTableRows()?.length}
                    columns={getCustomerRelationshipMetricsColumns()}
                    isLoading={isLoadingRelationshipMetrics}
                    isVisibleOnlyDataTable={true}
                    withPagination={false}
                    withPageSelector={false}
                />
            </div>
        </div>
    );
};

CustomerRelationshipMetricsList.propTypes = {
    parentDate: PropTypes.array.isRequired,
};

export default CustomerRelationshipMetricsList;
