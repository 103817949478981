import React from 'react';
// Import constants
import { l, EventLogType, DateTimeFormat, DataTableColumnsCustomTypes } from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion } = Helpers;

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.Date),
                dataKey: 'Date',
                sortable: true,
                formatter: (Date) => {
                    return customMomentWithoutTimezoneConversion(Date).format(DateTimeFormat);
                },
            },
            {
                text: t(l.User),
                dataKey: 'UserName',
                sortable: true,
            },
            {
                text: t(l.Type),
                dataKey: 'Type',
                sortable: true,
                colRenderer: (Type) => <div>{EventLogType[Type]}</div>,
            },
            {
                text: t(l.Action),
                dataKey: 'Name',
                sortable: true,
            },
        ],
    };
};

export { DataTableColumnsConfigs };
