import React, { FC, memo } from 'react';
// Import UI Components
import { Col, Row } from '@geneui/components';
// Import Components
import ABTestChartContainer from './ABTestChartContainer';
// Import Types
import { ABTestChartContainerProps } from './types';

interface Props {
    kpiList: ABTestChartContainerProps[];
}

const ABTestChartGroup: FC<Props> = ({ kpiList }) => {
    return (
        <div className="campaign-report-ab-test-charts-container">
            <Row>
                {kpiList.map(
                    ({ titleKey, pilotGroup, controlGroup, impactPercentage, confidencePercentage }, index) => (
                        <Col key={index} size={3}>
                            <ABTestChartContainer
                                titleKey={titleKey}
                                pilotGroup={pilotGroup}
                                controlGroup={controlGroup}
                                impactPercentage={impactPercentage}
                                confidencePercentage={confidencePercentage}
                            />
                        </Col>
                    ),
                )}
            </Row>
        </div>
    );
};

export default memo(ABTestChartGroup);
