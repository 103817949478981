/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNumber, isArray, isString, isBoolean, values } from 'lodash';
// Import Services
import { Helpers } from 'services';
// Import Types
import type { Paging, Sorting } from 'types';

const newFilterField = Helpers.generateRequestFilterField;

const useListBaseRequestBody = (
    filters: any[] = [],
    paging: Paging = {} as Paging,
    sorting: Sorting = {} as Sorting,
    sortingThen?: any,
) => {
    const baseRequestBody = {
        Filters: filters,
        Pageing: paging,
        Sorting: sorting,
        SortingThen: sortingThen,
    };

    const setFilters = (filtersList: any[]) => {
        if (!isArray(filtersList)) return;
        const latestFilters = {};
        filtersList.forEach((filter) => {
            latestFilters[filter.Name] = filter;
        });
        baseRequestBody.Filters = values(latestFilters);
    };

    const setPagingPageNumber = (pageNumber: number) => {
        if (!isNumber(pageNumber)) return;
        baseRequestBody.Pageing.PageNumber = pageNumber;
    };

    const setPagingPageSize = (pageSize: 'all' | number | null) => {
        if (!isNumber(pageSize) && !isString(pageSize)) return;
        if (pageSize === 'all') {
            pageSize = null;
        }
        baseRequestBody.Pageing.PageSize = pageSize;
    };

    const setSorting = (sortingDirection: Sorting['Direction'], columnName: string) => {
        if (!isBoolean(sortingDirection) && !isString(columnName)) {
            return;
        }
        baseRequestBody.Sorting.Direction = sortingDirection;
        baseRequestBody.Sorting.Name = columnName;
    };

    return [baseRequestBody, newFilterField, setFilters, setPagingPageNumber, setPagingPageSize, setSorting];
};

export default useListBaseRequestBody;
