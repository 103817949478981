import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, noop } from 'lodash';
// Import Hooks
import { useGoToRoute, useRequest, useQueryParams } from 'hooks';
//Import Components
import TemplateInfoModalBody from './TemplateInfoModalBody';
//Import UI components
import { Modal, Button, Tooltip } from '@geneui/components';
// Import Services
import { TemplateHttpService } from 'services/http';
//Import Constants
import { ModalsActions, TemplateTypesLabels, l } from 'constants/common';
import { RoutesList } from 'routes';
//Import SCSS
import 'assets/scss/segmentModal.scss';

const {
    TEMPLATE_CLONE_EMAIL,
    TEMPLATE_CLONE_SMS,
    TEMPLATE_CLONE_HOORY,
    TEMPLATE_CLONE_FASTEX_NOTIFICATION,
    TEMPLATE_CLONE_INTERNAL_MESSAGE,
    TEMPLATE_CLONE_SOCIAL_TELEGRAM,
    TEMPLATE_CLONE_PUSH_NOTIFICATION,
    TEMPLATE_EDIT_EMAIL,
    TEMPLATE_EDIT_SMS,
    TEMPLATE_EDIT_HOORY,
    TEMPLATE_EDIT_FASTEX_NOTIFICATION,
    TEMPLATE_EDIT_INTERNAL_MESSAGE,
    TEMPLATE_EDIT_SOCIAL_TELEGRAM,
    TEMPLATE_EDIT_PUSH_NOTIFICATION,
    TEMPLATE_PREDEFINED_EMAIL,
    TEMPLATE_PREDEFINED_SMS,
    TEMPLATE_PREDEFINED_HOORY,
    TEMPLATE_PREDEFINED_FASTEX_NOTIFICATION,
    TEMPLATE_PREDEFINED_INTERNAL_MESSAGE,
    TEMPLATE_PREDEFINED_SOCIAL_TELEGRAM,
    TEMPLATE_PREDEFINED_PUSH_NOTIFICATION,
    TEMPLATE_PREDEFINED_POP_UP_NOTIFICATION,
    TEMPLATE_PREDEFINED_DYNAMIC_POP_UP,
    TEMPLATE_CLONE_POP_UP_NOTIFICATION,
    TEMPLATE_CLONE_DYNAMIC_POP_UP,
    TEMPLATE_EDIT_POP_UP_NOTIFICATION,
    TEMPLATE_EDIT_DYNAMIC_POP_UP,
    TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION,
    TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION,
    TEMPLATE_CLONE_SOCIAL_VIBER,
    TEMPLATE_EDIT_SOCIAL_VIBER,
} = RoutesList;

const TemplateInfoModal = ({ templateId, childItemsInfo, isPredefined, handleClose, isVisible, isGlobal }) => {
    const { t } = useTranslation();
    const { goToRoute } = useGoToRoute();
    const notificationQueryParams = useQueryParams('notification');
    const { doGetRequest } = useRequest();

    const [isLoading, setIsLoading] = useState(false);
    const [templateInfo, setTemplateInfo] = useState(null);

    const { getTemplate } = useRef({ getTemplate: TemplateHttpService.getTemplate() }).current;

    const cloneClickHandler = () => {
        const { DeliveryMethod, TemplateId, EventId } = templateInfo;
        const params = { templateId: TemplateId, EventId: EventId };
        const queryString = isNil(EventId)
            ? null
            : notificationQueryParams.encode({ eventId: EventId, selectedChannel: DeliveryMethod });

        switch (DeliveryMethod) {
            case TemplateTypesLabels.Email:
                goToRoute(isPredefined ? TEMPLATE_PREDEFINED_EMAIL : TEMPLATE_CLONE_EMAIL, params, queryString);
                break;
            case TemplateTypesLabels.SMS:
                goToRoute(isPredefined ? TEMPLATE_PREDEFINED_SMS : TEMPLATE_CLONE_SMS, params, queryString);
                break;
            case TemplateTypesLabels.Hoory:
                goToRoute(isPredefined ? TEMPLATE_PREDEFINED_HOORY : TEMPLATE_CLONE_HOORY, params, queryString);
                break;
            case TemplateTypesLabels.FastexNotification:
                goToRoute(
                    isPredefined ? TEMPLATE_PREDEFINED_FASTEX_NOTIFICATION : TEMPLATE_CLONE_FASTEX_NOTIFICATION,
                    params,
                    queryString,
                );
                break;
            case TemplateTypesLabels.Internal:
                goToRoute(
                    isPredefined ? TEMPLATE_PREDEFINED_INTERNAL_MESSAGE : TEMPLATE_CLONE_INTERNAL_MESSAGE,
                    params,
                    queryString,
                );
                break;
            case TemplateTypesLabels.Telegram:
                goToRoute(
                    isPredefined ? TEMPLATE_PREDEFINED_SOCIAL_TELEGRAM : TEMPLATE_CLONE_SOCIAL_TELEGRAM,
                    params,
                    queryString,
                );
                break;
            case TemplateTypesLabels.PushNotification:
                goToRoute(
                    isPredefined ? TEMPLATE_PREDEFINED_PUSH_NOTIFICATION : TEMPLATE_CLONE_PUSH_NOTIFICATION,
                    params,
                    queryString,
                );
                break;
            case TemplateTypesLabels.PopUp:
                goToRoute(
                    isPredefined ? TEMPLATE_PREDEFINED_POP_UP_NOTIFICATION : TEMPLATE_CLONE_POP_UP_NOTIFICATION,
                    params,
                    queryString,
                );
                break;
            case TemplateTypesLabels.DynamicPopUp:
                goToRoute(
                    isPredefined ? TEMPLATE_PREDEFINED_DYNAMIC_POP_UP : TEMPLATE_CLONE_DYNAMIC_POP_UP,
                    params,
                    queryString,
                );
                break;
            case TemplateTypesLabels.CMSPopUp:
                goToRoute(TEMPLATE_CLONE_CMS_POP_UP_NOTIFICATION, params, queryString);
                break;
            case TemplateTypesLabels.Viber:
                goToRoute(TEMPLATE_CLONE_SOCIAL_VIBER, params, queryString);
                break;
            default:
        }
        handleClose(ModalsActions.CLONE);
    };

    const editClickHandler = () => {
        const { DeliveryMethod, TemplateId, EventId } = templateInfo;
        const params = { templateId: TemplateId };
        const queryString = isNil(EventId) ? null : notificationQueryParams.encode({ eventId: EventId });

        switch (DeliveryMethod) {
            case TemplateTypesLabels.Email:
                goToRoute(TEMPLATE_EDIT_EMAIL, params, queryString);
                break;
            case TemplateTypesLabels.SMS:
                goToRoute(TEMPLATE_EDIT_SMS, params, queryString);
                break;
            case TemplateTypesLabels.Hoory:
                goToRoute(TEMPLATE_EDIT_HOORY, params, queryString);
                break;
            case TemplateTypesLabels.FastexNotification:
                goToRoute(TEMPLATE_EDIT_FASTEX_NOTIFICATION, params, queryString);
                break;
            case TemplateTypesLabels.Internal:
                goToRoute(TEMPLATE_EDIT_INTERNAL_MESSAGE, params, queryString);
                break;
            case TemplateTypesLabels.Telegram:
                goToRoute(TEMPLATE_EDIT_SOCIAL_TELEGRAM, params, queryString);
                break;
            case TemplateTypesLabels.PushNotification:
                goToRoute(TEMPLATE_EDIT_PUSH_NOTIFICATION, params, queryString);
                break;
            case TemplateTypesLabels.PopUp:
                goToRoute(TEMPLATE_EDIT_POP_UP_NOTIFICATION, params, queryString);
                break;
            case TemplateTypesLabels.DynamicPopUp:
                goToRoute(TEMPLATE_EDIT_DYNAMIC_POP_UP, params, queryString);
                break;
            case TemplateTypesLabels.CMSPopUp:
                goToRoute(TEMPLATE_EDIT_CMS_POP_UP_NOTIFICATION, params, queryString);
                break;
            case TemplateTypesLabels.Viber:
                goToRoute(TEMPLATE_EDIT_SOCIAL_VIBER, params, queryString);
                break;
            default:
        }
        handleClose(ModalsActions.EDIT);
    };

    const getTitleKey = () => {
        return isPredefined ? l.PredefinedTemplateInfo : l.TemplateInfo; // TODO: need write label by type of template see the segment info modal code
    };

    const cleanUp = () => {
        return () => {
            setTemplateInfo(null);
            setIsLoading(false);
            getTemplate.cancel('TemplateModal:getTemplate');
        };
    };

    const getTemplateInfo = (templateId) => {
        return new Promise((resolve, reject) => {
            doGetRequest(getTemplate.request, {
                queryString: { templateId },
                successCallback: (Data) => {
                    resolve(Data);
                },
                errorCallback: (error) => {
                    reject(error);
                },
            });
        });
    };

    useEffect(() => {
        setIsLoading(true);

        getTemplateInfo(templateId)
            .then((data) => {
                setTemplateInfo(data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                // eslint-disable-next-line no-console
                console.log(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <Modal
            closeOnClickOutside={true}
            onCancel={() => handleClose(ModalsActions.CLOSE)}
            withPortal={true}
            background="dark-background"
            position="center"
            visible={isVisible}
            closable={false}
            size="content-size"
            className="segment-list-modal crm-template-info-modal"
            title={
                <div className="segment-list-modal-head">
                    <span className="segment-list-modal-h-text">{t(getTitleKey())}</span>
                    <div className="segment-list-modal-h-icons">
                        {!isNil(templateInfo) && (
                            <>
                                <Tooltip text={t(l.CloneTemplate)}>
                                    <Button
                                        icon="bc-icon-clone"
                                        appearance="minimal"
                                        color="primary"
                                        onClick={cloneClickHandler}
                                    />
                                </Tooltip>
                                {!isPredefined && !isGlobal && (
                                    <Tooltip text={t(l.EditTemplate)}>
                                        <Button
                                            icon="bc-icon-edit"
                                            appearance="minimal"
                                            color="primary"
                                            onClick={editClickHandler}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                </div>
            }
            footer={
                <Button appearance="minimal" onClick={() => handleClose(ModalsActions.CLOSE)}>
                    {t(l.Close)}
                </Button>
            }
        >
            <TemplateInfoModalBody
                isLoading={isLoading}
                isPredefined={isPredefined}
                childItemsInfo={isEmpty(childItemsInfo) ? templateInfo?.ChildItemsInfo : childItemsInfo}
                getTemplateInfo={getTemplateInfo}
                templateInfo={templateInfo}
                templateId={templateId}
                withActionButtons={false}
            />
        </Modal>
    );
};

TemplateInfoModal.propTypes = {
    templateId: PropTypes.number.isRequired,
    childItemsInfo: PropTypes.array,
    isVisible: PropTypes.bool,
    isPredefined: PropTypes.bool,
    handleClose: PropTypes.func,
    isGlobal: PropTypes.bool,
};

TemplateInfoModal.defaultProps = {
    handleClose: noop,
    isVisible: false,
    isPredefined: false,
    isGlobal: false,
    childItemsInfo: [],
};

TemplateInfoModal.displayName = 'TemplateInfoModal';

export default memo(TemplateInfoModal);
