import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// Import UI components
import { Toaster } from '@geneui/components';
// Import constants
import { AlertTypes, l, ErrorMessageSymbolsCount } from 'constants/common';
import { has, isString } from 'lodash';

const { warning, error, success, info } = AlertTypes;

const useToaster = () => {
    const { t } = useTranslation();

    const showToaster = useCallback(
        (type, message, defaultDuration = 5000) => {
            if (!has(AlertTypes, type)) {
                // eslint-disable-next-line no-console
                console.log('You should choose Toaster type from AlertTypes constants.');
                return;
            }

            if (!isString(message)) {
                // eslint-disable-next-line no-console
                console.log('message argument should be type of string.');
                return;
            }

            const calculatedDuration = (Math.ceil(message.length / ErrorMessageSymbolsCount) - 1) * 1000;

            const duration = calculatedDuration > defaultDuration ? calculatedDuration : defaultDuration;

            switch (type) {
                case success:
                    Toaster.success({
                        iconProps: { type: 'bc-icon-success-fill' },
                        title: t(l.Success),
                        message: message,
                        duration: duration,
                    });
                    break;
                case warning:
                    Toaster.warning({
                        iconProps: { type: 'bc-icon-info-fill' },
                        title: t(l.Warning),
                        message: message,
                        duration: duration,
                    });
                    break;
                case error:
                    Toaster.error({
                        iconProps: { type: 'bc-icon-error-fill' },
                        title: t(l.Error),
                        message: message,
                        duration: duration,
                    });
                    break;
                case info:
                    Toaster.info({
                        iconProps: { type: 'bc-icon-info-fill' },
                        title: t(l.Info),
                        message: message,
                        duration: duration,
                    });
                    break;
                default:
                    break;
            }
        },
        [t],
    );

    return { showToaster };
};

export default useToaster;
