import React from 'react';
import dagre from 'dagre';
import { v4 as uuidv4 } from 'uuid';
import { getOutgoers, getIncomers, isNode, isEdge } from 'react-flow-renderer';
import {
    has,
    values,
    isEmpty,
    uniqBy,
    findIndex,
    last,
    keys,
    first,
    isNil,
    isNaN,
    trim,
    countBy,
    isArray,
    noop,
    cloneDeep,
    sortBy,
    sum,
} from 'lodash';
// Import Component
import { ClientId } from 'components';
// Import Constants
import {
    blocksGroupEnum,
    nodeWidth,
    nodeHeight,
    connectionLineType,
    arrowHeadType,
    CJBlockColumnsTypesNames,
} from './config';
import { formsTypes, dependenciesConfig, formulaToReferenceMapToUIModel } from 'components/CustomerJourney/config';
import {
    l,
    layoutDirections,
    CustomerJourneyGroupBlockTypes,
    CJArgumentValuesTypes,
    CustomerJourneyErrorCodes,
    CJAutoMapMode,
    CJModelRelationScopes,
    CJReferenceInArgumentType,
    CJFormulaInArgumentType,
    FlowEditorElementsSetterActionTypes,
    MonthDayYearWithTime,
    timeSpanDefaultValue,
    PageTypes,
    CustomerJourneyStatusLabels,
    DataTableColumnsCustomTypes,
    defaultLogicFunction,
    clientIdTypeName,
    CJDependencyTypes,
    CJDynamicDateInArgumentType,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion, getBlockType, getTranslatableErrorText } = Helpers;
const { StaticInArgument, ReferenceInArgument, DynamicDateInArgument } = CJArgumentValuesTypes;

const createGraph = (elements, direction = layoutDirections.LR, addNotConnected = false) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    dagreGraph.setGraph({ rankdir: direction, ranksep: 80, acyclicer: 'greedy' });

    elements.forEach((el) => {
        if (isNode(el)) {
            if (addNotConnected || !isEmpty(getOutgoers(el, elements)) || !isEmpty(getIncomers(el, elements))) {
                dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
            }
        } else {
            dagreGraph.setEdge(el.source, el.target);
        }
    });

    return dagreGraph;
};

const getLayoutedElements = (elements, direction = layoutDirections.LR, isLayoutNonConnected = false) => {
    const dagreGraph = createGraph(elements, direction, isLayoutNonConnected);
    dagre.layout(dagreGraph);

    const isHorizontal = direction === layoutDirections.LR;
    const result = elements.map((el) => {
        if (isNode(el)) {
            const nodeWithPosition = dagreGraph.node(el.id);
            setLayoutPositions(el, isHorizontal);

            if (isLayoutNonConnected || !isEmpty(getOutgoers(el, elements)) || !isEmpty(getIncomers(el, elements))) {
                // unfortunately we need this little hack to pass a slighltiy different position
                // to notify react flow about the change. More over we are shifting the dagre node position
                // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
                el.position = {
                    x: nodeWithPosition.x - nodeWidth / 2,
                    y: nodeWithPosition.y - nodeHeight / 2,
                };
            }
        }

        return el;
    });

    return result;
};

const setElementsLayoutPositionsElements = (elements, direction = layoutDirections.LR) => {
    const isHorizontal = direction === layoutDirections.LR;
    elements.forEach((el) => {
        setLayoutPositions(el, isHorizontal);
    });
};

const setLayoutPositions = (element, isHorizontal = true) => {
    element.targetPosition = isHorizontal ? 'left' : 'top';
    element.sourcePosition = isHorizontal ? 'right' : 'bottom';
};

const mapTypes = (typesApiModel) => {
    const types = typesApiModel.reduce((acc, type) => {
        acc[type.Name] = type;
        return acc;
    }, {});

    values(types).forEach((item) => {
        if (!isNil(item?.ItemTypeName) && !isNil(types[item?.ItemTypeName])) {
            item.CustomAttributes = types[item.ItemTypeName]?.CustomAttributes;
            item.Validation = types[item.ItemTypeName]?.Validation;
        }
    });
    return types;
};

const mapLogicFunctions = (logicFunctionsApiModel, types) => {
    return logicFunctionsApiModel.reduce((acc, item) => {
        item.Overloads.forEach((overload) => {
            const inputType = first(overload.Parameters)?.TypeName;
            const returnType = overload.ReturnTypeName;
            if (isNil(acc[inputType])) {
                acc[inputType] = [];
            }
            acc[inputType].push({ label: item.Name, value: overload.Name, returnType: types[returnType] });
        });
        return acc;
    }, {});
};

const mapModels = (modelsApModel, types, logicFunctionsByType) => {
    return modelsApModel.reduce((acc, item) => {
        acc[item.Name] = item;
        acc[item.Name].Properties = item.Properties.map((property) => {
            const type = types[property.TypeName];
            let tmpLogicFunctions = [
                {
                    label: l.None,
                    value: defaultLogicFunction,
                    returnType: {
                        ...type,
                        CustomAttributes: { ...type.CustomAttributes, ...property.CustomAttributes },
                        Validation: { ...type.Validation, ...property.Validation },
                    },
                },
            ];
            if (!isNil(logicFunctionsByType[type.Name])) {
                tmpLogicFunctions = [
                    ...tmpLogicFunctions,
                    ...logicFunctionsByType[type.Name].map((logicFunction) => {
                        const result = cloneDeep(logicFunction);
                        result.returnType.CustomAttributes = {
                            ...result.returnType.CustomAttributes,
                            DisplayName: property?.CustomAttributes?.DisplayName,
                            Description: property?.CustomAttributes?.Description,
                        };
                        result.label = `CJTypeFunction${result.label}`;
                        return result;
                    }),
                ];
            }
            property = {
                Name: property.Name,
                Type: type.Name,
                Functions: tmpLogicFunctions,
                CustomAttributes: { ...property.CustomAttributes, DisplayName: `CJModelProperty${property?.Name}` },
                Hidden: property.CustomAttributes?.Hidden,
            };
            return property;
        });
        return acc;
    }, {});
};

const groupBlocksBuilder = ({ Blocks, Types, Models, Functions }) => {
    const types = mapTypes(Types);
    const logicFunctionsByType = mapLogicFunctions(Functions, types);
    const models = mapModels(Models, types, logicFunctionsByType);

    const groups = {};

    Blocks.forEach((block) => {
        const { Group } = block.CustomAttributes;

        block.Properties = models[block.PropertiesModel]?.Properties;
        block.Options = block.Options.map((option) => {
            const type = types[option.TypeName];
            option = {
                ...option,
                Type: type.Name,
                BaseTypes: type.BaseTypes,
                Validation: isNil(option.Validation) ? type.Validation : { ...type.Validation, ...option.Validation },
                CustomAttributes: {
                    ...type.CustomAttributes,
                    ...option.CustomAttributes,
                    DisplayName: `CJBlockOption${option?.Name}`,
                    Description: `CJBlockOption${option?.Name}Description`,
                },
            };
            if (!isNil(option.SelfModelTypeName)) {
                option.SelfModelProperties = models[option.SelfModelTypeName];
            }
            option.Hidden = option.CustomAttributes.Hidden || option.Hidden;
            option.Automap = option.CustomAttributes.Automap || option.Automap;
            return option;
        });
        block.Group = Group;
        if (has(groups, Group)) {
            groups[Group].blocks.push(block);
        } else {
            groups[Group] = Object.assign({}, blocksGroupEnum[Group], {
                blocks: [block],
            });
        }
    });

    return values(groups);
};

const getParents = (blocks, tree, comparator, uniqueIds) => {
    let incomers = [];
    let heads = [];

    blocks.forEach((block) => {
        const blockIncomers = getIncomers(block, tree);

        if (!isEmpty(blockIncomers)) {
            blockIncomers.forEach((block) => {
                if (!uniqueIds.has(block.id)) {
                    incomers.push(block);
                    uniqueIds.add(block.id);
                }
            });
        } else {
            if (comparator(block)) {
                heads.push(block);
            }
        }
    });

    if (!isEmpty(blocks)) {
        const { parents, heads: parentHeads } = getParents(incomers, tree, comparator, uniqueIds);
        incomers = incomers.concat(parents);
        heads = heads.concat(parentHeads);
    }

    return { parents: incomers, heads };
};

const getChildren = (blocks, tree, finishComparator) => {
    let outgoers = [];

    blocks.forEach((block) => {
        if (finishComparator(block)) {
            const blockOutgoers = getOutgoers(block, tree);

            if (!isEmpty(blockOutgoers)) {
                outgoers = outgoers.concat(blockOutgoers.filter(finishComparator));
            }
        }
    });

    if (!isEmpty(outgoers)) {
        outgoers = outgoers.concat(getChildren(outgoers.filter(finishComparator), tree, finishComparator));
    }

    return outgoers;
};

const isGraphCyclic = (elements) => {
    return !dagre.graphlib.alg.isAcyclic(createGraph(elements));
};

const createProperty = (block) => {
    return {
        id: block.id,
        name: block.data?.name,
        properties: block.data?.metaData.Properties,
    };
};

const getModalCollectedProperties = (modelRelationScope, block, option) => {
    let collectedProperties = [];
    if ((modelRelationScope & CJModelRelationScopes.Previous) > 0) {
        collectedProperties = collectedProperties.concat(block?.data?.collectedProperties);
    }

    if ((modelRelationScope & CJModelRelationScopes.Self) > 0) {
        const property = isNil(option?.SelfModelTypeName)
            ? { ...createProperty(block), name: `[${l.Current}] ${block.data?.name}` }
            : {
                  id: block.id,
                  name: block.data?.name,
                  properties: isNil(option.SelfModelProperties?.Properties)
                      ? []
                      : option.SelfModelProperties?.Properties,
              };

        collectedProperties = [property, ...collectedProperties];
    }
    return collectedProperties;
};

const propsCollector = (block, tree) => {
    if (!isNode(block)) return;
    const uniqueIds = new Set();
    const { parents, heads } = getParents([block], tree, (_block) => _block.id !== block.id, uniqueIds);
    const children = getChildren(heads, tree, (_block) => _block.id !== block.id);

    children.forEach((child) => {
        if (!uniqueIds.has(child.id)) {
            uniqueIds.add(child.id);
            parents.push(child);
        }
    });

    return parents.map((element) => {
        return createProperty(element);
    });
};

const getElementId = () => `crm_cj_element_${uuidv4()}`;

const edgeBuilder = (sourceName, targetName) => {
    return {
        source: sourceName,
        sourceHandle: `${sourceName}_source`,
        target: targetName,
        targetHandle: `${targetName}_target`,
        type: connectionLineType,
        arrowHeadType,
        id: `reactflow__edge-${sourceName}${sourceName}_source-${targetName}${targetName}_target`,
    };
};

const optionValueBuilder = (argumentInType, data = {}) => {
    switch (argumentInType) {
        case StaticInArgument: {
            const { value } = data;

            return value;
        }
        case ReferenceInArgument: {
            const { blockId, name, logicFunction } = data;
            if (!isNil(logicFunction) && logicFunction !== defaultLogicFunction) {
                return {
                    $type: CJFormulaInArgumentType,
                    Name: logicFunction,
                    Arguments: [
                        {
                            $type: CJReferenceInArgumentType,
                            Block: blockId,
                            Property: name,
                        },
                    ],
                };
            }
            return {
                $type: CJReferenceInArgumentType,
                Block: blockId,
                Property: name,
            };
        }

        case DynamicDateInArgument: {
            const { value } = data;
            const { DateOffset, Manipulations } = value;

            return {
                Manipulations,
                DateOffset,
            };
        }
        default:
            // DefaultInArgument
            return {};
    }
};

const mapOptionsValueToApiModel = (options) => {
    return options.reduce((acc, { Name, Value }) => {
        acc[Name] = Value;
        return acc;
    }, {});
};

const mapConfigToFlowEditorModel = (configBlocks, groupBlocks) => {
    const configBlocksInputs = {};
    const cachedBlockInfo = {};

    const nodes = configBlocks.map(({ TypeName, Name, DisplayName, Inputs, Options }) => {
        const configBlockType = getBlockType(TypeName);
        configBlocksInputs[Name] = Inputs;

        const node = {
            id: Name,
            data: {
                name: DisplayName,
                blockType: configBlockType,
                apiModel: mapOptionsValueToApiModel(Options),
            },
        };

        if (!has(cachedBlockInfo, configBlockType)) {
            for (let i = 0; i < groupBlocks.length; i++) {
                const group = groupBlocks[i];

                const matchedNodeIndex = findIndex(group.blocks, ({ Name }) => getBlockType(Name) === configBlockType);
                const blockIcon = group.blocks[matchedNodeIndex]?.CustomAttributes?.BCIcon;

                if (matchedNodeIndex !== -1) {
                    cachedBlockInfo[configBlockType] = {
                        type: group.blocksTypes,
                        cssClassName: group.cssClassName,
                        defaultIcon: !isNil(blockIcon) ? blockIcon : group.defaultBlockIcon,
                        metaData: group.blocks[matchedNodeIndex],
                    };

                    break;
                }
            }
        }

        node.type = cachedBlockInfo[configBlockType].type;
        node.data.cssClassName = cachedBlockInfo[configBlockType].cssClassName;
        node.data.defaultIcon = cachedBlockInfo[configBlockType].defaultIcon;
        node.data.metaData = cachedBlockInfo[configBlockType].metaData;

        return node;
    });

    const edges = [];
    keys(configBlocksInputs).forEach((name) => {
        if (!isEmpty(configBlocksInputs[name])) {
            configBlocksInputs[name].forEach((inputName) => {
                edges.push(edgeBuilder(inputName, name));
            });
        }
    });

    return [...nodes, ...edges];
};

const blockNameOptionCorrection = (setBlockNameApiData, blockNameApiModel, blockIds, checkingValue = true) => {
    if (blockIds.includes(blockNameApiModel) === checkingValue) {
        setBlockNameApiData(null);
    }
};

const conditionCorrection = (conditionApiModel, blockIds, checkingValue = false) => {
    if (conditionApiModel?.IsPrimitive === false) {
        if (!isNil(conditionApiModel?.Conditions) && isArray(conditionApiModel.Conditions)) {
            conditionApiModel.Conditions.forEach((condition) =>
                conditionCorrection(condition, blockIds, checkingValue),
            );
        }
    } else {
        if (
            conditionApiModel?.LeftValue?.$type === CJReferenceInArgumentType ||
            conditionApiModel?.LeftValue?.$type === CJFormulaInArgumentType
        ) {
            const leftValue = formulaToReferenceMapToUIModel(conditionApiModel.LeftValue);
            // never else
            if (blockIds.includes(leftValue.parentValue) === checkingValue) {
                conditionApiModel.LeftValue = {};
                conditionApiModel.Operator = null;
                conditionApiModel.RightValue = null;
            }
        }

        if (conditionApiModel?.LeftValue?.$type === CJDynamicDateInArgumentType) {
            const leftValue = formulaToReferenceMapToUIModel(conditionApiModel.LeftValue?.DateOffset);

            if (blockIds.includes(leftValue.parentValue) === checkingValue) {
                conditionApiModel.LeftValue = {};
                conditionApiModel.Operator = null;
                conditionApiModel.RightValue = null;
            }
        }

        if (
            conditionApiModel?.RightValue?.$type === CJReferenceInArgumentType ||
            conditionApiModel?.RightValue?.$type === CJFormulaInArgumentType
        ) {
            const rightValue = formulaToReferenceMapToUIModel(conditionApiModel.RightValue);

            if (blockIds.includes(rightValue.parentValue) === checkingValue) {
                conditionApiModel.RightValue = {};
            }
        }

        if (conditionApiModel?.RightValue?.$type === CJDynamicDateInArgumentType) {
            const rightValue = formulaToReferenceMapToUIModel(conditionApiModel.RightValue?.DateOffset);

            if (blockIds.includes(rightValue.parentValue) === checkingValue) {
                conditionApiModel.RightValue.DateOffset = {};
            }
        }
    }
};

const elementsCorrection = (elements, params) => {
    // remove elements correction api models
    if (
        !isNil(elements) &&
        isArray(elements) &&
        !isNil(params?.type) &&
        !isNil(params?.elementsToRemove) &&
        isArray(params.elementsToRemove) &&
        params.type === FlowEditorElementsSetterActionTypes.RemoveElements
    ) {
        const nodesToRemove = params.elementsToRemove.filter((el) => isNode(el)).map((el) => el.id);
        if (!isEmpty(nodesToRemove)) {
            elements.forEach((element) => {
                if (isNode(element) && !isNil(element?.data?.apiModel)) {
                    const options = element?.data?.metaData?.Options;
                    if (!isNil(options) && isArray(options)) {
                        element.data.metaData.Options.forEach((option) => {
                            if (!isNil(option?.Name) && !isNil(element.data.apiModel[option.Name])) {
                                const optionApiData = element.data.apiModel[option.Name];
                                if (
                                    optionApiData?.$type === CJReferenceInArgumentType ||
                                    optionApiData?.$type === CJFormulaInArgumentType
                                ) {
                                    const optionUIModel = formulaToReferenceMapToUIModel(optionApiData);
                                    if (nodesToRemove.includes(optionUIModel.parentValue)) {
                                        optionApiData.Block = null;
                                        optionApiData.Property = null;
                                    }
                                } else {
                                    const optionConfig = getFormContent(option.BaseTypes, formsTypes);
                                    if (optionConfig.optionType === formsTypes.FilterCondition.optionType) {
                                        conditionCorrection(optionApiData, nodesToRemove, true);
                                    }
                                    if (optionConfig.optionType === formsTypes.WfBlockName.optionType) {
                                        const setBlockNameApiData = (value) => {
                                            element.data.apiModel[option.Name] = value;
                                        };
                                        blockNameOptionCorrection(setBlockNameApiData, optionApiData, nodesToRemove);
                                    }
                                }
                            }
                        });
                    }
                }
            });
        }
    }
};

const getDependencies = (elements, pageType) => {
    const dependenciesMetadataInitialValue = values(CJDependencyTypes).reduce((acc, item) => {
        acc[item] = [];
        return acc;
    }, {});

    const dependenciesMetadata = elements
        .filter((el) => isNode(el))
        .reduce((acc, node) => {
            if (!isNil(node?.data?.metaData?.Options)) {
                node.data.metaData.Options.forEach((option) => {
                    const config = getFormContent(option.BaseTypes, formsTypes);
                    if (!isNil(config.dependencyType)) {
                        const value = node?.data?.apiModel[option.Name];
                        if (
                            !isNil(value) ||
                            value?.$type !== CJReferenceInArgumentType ||
                            value?.$type !== CJFormulaInArgumentType
                        ) {
                            acc[config.dependencyType].push({
                                value: value,
                                option: option,
                                node: node,
                                optionConfig: config,
                            });
                        }
                    }
                });
            }
            return acc;
        }, dependenciesMetadataInitialValue);

    return Promise.all(
        keys(dependenciesMetadata).map((dependencyType) =>
            dependenciesConfig[dependencyType].getDependencies(dependenciesMetadata[dependencyType], {
                pageType: pageType,
                elements: elements,
            }),
        ),
    );
};

const blockCollector = (block, elements) => {
    // TODO: optimize logic with blocks id keys, need reduce
    let blocks = [];
    let incomersBlocks = getIncomers(block, elements);

    // while go to parent
    while (!isEmpty(incomersBlocks)) {
        // eslint-disable-next-line no-loop-func
        const tmpIncomersBlocks = incomersBlocks.filter((item) => {
            return findIndex(blocks, (blockItem) => blockItem.id === item.id) === -1;
        });

        blocks = [...blocks, ...tmpIncomersBlocks];

        // uniq by id when two block has same incomer
        incomersBlocks = uniqBy(
            incomersBlocks.reduce((acc, item) => {
                return [...acc, ...getIncomers(item, elements)];
            }, []),
            'id',
        );
    }

    return blocks;
};

const collectedReferenceProperties = (blocks, option) => {
    const result = [];
    const type = first(option.BaseTypes); // TODO: change BaseType[0] to option Type because  BaseType[0] === option.Type
    blocks.forEach((itemBlock) => {
        const properties = itemBlock?.data?.metaData?.Properties;
        if (!isNil(properties)) {
            properties.forEach((property) => {
                // TODO: change expression logic, maybe property.BaseTypes find type
                if (property.Type === type) {
                    result.push({ blockId: itemBlock.id, name: property.Name });
                }
            });
        }
    });
    return result;
};

const setAutomapOptionsValues = (block, elements) => {
    if (block.type !== CustomerJourneyGroupBlockTypes.target) {
        const options = block?.data?.metaData?.Options;

        if (!isNil(options)) {
            const blocks = blockCollector(block, elements);

            options.forEach((option) => {
                if (option.Hidden) {
                    // TODO: when option does not hidden
                    if (!isNil(option.Automap) && option.Automap !== CJAutoMapMode.None) {
                        const properties = collectedReferenceProperties(blocks, option);
                        if (!isEmpty(properties)) {
                            if (
                                option.Automap === CJAutoMapMode.Closest ||
                                (option.Automap === CJAutoMapMode.Single && properties.length === 1) // Automap is equal CJAutoMapMode.Single need has only one property
                            ) {
                                block.data.apiModel[option.Name] = optionValueBuilder(
                                    ReferenceInArgument,
                                    first(properties),
                                );
                            } else {
                                block.data.apiModel[option.Name] = null; // delete option in when option is single but properties length > 1 something wrong in CJ
                            }
                        } else {
                            block.data.apiModel[option.Name] = null;
                        }
                    }
                }
            });
        }
    }
};

const mapFlowEditorModelToConfigModel = (elements) => {
    return elements
        .filter((element) => isNode(element))
        .map((node) => {
            const { id, data } = node;
            const { metaData, apiModel, name } = data;

            const options = metaData.Options.filter(
                ({ Hidden, Automap, Name }) => (!Hidden || Automap !== CJAutoMapMode.None) && !isNil(apiModel[Name]),
            ).map(({ Name }) => {
                return { Name, Value: apiModel[Name] };
            });

            return {
                TypeName: metaData.Name,
                Name: id,
                DisplayName: name,
                Options: options,
                Inputs: getIncomers(node, elements).map(({ id }) => id),
            };
        });
};

const getCustomerJourneyPageCustomGetLabel = (message) => {
    if (
        isNil(message?.errorCode) ||
        isNil(CustomerJourneyErrorCodes[message.errorCode]) ||
        isNil(CustomerJourneyErrorCodes[CustomerJourneyErrorCodes[message.errorCode]]?.label)
    ) {
        return message.errorMessage; // TODO: need format by type block
    }
    return CustomerJourneyErrorCodes[CustomerJourneyErrorCodes[message.errorCode]].label;
};

const getCustomerJourneyFormFieldTranslatableErrorText = (t, error) => {
    return getCustomerJourneyBlockTranslatableErrorText(t, [{ errorMessage: error?.label, errorParams: error }]);
};

const getCustomerJourneyBlockTranslatableErrorText = (t, errorMessages) => {
    return getTranslatableErrorText(t, errorMessages, getCustomerJourneyPageCustomGetLabel);
};

const getCustomerJourneyPageCustomValidationTypeErrorText =
    (t) =>
    ({ validationResultValue }) => {
        return getTranslatableErrorText(t, validationResultValue, getCustomerJourneyPageCustomGetLabel);
    };

const getFormContent = (baseTypes = [], formsTypes) => {
    const matchedType = baseTypes.find((baseType) => {
        const type = last(baseType.split('.'));
        return !isNil(formsTypes[type]?.Component);
    });
    if (!isNil(matchedType)) {
        return formsTypes[last(matchedType.split('.'))];
    }
    return {};
};

const getMatchedProperties = (optionType, collectedProperties) => {
    const result = [];
    collectedProperties.forEach((item) => {
        const matchedProperties = { id: item.id, name: item.name, properties: [] };
        item.properties.forEach(({ Functions: functions, ...property }) => {
            property.Functions = functions.filter((logicFunction) =>
                logicFunction.returnType.BaseTypes.includes(optionType),
            );

            if (property.Functions.length > 0) {
                matchedProperties.properties.push(property);
            }
        });
        if (matchedProperties.properties.length > 0) {
            result.push(matchedProperties);
        }
    });

    return result; // TODO: see duplicated cases  uniqBy(matchedProperties, 'Name');
};

const mapPropertiesToCascadeDropdownModel = (properties = []) => {
    const result = [];
    properties.forEach((item) => {
        const properties = item.properties
            .filter(({ Hidden }) => !Hidden)
            .map((propItem) => ({
                label: propItem.CustomAttributes.DisplayName,
                value: propItem.Name,
                logicFunctions: propItem.Functions,
            }));
        if (properties.length > 0) {
            result.push({
                label: item.name,
                value: item.id,
                children: properties,
            });
        }
    });
    return result;
};

const getBlockNames = (elements, filter = (_) => true) => {
    const result = [];
    if (!isNil(elements)) {
        elements.forEach((element) => {
            if (isNode(element) && !isNil(element?.data?.name) && filter(element)) {
                result.push(element.data.name);
            }
        });
    }
    return result;
};

const getNameRegexObject = (value) => {
    const result = { value: value.replace(/\s\s+/g, ' '), number: '', index: null };
    result.trimValue = trim(result.value);
    const regexMatch = result.trimValue.match(/\d+$/);
    if (isNil(regexMatch)) {
        result.nonNumberValue = result.value;
    } else {
        result.number = regexMatch[0];
        result.index = regexMatch.index;
        result.nonNumberValue = result.value.substring(0, result.index);
    }
    result.trimNonNumberValue = trim(result.nonNumberValue);
    result.comparisonName = result.trimNonNumberValue.replace(/\s/g, '').toLowerCase();

    return result;
};

const getName = (newName, suffix, countByNames) => {
    const nameCount = countByNames[`${newName.comparisonName}${trim(suffix)}`];

    if (isNil(nameCount) || nameCount === 0) {
        return trim(`${newName.trimNonNumberValue}${suffix}`);
    }

    return null;
};

const newVisualizationStepNameGenerator = (t, likeName, steps, label = l.NewStep) => {
    const tmpLikeName = !isNil(steps) && (isNil(likeName) || isEmpty(trim(likeName))) ? t(label) : likeName;
    const newName = getNameRegexObject(tmpLikeName);
    const names = steps.map((item) => getNameRegexObject(item.name));
    const countByNames = countBy(names, (name) => `${name.comparisonName}${name.number}`);

    let result = getName(newName, ` ${newName.number}`, countByNames);
    if (!isNil(result)) {
        return result;
    }

    if (!isEmpty(newName.comparisonName)) {
        result = getName(newName, '', countByNames);
        if (!isNil(result)) {
            return result;
        }
    }

    let count = 0;
    while (true) {
        result = getName(newName, ` ${++count}`, countByNames);
        if (!isNil(result)) {
            return result;
        }
    }
};

const newNodeNameGenerator = (t, likeName, elements, updatedNode) => {
    const tmpLikeName =
        !isNil(updatedNode) && (isNil(likeName) || isEmpty(trim(likeName)))
            ? t(l[`CJBlock${updatedNode.data.blockType}`])
            : likeName;

    const newName = getNameRegexObject(tmpLikeName);
    const names = getBlockNames(elements, (node) => isNil(updatedNode) || node.id !== updatedNode.id).map((item) =>
        getNameRegexObject(item),
    );
    const countByNames = countBy(names, (name) => `${name.comparisonName}${name.number}`);

    let result = getName(newName, ` ${newName.number}`, countByNames);
    if (!isNil(result)) {
        return result;
    }

    if (!isEmpty(newName.comparisonName)) {
        result = getName(newName, '', countByNames);
        if (!isNil(result)) {
            return result;
        }
    }

    let count = 0;
    while (true) {
        result = getName(newName, ` ${++count}`, countByNames);
        if (!isNil(result)) {
            return result;
        }
    }
};

const getBlocksGroup = (type) => {
    return blocksGroupEnum[type];
};

const getBlockColumns = (blocksColumns, elements, t) => {
    return sortBy(
        blocksColumns.map((item) => {
            const additionalSettings = {};
            if (item.Type === CJBlockColumnsTypesNames.DateTime) {
                additionalSettings.type = DataTableColumnsCustomTypes.Date;
            }
            const block = first(elements.filter((el) => el?.id === item.BlockName));

            if (!isNil(block?.data?.metaData?.Properties)) {
                for (let index = 0; index < block.data.metaData.Properties.length; index++) {
                    const prop = block.data.metaData.Properties[index];

                    if (
                        item.PropertyName === prop.Name &&
                        !isEmpty(
                            prop.Functions.filter(
                                (func) =>
                                    func.value === defaultLogicFunction &&
                                    func.returnType.BaseTypes.includes(clientIdTypeName),
                            ),
                        )
                    ) {
                        additionalSettings.colRenderer = (clientId) =>
                            isEmpty(clientId) ? '' : <ClientId clientId={clientId} />;
                        break;
                    }
                }
            }

            const arr = '[]';
            return {
                text: t(item.ColumnName),
                dataKey: item.DataKey,
                sortable: !item.Type.includes(arr) && item.IsSortable === true,
                formatter: (column) => {
                    if (item.Type.includes(arr)) {
                        if (isEmpty(column)) {
                            return '';
                        }
                        return column
                            .map((i) => {
                                return getColumn(item.Type.replace(arr, ''), i);
                            })
                            .join(', ');
                    }
                    return getColumn(item.Type, column, t);
                },
                ...additionalSettings,
            };
        }),
        ['index'],
    );
};

const getColumn = (type, column) => {
    if (!isEmpty(column)) {
        if (type === CJBlockColumnsTypesNames.DateTime) {
            return column && customMomentWithoutTimezoneConversion(column).format(MonthDayYearWithTime);
        } else if (type === CJBlockColumnsTypesNames.Money) {
            return `${column?.Amount} ${column?.Currency}`;
        }
    }

    return column;
};

// TODO: mapping from angular
const detectTemplate = ({ LeftValue: left, RightValue: right }) => {
    const rm = cloneDeep(right.Manipulations);
    const lm = cloneDeep(left.Manipulations);

    // TODO: migration for old cj's need to change logic
    if (!isNil(lm) && lm.length > 0 && lm[0].Method === 'add' && lm[0].Params[1] === 'minutes') {
        lm.shift();
    }
    if (!isNil(rm) && rm.length > 0 && rm[0].Method === 'add' && rm[0].Params[1] === 'minutes') {
        rm.shift();
    }
    if (lm && lm.length) {
        // has left manipulations
        if (!right.DateOffset) {
            return rm.length === 3 ? 'yesterday' : 'today';
        }
        return rm.length === 3 ? 'daysLater' : 'sameDay';
    } else {
        if (rm[0].Method === 'add') {
            return 'lastDayOfMonth';
        }
        if (rm[0].Method === 'days') {
            return 'weekday';
        }
        if (rm[0].Method === 'dates') {
            switch (rm.length) {
                case 1:
                    return 'dayOfMonth';
                case 2:
                    return 'dayOfYear';
                case 3:
                    return 'date';
                case 5:
                    return 'dateTime';
                default:
                    break;
            }
        }
        return 'time';
    }
};

const getTimeSpanValue = (value) => {
    let tmpValue = value;
    if (isNil(value)) {
        tmpValue = timeSpanDefaultValue;
    }

    const tmpTimeSpan = tmpValue.split('.');
    const tmpTime = tmpTimeSpan[1].split(':');
    return {
        day: +tmpTimeSpan[0],
        time: `${tmpTime[0]}:${tmpTime[1]}`,
    };
};

const getDoubleValue = (value) => {
    let tempValue = isNil(value) || isEmpty(value) ? null : value;
    if (!isNil(tempValue)) {
        const splittedValues = tempValue.split('.');
        tempValue = isNaN(+splittedValues[0]) ? splittedValues[0] : (+splittedValues[0]).toString();
        if (!isNil(splittedValues[1])) {
            tempValue = `${tempValue}.${splittedValues[1]}`;
        }
    }
    return tempValue;
};

const copySelectedElementsInClipboard = (selectedElements, elements, partnerId) => {
    return new Promise((resolve, reject) => {
        try {
            const result = copyElements(selectedElements, elements, partnerId, (errorMessage) => reject(errorMessage));
            if (!isNil(result)) {
                navigator.clipboard
                    .writeText(result.elementsToString())
                    .then(() => resolve(l.SuccessCoped))
                    .catch(() => reject(l.CantCopy));
            }
        } catch (error) {
            reject(l.CantCopy);
            // eslint-disable-next-line no-console
            console.error('Cant write navigator clipboard', error);
        }
    });
};

const copyElements = (selectedElements, elements, partnerId, errorCallBack = noop) => {
    if (isNil(selectedElements) || !isArray(selectedElements) || isNil(elements) || !isArray(elements)) {
        errorCallBack(l.CantCopy);
        return;
    }
    const tmpSelectedNodes = selectedElements.filter(isNode);
    if (isEmpty(tmpSelectedNodes)) {
        errorCallBack(l.HasNotAnySelectedBlock);
        return;
    }

    const targetBlockIndex = tmpSelectedNodes.findIndex((item) => item.type === CustomerJourneyGroupBlockTypes.target);
    if (targetBlockIndex !== -1) {
        errorCallBack(l.CantCopyTarget);
        return;
    }

    const selectedNodes = tmpSelectedNodes.reduce((acc, item) => {
        acc[item.id] = { id: item.id, position: item.position, type: item.type, data: item.data };
        return acc;
    }, {});

    const selectedEdges = [];
    const allEdges = elements.filter(isEdge).reduce((acc, item) => {
        if (isNil(acc[item.source])) {
            acc[item.source] = {};
        }
        acc[item.source][item.target] = item;
        return acc;
    }, {});

    keys(selectedNodes).forEach((item) => {
        if (!isNil(allEdges[item])) {
            keys(allEdges[item]).forEach((target) => {
                if (!isNil(selectedNodes[target])) {
                    selectedEdges.push(allEdges[item][target]);
                }
            });
        }
    });

    const tmpElements = { partnerId: partnerId, data: [...selectedEdges, ...values(selectedNodes)] };
    return {
        elements: tmpElements,
        elementsToString() {
            return JSON.stringify(tmpElements);
        },
    };
};

const cloneElements = (cloneableElements, elements, partnerId, blocksCountsConfig, errorCallBack = noop) => {
    try {
        const clonedElementsIds = cloneableElements.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});

        const copedData = copyElements(
            elements.filter((item) => !isNil(clonedElementsIds[item.id])),
            elements,
            partnerId,
            errorCallBack,
        );

        return pasteElements(copedData.elementsToString(), partnerId, blocksCountsConfig, errorCallBack);
    } catch (error) {
        errorCallBack(l.CantPaste);
        // eslint-disable-next-line no-console
        console.error('For clone need valid data', error);
    }
};

const pasteElements = (text, partnerId, { maxBlocksCounts, blocksCounts }, errorCallBack = noop) => {
    try {
        const pastedElements = JSON.parse(text);
        if (!isNil(pastedElements.partnerId) && pastedElements.partnerId !== partnerId) {
            errorCallBack(l.CantPasteFromOtherPartner);
            return;
        }
        const tmpNodes = pastedElements.data.filter(isNode);

        if (sum(values(blocksCounts)) + tmpNodes.length > maxBlocksCounts) {
            errorCallBack(l.CantPasteOutOfRangeBlocksCounts);
            return;
        }

        const newNodesNames = tmpNodes.reduce((acc, item) => {
            acc[item.id] = getElementId();
            return acc;
        }, {});
        let newText = text;
        keys(newNodesNames).forEach((prev) => {
            newText = newText.replaceAll(prev, newNodesNames[prev]);
        });
        const newElements = JSON.parse(newText).data;
        const nodes = newElements.filter(isNode);
        const edges = newElements.filter(isEdge);

        return { edges, nodes };
    } catch (error) {
        errorCallBack(l.CantPaste);
        // eslint-disable-next-line no-console
        console.error('Coped value does not valid', error);
    }
};

const pasteElementsFromClipboard = (partnerId, blocksCountsConfig) => {
    return new Promise((resolve, reject) => {
        try {
            navigator.permissions
                .query({ name: 'clipboard-read' })
                .then((result) => {
                    // if permission to read the clipboard is granted or if the user will
                    // be prompted to allow it, we proceed.

                    if (result.state === 'granted' || result.state === 'prompt') {
                        navigator.clipboard
                            .readText()
                            .then((text) => {
                                const tmpElements = pasteElements(
                                    text,
                                    partnerId,
                                    blocksCountsConfig,
                                    (errorMessage) => {
                                        reject(errorMessage);
                                    },
                                );

                                if (!isNil(tmpElements)) {
                                    resolve(tmpElements);
                                }
                            })
                            .catch((err) => {
                                reject(l.CantPaste);
                                // eslint-disable-next-line no-console
                                console.error('Failed to read clipboard contents: ', err);
                            });
                    }
                })
                .catch((err) => {
                    reject(l.CantPaste);
                    // eslint-disable-next-line no-console
                    console.error('Failed to query permissions ', err);
                });
        } catch (err) {
            reject(l.CantPaste);
            // eslint-disable-next-line no-console
            console.error('Failed to read from navigator clipboard', err);
        }
    });
};

const isEditOnlyLive = (type, status) => {
    return (
        type === PageTypes.edit &&
        (isNil(status) ||
            +status === +CustomerJourneyStatusLabels.InProgress ||
            +status === +CustomerJourneyStatusLabels.Suspended)
    );
};

const isFilterGroupEmpty = (filterGroup) => {
    if (!isNil(filterGroup?.filters) && !isEmpty(filterGroup?.filters)) {
        return filterGroup.filters.reduce((acc, item) => {
            if (item.isGroupFilter === true) {
                acc = acc && isFilterGroupEmpty(item);
            } else {
                acc = false;
            }
            return acc;
        }, true);
    }
    return true;
};

const calculateTotalClientsRemoveCount = (element, allElements, blocksClients) => {
    return allElements.reduce((acc, elem) => {
        if (elem.target === element.id) {
            const foundInputElem = allElements.find(({ id }) => id === elem.source);
            acc += blocksClients[foundInputElem?.id]?.RemovedModelsCount ?? 0;
        }
        return acc;
    }, 0);
};

const getTargetClientsData = (blocksCounts, allElements) => {
    const foundTargetElement = allElements.find(({ type }) => type === CustomerJourneyGroupBlockTypes.target);
    const targetBlock = blocksCounts[foundTargetElement?.id];

    return {
        targetClientsCount: targetBlock?.OutputModelsCount,
        targetClientsRemoveCount: targetBlock?.RemovedModelsCount,
    };
};

const correctionFilterGroup = (filterGroup) => {
    const newFilterGroup = cloneDeep(filterGroup);
    if (!isNil(filterGroup?.filters)) {
        newFilterGroup.filters = [];
        filterGroup.filters.forEach((item) => {
            if (item.isGroupFilter === true) {
                if (!isFilterGroupEmpty(item)) {
                    newFilterGroup.filters.push(correctionFilterGroup(item));
                }
            } else {
                newFilterGroup.filters.push(item);
            }
        });
    }
    return newFilterGroup;
};

export {
    getLayoutedElements,
    groupBlocksBuilder,
    propsCollector,
    isGraphCyclic,
    mapConfigToFlowEditorModel,
    mapFlowEditorModelToConfigModel,
    getElementId,
    optionValueBuilder,
    edgeBuilder,
    setAutomapOptionsValues,
    getCustomerJourneyPageCustomValidationTypeErrorText,
    getCustomerJourneyFormFieldTranslatableErrorText,
    getCustomerJourneyBlockTranslatableErrorText,
    getTranslatableErrorText,
    createProperty,
    getFormContent,
    getMatchedProperties,
    mapPropertiesToCascadeDropdownModel,
    getDependencies,
    newNodeNameGenerator,
    getModalCollectedProperties,
    elementsCorrection,
    getBlocksGroup,
    getBlockColumns,
    detectTemplate,
    getTimeSpanValue,
    getDoubleValue,
    setElementsLayoutPositionsElements,
    cloneElements,
    copySelectedElementsInClipboard,
    pasteElementsFromClipboard,
    isEditOnlyLive,
    isFilterGroupEmpty,
    correctionFilterGroup,
    newVisualizationStepNameGenerator,
    calculateTotalClientsRemoveCount,
    getTargetClientsData,
    conditionCorrection,
};
