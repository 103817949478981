import React, { Suspense, memo } from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute } from 'components';
// Import UI components
import { BusyLoader } from '@geneui/components';

import { publicRoutesList, notFoundRoute } from './config';

const PublicRoutes = () => {
    return (
        <Suspense fallback={<BusyLoader isBusy type="spinner" spinnerSize="big" />}>
            <Switch>
                {publicRoutesList.map(({ isPrivate, path, exact, component, titleKey }, index) => {
                    if (isPrivate) {
                        return <PrivateRoute path={path} component={component} key={index} titleKey={titleKey} />;
                    } else {
                        const Component = component;

                        return (
                            <Route path={path} exact={exact} key={index}>
                                <Component titleKey={titleKey} />
                            </Route>
                        );
                    }
                })}

                <Route component={notFoundRoute.component} />
            </Switch>
        </Suspense>
    );
};

PublicRoutes.displayName = 'PublicRoutes';

export default memo(PublicRoutes);
