import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, sumBy } from 'lodash';
// Import Hooks
import { useGACustomEvent, useRequest } from 'hooks';
// Import Services
import { DashboardHttpService } from 'services/http';
import { getStartDate, getEndDate } from 'services/dashboard';
// Import UI Components
import { DatePickerInput, BusyLoader, DalColumnChart, Dropdown, Empty, ModuleTitle } from '@geneui/components';
// Import configs
import { depositCumulativeChartDataTimeIntervalConfigs } from './config';
// Import Constants
import { GACategoryEvents, l } from 'constants/common';

let getDashboardOnBoardActivityObj;
const { GADateFilterCategory } = GACategoryEvents;

const ConversionTimeAnalyticsChart = ({ parentDate }) => {
    const { doPostRequest } = useRequest();
    const { t } = useTranslation();
    const { logCustomEvent } = useGACustomEvent();
    const { depositCumulativeChartDataTimeInterval } = depositCumulativeChartDataTimeIntervalConfigs(t);

    const [isLoading, setIsLoading] = useState(false);
    const [conversionTimeAnalyticsData, setConversionTimeAnalyticsData] = useState(null);
    const [date, setDate] = useState(parentDate);
    const [timeInterval, setTimeInterval] = useState(0);

    const { getDepositCumulativeChartDataRequest } = useRef({
        getDepositCumulativeChartDataRequest: DashboardHttpService.getDepositCumulativeChartData,
    }).current;

    const getDepositCumulativeChartData = (date, timeInterval) => {
        setIsLoading(true);

        if (!isNil(getDashboardOnBoardActivityObj)) {
            getDashboardOnBoardActivityObj.cancel('ConversionTimeAnalyticsChart:getDepositCumulativeChartDataRequest');
        }
        getDashboardOnBoardActivityObj = getDepositCumulativeChartDataRequest();
        doPostRequest(getDashboardOnBoardActivityObj.request, {
            requestBody: { DateStart: date[0], DateEnd: date[1], Interval: timeInterval },
            successCallback: (Data) => {
                const categories = [];
                const lineData = [];
                const columnData = [];
                Data.forEach((element) => {
                    lineData.push(element['Total']);
                    columnData.push(element['Count']);
                    categories.push(element['TimePart']);
                });
                setConversionTimeAnalyticsData({ lineData: lineData, columnData: columnData, categories: categories });
            },
        }).then(() => setIsLoading(false));
    };

    const handleDatePickerChange = (e) => {
        const date = [getStartDate(e[0]), getEndDate(e[1])];

        logCustomEvent(
            GADateFilterCategory.name,
            GADateFilterCategory.events.conversionTimeAnalytics,
            'conversion time analytics',
            `${date[0]} - ${date[1]}`,
        );
        setDate(date);
    };

    const dropDownHandler = (values) => {
        setTimeInterval(values.value);
        getDepositCumulativeChartData(date, values.value);
    };

    useEffect(() => {
        getDepositCumulativeChartData(date, timeInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date[0], date[1]]);

    useEffect(() => {
        setDate(parentDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentDate]);

    return (
        <>
            <div className="charts-wrap-inner-head">
                <ModuleTitle title={t(l.ConversionTimeAnalytics)} />

                <div className="campaign-metrics-wrap-c-h-d">
                    <Dropdown
                        data={depositCumulativeChartDataTimeInterval}
                        placeholder={t(l.ConversionTimeInterval)}
                        labelAppearance="swap"
                        onChange={(values) => {
                            dropDownHandler(values);
                        }}
                        value={timeInterval}
                    />
                    <DatePickerInput.WithRange
                        label={t(l.TimePeriod)}
                        labelAppearance="swap"
                        onChange={handleDatePickerChange}
                        value={date}
                    />
                </div>
            </div>
            {isLoading ? (
                <BusyLoader spinnerSize="big" isBusy={true} />
            ) : sumBy(conversionTimeAnalyticsData?.lineData) === 0 ? (
                <Empty title={t(l.NoDataToDisplay)} />
            ) : (
                <div className="charts-wrap-inner-content">
                    <DalColumnChart
                        lineData={conversionTimeAnalyticsData?.lineData ?? []}
                        columnData={
                            conversionTimeAnalyticsData?.columnData.map((x, i) => ({
                                y: x,
                                name: conversionTimeAnalyticsData?.categories[i],
                            })) ?? []
                        }
                        categories={conversionTimeAnalyticsData?.categories ?? []}
                    />
                </div>
            )}
        </>
    );
};

ConversionTimeAnalyticsChart.propTypes = {
    parentDate: PropTypes.array.isRequired,
};

export default ConversionTimeAnalyticsChart;
