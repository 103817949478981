import React, { FC, memo, PropsWithChildren, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
// Import Hooks
import { useHasPermission } from 'hooks';

interface Props {
    permissions?: string | string[];
    mandatoryPermissions?: string[];
    partnerSetting?: string;
}

const AccessControl: FC<PropsWithChildren<Props>> = (props) => {
    const { children, permissions = [], mandatoryPermissions = [], partnerSetting } = props;

    const [permissionDenied, setPermissionDenied] = useState(true);
    const [partnerSettingsDenied, setPartnerSettingsDenied] = useState(true);
    const { hasPermission, hasPartnerSettings } = useHasPermission();

    useEffect(() => {
        if (!isEmpty(partnerSetting)) {
            setPartnerSettingsDenied(hasPartnerSettings(partnerSetting!));
        }
        if (isEmpty(mandatoryPermissions) && !isEmpty(permissions)) {
            setPermissionDenied(!hasPermission(permissions));
        } else {
            // Should have all permissions in user permission list
            setPermissionDenied(!hasPermission(mandatoryPermissions, true));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissions]);

    return !permissionDenied && partnerSettingsDenied ? <>{children}</> : null;
};

export default memo(AccessControl);
