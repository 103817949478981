import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classNames from 'classnames';
import { first, isNil, keys, sortBy } from 'lodash';
// Import UI components
import { TimePicker, Button, Dropdown, Popover } from '@geneui/components';
// Import Components
import { ValidatableDatePickerInput } from 'components';
// Import Constants
import {
    l,
    ScheduleModalViews,
    CampaignStatusLabels,
    PageTypes,
    MonthDayYearFormat,
    TimeZonePack,
    DateFormat,
    DateTimeWithoutSecondsFormat,
} from 'constants/common';
// Import services
import Helpers from 'services/Helpers';
// Import UI components
const { dailyView, weeklyView, monthlyView, annuallyView, notRepeatView, hourlyView } = ScheduleModalViews;

const { customMoment, customMomentWithoutTimezoneConversion } = Helpers;

const SchedulerMainView = ({
    startDate,
    startTime,
    endDate,
    setStartDate,
    setStartTime,
    setEndDate,
    handleViewChange,
    selectedView,
    recurrenceData,
    isVisibleRecCount,
    startDateErrorText,
    endDateErrorText,
    startTimeErrorText,
    campaignStatus,
    pageType,
    timeZone,
    setTimeZone,
    isHourlyEnabled,
}) => {
    const { t } = useTranslation();
    const isItemActive = (value) => selectedView === value;
    const isStartDateAndStartTimeDisabled =
        +campaignStatus === +CampaignStatusLabels.Idle && pageType === PageTypes.edit;
    const timeZoneChangeHandler = (selectedTimeZone) => {
        setTimeZone(selectedTimeZone?.value);
    };

    const recurrenceDateFormat = selectedView === hourlyView ? DateTimeWithoutSecondsFormat : DateFormat;

    return (
        <>
            <div className="scheduler-modal-wrap-inner">
                <div className="scheduler-modal-wrap-inner-left">
                    <div className="start-date-block">
                        <p className="start-date-block-heading">{t(l.StartDate)}</p>

                        <ValidatableDatePickerInput
                            onChange={setStartDate}
                            value={startDate}
                            isValid={isStartDateAndStartTimeDisabled || !startDateErrorText}
                            errorText={!isStartDateAndStartTimeDisabled && startDateErrorText}
                            isTouched={true}
                            disabled={isStartDateAndStartTimeDisabled}
                            min={customMoment().format(MonthDayYearFormat)}
                        />
                    </div>
                    <div className="start-time-block">
                        <p>{t(l.StartTime)}</p>
                        <TimePicker
                            appearance="outline"
                            value={startTime}
                            onChange={setStartTime}
                            disabled={isStartDateAndStartTimeDisabled}
                            showSeconds={false}
                            withPortal={false}
                            isValid={!startTimeErrorText}
                            errorText={startTimeErrorText}
                        />
                    </div>
                    <div className="repeat-block">
                        {t(l.TimeZone)}
                        <Dropdown
                            flexibility="full-width"
                            hasSearch={true}
                            onChange={timeZoneChangeHandler}
                            value={isNil(timeZone) ? 0 : timeZone}
                            appearance="outline"
                            data={sortBy(
                                keys(TimeZonePack).map((key) => ({ label: TimeZonePack[key], value: +key })),
                                ['value'],
                            )}
                            noDataText={t(l.NoDataFound)}
                        />
                    </div>
                </div>
                <div className="scheduler-modal-wrap-inner-right">
                    <div className="scheduler-modal-wrap-inner-right-inside">
                        <div className="days-button">
                            <Button
                                className={classNames({ active: isItemActive(notRepeatView) })}
                                appearance="grayscale"
                                flexibility="content-size"
                                onClick={() => handleViewChange(notRepeatView)}
                            >
                                {t(l.OneTime)}
                            </Button>
                            {isHourlyEnabled && (
                                <Button
                                    className={classNames({ active: isItemActive(hourlyView) })}
                                    appearance="grayscale"
                                    flexibility="content-size"
                                    onClick={() => handleViewChange(hourlyView)}
                                >
                                    {t(l.Hourly)}
                                </Button>
                            )}
                            <Button
                                className={classNames({ active: isItemActive(dailyView) })}
                                appearance="grayscale"
                                flexibility="content-size"
                                onClick={() => handleViewChange(dailyView)}
                            >
                                {t(l.Daily)}
                            </Button>
                            <Button
                                className={classNames({ active: isItemActive(weeklyView) })}
                                appearance="grayscale"
                                flexibility="content-size"
                                onClick={() => handleViewChange(weeklyView)}
                            >
                                {t(l.Weekly)}
                            </Button>
                            <Button
                                className={classNames({ active: isItemActive(monthlyView) })}
                                appearance="grayscale"
                                onClick={() => handleViewChange(monthlyView)}
                            >
                                {t(l.Monthly)}
                            </Button>
                            <Button
                                className={classNames({ active: isItemActive(annuallyView) })}
                                appearance="grayscale"
                                flexibility="content-size"
                                onClick={() => handleViewChange(annuallyView)}
                            >
                                {t(l.Annually)}
                            </Button>
                        </div>
                        {`${selectedView}` !== `${notRepeatView}` && (
                            <>
                                <div className="start-date-block">
                                    <p className="start-date-block-heading">{t(l.EndDate)}</p>
                                    <ValidatableDatePickerInput
                                        onChange={setEndDate}
                                        value={endDate}
                                        isValid={!endDateErrorText}
                                        errorText={endDateErrorText}
                                        isTouched={true}
                                    />
                                </div>
                                {isVisibleRecCount && (
                                    <div className="sheduler-info-container">
                                        <div className="recur-count">
                                            <p>{t(l.RecurrenceCount)}</p>
                                            <Popover
                                                className="next-run-date-popover-container"
                                                extendTargetWidth={false}
                                                Content={recurrenceData?.UpcomingExecutions?.map((executionDate, i) => {
                                                    return (
                                                        <p key={i}>
                                                            {customMomentWithoutTimezoneConversion(
                                                                executionDate.SelectedTimeZoneTime,
                                                            ).format(recurrenceDateFormat)}
                                                        </p>
                                                    );
                                                })}
                                                align="center"
                                            >
                                                <button disabled={recurrenceData?.Count === 0}>
                                                    {recurrenceData?.Count}
                                                </button>
                                            </Popover>
                                        </div>
                                        <div className="upcoming-executions">
                                            <p>{t(l.NextRunDate)}</p>
                                            {recurrenceData?.UpcomingExecutions?.length > 0 && (
                                                <span>
                                                    {moment(
                                                        first(recurrenceData.UpcomingExecutions).SelectedTimeZoneTime,
                                                    ).format(DateFormat)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

SchedulerMainView.propTypes = {
    isVisibleRecCount: PropTypes.bool,
    recurrenceData: PropTypes.object,
    startTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    selectedView: PropTypes.string,
    schedule: PropTypes.object,
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    handleViewChange: PropTypes.func,
    setStartDate: PropTypes.func,
    setStartTime: PropTypes.func,
    setEndDate: PropTypes.func,
    startDateErrorText: PropTypes.string,
    startTimeErrorText: PropTypes.string,
    endDateErrorText: PropTypes.string,
    campaignStatus: PropTypes.number,
    pageType: PropTypes.string,
    timeZone: PropTypes.number,
    setTimeZone: PropTypes.func,
    isHourlyEnabled: PropTypes.bool,
};

SchedulerMainView.defaultProps = {
    startDateErrorText: '',
    endDateErrorText: '',
    campaignStatus: null,
};

export default memo(SchedulerMainView);
