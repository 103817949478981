import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { noop, isNil, isArray } from 'lodash';
// Import UI components
import { BusyLoader, Modal, Button } from '@geneui/components';
// Import components
import { Block } from 'components';
// Import Constants
import { l } from 'constants/common';
// Import SCSS
import 'assets/scss/fromToBlocks.scss';

const StepsSetting = ({ steps, isLoading, isVisible, onOk, onCancel, columnName }) => {
    const { t } = useTranslation();

    const initialData = useRef({});

    const [fixedStepItems, setFixedStepItems] = useState();
    const [isSaveActive, setIsSaveActive] = useState(false);
    const [stepItems, setStepItems] = useState();

    const mapToLocalModel = (item, withStepName = false) => ({
        ...item,
        isVisible: true,
        isChecked: true,
        isPermanent: false,
        name: withStepName ? `${t(l[columnName])} ${item.index + 1}` : item.name,
    });

    const okClickHandler = () => {
        onOk(stepItems);
    };

    const cancelClickHandler = () => {
        onCancel();
    };

    const resetClickHandler = () => {
        setStepItems(initialData.current?.stepItems);
        setIsSaveActive(false);
    };

    const stepsChangeHandler = (items) => {
        if (!isNil(fixedStepItems) && isArray(fixedStepItems)) {
            let tmpIsSaveActive = false;
            for (let i = 0; i < fixedStepItems.length; i++) {
                if (fixedStepItems[i].id !== items[i].id) {
                    tmpIsSaveActive = true;
                    break;
                }
            }
            setIsSaveActive(tmpIsSaveActive);
        }

        setStepItems(items);
    };

    useEffect(() => {
        const initialStepItems = steps.map((item) => mapToLocalModel(item));
        setFixedStepItems(steps.map((item) => mapToLocalModel(item, true)));
        setStepItems(initialStepItems);
        initialData.current = { stepItems: initialStepItems };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCustomFooter = () => {
        return (
            <>
                <Button appearance="minimal" onClick={cancelClickHandler}>
                    {t(l.Cancel)}
                </Button>
                <Button disabled={!isSaveActive} appearance="minimal" onClick={resetClickHandler}>
                    {t(l.Reset)}
                </Button>
                <Button disabled={!isSaveActive} onClick={okClickHandler}>
                    {t(l.Save)}
                </Button>
            </>
        );
    };

    return (
        <div>
            <Modal
                title={t(l.StepsSetting)}
                className="d-a-d-popup"
                background="dark-background"
                closable={true}
                position="center"
                onCancel={cancelClickHandler}
                cancelText={t(l.Reset)}
                okText={t(l.Save)}
                closeOnClickOutside={true}
                visible={isVisible}
                size="content-size"
                footer={getCustomFooter()}
            >
                <DndProvider backend={HTML5Backend} context={window}>
                    <div className="drag-and-drop-wrapper">
                        <BusyLoader
                            isBusy={isLoading || isNil(fixedStepItems) || isNil(stepItems)}
                            type="spinner"
                            loadingText={t(l.Loading)}
                        >
                            {!isLoading && !isNil(fixedStepItems) && (
                                <Block
                                    key="fixedStepItemsBlock"
                                    blockList={fixedStepItems}
                                    withParent={false}
                                    withPosition={false}
                                    title={t(l.Order)}
                                    withSelect={false}
                                    withSearch={false}
                                    hasInfoTooltip={false}
                                />
                            )}
                            {!isLoading && !isNil(stepItems) && (
                                <Block
                                    key="stepItemsBlock"
                                    blockList={stepItems}
                                    withParent={false}
                                    getUpdate={stepsChangeHandler}
                                    withPosition={true}
                                    title={t(l.Steps)}
                                    withSelect={false}
                                    withSearch={false}
                                    hasInfoTooltip={false}
                                />
                            )}
                        </BusyLoader>
                    </div>
                </DndProvider>
            </Modal>
        </div>
    );
};

StepsSetting.propTypes = {
    columnName: PropTypes.string.isRequired,
    isVisible: PropTypes.bool,
    isLoading: PropTypes.bool,
    steps: PropTypes.array,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

StepsSetting.defaultProps = {
    isVisible: false,
    isLoading: false,
    steps: [],
    onOk: noop,
    onCancel: noop,
};

export default StepsSetting;
