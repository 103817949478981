import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, noop } from 'lodash';
import { RoutesList } from 'routes';
// Import UI Components
import { Tooltip, Icon, Popover } from '@geneui/components';
// Import Components
import { CampaignActionsButtons } from 'components/CampaignList';
import { CopyToClipboard } from 'components';
// Import Hooks
import { useGoToRoute, useQueryParams } from 'hooks';
// Import Config
import { modalsStateSetDictKeys } from 'pages/CampaignListPage/config';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { l, StateModesLabels, DateTimeWithoutSecondsFormat, DateFormat, TimeZonePack } from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;

const { CAMPAIGN_EDIT, CAMPAIGN_EDIT2, CAMPAIGN_CLONE, CAMPAIGN_REPORT } = RoutesList;

const CampaignInfoModalBody = ({ campaignInfo, handleInfoModalActionClick, recurrenceData, onStopABTestCompleted }) => {
    const { t } = useTranslation();
    const { search } = useLocation();
    const { goToRoute } = useGoToRoute();
    const goToRouteQueryParams = useQueryParams();

    // Actions Part
    const unArchiveCampaignAction = (campaign) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.unarchiveCampaign, campaign);
    };
    const archiveCampaignAction = (campaign) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.archiveCampaign, campaign);
    };

    const deleteCampaignAction = (campaign) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.deleteCampaign, campaign);
    };

    const stopSendCampaignAction = (campaign) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.stopSendCampaign, campaign);
    };

    const changeModeCampaignAction = (campaign) => {
        handleInfoModalActionClick(modalsStateSetDictKeys.changeModeCampaign, campaign);
    };

    const cloneCampaignAction = (campaign) => {
        const { CampaignId } = campaign;
        const params = { campaignId: CampaignId };
        goToRoute(CAMPAIGN_CLONE, params);
    };

    const editCampaignAction = (campaign) => {
        const { CampaignId } = campaign;
        const params = { campaignId: CampaignId };
        goToRoute(CAMPAIGN_EDIT, params, goToRouteQueryParams.encode({ backParams: search }));
    };

    const editCampaignAction2 = (campaign) => {
        const { CampaignId } = campaign;
        const params = { campaignId: CampaignId };
        goToRoute(CAMPAIGN_EDIT2, params, goToRouteQueryParams.encode({ backParams: search }));
    };

    const deepViewCampaignAction = (campaign) => {
        const { CampaignId } = campaign;
        const params = { campaignId: CampaignId };
        goToRoute(CAMPAIGN_REPORT, params, goToRouteQueryParams.encode({ backParams: search }));
    };

    const stopABTestCampaignAction = () => {
        onStopABTestCompleted();
    };

    return campaignInfo ? (
        <div className="crm-campaign-info-detail-modal-body crm-scrollable-container">
            <div className="crm-campaign-info-action-buttons-container">
                <CampaignActionsButtons
                    data={{ ...campaignInfo, Name: { Name: campaignInfo.Name } }}
                    unArchiveCampaignAction={unArchiveCampaignAction}
                    archiveCampaignAction={archiveCampaignAction}
                    deleteCampaignAction={deleteCampaignAction}
                    cloneCampaignAction={cloneCampaignAction}
                    editCampaignAction={editCampaignAction}
                    editCampaignAction2={editCampaignAction2}
                    stopSendCampaignAction={stopSendCampaignAction}
                    changeModeCampaignAction={changeModeCampaignAction}
                    viewCampaignReportAction={deepViewCampaignAction}
                    stopABTestCampaignAction={stopABTestCampaignAction}
                />
            </div>

            <div className="title-wrapper">
                <p className="title-wrapper-text">{t(l.GeneralInfo)}</p>
                <span className="title-wrapper-line" />
            </div>
            <div className="key-value-h">
                <span className="ellipsis-text kv-label">{t(l.CampaignId)}</span>
                <span className="ellipsis-text kv-value kv-value-css">
                    <CopyToClipboard>{campaignInfo.CampaignId}</CopyToClipboard>
                </span>
            </div>
            <div className="key-value-h">
                <span className="ellipsis-text kv-label">{t(l.Title)}</span>
                <span className="ellipsis-text kv-value kv-value-css">{campaignInfo.Name}</span>
            </div>
            <div className="key-value-h">
                <span className="ellipsis-text kv-label">{t(l.Description)}</span>
                <span className="ellipsis-text kv-value kv-value-css">{campaignInfo.Description}</span>
            </div>
            <div className="key-value-h">
                <span className="ellipsis-text kv-label">{t(l.Mode)}</span>
                <span className="ellipsis-text kv-value kv-value-css">
                    {campaignInfo && StateModesLabels[campaignInfo.State]}
                </span>
            </div>
            <div className="key-value-h">
                <span className="ellipsis-text kv-label">{t(l.ProviderName)}</span>
                <span className="ellipsis-text kv-value kv-value-css">{campaignInfo.MessageProvider?.Name}</span>
            </div>
            <div className="key-value-h">
                <span className="ellipsis-text kv-label">{t(l.ProviderType)}</span>
                <span className="ellipsis-text kv-value kv-value-css">{campaignInfo.MessageProvider?.Type}</span>
            </div>
            {!isNil(campaignInfo.CustomFields) &&
                campaignInfo.CustomFields.map(({ ViewData }, index) => {
                    return (
                        <div key={index}>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{ViewData?.Name}</span>
                                <span className="ellipsis-text kv-value kv-value-css">{ViewData?.Value}</span>
                            </div>
                        </div>
                    );
                })}
            {isNil(campaignInfo.Schedule) && (
                <div className="key-value-h">
                    <span className="ellipsis-text kv-label">{t(l.Schedule)}</span>
                    <span className="ellipsis-text kv-value kv-value-css">{t(l.ScheduleOffImmediately)}</span>
                </div>
            )}
            {!isNil(campaignInfo.Schedule) && (
                <>
                    <div className="title-wrapper">
                        <p className="title-wrapper-text">{t(l.Schedule)}</p>
                        <span className="title-wrapper-line" />
                    </div>
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.StartDate)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">
                            {isEmpty(campaignInfo.Schedule?.CronTime)
                                ? customMomentWithoutTimezoneConversion(campaignInfo.Schedule?.StartDate).format(
                                      DateTimeWithoutSecondsFormat,
                                  )
                                : customMomentWithoutTimezoneConversion(campaignInfo.Schedule?.StartDate).format(
                                      DateFormat,
                                  )}
                        </span>
                    </div>
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.EndDate)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">
                            {isEmpty(campaignInfo.Schedule?.CronTime)
                                ? customMomentWithoutTimezoneConversion(campaignInfo.Schedule?.EndDate).format(
                                      DateTimeWithoutSecondsFormat,
                                  )
                                : customMomentWithoutTimezoneConversion(campaignInfo.Schedule?.EndDate).format(
                                      DateFormat,
                                  )}
                        </span>
                    </div>

                    {!isNil(campaignInfo.Schedule?.CronTime) && !isEmpty(campaignInfo.Schedule?.CronTime) && (
                        <>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">
                                    {
                                        <p className="key-value-info-wrapper">
                                            <span>{t(l.StartTime)}</span>
                                            <Tooltip text={t(campaignInfo.Schedule.CronSummary)} position="top">
                                                <Icon type="bc-icon-info" />
                                            </Tooltip>
                                        </p>
                                    }
                                </span>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {campaignInfo.Schedule?.CronTime}
                                </span>
                            </div>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">
                                    <p className="key-value-info-wrapper">
                                        <span>{t(l.ScheduleType)}</span>
                                    </p>
                                </span>
                                <span className="ellipsis-text kv-value kv-value-css">
                                    {t(campaignInfo.Schedule?.ScheduleType)}
                                </span>
                            </div>
                            <div className="key-value-h">
                                <span className="ellipsis-text kv-label">{t(l.RecurrenceCount)}</span>
                                <span className="ellipsis-text kv-value kv-value-css recur-count">
                                    <Popover
                                        className="next-run-date-popover-container"
                                        extendTargetWidth={false}
                                        Content={recurrenceData?.UpcomingExecutions?.map((executionDate, i) => {
                                            return (
                                                <p key={i}>
                                                    {customMomentWithoutTimezoneConversion(
                                                        executionDate.SelectedTimeZoneTime,
                                                    ).format(DateFormat)}
                                                </p>
                                            );
                                        })}
                                        align="end"
                                        position="right"
                                    >
                                        <button disabled={recurrenceData?.Count === 0} className="blue-color-button">
                                            {recurrenceData?.Count}
                                        </button>
                                    </Popover>
                                </span>
                            </div>
                        </>
                    )}
                    <div className="key-value-h">
                        <span className="ellipsis-text kv-label">{t(l.TimeZone)}</span>
                        <span className="ellipsis-text kv-value kv-value-css">
                            {TimeZonePack[campaignInfo.Schedule?.TimeZone]}
                        </span>
                    </div>
                </>
            )}
        </div>
    ) : (
        <></>
    );
};

CampaignInfoModalBody.propTypes = {
    campaignInfo: PropTypes.object.isRequired,
    handleInfoModalActionClick: PropTypes.func.isRequired,
    recurrenceData: PropTypes.object,
    onStopABTestCompleted: PropTypes.func,
};

CampaignInfoModalBody.defaultProps = {
    onStopABTestCompleted: noop,
};

export default memo(CampaignInfoModalBody);
