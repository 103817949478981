import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil, noop, values } from 'lodash';
// Import Components
import { CustomerJourneyVisualizationTemplateGroup, CJFilterCondition } from 'components';
// Import UI Components
import { ExtendedInput, Checkbox } from '@geneui/components';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { l, LogicTypesValues } from 'constants/common';

const { itemsIsValid } = Helpers;

const CustomerJourneyVisualizationSubStep = ({
    collectedProperties,
    node,
    elements,
    option,
    getUpdate,
    defaultValue,
    stepNameGenerator,
}) => {
    const [filterConditionFilters, setFilterConditionFilters] = useState(defaultValue?.condition);
    const conditionUpdateHandler = (filterValue) => {
        setFilterConditionFilters((prev) => ({ ...prev, ...filterValue }));
    };
    const [templateData, setTemplateData] = useState(defaultValue?.templates);

    // get update only blur
    const [name, setName] = useState(defaultValue?.name);
    const [description, setDescription] = useState(defaultValue?.description);
    const [isHidden, setIsHidden] = useState(defaultValue?.isHidden);

    const init = noop;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    const cleanUp = () => {
        return noop;
    };

    useEffect(() => {
        getUpdate({
            name: name,
            description: description,
            isHidden: isHidden,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, description, isHidden]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    const { t } = useTranslation();

    const nameChangeHandler = (e) => {
        setName(e.target.value);
    };

    const descriptionChangeHandler = (e) => {
        setDescription(e.target.value);
    };

    const nameBlurHandler = () => {
        const tmpName = stepNameGenerator(name);
        setName(tmpName);
    };

    const isHiddenChangeHandler = (event) => {
        setIsHidden(event.target.checked);
    };

    const descriptionBlurHandler = () => {
        setDescription(description);
    };
    useEffect(() => {
        const isSubStepValid = itemsIsValid(values(templateData)) && !!filterConditionFilters?.isValid;
        getUpdate({
            isValid: isSubStepValid,
            templates: templateData,
            condition: filterConditionFilters,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData, filterConditionFilters]);

    const subStepTemplateChangeHandler = (languageKey, data) => {
        setTemplateData((prev) => {
            return { ...prev, [languageKey]: { ...prev[languageKey], ...data } };
        });
    };

    return (
        <div className="crm-cj-visualization-step">
            <div className="crm-cj-visualization-step-block">
                <div className="c-input-group-wrapper">
                    <ExtendedInput
                        appearance="outline"
                        inputSize="default"
                        labelAppearance="swap"
                        placeholder={t(l.Name)}
                        onChange={nameChangeHandler}
                        onBlur={nameBlurHandler}
                        value={isNil(name) ? '' : name}
                    />
                    <ExtendedInput
                        appearance="outline"
                        inputSize="default"
                        labelAppearance="swap"
                        placeholder={t(l.Description)}
                        onChange={descriptionChangeHandler}
                        onBlur={descriptionBlurHandler}
                        value={isNil(description) ? '' : description}
                    />
                    <Checkbox label={t(l.IsHidden)} checked={isHidden} onChange={isHiddenChangeHandler} />
                </div>
            </div>
            <CustomerJourneyVisualizationTemplateGroup
                templates={templateData}
                onChange={subStepTemplateChangeHandler}
            />
            <CJFilterCondition
                collectedProperties={collectedProperties}
                node={node}
                elements={elements}
                option={option}
                getUpdate={conditionUpdateHandler}
                defaultValue={filterConditionFilters}
                groupLabel={t(l.Condition)}
            />
        </div>
    );
};

CustomerJourneyVisualizationSubStep.propTypes = {
    defaultValue: PropTypes.shape({
        templates: PropTypes.object,
        name: PropTypes.string,
        description: PropTypes.string,
        isHidden: PropTypes.bool,
        condition: {
            filters: PropTypes.array,
            logic: PropTypes.string,
        },
    }),
    iconsData: PropTypes.shape({
        fontFamily: PropTypes.string,
        data: PropTypes.array,
    }),
    node: PropTypes.object,
    elements: PropTypes.array,
    option: PropTypes.object,
    stepNameGenerator: PropTypes.func.isRequired,
    collectedProperties: PropTypes.array,
    getUpdate: PropTypes.func,
    addTemplateValidationProperties: PropTypes.func,
    removeTemplateValidationProperties: PropTypes.func,
};

CustomerJourneyVisualizationSubStep.defaultProps = {
    defaultValue: {
        templates: {},
        condition: {
            logic: LogicTypesValues.and,
            filters: [],
        },
        isHidden: false,
        name: '',
        description: '',
    },
    iconsData: {},
    collectedProperties: [],
    node: null,
    elements: null,
    option: null,
    getUpdate: noop,
    addTemplateValidationProperties: noop,
    removeTemplateValidationProperties: noop,
};

export default memo(CustomerJourneyVisualizationSubStep);
