import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import UI components
import { ColumnChart, BusyLoader, Empty } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';

const CampaignCountOfData = ({ isLoading, data, title }) => {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState([]);
    const [chartCategories, setChartCategories] = useState([]);

    const init = () => {
        const chartData = [];
        const categories = [];
        if (!isNil(data)) {
            data.forEach(function ({ date, value }) {
                chartData.push([String(date), value]);
                categories.push(date);
            });
            setChartData(chartData);
            setChartCategories(categories);
        }
    };

    useEffect(init, [data]);

    return (
        <div className="report-visualization">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text">
                            <div className="crm-statics-segment-data-table-name">{title}</div>
                        </div>
                    </div>
                </li>
            </ul>

            {isLoading ? (
                <BusyLoader spinnerSize="big" isBusy={true} />
            ) : isNil(data) || isEmpty(data) ? (
                <Empty title={t(l.NoDataToDisplay)} />
            ) : (
                <ColumnChart data={chartData} color={'rgb(1, 236, 197)'} categories={chartCategories} />
            )}
        </div>
    );
};

CampaignCountOfData.propTypes = {
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    title: PropTypes.string,
};

CampaignCountOfData.defaultProps = {
    isLoading: false,
    title: '',
};
export default memo(CampaignCountOfData);
