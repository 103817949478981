import React from 'react';
// Import UI Components
import { Icon } from '@geneui/components';
// Import Components
import { CopyToClipboard, TittleLink } from 'components';
// Import Constants
import {
    SegmentTypesLabels,
    DateTimeFormat,
    l,
    StateModesLabels,
    DataTableColumnsCustomTypes,
    CustomersTypesLabels,
} from 'constants/common';
// Import Services
import { Helpers } from 'services';

const { customMomentWithoutTimezoneConversion } = Helpers;

const CompositeSegmentFiltersConfigs = [
    { valueFieldKey: 'SegmentId', comparision: 8 },
    { valueFieldKey: 'Name', comparision: 2, values: [''] },
];

const DataTableColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.SegmentId),
                dataKey: 'SegmentId',
                sortable: true,
                colRenderer: (id) => <CopyToClipboard>{id}</CopyToClipboard>,
            },
            {
                text: t(l.Title),
                dataKey: 'Name',
                sortable: true,
                isPermanent: true,
                // eslint-disable-next-line react/prop-types
                colRenderer: ({ Name, TargetObject }) => (
                    <div className="crm-list-column-title-icon-components">
                        <TittleLink name={Name} isClickable={false} />
                        {+TargetObject === +CustomersTypesLabels.NonCustomers && <Icon type="bc-icon-non-customer" />}
                    </div>
                ),
            },
            {
                text: t(l.Type),
                dataKey: 'Type',
                sortable: true,
                colRenderer: (type) => (
                    <div className="crm-segment-type-label">
                        {type === SegmentTypesLabels.Dynamic && (
                            <Icon type="bc-icon-dynamic crm-segment-type-icon dynamic" />
                        )}
                        {type === SegmentTypesLabels.Static && (
                            <Icon type="bc-icon-static crm-segment-type-icon static" />
                        )}
                        {type === SegmentTypesLabels.Composite && (
                            <Icon type="bc-icon-composite crm-segment-type-icon composite" />
                        )}
                        <span className="crm-segment-type-text">{SegmentTypesLabels[type]}</span>
                    </div>
                ),
            },
            {
                text: t(l.State),
                dataKey: 'IsUsed',
                sortable: true,
                colRenderer: (IsUsed) => (
                    <div className={`crm-used-or-not-used-badge-cnt crm-used-or-not-used-label-${IsUsed}`}>
                        {IsUsed ? t(l.Used) : t(l.NotUsed)}
                    </div>
                ),
            },
            {
                text: t(l.User),
                dataKey: 'CreatedBy.Name',
                sortable: true,
            },
            {
                type: DataTableColumnsCustomTypes.Date,
                text: t(l.CreatedDate),
                dataKey: 'CreatedDate',
                sortable: true,
                formatter: (CreatedDate) => {
                    return customMomentWithoutTimezoneConversion(CreatedDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Mode),
                dataKey: 'State',
                sortable: true,
                colRenderer: (state) => (
                    <div className={`crm-mode-badge-cnt crm-cj-mode-label-${state}`}>{t(StateModesLabels[state])}</div>
                ),
            },
        ],
    };
};

export { DataTableColumnsConfigs, CompositeSegmentFiltersConfigs };
