import React, { memo, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isObject } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { BonusHttpService, UtilsHttpService } from 'services/http';
// Import Constants
import { l, DynamicBonusTypes, KPISources } from 'constants/common';
// Import Components
import { CJInfoBlockLabelValueWrapper, CJMenuItemDropDownInfoBlock } from 'components/CustomerJourney/infoBlocks';
import { DynamicTypesRadioGroupOptionsConfig } from '../config';

const CJCalculateKPIInfoBlock = ({ data, customAttributes, option }) => {
    const { t } = useTranslation();

    const { doGetRequest } = useRequest();

    const [isLoadingBonusCalculateAction, setIsLoadingBonusCalculateAction] = useState(true);
    const [isLoadingBonusObject, setIsLoadingBonusObject] = useState(true);
    const [isLoadingBonusKPI, setIsLoadingBonusKPI] = useState(true);

    const [bonusCalculateAction, setBonusCalculateAction] = useState();
    const [bonusObject, setBonusObject] = useState();
    const [bonusKPI, setBonusKPI] = useState();

    const { getBonusList, getBonusCalculateActions, getBonusObjectsByType } = useMemo(
        () => ({
            getBonusList: BonusHttpService.getBonusList(),
            getBonusCalculateActions: UtilsHttpService.getBonusCalculateActions(),
            getBonusObjectsByType: UtilsHttpService.getBonusObjectsByType(),
        }),
        [],
    );

    const { amountSourceType, objectType, calculationMethod, columnInfoId } = data;

    const calculateType = useMemo(() => {
        return DynamicTypesRadioGroupOptionsConfig.find((config) => config.value === amountSourceType).label;
    }, [amountSourceType]);

    const init = () => {
        if (amountSourceType === DynamicBonusTypes.Object) {
            doGetRequest(getBonusObjectsByType.request, {
                queryString: { type: KPISources.Object },
                successCallback: (data) => {
                    const objects =
                        (isObject(data) && data.map((item) => ({ label: item.Name, value: item.Id }))) || [];
                    setBonusObject(objects.find((object) => object.value === objectType).label);
                    setIsLoadingBonusObject(false);
                },
            });
            doGetRequest(getBonusCalculateActions.request, {
                successCallback: (data) => {
                    const actions =
                        (isObject(data) && data.map((item) => ({ label: item.Name, value: item.Id }))) || [];
                    setBonusCalculateAction(actions.find((action) => action.value === calculationMethod).label);
                    setIsLoadingBonusCalculateAction(false);
                },
            });
        }

        if (amountSourceType === DynamicBonusTypes.KPI) {
            doGetRequest(getBonusObjectsByType.request, {
                queryString: { type: KPISources.KPI },
                successCallback: (data) => {
                    const kpis = (isObject(data) && data.map((item) => ({ label: item.Name, value: item.Id }))) || [];
                    setBonusKPI(kpis.find((kpi) => kpi.value === columnInfoId).label);
                    setIsLoadingBonusKPI(false);
                },
            });
        }
    };

    const cleanUp = () => {
        return () => {
            getBonusList.cancel('CJCalculateKpiInfoBlock:getBonusList');
            getBonusCalculateActions.cancel('CJCalculateKpiInfoBlock:getBonusCalculateActions');
            getBonusObjectsByType.cancel('CJCalculateKpiInfoBlock:getBonusObjectsByType');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <>
            <CJMenuItemDropDownInfoBlock
                data={{ value: data.segmentId, label: l.Segment }}
                customAttributes={customAttributes}
                option={option}
            />
            <CJInfoBlockLabelValueWrapper label={t(l.CJBlockCalculateKPICalculateType)} value={calculateType} />
            {amountSourceType === DynamicBonusTypes.Object && (
                <>
                    <CJInfoBlockLabelValueWrapper
                        label={t(l.CJBlockCalculateKPIKind)}
                        value={bonusObject}
                        isLoading={isLoadingBonusObject}
                    />
                    <CJInfoBlockLabelValueWrapper
                        label={t(l.CJBlockCalculateKPICalculateMethod)}
                        value={bonusCalculateAction}
                        isLoading={isLoadingBonusCalculateAction}
                    />
                </>
            )}
            {amountSourceType === DynamicBonusTypes.KPI && (
                <CJInfoBlockLabelValueWrapper
                    label={t(l.CJBlockCalculateKPIKind)}
                    value={bonusKPI}
                    isLoading={isLoadingBonusKPI}
                />
            )}
        </>
    );
};

CJCalculateKPIInfoBlock.propTypes = {
    data: PropTypes.object.isRequired,
    customAttributes: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
};

export default memo(CJCalculateKPIInfoBlock);
