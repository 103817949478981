import { useState, useEffect, useRef } from 'react';
import useQueryParams from './UseQueryParams';
import { cloneDeep, has, isEmpty, isNil } from 'lodash';

const useDataTable = (
    defaultPageNumber,
    defaultPageSize,
    defaultSortingName,
    defaultSortingDirection,
    withQueryParams = false,
    queryNamespace = '',
) => {
    const defaultSorting = useRef({ name: defaultSortingName, direction: defaultSortingDirection });

    const queryParams = useQueryParams(queryNamespace);

    const getDefaultData = () => {
        const result = {
            pageSize: defaultPageSize,
            pageNumber: defaultPageNumber,
            sortingName: defaultSortingName,
            sortingDirection: defaultSortingDirection,
        };
        if (withQueryParams && !isNil(queryNamespace) && !isEmpty(queryNamespace)) {
            const decodedParams = queryParams.decode();
            if (!isNil(decodedParams?.pageNumber)) {
                result.pageNumber = decodedParams.pageNumber;
            }
            if (!isNil(decodedParams?.pageSize)) {
                result.pageSize = decodedParams.pageSize;
            }
            if (!isNil(decodedParams?.sortingName)) {
                result.sortingName = decodedParams.sortingName;
            }
            if (!isNil(decodedParams?.sortingDirection)) {
                result.sortingDirection = decodedParams.sortingDirection;
            }
        }

        return result;
    };

    const [data, setData] = useState(getDefaultData());

    const changeDefaultSorting = (tmpData) => {
        if (!isNil(tmpData?.sortingName)) {
            defaultSorting.current.name = tmpData.sortingName;
        }
        if (!isNil(tmpData?.sortingDirection)) {
            defaultSorting.current.direction = tmpData.sortingDirection;
        }
    };

    const changeData = (tmpData) => {
        const newData = cloneDeep(data);

        if (!isNil(tmpData?.pageNumber)) {
            newData.pageNumber = tmpData.pageNumber;
        }
        if (!isNil(tmpData?.pageSize)) {
            newData.pageSize = tmpData.pageSize;
        }
        if (has(tmpData, 'sortingName')) {
            newData.sortingName = !isNil(tmpData?.sortingName) ? tmpData.sortingName : defaultSorting.current.name;
        }
        if (has(tmpData, 'sortingDirection')) {
            if (!isNil(tmpData?.sortingDirection)) {
                newData.sortingDirection = tmpData.sortingDirection;
            } else {
                newData.sortingDirection = defaultSorting.current.direction;
                newData.sortingName = defaultSorting.current.name;
            }
        }

        if (withQueryParams) {
            queryParams.historyReplace(newData);
        }

        setData(newData);
    };

    useEffect(() => {
        if (withQueryParams) {
            queryParams.historyReplace(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const result = { data, setData: changeData, changeDefaultSorting };
    if (withQueryParams) {
        result.queryParams = queryParams;
    }

    return result;
};

export default useDataTable;
