import React, { memo, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { CampaignHttpService } from 'services/http';
// Import Components
import { PlayersDataTable } from 'components';
// Import Constants
import { CampaignActivityOpenedMailPlayersTableConfig } from './config';

const CampaignActivityMetricsPlayersWhoOpenedMail = ({
    campaignId,
    startDate,
    endDate,
    setIsDisableDownload,
    isLoadingSchedule,
    isActive,
}) => {
    const { doPostRequest } = useRequest();
    const [isLoadingPlayers, setIsLoadingPlayers] = useState(true);
    const [isRequestBodyChanged, setIsRequestBodyChanged] = useState(false);
    const [players, setPlayers] = useState({});

    const { getCampaignEmailTrackInfoOpenRequest } = useMemo(
        () => ({
            getCampaignEmailTrackInfoOpenRequest: CampaignHttpService.getCampaignEmailTrackInfoOpen(),
        }),
        [],
    );

    useEffect(() => {
        if (!isLoadingSchedule && isActive && isRequestBodyChanged) {
            setIsLoadingPlayers(true);
            setIsRequestBodyChanged(false);

            doPostRequest(getCampaignEmailTrackInfoOpenRequest.request, {
                requestBody: { CampaignId: campaignId, StartDate: startDate, EndDate: endDate },
                successCallback: (Data) => {
                    setPlayers(Data);
                    setIsDisableDownload(isNil(Data?.Count) || Data?.Count === 0);
                },
            }).then(() => {
                setIsLoadingPlayers(false);
            });
        }
    }, [
        isActive,
        isRequestBodyChanged,
        isLoadingSchedule,
        campaignId,
        startDate,
        endDate,
        setIsDisableDownload,
        getCampaignEmailTrackInfoOpenRequest,
        doPostRequest,
    ]);

    useEffect(() => {
        setIsRequestBodyChanged(true);
    }, [campaignId, startDate, endDate]);

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getCampaignEmailTrackInfoOpenRequest.cancel(
                'CampaignActivityMetricsPlayersWhoOpenedMail:getCampaignEmailTrackInfoOpenRequest',
            );
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <PlayersDataTable
            key="ClientId"
            config={CampaignActivityOpenedMailPlayersTableConfig}
            isLoading={isLoadingPlayers}
            data={players}
        />
    );
};

CampaignActivityMetricsPlayersWhoOpenedMail.propTypes = {
    campaignId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    setIsDisableDownload: PropTypes.func.isRequired,
    isLoadingSchedule: PropTypes.bool.isRequired,
    isActive: PropTypes.bool,
};

CampaignActivityMetricsPlayersWhoOpenedMail.defaultProps = {
    isActive: true,
};

export default memo(CampaignActivityMetricsPlayersWhoOpenedMail);
