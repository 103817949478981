import { lazy } from 'react';
import { isNil, isUndefined, first, isEmpty, unionBy, isArray } from 'lodash';
// Import Constants
import { FilterHolderFields, DateToISOStringFormat, FilterComparisions, GlobalEvents } from 'constants/common';

const {
    textInput,
    select,
    multiSelect,
    rangeDate,
    checkbox,
    singleDate,
    multiSelectAutocomplete,
    autocompleteOptionList,
} = FilterHolderFields;

export const FilterHolderComponentsTypes = {
    textInput: {
        keyName: textInput.type,
        Component: lazy(() => import('@geneui/components/ExtendedInput')),
        changeInputValue: function (event, callback = (value) => value, data, index) {
            const { value } = event.target;

            data[index].value = callback(value);
            return [...data];
        },
        props: {
            canClear: true,
        },
    },
    select: {
        keyName: select.type,
        Component: lazy(() => import('components/common/TDropdown')),
        changeInputValue: function (event, callback = (value) => value, data, index) {
            data[index].value = callback(event?.value);
            return [...data];
        },
        props: {
            clearable: true,
        },
    },
    multiSelect: {
        keyName: multiSelect.type,
        Component: lazy(() => import('components/common/TDropdown')),
        changeInputValue: function (event, callback = (value) => value, data, index) {
            data[index].value = callback(event.map((val) => val.value));
            return [...data];
        },
        props: {
            clearable: true,
        },
    },
    checkbox: {
        keyName: checkbox.type,
        Component: lazy(() => import('@geneui/components/Checkbox')),
        changeInputValue: function (event, callback = (value) => value, data, index) {
            data[index].checked = callback(event.target.checked);
            return [...data];
        },
    },
    singleDate: {
        keyName: singleDate.type,
        Component: lazy(() => import('@geneui/components/Checkbox')),
        changeInputValue: function (event, callback = (value) => value, data, index) {
            data[index].value = callback(event);
            return [...data];
        },
    },
    rangeDate: {
        keyName: rangeDate.type,
        changeInputValue: function (event, callback = (value) => value, data, index, filterInputData) {
            const callbackParams = event;
            if (
                filterInputData?.withTime !== true &&
                !isNil(callbackParams) &&
                isArray(callbackParams) &&
                callbackParams.length === 2 &&
                !isNil(callbackParams[1])
            ) {
                callbackParams[1] = callbackParams[1].set('hour', 23).set('minute', 59).set('second', 59);
            }
            data[index].value = callback(callbackParams);
            return [...data];
        },
    },
    multiSelectAutocomplete: {
        keyName: multiSelectAutocomplete.type,
        Component: lazy(() => import('components/common/AutocompleteDropdown')),
        changeInputValue: function (event, callback = (value) => value, data, index) {
            data[index].value = callback(event.map((val) => val.value));
            data[index].data = unionBy(data[index].data, event, 'value');
            return [...data];
        },
        props: {
            clearable: true,
        },
    },
    autocompleteOptionList: {
        keyName: autocompleteOptionList.type,
        Component: lazy(() => import('components/common/AutocompleteOptionList')),
        changeInputValue: function (event, callback = (value) => value, data, index) {
            data[index].value = callback(event?.value);
            data[index].data = unionBy(data[index].data, [event], 'value');
            return [...data];
        },
        props: {
            clearable: true,
        },
    },
    menuItemMultiSelect: {
        keyName: multiSelect.type,
        Component: lazy(() => import('components/common/MenuItemDropdownWithId')),
        changeInputValue: function (event, callback = (value) => value, data, index) {
            data[index].value = callback(event.map((val) => val.value));
            return [...data];
        },
        props: {
            eventName: GlobalEvents.ClientTagsChange,
            clearable: true,
            isMultiSelect: true,
        },
    },
};

export const FilterExistingValueChecker = (filterData) => {
    return !!filterData.find((data) => !isNil(data.value));
};

export const FiltersFieldsMap = (filterInputsData) => {
    return filterInputsData.reduce((acc, { valueFieldKey, value, checked, type, comparision }) => {
        const { rangeDate, singleDate } = FilterHolderComponentsTypes;
        const compValue = !isUndefined(checked) ? checked : value;
        if (!isNil(compValue) && !isNil(first(compValue)) && !isEmpty(compValue)) {
            if (type === rangeDate.keyName) {
                acc[valueFieldKey] = !isNil(compValue)
                    ? compValue.map((date) => (date.format ? date.format(DateToISOStringFormat) : date))
                    : compValue;
            } else if (type === singleDate.keyName) {
                acc[valueFieldKey] = !isNil(compValue)
                    ? compValue.toISOString
                        ? compValue.toISOString()
                        : compValue
                    : compValue;
            } else {
                acc[valueFieldKey] = compValue;
            }
        }
        if (comparision === FilterComparisions.IsBlank && !isNil(compValue)) {
            acc[valueFieldKey] = compValue;
        }

        return acc;
    }, {});
};

export const FiltersFieldsWithComparisionMap = (filterInputsData) => {
    const filtersFields = FiltersFieldsMap(filterInputsData);
    const comparisions = filterInputsData.reduce((acc, { valueFieldKey, comparision }) => {
        acc[valueFieldKey] = comparision;
        return acc;
    }, {});

    return Object.keys(filtersFields).reduce((acc, i) => {
        acc[i] = { value: filtersFields[i], comparison: comparisions[i] };
        return acc;
    }, {});
};
