import { useCallback, useEffect, useRef, useState } from 'react';

interface UsePeriodicFetchConfig {
    interval?: number;
    duration?: number;
}

const DEFAULT_PERIODIC_FETCH_CONFIG = {
    interval: 10 * 1000,
    duration: 2 * 60 * 1000,
};

export const usePeriodicFetch = (config: UsePeriodicFetchConfig = DEFAULT_PERIODIC_FETCH_CONFIG) => {
    const { interval = DEFAULT_PERIODIC_FETCH_CONFIG.interval, duration = DEFAULT_PERIODIC_FETCH_CONFIG.duration } =
        config;
    const [isPeriodicFetching, setIsPeriodicFetching] = useState(false);

    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const endTimeRef = useRef<number>(Date.now() + duration);

    const stopPeriodicFetching = useCallback(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        setIsPeriodicFetching(false);
    }, []);

    const startPeriodicFetching = useCallback(
        (promiseFn?: () => Promise<void>) => {
            endTimeRef.current = Date.now() + duration;
            setIsPeriodicFetching(true);

            const poll = () => {
                return promiseFn?.()
                    .then(() => {
                        if (Date.now() >= endTimeRef.current) {
                            stopPeriodicFetching();
                        }
                    })
                    .catch(stopPeriodicFetching);
            };

            timerRef.current = setInterval(poll, interval);

            if (!promiseFn) {
                return stopPeriodicFetching;
            }

            return poll();
        },
        [duration, interval, stopPeriodicFetching],
    );

    const cleanUp = () => {
        return () => {
            stopPeriodicFetching();
        };
    };

    useEffect(cleanUp, []);

    return {
        isPeriodicFetching,
        startPeriodicFetching,
        stopPeriodicFetching,
    };
};
