import React, { memo, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import components
import CompositeReportInfoTab from './CompositeReportInfoTab';
//Import UI components
import { Tabs, Tab, BusyLoader } from '@geneui/components';
// Import services
import { ReportHttpService } from 'services/http';
import { getQueryConfigurationsList } from 'services/segment';
// Import hooks
import { useRequest } from 'hooks';

const CompositeReportInfoTabs = ({ compositeConfiguration, segmentFiltersData, setSegmentFiltersData }) => {
    const { t } = useTranslation();
    const { doGetRequest, doPostRequest } = useRequest();

    const dispatch = useDispatch();
    const queryConfigurations = useSelector((state) => state.queryConfigurations);

    const [isLoading, setIsLoading] = useState(false);
    const [childReports, setChildReports] = useState();
    const { getReportById, getReportsByReport, getQueryConfigurationsListRequest } = useMemo(
        () => ({
            getReportById: ReportHttpService.getReportById(),
            getReportsByReport: ReportHttpService.getReportsByReport(),
            getQueryConfigurationsListRequest: getQueryConfigurationsList(),
        }),
        [],
    );

    const getReports = (reportIdsAndTypes) => {
        return reportIdsAndTypes.map((report) => {
            return doGetRequest(getReportById.request, {
                queryString: {
                    reportId: report.ReportId,
                    reportType: report.ReportType,
                },
            });
        });
    };

    const mapReportsInfo = (responses) => {
        // for promise all need has error but case dows not cover
        let hasError = false;
        const segmentsData = responses.map((response) => {
            // for cases when promise is null
            if (isNil(response)) return response;

            const { HasError, Data } = response;
            if (!HasError) {
                return Data;
            }
            // case when one of one promise has error
            hasError = true;
            return null;
        });
        return { Data: segmentsData, HasError: hasError };
    };

    const mapChildReports = (reportsData) => {
        return reportsData.map((report) => {
            const reportType = compositeConfiguration.Children.find(
                (child) => child.ReportId === report.ReportId,
            ).ReportType;
            return { ...report, reportType };
        });
    };

    const tabChangeHandler = (tabIndex) => {
        if (!isNil(childReports) && isNil(childReports[tabIndex].usedIn)) {
            doPostRequest(getReportsByReport.request, {
                requestBody: {
                    reportId: childReports[tabIndex].ReportId,
                    reportType: childReports[tabIndex].reportType,
                },
                successCallback: (Data) => {
                    const reportsWithUsedIn = [...childReports];
                    reportsWithUsedIn[tabIndex] = { ...reportsWithUsedIn[tabIndex], usedIn: Data };
                    setChildReports(reportsWithUsedIn);
                },
            });
        }

        getQueryConfigurationsListRequest.request(
            dispatch,
            queryConfigurations,
            childReports[tabIndex],
            {
                shouldNotDispatch: true,
                setData: setSegmentFiltersData,
            },
            t,
        );
    };

    const init = () => {
        setIsLoading(true);
        Promise.all(getReports(compositeConfiguration.Children)).then((responses) => {
            const mapReportsData = mapReportsInfo(responses);
            const data = mapChildReports(mapReportsData.Data);
            setChildReports(data);
            setIsLoading(mapReportsData.HasError);
        });
    };

    const cleanUp = () => {
        return () => {
            getReportById.cancel('CompositeReportInfo:getReportById');
        };
    };

    useEffect(() => {
        if (!isLoading && !isNil(childReports)) {
            tabChangeHandler(0); //for first time when loading
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <div className="composite-report-tabs">
            <BusyLoader isBusy={isLoading || isNil(childReports)} type="spinner" spinnerSize="medium">
                {!isLoading && !isNil(childReports) && (
                    <Tabs type="box" position="top" onChange={tabChangeHandler}>
                        {childReports.map((report, index) => (
                            <Tab key={index} title={report.Name}>
                                <CompositeReportInfoTab
                                    segmentFiltersData={segmentFiltersData}
                                    key={index}
                                    report={report}
                                />
                            </Tab>
                        ))}
                    </Tabs>
                )}
            </BusyLoader>
        </div>
    );
};

CompositeReportInfoTabs.propTypes = {
    compositeConfiguration: PropTypes.object.isRequired,
    segmentFiltersData: PropTypes.shape({
        [PropTypes.string]: {
            id: PropTypes.number,
            isMain: PropTypes.bool,
            seqId: PropTypes.number,
            name: PropTypes.string,
            filters: PropTypes.shape({
                [PropTypes.string]: PropTypes.array,
            }),
        },
    }),
    setSegmentFiltersData: PropTypes.func,
};

export default memo(CompositeReportInfoTabs);
