import {
    CRMRequestService,
    CRMGalleryRequestService,
    getCancelableRequest,
    postCancelableRequest,
    deleteCancelableRequest,
} from './http.config';
import Helpers from 'services/Helpers';

const { combineUrlParams } = Helpers;

const UtilsHttpService = {
    getTranslations: () => {
        return CRMRequestService.get('/Translations/GetTranslations');
    },
    getTranslationsVersion: () => {
        return CRMRequestService.get('/Translations/GetVersion');
    },
    getClientFields: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/OptionsList/ClientFields',
        });
    },
    getChannelsList: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/OptionsList/Channels',
        });
    },
    getCurrencyRates: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'OptionsList/GetCurrencyRates',
        });
    },
    getBonusCalculateActions: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/OptionsList/BonusCalculateActions',
        });
    },
    getBonusObjectsByType: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ type }) => `/OptionsList/BonusObjects?type=${type}`,
        });
    },
    getTagsList: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ targetType }) => `/ObjectCategory/GetTagsList?targetType=${targetType}`,
        });
    },
    tagsList: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ObjectCategory/TagsList',
        });
    },
    createTag: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ObjectCategory/CreateTag',
        });
    },
    createObjectTag: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ObjectCategory/CreateObjectTag',
        });
    },
    updateTag: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/ObjectCategory/UpdateTag',
        });
    },
    deleteTag: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ id }) => `/ObjectCategory/DeleteTag?tagId=${id}`,
        });
    },
    getMenuItemsById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ id, params }) => {
                return `/OptionsList/GetMenuItemsById?id=${id}${params ? '&' + params : ''}`;
            },
        });
    },
    getMenuItemsConfig: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => {
                return `/OptionsList/GetMenuItemsConfig`;
            },
        });
    },
    getValidations: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => {
                return '/Validation/List';
            },
        });
    },
    getObjectDependencies: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ objectId, dependencyType }) =>
                `/Validation/GetObjectDependencies?objectId=${objectId}&dependencyType=${dependencyType}`,
        });
    },
    getPartnerLanguage: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/OptionsList/GetPartnerLanguages`,
        });
    },
    getAllKPIs: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/Configuration/Columns`,
        });
    },
    getPartnerProviders: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => `/OptionsList/GetPartnerProviders`,
        });
    },
    getDataTabelConfig: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ tableName }) => `/User/DataTabelConfig?tabelName=${tableName}`,
        });
    },
    saveDataTabelConfig: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/User/DataTabelConfig',
        });
    },
    getFiltersTagGroup: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ filtersTagGroupName }) => `User/GetFilterTags?filterTagName=${filtersTagGroupName}`,
        });
    },
    addFiltersTagGroup: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/User/AddFilterGroup',
        });
    },
    deleteFiltersTagGroup: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/User/RemoveFilterGroupByLabel',
        });
    },
    getScheduleRunDates: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Schedule/GetCronFireTimes',
        });
    },
    getPartnerMessageProviders: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Partner/GetMessageProviderList',
        });
    },
    getPartnerSiteSetting: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Partner/GetPartnerSiteSetting',
        });
    },
    updatePartnerSiteSetting: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Partner/UpdatePartnerSiteSetting',
        });
    },
    getPartnerSettings: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/PartnerSettings/Get',
        });
    },
    getPartnerCustomFields: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ objectType }) => `/CustomField/GetPartnerCustomFields?objectType=${objectType}`,
        });
    },
    getCustomFieldOptions: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/CustomField/GetCustomFieldOptions',
        });
    },
    addAsset: () => {
        return postCancelableRequest({
            requestService: CRMGalleryRequestService,
            endpointPath: () => 'Save/Image',
        });
    },
    deleteObjectTag: () => {
        return deleteCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ id, objectType }) =>
                `/ObjectCategory/DeleteObjectTag?objectId=${id}&objectType=${objectType}`,
        });
    },
    getByPath: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            uriEncoding: false,
            endpointPath: ({ url, queryParams }) => `${url}${combineUrlParams(queryParams)}`,
        });
    },
    postByPath: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            uriEncoding: false,
            endpointPath: ({ url, queryParams }) => `${url}${combineUrlParams(queryParams)}`,
        });
    },
    getPopUpComparisons: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'OptionsList/GetPopUpComparision',
        });
    },
    getDataById: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            uriEncoding: false,
            defaultParams: { queryParams: '' },
            endpointPath: ({ url, queryParams }) => {
                return `/${url}${queryParams}`;
            },
        });
    },
    getDataByIds: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            uriEncoding: false,
            endpointPath: ({ url }) => {
                return `/${url}`;
            },
        });
    },
    getMenuItems: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            uriEncoding: false,
            endpointPath: ({ url }) => {
                return `?id=${url}`;
            },
        });
    },
    getIconsData: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => {
                return '/Provider/GetIconsData';
            },
        });
    },
    getPushNotificationConfig: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/OptionsList/GetPushNotificationSettings',
        });
    },
    getSiteBuilderTypes: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/OptionsList/SiteBuilderTypes',
        });
    },
    getPartnerState: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Partner/GetPartnerState',
        });
    },
    getNftMarketplaces: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/OptionsList/GetPartnerAvailableNftMarketplaces',
        });
    },
    getSpinExchangeRatesRequest: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ bonusType }) => `/PartnerSettings/GetSpinExchangeRates?bonusType=${bonusType}`,
        });
    },
    addSpinExchangeRate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ bonusType }) => `/PartnerSettings/AddSpinExchangeRate?bonusType=${bonusType}`,
        });
    },
    updateSpinExchangeRate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ bonusType }) => `/PartnerSettings/UpdateSpinExchangeRate?bonusType=${bonusType}`,
        });
    },
    deleteSpinExchangeRate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ bonusType }) => `/PartnerSettings/DeleteSpinExchangeRate?bonusType=${bonusType}`,
        });
    },
    setDefaultSpinExchangeRate: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ bonusType }) => `/PartnerSettings/SetDefaultSpinExchangeRate?bonusType=${bonusType}`,
        });
    },
    getTestConfig: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/PartnerSettings/GetTestConfig',
        });
    },
    updateTestConfig: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/PartnerSettings/UpdateTestConfig',
        });
    },
    getUserInfo: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/User/Info',
        });
    },
    getGlobalSearchUsers: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Search/RecentUsers',
        });
    },
    getGlobalSearchResult: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Search/Global',
        });
    },
    getPartnerCurrencies: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'OptionsList/GetPartnerCurrencies',
        });
    },
    getNftVoucherCollectionsList: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => 'OptionsList/GetNftVoucherCollections',
        });
    },
};

export default UtilsHttpService;
