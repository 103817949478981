import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// Import UI Components
import { Popover, Icon, Option, BusyLoader } from '@geneui/components';
// Import Components
import { ButtonWithLoader } from 'components';
// Import Services
import { Helpers } from 'services';
// Import Constants
import { l, DownloadPopoverViews, DownloadExtensions } from 'constants/common';
// Import Hooks
import { useOnClickOutside } from 'hooks';
// Import SCSS
import 'assets/scss/downloadToolbar.scss';
// Import Images
import csvImg from 'assets/images/docs/csv.svg';
import xlsImg from 'assets/images/docs/xls.svg';

const { xlsx, csv } = DownloadExtensions;

const DownloadToolbar = ({
    downloadClickHandler,
    withTypeSelector,
    withCountDropdown,
    buttonText,
    isFileLoading,
    isDisableDownload,
}) => {
    const { t } = useTranslation();
    const { typesView, countView } = DownloadPopoverViews;
    const toolbarRef = useRef(null);

    const [downloadDropdownValue, setDownloadDropdownValue] = useState(null);
    const [downloadType, setDownloadType] = useState(xlsx);
    const [toolbarVisibilityStatus, setToolbarVisibilityStatus] = useState(false);
    const [downloadPopoverView, setDownloadPopoverView] = useState(withTypeSelector ? typesView : countView);
    const defaultStates = {
        export: false,
        xlsx: false,
        csv: false,
        all: false,
        top10: false,
        top20: false,
        top50: false,
        top100: false,
    };

    const [isShowLoader, setIsShowLoader] = useState(defaultStates);

    const downloadDropdownChangeHandler = (value) => {
        const key = `top${value}`;
        updateIsShowState(key);
        updateIsShowState(downloadType);
        updateIsShowState('export');
        setDownloadDropdownValue(value);
        downloadClickHandler(downloadType, value);
    };

    const downloadButtonClickHandler = (type) => {
        updateIsShowState('export');
        updateIsShowState(type);
        downloadClickHandler(type, downloadDropdownValue);
    };

    const updateIsShowState = (key) => {
        const states = defaultStates;
        states[key] = true;
        setIsShowLoader(states);
    };

    const changeView = (view, type) => {
        setDownloadPopoverView(view);
        setDownloadType(type);
    };

    const cancel = () => {
        changeView(withTypeSelector ? typesView : countView, csv);
        setDownloadDropdownValue(null);
    };

    const btnOnclickHandler = () => {
        setToolbarVisibilityStatus(!toolbarVisibilityStatus);
        if (toolbarVisibilityStatus) {
            cancel();
        }
    };

    useOnClickOutside(
        toolbarRef,
        useCallback(() => {
            if (toolbarVisibilityStatus) {
                setToolbarVisibilityStatus(false);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [toolbarVisibilityStatus]),
    );

    const renderContent = () => {
        return downloadPopoverView === typesView ? (
            <div className="d-toolbar-menu">
                <button
                    className="d-toolbar-menu-row"
                    onClick={() => (withCountDropdown ? changeView(countView, csv) : downloadButtonClickHandler(csv))}
                >
                    {(!isFileLoading || !isShowLoader.csv) && <img src={csvImg} alt="" />}
                    {isShowLoader.csv && <BusyLoader type="spinner" spinnerSize="small" isBusy={isFileLoading} />}
                    <span>{t(l.CSV)}</span>
                    {withCountDropdown && <Icon type="icon bc-icon-arrow-right" />}
                </button>
                <button
                    className="d-toolbar-menu-row"
                    onClick={() => (withCountDropdown ? changeView(countView, xlsx) : downloadButtonClickHandler(xlsx))}
                >
                    {(!isFileLoading || !isShowLoader.xlsx) && <img src={xlsImg} alt="" />}
                    {isShowLoader.xlsx && <BusyLoader type="spinner" spinnerSize="small" isBusy={isFileLoading} />}
                    <span>{t(l.XLS)}</span>
                    {withCountDropdown && <Icon type="icon bc-icon-arrow-right" />}
                </button>
            </div>
        ) : (
            <div className="d-toolbar-content">
                {withTypeSelector && (
                    <button className="d-toolbar-icon-wrapper" onClick={cancel}>
                        <Icon className="d-toolbar-icon" type="bc-icon-arrow-left" />
                        <span>{downloadType === csv ? t(l.CSV) : t(l.XLS)}</span>
                    </button>
                )}
                <div className={classNames(isFileLoading ? 'download-disable' : '')}>
                    {Helpers.downloadDropdownDataCreator(t).map(({ value, label }) => {
                        return (
                            <div className="d-toolbar-menu-row" key={value}>
                                {(!isFileLoading || !(isShowLoader[`top${value}`] && isShowLoader[downloadType])) && (
                                    <Icon type="bc-icon-download" />
                                )}
                                {isShowLoader[`top${value}`] && isShowLoader[downloadType] && (
                                    <BusyLoader type="spinner" spinnerSize="small" isBusy={isFileLoading} />
                                )}
                                <Option
                                    key={value}
                                    title={label}
                                    onClick={() => downloadDropdownChangeHandler(value)}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="download-toolbar" ref={toolbarRef}>
            <div className="download-toolbar-inner">
                <Popover
                    isOpen={toolbarVisibilityStatus}
                    extendTargetWidth={false}
                    minHeight={277}
                    align="end"
                    scrollbarNeeded={false}
                >
                    <>
                        <div className="download-toolbar-info-group">
                            <ButtonWithLoader
                                className="user-btn d-toolbar-btn"
                                appearance="minimal"
                                onClick={() =>
                                    withTypeSelector || withCountDropdown
                                        ? btnOnclickHandler()
                                        : downloadButtonClickHandler(xlsx)
                                }
                                isLoading={isShowLoader.export && isFileLoading}
                                isDisabled={isDisableDownload}
                            >
                                {t(buttonText)}
                                {(withTypeSelector || withCountDropdown) && (
                                    <Icon type={`bc-icon-arrow-${!toolbarVisibilityStatus ? 'down' : 'up'}`} />
                                )}
                            </ButtonWithLoader>
                        </div>

                        {toolbarVisibilityStatus && (withTypeSelector || withCountDropdown) ? (
                            <>{renderContent()}</>
                        ) : (
                            <></>
                        )}
                    </>
                </Popover>
            </div>
        </div>
    );
};

DownloadToolbar.propTypes = {
    downloadClickHandler: PropTypes.func.isRequired,
    withTypeSelector: PropTypes.bool,
    withCountDropdown: PropTypes.bool,
    isFileLoading: PropTypes.bool,
    buttonText: PropTypes.string,
    isDisableDownload: PropTypes.bool,
};

DownloadToolbar.defaultProps = {
    withTypeSelector: false,
    withCountDropdown: false,
    isFileLoading: false,
    buttonText: l.Download,
    isDisableDownload: false,
};

export default DownloadToolbar;
