import React from 'react';
import classNames from 'classnames';
import { useDragLayer } from 'react-dnd';
import { usePreview } from 'react-dnd-preview';
// Import UI components
import { Checkbox, Icon } from '@geneui/components';

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '48%',
    height: '100%',
};

const PreviewRow = () => {
    const previewCollect = usePreview();
    const collect = useDragLayer((monitor) => {
        return {
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        };
    });

    if (!previewCollect.display) {
        return null;
    }
    if (!collect.isDragging) {
        return null;
    }

    function getItemStyles(initialOffset, currentOffset, defaultTranslateX = 0, defaultTranslateY = 0) {
        if (!initialOffset || !currentOffset) {
            return {
                display: 'none',
            };
        }
        const { x, y } = currentOffset;
        const transform = `translate(${x - defaultTranslateX}px, ${y - defaultTranslateY}px)`;

        return {
            transform,
            WebkitTransform: transform,
        };
    }

    return (
        <>
            <div style={{ ...layerStyles, ...collect.item?.style }}>
                <div
                    className={classNames('d-a-d-item-draggable', {
                        'permanent-row': collect.item.isPermanent,
                    })}
                    style={getItemStyles(collect.initialOffset, collect.currentOffset)}
                >
                    <div className="d-a-d-item-label-part">
                        {collect.item.withSelect && <Checkbox checked={collect.item.isChecked} />}
                        <span>{collect.item.name}</span>
                    </div>

                    <Icon type="bc-icon-drag" />
                </div>
            </div>
        </>
    );
};

export default PreviewRow;
