import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
// Import Components
import { PartnersListWithPartnerGroup } from 'components';
// Import UI Components
import { Empty, ExtendedInput, Option } from '@geneui/components';
// Import Constants
import { l } from 'constants/common';
//Import SCSS
import 'assets/scss/partners.scss';

const PartnersOptionList = ({ partnerGroupsListData, titles, partnerSelectHandler }) => {
    const { t } = useTranslation();
    const searchRef = useRef();
    const searchHandlerTimeout = useRef(null);
    const partnersGroupHeightsRef = useRef([]);

    const [data, setData] = useState(partnerGroupsListData);
    const [searchValue, setSearchValue] = useState('');

    const getTitle = useMemo(() => {
        return titles.map((element, index) => {
            return (
                <span key={element} className={`crm-option-header-column-${index}`}>
                    {element}
                </span>
            );
        });
    }, [titles]);

    const searchHandler = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        clearTimeout(searchHandlerTimeout.current);
        searchHandlerTimeout.current = setTimeout(() => {
            const filteredList = partnerGroupsListData
                .map((partnerGroup) => {
                    return {
                        ...partnerGroup,
                        partners: partnerGroup.partners.filter(({ label, displayId }) => {
                            return (
                                label.toLowerCase().includes(searchValue.trim().toLowerCase()) ||
                                +displayId === +searchValue
                            );
                        }),
                    };
                })
                .filter((partnerGroup) => partnerGroup.partners.length > 0);
            setData(filteredList);
        }, 300);
    }, [partnerGroupsListData, searchValue]);

    const init = () => {
        searchRef.current.focus();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    return (
        <div className="crm-option-list-content">
            <ExtendedInput
                ref={searchRef}
                value={searchValue}
                onChange={searchHandler}
                icon="bc-icon-search"
                className="partner-search-input"
            />
            <Option title={getTitle} border="bottom" className="crm-option-list-content-column-names" />
            <div className={`partners-group-wrapper ${isEmpty(data) ? 'small-empty-option-list' : ''}`}>
                <>
                    {!isEmpty(data) ? (
                        data.map((partnerGroup, i) => {
                            return (
                                <PartnersListWithPartnerGroup
                                    partnersGroupHeightsRef={partnersGroupHeightsRef}
                                    partnerGroupIndex={i}
                                    data={partnerGroup}
                                    key={`${partnerGroup.partnerGroupId}_${i}_${partnerGroup.partnerGroupName}`}
                                    partnerSelectHandler={partnerSelectHandler}
                                    withGroupLabel={partnerGroupsListData.length > 1}
                                    hasSearchValue={!!searchValue}
                                />
                            );
                        })
                    ) : (
                        <Empty title={t(l.NoDataToDisplay)} size="small" />
                    )}
                </>
            </div>
        </div>
    );
};

PartnersOptionList.propTypes = {
    partnerGroupsListData: PropTypes.array,
    titles: PropTypes.array,
    partnerSelectHandler: PropTypes.func.isRequired,
};

export default PartnersOptionList;
