import React, { ComponentProps, FC, memo } from 'react';
import classNames from 'classnames';
// Import UI components
import { Button } from '@geneui/components';

interface Props extends ComponentProps<typeof Button> {
    defaultClassNames?: string;
    icon?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
}

const ButtonWithLoader: FC<Props> = ({ defaultClassNames, isLoading, isDisabled, children, icon = '', ...rest }) => {
    return (
        <Button
            className={classNames(defaultClassNames, { 'crm-modal-action-btn': isLoading })}
            icon={isLoading ? 'bc-icon-loader' : icon}
            disabled={isLoading || isDisabled}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default memo(ButtonWithLoader);
