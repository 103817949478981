import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { ConfirmationModal } from 'components';
// Import Constants
import { l, ActionsIcons, ModalsClassNames, ModalsActions } from 'constants/common';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { CommunicationHttpService } from 'services/http';

const CommunicationActionsModals = ({
    isResendModalOpenedState,
    onCloseModalHandler,
    modalsStateSetDict,
    openedModalData,
}) => {
    const { CommunicationId, ClientId } = openedModalData;
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();
    const { showToaster } = useToaster();
    const [isLoading, setIsLoading] = useState(false);

    const { sendCommunicationRequest } = useRef({
        sendCommunicationRequest: CommunicationHttpService.resendCommunication(),
    }).current;

    const resend = () => {
        setIsLoading(true);
        doPostRequest(sendCommunicationRequest.request, {
            queryString: { communicationId: CommunicationId },
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(
                modalsStateSetDict.resendCommunication.key,
                ModalsActions.RESEND,
                AlertType,
                AlertMessage,
            );
            showToaster(AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const cleanUp = () => {
        return () => {
            sendCommunicationRequest.cancel('CommunicationListPage:sendCommunicationRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <>
            {isResendModalOpenedState && (
                <ConfirmationModal
                    onOk={resend}
                    onCancel={() => onCloseModalHandler(modalsStateSetDict.resendCommunication.key)}
                    isVisibleModal={isResendModalOpenedState}
                    titleText={t(l.ResendTittle)}
                    isLoading={isLoading}
                    iconType={ActionsIcons.Resend}
                    actionLabel={t(l.Send)}
                    className={ModalsClassNames.Start}
                    questionLabel={t(l.AreYouSureYouWantToSendCommunicationToClient, {
                        clientId: `"${ClientId}"`,
                    })}
                />
            )}
        </>
    );
};

CommunicationActionsModals.propTypes = {
    isResendModalOpenedState: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.any.isRequired,
};

export default memo(CommunicationActionsModals);
