import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { ABTestingHttpService, CampaignHttpService } from 'services/http';
import { Helpers } from 'services';
// Import UI Components
import { Alert, BusyLoader, Empty, Tab, Tabs } from '@geneui/components';
// Import Components
import ABTestGeneralDetails from './ABTestGeneralDetails';
import ABTestChartGroup from './ABTestChartGroup';
// Import Types
import { PromotionPresetsAPIModel, TestResultsAPIModel, ParseToUIModel } from './types';
// Import Constants
import { l, MonthDayYearFormat } from 'constants/common';

interface Props {
    campaignId: string;
}

const { customMomentWithoutTimezoneConversion } = Helpers;

const CampaignReportABTest: FC<Props> = ({ campaignId }) => {
    const { t } = useTranslation();

    const { doGetRequest } = useRequest();

    const [testResultsAPIModel, setTestResultsAPIModel] = useState<TestResultsAPIModel[] | null>(null);
    const [promotionPresetsAPIModel, setPromotionPresetsAPIModel] = useState<PromotionPresetsAPIModel[] | null>(null);

    const isLoading: boolean = isNil(testResultsAPIModel) || isNil(promotionPresetsAPIModel);

    const { getCampaignABTestResult, getPresets } = useMemo(
        () => ({
            getCampaignABTestResult: CampaignHttpService.getCampaignABTestResult(),
            getPresets: ABTestingHttpService.getPresets(),
        }),
        [],
    );

    const parseToUIModel: ParseToUIModel = useCallback(
        (testResultsAPI, promotionPresets) => {
            if (isNil(testResultsAPI) || isNil(promotionPresets)) {
                return null;
            }

            return testResultsAPI.map((result, index) => {
                const goal = promotionPresets.find((preset) => {
                    const metricIds = preset.PromotionTestMetrics.map(
                        ({ PromotionTestMetricId }) => PromotionTestMetricId,
                    );

                    return result.PromotionTestMetrics.every((metricId) => metricIds.includes(metricId));
                });

                const kpiIdNameGroup =
                    goal?.PromotionTestMetrics.reduce((acc, { PromotionTestMetricId, Name }) => {
                        acc[PromotionTestMetricId] = Name;
                        return acc;
                    }, {}) ?? {};

                const isResultEmpty = isEmpty(result.ABTestResults);

                const kpiList = isResultEmpty
                    ? result.PromotionTestMetrics.map((kpiId) => ({
                          titleKey: kpiIdNameGroup[kpiId],
                          pilotGroup: 0,
                          controlGroup: 0,
                          impactPercentage: null,
                          confidencePercentage: null,
                      }))
                    : result.ABTestResults.map((kpi) => ({
                          titleKey: kpiIdNameGroup[kpi.MetricId],
                          pilotGroup: kpi.TargetResult,
                          controlGroup: kpi.ControlResult,
                          impactPercentage: kpi.Impact,
                          confidencePercentage: kpi.Significance,
                      }));

                const kpiNames = result.PromotionTestMetrics.map((metricId) => kpiIdNameGroup[metricId]);

                return {
                    tabTitle: `${t(l.ABTest)} ${index + 1}`,
                    generalDetails: {
                        goal: goal?.Name || '',
                        segmentSplitEndDate: customMomentWithoutTimezoneConversion(result.EndDate)
                            .subtract(result.AnalyticsPeriod, 'd')
                            .format(MonthDayYearFormat),
                        kpiNames: kpiNames,
                        endDate: customMomentWithoutTimezoneConversion(result.EndDate).format(MonthDayYearFormat),
                        startDate: customMomentWithoutTimezoneConversion(result.StartDate).format(MonthDayYearFormat),
                        controlGroup: result.SampleSize,
                        pilotGroup: 100 - result.SampleSize,
                    },
                    kpiList: kpiList,
                    isResultEmpty,
                };
            });
        },
        [t],
    );

    const aBTestResultsUIModel = useMemo(
        () => parseToUIModel(testResultsAPIModel, promotionPresetsAPIModel),
        [testResultsAPIModel, parseToUIModel, promotionPresetsAPIModel],
    );

    const init = () => {
        doGetRequest(getCampaignABTestResult.request, {
            queryString: { CampaignId: campaignId },
            successCallback: (data: TestResultsAPIModel[]) => setTestResultsAPIModel(data),
        });

        doGetRequest(getPresets.request, {
            successCallback: (data: PromotionPresetsAPIModel[]) => setPromotionPresetsAPIModel(data),
        });
    };

    const cleanUp = () => {
        return () => {
            getCampaignABTestResult.cancel('CampaignReportABTest:getCampaignABTestResult');
            getPresets.cancel('CampaignReportABTest:getPresets');
        };
    };

    useEffect(init, []);
    useEffect(cleanUp, []);

    return (
        <div className="campaign-report-ab-test-tab-container">
            <BusyLoader isBusy={isLoading || isNil(aBTestResultsUIModel)} type="spinner" spinnerSize="medium">
                {isEmpty(aBTestResultsUIModel) ? (
                    <Empty title={t(l.NoDataToDisplay)} />
                ) : (
                    <Tabs type="box" position="top">
                        {aBTestResultsUIModel?.map(
                            ({ tabTitle, generalDetails, kpiList, isResultEmpty }, index: number) => {
                                return (
                                    <Tab key={index} title={tabTitle}>
                                        <div className="campaign-report-ab-test-scrollable-container">
                                            <ABTestGeneralDetails generalDetails={generalDetails} />
                                            {isResultEmpty && (
                                                <Alert
                                                    closable={false}
                                                    title={l.ABTestNoResultYet}
                                                    type="warning"
                                                    iconProps={{ type: 'bc-icon-info' }}
                                                />
                                            )}
                                            <ABTestChartGroup kpiList={kpiList} />
                                        </div>
                                    </Tab>
                                );
                            },
                        )}
                    </Tabs>
                )}
            </BusyLoader>
        </div>
    );
};

CampaignReportABTest.propTypes = {
    campaignId: PropTypes.string.isRequired,
};

export default memo(CampaignReportABTest);
