import React, { memo, useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import UI Components
import { BusyLoader, Tag, Tabs, Tab } from '@geneui/components';
//Import Components
import { KeyValueItems, TittleLink } from 'components';
// Import Actions
import { PartnerLanguageAction } from 'actions';
// Import Services
import { CustomerJourneyHttpService, UtilsHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import { BooleanTypeLabels, StateModesLabels, l, DateTimeFormat } from 'constants/common';

const { getPartnerLanguageList } = PartnerLanguageAction;

const { customMomentWithoutTimezoneConversion } = Helpers;

const CJVisualizationInfo = ({ workflowId }) => {
    const { t } = useTranslation();

    const { doGetRequest } = useRequest();

    const [isLoading, setIsLoading] = useState(false);
    const [visualizationData, setVisualizationData] = useState({});
    const [iconsConfig, setIconsConfig] = useState({});
    const [subTubIndex, setSubTubIndex] = useState('0');

    const dispatch = useDispatch();
    const partnerLanguages = useSelector((state) => state.partnerLanguages);

    const { getCustomerJourneyVisualizationById, getPartnerLanguage, getIconsData } = useMemo(
        () => ({
            getCustomerJourneyVisualizationById: CustomerJourneyHttpService.getCustomerJourneyVisualizationById(),
            getPartnerLanguage: UtilsHttpService.getPartnerLanguage(),
            getIconsData: UtilsHttpService.getIconsData(),
        }),
        [],
    );

    const generalDetailsData = useMemo(() => {
        if (isEmpty(visualizationData)) return [];

        const getLanguages = () => (
            <div className="languages-container">
                {visualizationData.VisualizationTemplates.map((template, index) => (
                    <Tag
                        key={index}
                        name={isNil(template.Language) ? t(l.Default) : partnerLanguages[template.Language]?.Name}
                        cornerRadius="smooth-radius"
                        appearance="outline"
                    />
                ))}
            </div>
        );

        return [
            {
                label: l.Title,
                value: (
                    <TittleLink
                        name={t(visualizationData.Name)}
                        isClickable={false}
                        tooltipConfig={{ position: 'top' }}
                    />
                ),
            },
            {
                label: l.Description,
                value: (
                    <TittleLink
                        name={t(visualizationData.Description)}
                        isClickable={false}
                        tooltipConfig={{ position: 'top' }}
                    />
                ),
            },
            {
                label: l.Languages,
                value: getLanguages(),
                isLoading: isEmpty(partnerLanguages),
            },
            {
                label: l.IsLive,
                value: BooleanTypeLabels[+visualizationData.State === StateModesLabels.Live],
            },
            {
                label: l.VisibleTo,
                value: isNil(visualizationData.Condition) ? l.AllPlayers : l.CJPlayers,
            },
            {
                label: l.CreatedDate,
                value:
                    visualizationData.CreatedDate &&
                    customMomentWithoutTimezoneConversion(visualizationData.CreatedDate).format(DateTimeFormat),
            },
            {
                label: l.User,
                value: visualizationData.CreatedBy.Name,
            },
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visualizationData, partnerLanguages]);

    const getStepsInfo = useMemo(() => {
        if (isEmpty(visualizationData)) return [];

        return visualizationData.VisualizationSteps.map((step, index) => ({
            name: t(`${l.Step} ${index + 1}`),
            info: [
                {
                    label: l.Name,
                    value: <TittleLink name={step.Name} isClickable={false} tooltipConfig={{ position: 'top' }} />,
                },
                {
                    label: l.Description,
                    value: (
                        <TittleLink
                            name={isEmpty(step.Description) ? t(l.NA) : step.Description}
                            isClickable={false}
                            tooltipConfig={{ position: 'top' }}
                        />
                    ),
                },
                {
                    label: l.Icon,
                    value:
                        isEmpty(iconsConfig) ||
                        isNil(iconsConfig.data.find((item) => item.value === step.Icon)) ? null : (
                            <span className="crm-option-icon-column">
                                <span style={{ fontFamily: iconsConfig.fontFamily }}>
                                    <span
                                        className={iconsConfig.data.find((item) => item.value === step.Icon)?.label}
                                    ></span>
                                </span>
                            </span>
                        ),
                    isLoading: isEmpty(iconsConfig),
                },
            ],
            subSteps: step.VisualizationSubSteps.map((subStep, index) => ({
                name: t(`${l.SubStep} ${index + 1}`),
                info: [
                    {
                        label: l.Name,
                        value: (
                            <TittleLink name={subStep.Name} isClickable={false} tooltipConfig={{ position: 'top' }} />
                        ),
                    },
                    {
                        label: l.Description,
                        value: (
                            <TittleLink
                                name={isEmpty(subStep.Description) ? t(l.NA) : subStep.Description}
                                isClickable={false}
                                tooltipConfig={{ position: 'top' }}
                            />
                        ),
                    },
                    {
                        label: l.IsHidden,
                        value: t(subStep.IsHidden ? l.Yes : l.No),
                    },
                ],
            })),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visualizationData, iconsConfig]);

    const init = () => {
        setIsLoading(true);

        doGetRequest(getCustomerJourneyVisualizationById.request, {
            queryString: {
                workflowId: workflowId,
            },
            successCallback: (Data) => {
                setVisualizationData(Data);
            },
        }).then(() => setIsLoading(false));

        isEmpty(partnerLanguages) && getPartnerLanguageList(dispatch, getPartnerLanguage.request);

        doGetRequest(getIconsData.request, {
            successCallback: (data) => {
                setIconsConfig({
                    css: data?.CSS,
                    fontFamily: data?.FontFamily,
                    data: isNil(data?.Data) ? [] : data.Data.map((item) => ({ label: item.Name, value: item.Id })),
                });
            },
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    return (
        <BusyLoader isBusy={isLoading} type="spinner" spinnerSize="medium">
            {!isEmpty(visualizationData) && (
                <div className="customer-journey-visualization-tab">
                    <Helmet>
                        <link type="text/css" rel="stylesheet" href={iconsConfig?.css} />
                    </Helmet>
                    <div className="general-details-section">
                        <div className="title-wrapper">
                            <p className="title-wrapper-text">{t(l.GeneralDetails)}</p>
                            <span className="title-wrapper-line" />
                        </div>
                        <div className="general-details-content">
                            <div className="general-details-column">
                                <KeyValueItems items={generalDetailsData} />
                            </div>
                        </div>
                    </div>
                    <Tabs type="box" position="top" onChange={() => setSubTubIndex('0')}>
                        {getStepsInfo.map((step, index) => (
                            <Tab key={index} title={step.name}>
                                <>
                                    <KeyValueItems items={step.info} vertical={true} />
                                    <Tabs type="text" position="top" onChange={setSubTubIndex} activeKey={subTubIndex}>
                                        {step.subSteps.map((subStep, index) => (
                                            <Tab key={index} title={subStep.name}>
                                                <KeyValueItems items={subStep.info} vertical={true} />
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </>
                            </Tab>
                        ))}
                    </Tabs>
                </div>
            )}
        </BusyLoader>
    );
};

CJVisualizationInfo.propTypes = {
    workflowId: PropTypes.number.isRequired,
};

export default memo(CJVisualizationInfo);
