/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useRef, forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep, findIndex, first, isNil, isUndefined, noop, find, isEmpty, has } from 'lodash';
import ReactFlow, {
    useStoreActions,
    removeElements,
    addEdge,
    isNode,
    Controls,
    ControlButton,
    Background,
} from 'react-flow-renderer';
import classNames from 'classnames';
// Import Constants
import {
    l,
    layoutDirections,
    AlertTypes,
    FlowEditorElementsSetterActionTypes,
    ModalsClassNames,
    CustomerJourneyGroupBlockTypes,
} from 'constants/common';
import {
    customerJourneyFlowEditorUndoRedoMaxCount,
    deleteKeycode,
    ctrlKeyCode,
    copyKeyCode,
    pastedElementsDeviation,
    pasteKeyCode,
    connectionLineType,
    maxZoom,
    minZoom,
    nodeTypes,
} from './config';
// Import Components
import { Button, Icon, Tooltip } from '@geneui/components';
import { ConfirmationModal } from 'components';
import EditModal from './EditModal';
// Import Actions
import { CustomerJourneyAction } from 'actions';
// Import Services
import {
    getLayoutedElements,
    getElementId,
    propsCollector,
    edgeBuilder,
    isGraphCyclic,
    setAutomapOptionsValues,
    newNodeNameGenerator,
    copySelectedElementsInClipboard,
    pasteElementsFromClipboard,
    cloneElements,
    setElementsLayoutPositionsElements,
    calculateTotalClientsRemoveCount,
    getTargetClientsData,
} from 'services/customerJourney';
// Import hooks
import { useToaster } from 'hooks';

const { warning, success } = AlertTypes;
const { incrementBlockCount, decrementBlockCount, resetBlocksCount, setBlocksCounts } = CustomerJourneyAction;

const FlowEditor = forwardRef(
    (
        {
            pushElementsUpdate,
            initialElements,
            readOnly,
            elementsCorrection,
            onSelectionChange,
            customActions,
            customerJourneyStatus: _customerJourneyStatus,
        },
        cjPageRef,
    ) => {
        const { t } = useTranslation();
        const { settings } = useSelector((state) => state.header);
        const { ActivePartner } = settings;
        const { blocksCounts, maxBlocksCounts } = useSelector((state) => state.customerJourney);
        const customerJourneyBlocksCountsConfigRef = useRef({ blocksCounts, maxBlocksCounts });

        useEffect(() => {
            customerJourneyBlocksCountsConfigRef.current = { blocksCounts, maxBlocksCounts };
        }, [blocksCounts, maxBlocksCounts]);

        const { showToaster } = useToaster();
        const dispatch = useDispatch();

        const setSelectedElements = useStoreActions((actions) => actions.setSelectedElements);

        const initialElementsRef = useRef(isNil(initialElements) ? [] : cloneDeep(initialElements));
        const pastedCount = useRef(0);
        const reactFlowWrapper = useRef(null);
        const reactFlowRef = useRef(null);
        const selectedElements = useRef(null);

        // Vars for layout functional
        const [layoutDirection, setLayoutDirection] = useState(layoutDirections.LR);
        const layoutDirectionRef = useRef(layoutDirection);

        // Vars for entire flow editor
        const [flowEditorInstance, setFlowEditorInstance] = useState(null);

        const [shouldFitView, setShouldFitView] = useState(false);

        const [elements, setElements] = useState([]);
        const elementsRef = useRef(elements);
        const snapshots = useRef({ flowSnapshots: [], currentVisibleFlowVersion: -1 });

        const changeBlocksCount = (elements) => {
            const _blocksCounts = {};
            elements.forEach((el) => {
                if (isNode(el)) {
                    const { type } = el;
                    _blocksCounts[type] = isUndefined(_blocksCounts[type]) ? 1 : ++_blocksCounts[type];
                }
            });
            dispatch(setBlocksCounts(_blocksCounts));
        };

        // TODO: move to service delete from redux
        const changeVisibleFlowVersion = (payload) => {
            if (payload >= 0 && payload < customerJourneyFlowEditorUndoRedoMaxCount) {
                snapshots.current.currentVisibleFlowVersion = payload;

                SetElements(
                    () => {
                        const newElements = cloneDeep(
                            snapshots.current.flowSnapshots[snapshots.current.currentVisibleFlowVersion],
                        );
                        changeBlocksCount(newElements);
                        return newElements;
                    },
                    true,
                    false,
                );
            }
        };

        const updateSnapshots = (blocksCounts, isAllClientsCounts, isFiltered) => {
            initialElementsRef.current.forEach((element) => {
                updateElementClientsCount(
                    initialElementsRef.current,
                    element,
                    blocksCounts,
                    isAllClientsCounts,
                    isFiltered,
                );
            });

            snapshots.current.flowSnapshots.forEach((elements) => {
                elements.forEach((element) => {
                    updateElementClientsCount(elements, element, blocksCounts, isAllClientsCounts, isFiltered);
                });
            });
        };

        const addSnapshot = (payload) => {
            if (snapshots.current.flowSnapshots.length >= customerJourneyFlowEditorUndoRedoMaxCount) {
                snapshots.current.flowSnapshots.shift();
            }
            snapshots.current.flowSnapshots.push(payload);
            snapshots.current.currentVisibleFlowVersion = snapshots.current.flowSnapshots.indexOf(payload);
        };

        const clearSnapshots = () => {
            snapshots.current.flowSnapshots = [];
            snapshots.current.currentVisibleFlowVersion = -1;
        };

        // Vars for delete logic
        const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
        const [elementsToRemove, setElementsToRemove] = useState([]);

        // Vars for edit logic
        const [isEditModalOpened, setIsEditModalOpened] = useState(false);
        const [editableBlock, setEditableBlock] = useState(null);

        // Vars for connecting state
        const [isConnectionInProgress, setIsConnectionInProgress] = useState(false);

        // Start undo redo functional implementation
        const [isDisabledUndo, setIsDisabledUndo] = useState(true);
        const [isDisabledRedo, setIsDisabledRedo] = useState(true);

        const undo = () => {
            changeVisibleFlowVersion(snapshots.current.currentVisibleFlowVersion - 1);
        };

        const redo = () => {
            changeVisibleFlowVersion(snapshots.current.currentVisibleFlowVersion + 1);
        };
        // End undo redo functional implementation

        // Start delete elements logic implementation
        const deleteModalCloseHandler = () => {
            setIsDeleteModalOpened(false);
        };

        useEffect(() => {
            if (!isNil(flowEditorInstance)) {
                flowEditorInstance.fitView();
            }
        }, [shouldFitView]);

        const removeElementsClickHandler = () => {
            SetElements(
                (els) => removeElements(elementsToRemove, els),
                true,
                true,
                layoutDirectionRef.current,
                {
                    type: FlowEditorElementsSetterActionTypes.RemoveElements,
                    elementsToRemove,
                },
                true,
            );

            deleteModalCloseHandler();

            const _blocksCounts = {};
            elementsToRemove.forEach((el) => {
                if (isNode(el)) {
                    const { type } = el;
                    _blocksCounts[type] = isUndefined(_blocksCounts[type]) ? 1 : ++_blocksCounts[type];
                }
            });
            dispatch(decrementBlockCount(_blocksCounts));
        };

        const getBlocksDeleteDataList = (elementsToRemove) => {
            const allowDataList = [];
            const notAllowDataList = [];
            if (!isNil(elementsToRemove)) {
                elementsToRemove
                    .filter((item) => isNode(item))
                    .forEach((item) => {
                        if (!isNil(item?.data?.allClientsCounts)) {
                            allowDataList.push({
                                name: item?.data?.name,
                            });
                        } else {
                            notAllowDataList.push({
                                name: item?.data?.name,
                            });
                        }

                        return {
                            name: item?.data?.name,
                            hasData: !isNil(item?.data?.allClientsCounts),
                        };
                    });
            }
            return { allowDataList, notAllowDataList };
        };

        const getQuestionLabel = (elementsToRemove) => {
            const hasBlock = !isNil(find(elementsToRemove, isNode));

            if (hasBlock) {
                return getBlocksDeleteDataList(elementsToRemove).allowDataList.length > 0
                    ? l.DeleteSelectedBlocksWarningConfirmationText
                    : l.DeleteSelectedBlocksConfirmationText;
            } else {
                return l.DeleteSelectedLinesConfirmationText;
            }
        };

        const onElementsRemove = (elementsToRemove) => {
            const index = elementsToRemove.findIndex((item) => isNode(item) && item?.data?.isDeletable === false);
            if (index === -1) {
                setIsDeleteModalOpened(true);
                setElementsToRemove(elementsToRemove);
            } else {
                showToaster(warning, t(l.HasUndeletableItemsMessage));
            }
        };
        // End delete elements logic implementation

        // Start connection edges logic implementation
        const onConnectStart = () => {
            setIsConnectionInProgress(true);
        };

        const onConnect = ({ source, target }) => {
            const newEdge = edgeBuilder(source, target);

            let tmpElements = cloneDeep(elements);
            tmpElements = addEdge(cloneDeep(newEdge), tmpElements);

            if (isGraphCyclic(tmpElements)) {
                showToaster(warning, t(l.GraphIsCyclicMessage));
                return;
            }

            SetElements((els) => addEdge(newEdge, els));
        };

        const onConnectEnd = () => {
            setIsConnectionInProgress(false);
            if (!isNil(flowEditorInstance)) {
                flowEditorInstance.fitView();
            }
        };
        // End connection edges logic implementation

        // Start edit block logic implementation
        const editBlock = (node) => {
            if (!isNode(node)) return;
            setEditableBlock(node);
            setIsEditModalOpened(true);
        };

        const cloneBlock = (node) => {
            if (!isNode(node)) return;

            pastedCount.current++;
            const clonedElements = cloneElements(
                [node],
                elementsRef.current,
                ActivePartner?.PartnerId,
                customerJourneyBlocksCountsConfigRef.current,
                (errorMessage) => {
                    showToaster(warning, t(errorMessage));
                    // eslint-disable-next-line no-console
                    console.error('Cant clone elements', errorMessage);
                },
            );
            if (!isNil(clonedElements)) {
                addElements(clonedElements, 1);
            }
        };

        const editModalSaveHandler = (updatedNode) => {
            SetElements((prev) => {
                return prev.map((node) => {
                    if (node.id === updatedNode.id) {
                        Object.keys(updatedNode)
                            .filter((key) => key in node)
                            .forEach((key) => {
                                node[key] = updatedNode[key];
                            });
                    }
                    return node;
                });
            }, false);
            setIsEditModalOpened(false);
        };

        const onEditModalCancelHandler = () => {
            // elementsRef.current.elements.forEach((node, index) => {
            //     if (node.id === prevNode.id) {
            //         elementsRef.current.elements[index] = prevNode;
            //     }
            //     return node;
            // });

            // setIsInvokeAddSnapshot(false);
            // setElements(cloneDeep(elementsRef.current.elements));

            setIsEditModalOpened(false);
        };
        // End edit block logic implementation

        // Start dragging logic implementation
        const onDragOver = (event) => {
            event.preventDefault();
            event.dataTransfer.dropEffect = 'move';
        };

        const onDrop = (event) => {
            event.preventDefault();
            const transferredData = event.dataTransfer.getData('application/reactflow');
            if (isEmpty(transferredData)) {
                return;
            }
            const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
            const { type, titleKey, blockTitleLabel, defaultIcon, cssClassName, metaData } =
                JSON.parse(transferredData);

            const position = flowEditorInstance.project({
                x: event.clientX - reactFlowBounds.left,
                y: event.clientY - reactFlowBounds.top,
            });

            const id = getElementId();

            const newNode = {
                id,
                type,
                position,
                data: {
                    readOnly,
                    defaultIcon,
                    cssClassName,
                    blockType: titleKey,
                    actions: {
                        editNameAction: editBlockName,
                        deleteAction: onElementsRemove,
                        editAction: editBlock,
                        cloneAction: cloneBlock,
                    },
                    name: newNodeNameGenerator(t, t(blockTitleLabel), elementsRef.current),
                    metaData,
                    apiModel: {},
                    isCloneable: type !== CustomerJourneyGroupBlockTypes.target,
                },
            };

            SetElements((es) => es.concat(newNode));

            dispatch(
                incrementBlockCount({
                    [type]: 1,
                }),
            );
        };

        const onNodeDragStop = (_e, node) => {
            SetElements(
                (els) => {
                    els[findIndex(els, { id: node.id })] = node;
                    return [...els];
                },
                false,
                false,
            );
        };
        // End dragging logic implementation

        // Start flow layout functional
        const onLayout = useCallback(
            (direction) => {
                SetElements((prev) => prev, true, false, direction);
                setLayoutDirection(() => {
                    layoutDirectionRef.current = direction;
                    return direction;
                });
            },
            [elements],
        );

        // End flow layout functional

        // Start init logic implementation
        const onLoad = (editorInstance) => {
            setFlowEditorInstance(editorInstance);
        };

        const editBlockName = (updatedNode) => {
            SetElements((prev) => {
                return prev.map((node) => {
                    if (node.id === updatedNode.id) {
                        node.data.name = newNodeNameGenerator(t, updatedNode.data.name, prev, updatedNode);
                    }
                    return node;
                });
            }, false);
        };

        const resetFlowEditor = () => {
            clearSnapshots();
            layoutDirectionRef.current = layoutDirections.LR;
            setLayoutDirection(() => {
                return layoutDirectionRef.current;
            });

            SetElements(
                () => cloneDeep(initialElementsRef.current),
                true,
                true,
                layoutDirectionRef.current,
                null,
                true,
            );
            changeBlocksCount(cloneDeep(initialElementsRef.current));

            setIsDisabledRedo(true);
            setIsDisabledUndo(true);
        };

        const updateElementClientsCount = (
            allElements,
            element,
            blocksClientsCount,
            isAllClientsCounts,
            isFiltered,
        ) => {
            if (isNode(element) && has(blocksClientsCount, element.id)) {
                if (isAllClientsCounts) {
                    element.data.allClientsCounts = blocksClientsCount[element.id].OutputModelsCount;
                }

                if (!isFiltered || !isAllClientsCounts) {
                    const { targetClientsCount, targetClientsRemoveCount } = getTargetClientsData(
                        blocksClientsCount,
                        allElements,
                    );

                    element.data.currentClientsCounts = blocksClientsCount[element.id].OutputModelsCount;
                    element.data.totalClientsCount = blocksClientsCount[element.id]?.TotalModelsCount;
                    element.data.totalClientsRemoveCount = calculateTotalClientsRemoveCount(
                        element,
                        allElements,
                        blocksClientsCount,
                    );
                    element.data.removeClientsCounts = blocksClientsCount[element.id]?.RemovedModelsCount;
                    element.data.targetClientsCount = targetClientsCount;
                    element.data.targetClientsRemoveCount = targetClientsRemoveCount;
                }
                element.data.isFiltered = isFiltered;
            }
        };

        // Handle case when CJ page want to update initial elements
        useImperativeHandle(cjPageRef, () => ({
            deleteAction(args) {
                onElementsRemove(args);
            },
            editAction(args) {
                editBlock(args);
            },
            cloneAction(args) {
                cloneBlock(args);
            },
            editNameAction(args) {
                editBlockName(args);
            },
            resetFlowEditor() {
                resetFlowEditor();
            },
            setInitialElements(elements) {
                initialElementsRef.current = cloneDeep(elements);
            },
            setClientsCountsByBlock(blocksCounts, isAllClientsCounts = false, isFiltered = false) {
                updateSnapshots(blocksCounts, isAllClientsCounts, isFiltered);

                SetElements(
                    (els) =>
                        els.map((element) => {
                            updateElementClientsCount(els, element, blocksCounts, isAllClientsCounts, isFiltered);
                            return element;
                        }),
                    false,
                    false,
                );
            },
            setBlockCountCalculationTypes(clientsCountType, showRemovedClientsCount) {
                SetElements(
                    (els) =>
                        els.map((element) => {
                            if (isNode(element)) {
                                element.data.clientsCountType = clientsCountType;
                                element.data.showRemovedClientsCount = showRemovedClientsCount;
                            }
                            return element;
                        }),
                    false,
                    false,
                );
            },
        }));
        // End init logic implementation

        // Start selection change logic
        const onSelectionChangeHandler = (selected) => {
            if (readOnly) {
                // It means selected block can't be more then one
                onSelectionChange(first(selected));
            } else {
                // Otherwise pass array of selected blocks
                selectedElements.current = selected;
                onSelectionChange(selected);
            }
        };
        // End selection change logic

        const cleanUp = () => {
            return () => {
                SetElements(() => []);
                clearSnapshots();
                dispatch(resetBlocksCount());
            };
        };

        const addElements = ({ nodes, edges }, deviationMultiplier = pastedCount.current) => {
            const tmpNodes = [];
            nodes.forEach((item) => {
                const tmpNode = {
                    ...item,
                    position: {
                        x: item.position.x + deviationMultiplier * pastedElementsDeviation,
                        y: item.position.y + deviationMultiplier * pastedElementsDeviation,
                    },
                    data: {
                        ...item.data,
                        name: newNodeNameGenerator(t, item.data.name, [...elementsRef.current, ...tmpNodes]),
                        allClientsCounts: null,
                        currentClientsCounts: null,
                        actions: {
                            editNameAction: editBlockName,
                            deleteAction: onElementsRemove,
                            editAction: editBlock,
                            cloneAction: cloneBlock,
                        },
                    },
                };
                tmpNodes.push(tmpNode);
            });

            SetElements((prev) => {
                let tmpPrevElements = prev.concat([...tmpNodes]);
                edges.forEach((item) => {
                    tmpPrevElements = addEdge(edgeBuilder(item.source, item.target), tmpPrevElements);
                });
                changeBlocksCount(tmpPrevElements);
                return cloneDeep(tmpPrevElements);
            }, false);
            setSelectedElements(tmpNodes);
        };

        const keydownHandler = (e) => {
            if (e.keyCode === copyKeyCode && e.ctrlKey) {
                copySelectedElementsInClipboard(selectedElements.current, elementsRef.current, ActivePartner?.PartnerId)
                    .then((message) => {
                        showToaster(success, t(message));
                    })
                    .catch((error) => {
                        showToaster(warning, t(error));
                    });
                pastedCount.current = 0;
            }

            if (e.keyCode === pasteKeyCode && e.ctrlKey) {
                pastedCount.current++;
                pasteElementsFromClipboard(ActivePartner?.PartnerId, customerJourneyBlocksCountsConfigRef.current)
                    .then(addElements)
                    .catch((message) => {
                        showToaster(warning, t(message));
                    });
            }
        };
        useEffect(() => {
            SetElements(() => initialElements, true, true, layoutDirectionRef.current, null, true);
        }, []);

        useEffect(() => {
            if (!readOnly && !isNil(reactFlowRef.current)) {
                reactFlowRef.current.addEventListener('keydown', keydownHandler);

                return () => {
                    reactFlowRef.current.removeEventListener('keydown', keydownHandler);
                };
            }
        }, [reactFlowRef.current]);

        useEffect(() => {
            if (!isNil(flowEditorInstance)) {
                flowEditorInstance.fitView();
            }
        }, [flowEditorInstance, layoutDirection]);

        useEffect(cleanUp, []);

        const [data, setData] = useState();

        useEffect(() => {
            if (!isNil(data)) {
                const { isAddSnapshot, elements } = data;
                if (isAddSnapshot) {
                    addSnapshot(cloneDeep(elements));
                }
                setIsDisabledRedo(
                    snapshots.current.currentVisibleFlowVersion === snapshots.current.flowSnapshots.length - 1,
                );
                setIsDisabledUndo(snapshots.current.currentVisibleFlowVersion === 0);

                if (!isNil(elements)) {
                    elements.forEach((el) => {
                        if (isNode(el)) {
                            el.data.collectedProperties = propsCollector(el, elements);
                            setAutomapOptionsValues(el, elements);
                        }
                    });
                }

                pushElementsUpdate(elements);
            }
        }, [data]);

        const SetElements = (
            setElementsFunc,
            isLayout = true,
            isAddSnapshot = true,
            _layoutDirection = layoutDirectionRef.current,
            elementsCorrectionParams = { type: FlowEditorElementsSetterActionTypes.None, params: null }, // additional params for correction, type of { type: , params: }
            isLayoutNonConnected = false,
        ) => {
            setElements((prev) => {
                let tmpElements = setElementsFunc(prev);
                if (isLayout) {
                    tmpElements = getLayoutedElements(tmpElements, _layoutDirection, isLayoutNonConnected);
                    if (isLayoutNonConnected) {
                        setShouldFitView(!shouldFitView);
                    }
                } else {
                    setElementsLayoutPositionsElements(tmpElements, _layoutDirection);
                }
                elementsCorrection(tmpElements, elementsCorrectionParams); // it's not return elements, its correction elements with ref
                elementsRef.current = tmpElements;

                setData({ isAddSnapshot, elements: tmpElements }); // this is fix when react setElements calling 2 times
                return elementsRef.current;
            });
        };

        const eventHandlersProps = {
            onSelectionChange: onSelectionChangeHandler,
        };
        const interactionProps = {};

        if (!readOnly) {
            // Event Handlers Props
            eventHandlersProps.onElementsRemove = onElementsRemove;
            eventHandlersProps.onConnectStart = onConnectStart;
            eventHandlersProps.onConnectEnd = onConnectEnd;
            eventHandlersProps.onConnect = onConnect;
            eventHandlersProps.onDrop = onDrop;
            eventHandlersProps.onDragOver = onDragOver;
            eventHandlersProps.onNodeDragStop = onNodeDragStop;
            // Interaction Props
            interactionProps.deleteKeyCode = deleteKeycode;
            interactionProps.multiSelectionKeyCode = ctrlKeyCode;
        }

        return (
            <div
                className={classNames('crm-flow-editor-wrapper', {
                    'crm-flow-editor-connection-state': isConnectionInProgress,
                    'crm-flow-editor-horizontal-layout': layoutDirection === layoutDirections.LR,
                    'crm-flow-editor-vertical-layout': layoutDirection === layoutDirections.TB,
                    'crm-flow-editor-read-only': readOnly,
                })}
                ref={reactFlowWrapper}
            >
                <ReactFlow
                    tabIndex={0}
                    maxZoom={maxZoom}
                    minZoom={minZoom}
                    elements={elements}
                    nodeTypes={nodeTypes}
                    connectionLineType={connectionLineType}
                    onLoad={onLoad}
                    nodesDraggable={!readOnly}
                    nodesConnectable={!readOnly}
                    {...eventHandlersProps}
                    {...interactionProps}
                    ref={reactFlowRef}
                >
                    <Controls
                        showInteractive={false}
                        className={classNames('crm-flow-editor-controls', { 'read-only': readOnly })}
                    >
                        {!readOnly && (
                            <div className="crm-flow-editor-controls-undo-redo-panel">
                                <ControlButton onClick={undo} className={classNames({ disabled: isDisabledUndo })}>
                                    <Icon type="bc-icon-undo1" />
                                </ControlButton>
                                <ControlButton onClick={redo} className={classNames({ disabled: isDisabledRedo })}>
                                    <Icon type="bc-icon-redo1" />
                                </ControlButton>
                            </div>
                        )}
                    </Controls>

                    <div className="crm-flow-editor-layout-controls">
                        {customActions}

                        <Tooltip text={t(l.IconSchemeHorizontal)}>
                            <Button
                                icon="bc-icon-scheme-horizontal"
                                className={classNames({
                                    active: layoutDirection === layoutDirections.LR,
                                    'new-section': true,
                                })}
                                onClick={() => onLayout(layoutDirections.LR)}
                            />
                        </Tooltip>
                        <Tooltip text={t(l.IconSchemeVertical)}>
                            <Button
                                icon="bc-icon-scheme-vertical"
                                className={classNames({ active: layoutDirection === layoutDirections.TB })}
                                onClick={() => onLayout(layoutDirections.TB)}
                            />
                        </Tooltip>
                    </div>

                    <Background variant="lines" gap={20} size={0.5} className="crm-cj-flow-editor-background" />
                </ReactFlow>

                {isDeleteModalOpened && (
                    <ConfirmationModal
                        onOk={removeElementsClickHandler}
                        onCancel={deleteModalCloseHandler}
                        isLoading={false}
                        isVisibleModal={isDeleteModalOpened}
                        titleText={
                            !isNil(find(elementsToRemove, isNode))
                                ? t(l.DeleteSelectedBlocks)
                                : t(l.DeleteSelectedLines)
                        }
                        questionLabel={getQuestionLabel(elementsToRemove)}
                        iconType="bc-icon-delete-2"
                        actionLabel={t(l.Delete)}
                        className={ModalsClassNames.Delete}
                        isBulkAction={false}
                        {...getBlocksDeleteDataList(elementsToRemove)}
                    />
                )}

                {isEditModalOpened && (
                    <EditModal
                        block={editableBlock}
                        onOk={editModalSaveHandler}
                        onCancel={onEditModalCancelHandler}
                        isLoading={false}
                        isVisibleModal={isEditModalOpened}
                        elements={elementsRef.current}
                    />
                )}
            </div>
        );
    },
);

FlowEditor.displayName = 'FlowEditor';

FlowEditor.propTypes = {
    initialElements: PropTypes.array,
    pushElementsUpdate: PropTypes.func,
    elementsCorrection: PropTypes.func,
    readOnly: PropTypes.bool,
    onSelectionChange: PropTypes.func,
    customActions: PropTypes.node,
    customerJourneyStatus: PropTypes.string,
};

FlowEditor.defaultProps = {
    initialElements: [],
    readOnly: false,
    pushElementsUpdate: noop,
    elementsCorrection: noop,
    onSelectionChange: noop,
    customActions: <></>,
};

export default memo(FlowEditor);
