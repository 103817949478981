import React, { FC, memo, useMemo, useState } from 'react';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Constants
import l from 'constants/localizationLabels';
import { AlertTypes } from 'constants/common';
import { ABTestStatusEnum } from 'constants/abTest';
// Import Helpers
import { checkIfNowInDaysPeriod } from './helpers';
// Import Services
import { ABTestingHttpService } from 'services/http';
// Import Components
import { Alert, Button, Icon, Modal, Tooltip } from '@geneui/components';
import { ButtonWithLoader } from 'components';
// Import Styles
import styles from './StopABTestButtonWithConfirmationModal.module.scss';

const { success } = AlertTypes;

interface Props {
    buttonType?: 'icon' | 'text';
    campaignId?: string | null;
    abTestStatus: ABTestStatusEnum | null;
    abTestStartDate?: string | null;
    analyticsPeriod?: number | null;
    onStopCompleted?: () => void;
}

const StopABTestButtonWithConfirmationModal: FC<Props> = (props) => {
    const {
        buttonType = 'text',
        campaignId,
        abTestStatus,
        abTestStartDate,
        analyticsPeriod = 0,
        onStopCompleted,
    } = props;

    const { t } = useTranslation();
    const { showToaster } = useToaster();
    const { doPostRequest } = useRequest();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isStopABTestLoading, setIsStopABTestLoading] = useState(false);

    const { stopCampaignABTestRequest } = useMemo(
        () => ({
            stopCampaignABTestRequest: ABTestingHttpService.stopCampaignABTest(),
        }),
        [],
    );

    const isABTestInProgress = abTestStatus === ABTestStatusEnum.InProgress;
    if (!isABTestInProgress) {
        return null;
    }

    const shouldStopWithFailedStatus =
        abTestStatus === ABTestStatusEnum.InProgress &&
        !isNil(abTestStartDate) &&
        checkIfNowInDaysPeriod(abTestStartDate, analyticsPeriod || 0);

    const handleConfirmABTestStop = () => {
        setIsStopABTestLoading(true);
        doPostRequest(stopCampaignABTestRequest.request, {
            queryString: { campaignId },
            successCallback: () => {
                if (shouldStopWithFailedStatus) {
                    showToaster(success, t(l.TheABTestWasStoppedWithFailedStatus));
                } else {
                    showToaster(success, t(l.TheABTestWasStopped));
                }
                handleCloseModal();
                onStopCompleted?.();
            },
        })?.then(() => {
            setIsStopABTestLoading(false);
        });
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className={styles.container}>
            {buttonType === 'icon' ? (
                <Tooltip text={t(l.StopABTest)}>
                    <Button
                        color="primary"
                        flexibility="default"
                        appearance="minimal"
                        icon="bc-icon-test-stop"
                        onClick={handleOpenModal}
                        disabled={isStopABTestLoading}
                        children={undefined}
                    />
                </Tooltip>
            ) : (
                <Button
                    color="danger"
                    flexibility="default"
                    appearance="default"
                    onClick={handleOpenModal}
                    disabled={isStopABTestLoading}
                >
                    {t(l.StopABTest)}
                </Button>
            )}

            <Modal
                withPortal={true}
                background="dark-background"
                position="top"
                title={t(l.StopABTest)}
                size="content-size"
                className="crm-modal"
                appearance="compact"
                closable={false}
                closeOnClickOutside={true}
                visible={isModalOpen}
                footer={
                    <>
                        <Button flexibility="default" color="default" appearance="minimal" onClick={handleCloseModal}>
                            {t(l.Cancel)}
                        </Button>
                        <ButtonWithLoader
                            flexibility="default"
                            color="danger"
                            onClick={handleConfirmABTestStop}
                            isLoading={isStopABTestLoading}
                            isDisabled={isStopABTestLoading}
                        >
                            {t(l.Stop)}
                        </ButtonWithLoader>
                    </>
                }
            >
                <div className="confirmation-modal-warning-icon-container">
                    <div className="crm-circle-icon-cnt">
                        <Icon type="bc-icon-test-stop" />
                    </div>
                    <div className="crm-allowd-not-allowd-cnt">{t(l.AreYouSureYouWantToStopTheABTest)}</div>
                </div>
                {shouldStopWithFailedStatus && (
                    <Alert
                        className="pre-save-warning-alert"
                        closable="false"
                        title={t(l.WarningMessage)}
                        message={t(l.TheABTestWillBeFailedAnalyticPeriodNotPassed)}
                        type="warning"
                        iconProps={{ type: 'bc-icon-info' }}
                    />
                )}
            </Modal>
        </div>
    );
};

export default memo(StopABTestButtonWithConfirmationModal);
