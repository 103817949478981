import React, { memo, useEffect, useState, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isArray, isEqual, isNil, sortBy } from 'lodash';
// Import UI Components
import { Modal, Button, BusyLoader } from '@geneui/components';
// Import Components
import { FromToBlocks } from 'components';
// Import Constants
import { l } from 'constants/common';
// Import Services
import { CustomerJourneyHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import SCSS
import 'assets/scss/columnsChooser.scss';

const CJColumnsChooser = ({
    modalOpenedState,
    setModalOpenedState,
    workflowId,
    columns,
    setColumns,
    left,
    right,
    getCLientList,
    blocksData,
    clientBlockId,
}) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();
    // Segment fetch part
    const { saveBlockReportConfigRequest } = useRef({
        saveBlockReportConfigRequest: CustomerJourneyHttpService.saveBlockReportConfig(),
    }).current;

    const [isSaveActive, setIsSaveActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingTextLabel, setLoadingTextLabel] = useState(l.Loading);

    const [leftBlockList, setLeftBlockList] = useState(null);
    const [rightBlockList, setRightBlockList] = useState(null);
    const fromToBlocksRef = useRef();

    const columnsDataRef = useRef();

    useEffect(() => {
        if (!isArray(columns)) return;
        setIsSaveActive(!isEqual(createResultColumns(right), columns));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    const getRequestColumns = (columns) => {
        return columns.map(({ PropertyId, BlockName }) => ({
            BlockName,
            PropertyId,
        }));
    };

    const onSaveHandler = () => {
        setLoadingTextLabel(l.Saving);
        setIsLoading(true);
        setIsSaveActive(false);
        const requestBody = {
            workflowId,
            BlockName: clientBlockId,
            Config: getRequestColumns(columns),
        };
        doPostRequest(saveBlockReportConfigRequest.request, {
            requestBody,
            successCallback: () => {
                setModalOpenedState(false);
                setIsLoading(false);
                getCLientList();
            },
        });
    };

    const onResetHandler = () => {
        fromToBlocksRef.current.reset();
    };

    const onCancelHandler = () => {
        setModalOpenedState(false);
    };

    const getCustomFooter = () => {
        return (
            <>
                <Button appearance="minimal" onClick={onCancelHandler}>
                    {t(l.Close)}
                </Button>
                <Button disabled={!isSaveActive} appearance="minimal" onClick={onResetHandler}>
                    {t(l.Reset)}
                </Button>

                <Button disabled={!isSaveActive} onClick={onSaveHandler}>
                    {t(l.Save)}
                </Button>
            </>
        );
    };

    useImperativeHandle(columnsDataRef, () => ({
        reset: fromToBlocksRef.current?.reset,
        setInitialData(rightColumns, leftColumns) {
            if (!isNil(fromToBlocksRef.current?.setInitialBlocksData)) {
                fromToBlocksRef.current.setInitialBlocksData(
                    rightColumns.map(mapToLocalModel),
                    leftColumns.map(mapToLocalModel),
                );
            }
        },
    }));

    const mapToLocalModel = (item) => ({
        ...item,
        isVisible: true,
        isChecked: false,
        description: item.Description,
        childrenList: isNil(item.childrenList)
            ? []
            : item.childrenList.map((row) => ({ ...row, isChecked: false, isVisible: true })),
    });

    const createResultColumns = (data) => {
        const resultData = [];
        data.forEach(({ name, childrenList }) => {
            const blockName = blocksData.find((r) => r.DisplayName === name)?.Name;
            childrenList.forEach((item) => {
                resultData.push({
                    BlockName: blockName,
                    PropertyId: item.id,
                    index: item.index,
                    Type: item.Type,
                    DataKey: `${name}_${item.ColumnName}`,
                    ColumnName: blockName === clientBlockId ? t(item.ColumnName) : `${t(item.ColumnName)}/${name}`,
                });
            });
        });
        return sortBy(resultData, ['index']);
    };

    const getFromToBlockUpdates = ({ rightBlockList, leftBlockList }) => {
        setLeftBlockList(leftBlockList?.map(mapToLocalModel));
        setRightBlockList(rightBlockList?.map(mapToLocalModel));
        setColumns(createResultColumns(rightBlockList));
    };

    const init = () => {
        setLeftBlockList(left?.map(mapToLocalModel));
        setRightBlockList(right?.map(mapToLocalModel));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, [columns]);

    return (
        <div>
            {modalOpenedState && (
                <Modal
                    title={t(l.ColumnsChooser)}
                    className="d-a-d-popup"
                    background="dark-background"
                    closable={true}
                    position="center"
                    onCancel={onCancelHandler}
                    cancelText={t(l.Reset)}
                    okText={t(l.Save)}
                    closeOnClickOutside={true}
                    visible={modalOpenedState}
                    size="content-size"
                    footer={getCustomFooter()}
                    withPortal={true}
                >
                    <BusyLoader
                        isBusy={isLoading || isNil(leftBlockList) || isNil(rightBlockList)}
                        type="spinner"
                        loadingText={t(loadingTextLabel)}
                    >
                        {!isLoading && !isNil(leftBlockList) && !isNil(rightBlockList) && (
                            <FromToBlocks
                                leftBlockList={leftBlockList}
                                rightBlockList={rightBlockList}
                                leftBlockWithParent={true}
                                rightBlockWithParent={false}
                                getUpdate={getFromToBlockUpdates}
                                leftBlockWithPosition={false}
                                rightBlockWithPosition={true}
                                leftBlockTitle={t(l.NotSelected)}
                                rightBlockTitle={t(l.Selected)}
                                ref={fromToBlocksRef}
                            />
                        )}
                    </BusyLoader>
                </Modal>
            )}
        </div>
    );
};

CJColumnsChooser.propTypes = {
    workflowId: PropTypes.string.isRequired,
    modalOpenedState: PropTypes.bool.isRequired,
    setModalOpenedState: PropTypes.func.isRequired,
    left: PropTypes.array.isRequired,
    right: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    setColumns: PropTypes.func.isRequired,
    getCLientList: PropTypes.func.isRequired,
    blocksData: PropTypes.array.isRequired,
    clientBlockId: PropTypes.string,
};
CJColumnsChooser.defaultProps = {
    clientBlockId: '',
};

export default memo(CJColumnsChooser);
