import React, { memo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isNil, noop } from 'lodash';
// Import hooks
import { useListBaseRequestBody, useGoToRoute, useRequest } from 'hooks';
// Import services
import { CustomerJourneyHttpService } from 'services/http';
// Import components
import PredefinedListMenu from './PredefinedListMenu';
import { CustomerJourneyPredefinedInfoModal } from 'components';
// Import constants
import { ModalsActions } from 'constants/common';
import { PredefinedListMenuRequestModel } from './constants';
import { RoutesList } from 'routes';

const { REALTIME_JOURNEY_PREDEFINED } = RoutesList;

const PredefinedCustomerJourneyListMenu = ({
    handleClose,
    predefinedInfoModalVisibleState,
    setPredefinedInfoModalVisibleState,
}) => {
    const { Filters, Paging, Sorting } = PredefinedListMenuRequestModel.journeys;
    const { goToRoute } = useGoToRoute();
    const { doPostRequest } = useRequest();
    const [selectedInfoItem, setSelectedInfoItem] = useState(null);
    const [baseRequestBody] = useListBaseRequestBody(Filters, Paging, Sorting);

    const { getCustomerJourneysRequest } = useRef({
        getCustomerJourneysRequest: CustomerJourneyHttpService.getCustomerJourneyList(),
    }).current;

    const handleInfoItemClick = (item) => {
        setSelectedInfoItem(item);
        setPredefinedInfoModalVisibleState(true);
    };

    const handleListItemClick = (item) => {
        const { WorkflowId } = item;
        const params = { workflowId: WorkflowId };

        goToRoute(REALTIME_JOURNEY_PREDEFINED, params);
        handleClose();
    };

    const modalCloseHandler = (action) => {
        if (action !== ModalsActions.CLOSE) {
            handleClose();
        }
        setPredefinedInfoModalVisibleState(false);
    };

    const getPredefinedCustomerJourneyList = () => {
        return doPostRequest(getCustomerJourneysRequest.request, { requestBody: baseRequestBody });
    };

    const cleanUp = () => {
        return () => {
            setSelectedInfoItem(null);
            setPredefinedInfoModalVisibleState(false);
            getCustomerJourneysRequest.cancel('PredefinedCustomerJourneyListMenu:getCustomerJourneysRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <>
            {predefinedInfoModalVisibleState && !isNil(selectedInfoItem) && (
                <CustomerJourneyPredefinedInfoModal
                    workflowId={selectedInfoItem.WorkflowId}
                    handleClose={modalCloseHandler}
                    isVisible={predefinedInfoModalVisibleState}
                    isPredefined={true}
                />
            )}

            <PredefinedListMenu
                infoItemClickHandler={handleInfoItemClick}
                listItemClickHandler={handleListItemClick}
                getList={getPredefinedCustomerJourneyList}
            />
        </>
    );
};

PredefinedCustomerJourneyListMenu.propTypes = {
    handleClose: PropTypes.func,
    predefinedInfoModalVisibleState: PropTypes.bool.isRequired,
    setPredefinedInfoModalVisibleState: PropTypes.func.isRequired,
};

PredefinedCustomerJourneyListMenu.defaultProps = {
    handleClose: noop,
};

export default memo(PredefinedCustomerJourneyListMenu);
