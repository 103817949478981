import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// Import Components
import { ActivityKPI } from 'components';
import CustomerJourneyProgressData from './CustomerJourneyProgressData';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import { CampaignType, l } from 'constants/common';
// Import Services
import { CommunicationHttpService } from 'services/http';

const CustomerJourneyProgress = ({ workflowId }) => {
    const { doPostRequest } = useRequest();
    const [isLoadingWorkflowReport, setIsLoadingWorkflowReport] = useState(false);
    const [workflowKPIs, setWorkflowKPIs] = useState({ SendCount: 0, OpenCount: 0, ClickCount: 0 });
    const [workflowReportData, setWorkflowReportData] = useState(null);

    const { getWorkflowGeneralReportRequest } = useRef({
        getWorkflowGeneralReportRequest: CommunicationHttpService.getCampaignGeneralReport(),
    }).current;

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getWorkflowGeneralReportRequest.cancel('CustomerJourneyProgress:getWorkflowGeneralReportRequest');
        };
    };

    useEffect(() => {
        setIsLoadingWorkflowReport(true);
        const requestBody = {
            CampaignId: workflowId,
            CampaignType: CampaignType.WorkFlow,
        };
        doPostRequest(getWorkflowGeneralReportRequest.request, {
            requestBody,
            successCallback: ({ Data, TotalSent, TotalOpened, TotalClicked }) => {
                setWorkflowReportData(Data);
                setWorkflowKPIs({
                    SendCount: TotalSent,
                    OpenCount: TotalOpened,
                    ClickCount: TotalClicked,
                });
            },
        }).then(() => {
            setIsLoadingWorkflowReport(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workflowId]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <div className="details-page-wrapper c-j-report-grand-wrapper">
            <ActivityKPI isLoading={isLoadingWorkflowReport} data={workflowKPIs} title={l.CustomerJourneyKPIs} />
            <CustomerJourneyProgressData
                reportData={workflowReportData}
                isLoadingReport={isLoadingWorkflowReport}
                workflowId={workflowId}
            />
        </div>
    );
};

CustomerJourneyProgress.propTypes = {
    workflowId: PropTypes.string.isRequired,
};

export default memo(CustomerJourneyProgress);
