import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
// Import UI components
import { DataTable } from 'components';
// Import hooks
import { useRequest } from 'hooks';
// Import services
import { ExecutedReportHttpService } from 'services/http';
// Import configs
import { DataTableColumnsConfigs } from './HistoryInfoTabConfig';

let executedReportHistoryData = [];
let HistoryData = 0;

const HistoryInfoTab = ({ adHocReportResultId, reportType }) => {
    const { t } = useTranslation();
    const { doPostRequest } = useRequest();
    const [dataCount, setDataCount] = useState(HistoryData);
    const [isLoading, setIsLoading] = useState(false);

    const { reportId } = useParams();

    const { columns } = DataTableColumnsConfigs(t);

    const { getExecutedReportHistory } = useMemo(
        () => ({
            getExecutedReportHistory: ExecutedReportHttpService.getExecutedReportHistory(),
        }),
        [],
    );

    const cleanUp = () => {
        return () => {
            getExecutedReportHistory.cancel('HistoryInfoTab:getExecutedReportHistory');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    const executedReportHistory = useCallback(
        () => {
            setIsLoading(true);
            // TODO: change in from configs
            doPostRequest(getExecutedReportHistory.request, {
                requestBody: {
                    Filters: [
                        { Name: 'ExternalId1', Comparision: '0', Values: [reportType] },
                        { Name: 'ExternalId2', Comparision: '0', Values: [reportId] },
                        { Name: 'ExternalId3', Comparision: '0', Values: [adHocReportResultId] },
                        { Name: 'Source', Comparision: '0', Values: [5] },
                        { Name: 'Action', Comparision: '8', Values: [6, 7, 42] },
                    ],
                    Pageing: { PageSize: 1000, PageNumber: 1 },
                    Sorting: { Name: 'Date', Direction: 'asc' },
                },
                successCallback: (Data) => {
                    HistoryData = Data.Count;
                    setDataCount(HistoryData);
                    executedReportHistoryData = Data.Data;
                },
            }).then(() => setIsLoading(false));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [adHocReportResultId, getExecutedReportHistory, reportId],
    );

    useEffect(() => {
        executedReportHistory();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    const getTableColumns = () => {
        return columns;
    };

    const getTableRows = () => {
        return executedReportHistoryData.map(({ Date, UserName, Type, Action }, index) => {
            return {
                hasHover: true,
                dragDisable: true,
                data: {
                    index,
                    isRowOpend: false,
                    Date,
                    UserName,
                    Type,
                    Name: Action.Name,
                },
            };
        });
    };

    return (
        <div className="crm-section">
            <DataTable
                columnKey="dataKey"
                rowKey="index"
                data={getTableRows()}
                columns={getTableColumns()}
                isLoading={isLoading}
                withPagination={false}
                withPageSelector={false}
                isBackendPagination={false}
                dataCount={dataCount}
            />
        </div>
    );
};

HistoryInfoTab.propTypes = {
    adHocReportResultId: PropTypes.number.isRequired,
    reportType: PropTypes.string.isRequired,
};

export default memo(HistoryInfoTab);
