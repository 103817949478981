import React, { memo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import UI Components
import { Dropdown, ExtendedInput, Tooltip, Icon, Checkbox } from '@geneui/components';
// Import Components
import { AccessControl, ValidatableDatePickerInput } from 'components';
// Import Hooks
import { useRequest } from 'hooks';
// Import Constants
import { TemplateTypesLabels, l, PERMISSIONS, GoalDropdownOptions, MonthDayYearFormat } from 'constants/common';
// Import Services
import { UtilsHttpService } from 'services/http';
import Helpers from 'services/Helpers';

const { customMoment } = Helpers;

const CampaignGeneralDetails = ({
    selectedProvider,
    selectedGoalType,
    setSelectedProvider,
    setSelectedGoalType,
    setGoalCount,
    goalCount,
    setAnalysisDate,
    analysisDate,
    selectedChannel,
    setIsnNonMarketing,
    isNonMarketing,
}) => {
    const { t } = useTranslation();

    const pageValidation = useSelector((state) => state.pageValidation);

    const { doPostRequest } = useRequest();
    const [providers, setProviders] = useState([]);
    const [isDisableProviders, setIsDisableProviders] = useState(isNil(selectedChannel) || selectedChannel === l.Empty);
    const [isDisableGoalCount, setIsDisableGoalCount] = useState(isNil(goalCount) || goalCount === 0);

    const { getPartnerMessageProvidersRequest } = useRef({
        getPartnerMessageProvidersRequest: UtilsHttpService.getPartnerMessageProviders(),
    }).current;

    const providerOnChangeHandler = (e) => {
        isNil(e) ? setSelectedProvider(null) : setSelectedProvider(e.value);
    };

    const goalTypeOnChangeHandler = (e) => {
        setIsDisableGoalCount(isNil(e));
        setSelectedGoalType(e?.value);
    };

    const countOnChangeHandler = (e) => {
        const { validity, value } = e.target;

        if (validity.valid && (value !== '' || value === 0)) {
            +value[0] === 0 && value.length !== 1 ? setGoalCount(`${value.substr(1)}`) : setGoalCount(`${value}`);
        } else if (value === '') {
            validity.valid ? setGoalCount(null) : setGoalCount(`${goalCount}`);
        }
    };

    const isNonMarketingChangeHandler = (e) => {
        setIsnNonMarketing(e.target.checked);
    };

    const init = () => {
        if (selectedChannel === TemplateTypesLabels[0] || selectedChannel === TemplateTypesLabels[2]) {
            setIsDisableProviders(false);
        } else {
            setIsDisableProviders(true);
        }
        setIsDisableGoalCount(isNil(selectedGoalType));
        doPostRequest(getPartnerMessageProvidersRequest.request, {
            requestBody: { DeliveryMethod: TemplateTypesLabels[selectedChannel] },
            successCallback: (Data) => {
                setProviders(
                    Data.map(({ Id, Name }) => {
                        return {
                            value: +Id,
                            label: Name,
                        };
                    }),
                );
            },
        });
    };

    const cleanUp = () => {
        return () => {
            getPartnerMessageProvidersRequest.cancel('CampaignPage:getPartnerMessageProvidersRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, [selectedChannel]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);
    return (
        <>
            <div className="chanel-row">
                <ExtendedInput
                    title={t(l.Channel)}
                    label={t(l.Channel)}
                    placeholder={t(l.Channel)}
                    labelAppearance="swap"
                    disabled={true}
                    min={1}
                    value={t(selectedChannel)}
                />
                <Dropdown
                    placeholder={t(l.Providers)}
                    labelAppearance="swap"
                    isMultiSelect={false}
                    disabled={isDisableProviders}
                    hasSearch={true}
                    inputSize="default"
                    appearance="outline"
                    data={providers}
                    searchPlaceholderText={t(l.Search)}
                    noDataText={t(l.NoDataFound)}
                    value={selectedProvider}
                    onChange={providerOnChangeHandler}
                    clearable={true}
                />
            </div>
            <div className="collect-row">
                <ValidatableDatePickerInput
                    title={t(l.CollectAnalysisBy)}
                    labelAppearance="swap"
                    onChange={setAnalysisDate}
                    value={analysisDate}
                    isValid={!t(pageValidation?.CollectDate?.errorText)}
                    errorText={t(pageValidation?.CollectDate?.errorText)}
                    isTouched={true}
                    min={customMoment().format(MonthDayYearFormat)}
                />
                <Tooltip text={t(l.TooltipCollectAnalysisBy)} position="bottom">
                    <Icon type="bc-icon-info" />
                </Tooltip>
            </div>
            <div className="non-market">
                <AccessControl permissions={PERMISSIONS.ManageNonMarketingCampaign}>
                    <Checkbox
                        label={t(l.IsNonMarketing)}
                        checked={isNonMarketing}
                        onChange={isNonMarketingChangeHandler}
                    />
                </AccessControl>
            </div>
            <div className="goal-row">
                <Dropdown
                    placeholder={t(l.GoalType)}
                    labelAppearance="swap"
                    isMultiSelect={false}
                    disabled={false}
                    hasSearch={true}
                    inputSize="default"
                    appearance="outline"
                    data={GoalDropdownOptions}
                    searchPlaceholderText="Search"
                    noDataText="No data found"
                    value={selectedGoalType}
                    onChange={goalTypeOnChangeHandler}
                    clearable={true}
                />
                <ExtendedInput
                    placeholder={t(l.Count)}
                    labelAppearance="swap"
                    pattern="[0-9]*"
                    type="number"
                    min={1}
                    onChange={countOnChangeHandler}
                    value={goalCount}
                    disabled={isDisableGoalCount}
                />
            </div>
        </>
    );
};

CampaignGeneralDetails.propTypes = {
    isNonMarketing: PropTypes.bool,
    goalCount: PropTypes.number,
    selectedProvider: PropTypes.number,
    analysisDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    selectedGoalType: PropTypes.string,
    selectedChannel: PropTypes.string,
    setSelectedProvider: PropTypes.func,
    setSelectedGoalType: PropTypes.func,
    setGoalCount: PropTypes.func,
    setAnalysisDate: PropTypes.func,
    setIsnNonMarketing: PropTypes.func,
};

export default memo(CampaignGeneralDetails);
