import { useTranslation } from 'react-i18next';
import localForage from 'localforage';
import { isObject, has, noop } from 'lodash';
import { useDispatch } from 'react-redux';
// Import services
import { UtilsHttpService } from 'services/http';
// Import actions
import { HeaderAction } from 'actions';
// Import constants
import { SupportedLanguages } from 'constants/common';

const useLocalization = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { setLanguagesList } = HeaderAction;

    const setInitialSelectedLanguage = (langId) => {
        for (let i = 0; i < SupportedLanguages.length; i++) {
            const language = SupportedLanguages[i];
            language.IsSelected = language.Id === langId;
        }

        dispatch(setLanguagesList(SupportedLanguages));
    };

    const setLocalization = async (langId, changeLanguageCallback = noop) => {
        setInitialSelectedLanguage(langId);

        UtilsHttpService.getTranslationsVersion().then(async (versionResponse) => {
            const originVersion = versionResponse.Data;

            const localizationResources = (await localForage.getItem('localizationResources')) || {};
            const localVersion = has(localizationResources, langId) ? localizationResources[langId].version : '';
            const isVersionMatchWithLocal = localVersion === originVersion;

            if (isObject(localizationResources) && has(localizationResources, langId) && isVersionMatchWithLocal) {
                i18n.addResources(langId, 'translations', localizationResources[langId].translations);
                i18n.changeLanguage(langId).then(() => {
                    changeLanguageCallback(langId);
                });
            } else {
                if (!isObject(localizationResources) || !has(localizationResources, langId)) {
                    localizationResources[langId] = {
                        translations: {},
                        version: originVersion,
                    };
                }

                UtilsHttpService.getTranslations().then(({ HasError, Data }) => {
                    if (!HasError) {
                        i18n.addResources(langId, 'translations', Data);
                        i18n.changeLanguage(langId).then(() => changeLanguageCallback(langId));
                        localizationResources[langId].translations = Data;
                        localizationResources[langId].version = originVersion;
                        localForage.setItem('localizationResources', localizationResources);
                    } else {
                        changeLanguageCallback(langId);
                    }
                });
            }
        });
    };

    return [setLocalization];
};

export default useLocalization;
