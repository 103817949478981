import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Hooks
import { useCountdownTimer } from 'hooks';
// Import Constants
import { UnderMaintenanceImage } from 'constants/utils';
import { l, ZeroTimeRemaining } from 'constants/common';

const UnderMaintenance = ({ endDate }) => {
    const { t } = useTranslation();

    const remainingTime = useCountdownTimer(endDate);

    const [hours = '', minutes = '', seconds = ''] = remainingTime.split(':');

    if (remainingTime === ZeroTimeRemaining) {
        window.location.reload();
    }

    return (
        <div className="crm-under-maintenance">
            <img alt="under maintenance" src={UnderMaintenanceImage.path} />
            <p>{t(l.CRMUnderMaintenanceTittle)}</p>
            <p>{t(l.CRMUnderMaintenanceDescription)}</p>
            <h3>{t(l.Remaining)}</h3>
            <div className="countdown-timer-container">
                <div className="countdown-timer-section">
                    <div className="countdown-timer-title">{t(l.Hours)}</div>
                    <div className="countdown-timer-value">{hours}</div>
                </div>
                <div className="countdown-timer-section">
                    <div className="countdown-timer-title">{t(l.Minutes)}</div>
                    <div className="countdown-timer-value">{minutes}</div>
                </div>
                <div className="countdown-timer-section">
                    <div className="countdown-timer-title">{t(l.Seconds)}</div>
                    <div className="countdown-timer-value">{seconds}</div>
                </div>
            </div>
        </div>
    );
};

UnderMaintenance.propTypes = {
    endDate: PropTypes.string.isRequired,
};

export default memo(UnderMaintenance);
