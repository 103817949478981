import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// Import Services
import { Helpers } from 'services';
// Import Hooks
import { useCountdownTimer } from 'hooks';
// Import Constants
import { l, MonthDayYearWithTimeFormat } from 'constants/common';

const { customMoment } = Helpers;

const MaintenanceInfo = ({ startDate, endDate, isUnderMaintenance }) => {
    const { t } = useTranslation();

    const currentRemainingTime = useCountdownTimer(endDate);
    const startDateTime = isUnderMaintenance ? new Date().getTime() : new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    const formattedStartDate = customMoment(startDateTime).format(MonthDayYearWithTimeFormat);
    const duration = moment.duration(endDateTime - startDateTime);
    const durationInHours = Math.floor(duration.asHours());
    const durationInMinutes = Math.floor(duration.asMinutes()) - durationInHours * 60;
    const formattedDurationInHours =
        durationInHours > 0 ? ` ${durationInHours} ${durationInHours > 1 ? t(l.Hours) : t(l.Hour)} ` : '';
    const formattedDurationInMinutes =
        durationInMinutes > 0 ? ` ${durationInMinutes} ${durationInMinutes > 1 ? t(l.Minutes) : t(l.Minute)}` : '';

    return (
        <div className="crm-header-maintenance-main-content">
            <p className="maintenance-title">{isUnderMaintenance ? t(l.UnderMaintenance) : t(l.UpcomingMaintenance)}</p>
            {!isUnderMaintenance && (
                <p className="maintenance-start-date-container">
                    <span> {t(l.Start)}:</span>
                    <span className="maintenance-start-date">{formattedStartDate}</span>
                </p>
            )}
            <p className="maintenance-duration-container">
                {isUnderMaintenance
                    ? `${t(l.Remaining)} ${currentRemainingTime}`
                    : ` ${t(l.Duration)}: ${formattedDurationInHours}  ${formattedDurationInMinutes}`}
            </p>
        </div>
    );
};

MaintenanceInfo.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    isUnderMaintenance: PropTypes.bool.isRequired,
};

export default MaintenanceInfo;
