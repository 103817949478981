// Import configs
import { MergeResultValues } from 'components/DynamicSegment/DynamicSegmentForms/config';
import { keys } from 'lodash';
// Import services
import { getFormViewWithValueLabel } from './index';
import { Helpers } from 'services';
// Import constants
import { l, TimeFormatWithoutSeconds, SegmentFilterApplicationColumnId } from 'constants/common';

const { customMomentWithoutTimezoneConversion } = Helpers;

function valueLabelFilterAdditionalData({ Data }, source, viewType) {
    const { DisplayName, Value } = JSON.parse(source).find((obj) => obj.Value === Data.Value);

    return getFormViewWithValueLabel(viewType, {
        label: DisplayName,
        valueShouldBeSend: Value,
    });
}

function staticSingleDataAdditionalDataTextCreate(number, atThisMoment, t) {
    if (number === 0) {
        return atThisMoment ? t(l.Now) : t(l.Today);
    }
    if (number > 0) {
        return atThisMoment
            ? t(l.InDaysAtThisMoment, { count: Math.abs(number) })
            : t(l.InDays, { count: Math.abs(number) });
    }
    return atThisMoment
        ? t(l.DaysAgoAtThisMoment, { count: Math.abs(number) })
        : t(l.DaysAgo, { count: Math.abs(number) });
}

function getTimeValue(Data) {
    const timeValue = {};
    timeValue.hasTime = Data.Value;
    timeValue.value = customMomentWithoutTimezoneConversion(Data.Value).format(TimeFormatWithoutSeconds);
    return timeValue;
}

function getMessageForDynamicCustomValues({ label, daysCount, Operation, t }) {
    if (Operation === 0 || daysCount === 0) return `${t(label)}`;

    return `${t(label)} ${Operation === 1 ? '+' : '-'} ${daysCount} ${daysCount === 1 ? t(l.Day) : t(l.Days)}`;
}

function staticSingleDataAdditionalData({ Argument, Data, Operation }, t) {
    switch (Data.ValueType) {
        case 5:
        case 6:
        case 10:
        case 11:
        case 14:
        case 15: {
            const timeValue = getTimeValue(Data);
            const { label } = MergeResultValues[Data.ValueType];
            const { Value } = Argument;
            const daysCount = +Value.substring(0, Value.indexOf('.')) || 0;

            let result = getMessageForDynamicCustomValues({ label, daysCount, Operation, t });

            if (timeValue?.hasTime) {
                result += ` (${timeValue.value})`;
            }

            return result;
        }
        default:
            if (Data.ValueType > 0) {
                let result = '';
                const { value } = MergeResultValues[Data.ValueType];
                const { Value } = Argument;

                if (Operation === 0) {
                    result = staticSingleDataAdditionalDataTextCreate(value, +Data.ValueType === 1, t);
                } else if (Operation === 1) {
                    const countOfAddDays = value + +Value.substring(0, Value.indexOf('.'));
                    result = staticSingleDataAdditionalDataTextCreate(countOfAddDays, +Data.ValueType === 1, t);
                } else {
                    const countOfSubDays = value - +Value.substring(0, Value.indexOf('.'));
                    result = staticSingleDataAdditionalDataTextCreate(countOfSubDays, +Data.ValueType === 1, t);
                }
                if (Data.Value) {
                    result += ` (${customMomentWithoutTimezoneConversion(Data.Value).format(
                        TimeFormatWithoutSeconds,
                    )})`;
                }
                return result;
            }
            return null;
    }
}

function fromToViewWithAdditionalData(elementValues, callbackForAdditionalData, t) {
    return [callbackForAdditionalData(elementValues.From, t), callbackForAdditionalData(elementValues.To, t)];
}

function monthDayFormAdditionalData(data, t) {
    if (data.Data.ValueType > 0) {
        return staticSingleDataAdditionalData(data, t);
    }
    const date = customMomentWithoutTimezoneConversion(data.Data.Value).format('MMM D').split(' ');
    return { month: date[0], day: date[1] };
}

function monthDayFormFromToAdditionalData(data, t) {
    return Object.values(data).map((elem) => {
        return monthDayFormAdditionalData(elem, t);
    });
}

function multiSelectAdditionalValue(
    { Data },
    source,
    getMenuItemsById,
    dynamicSegmentFiltersData,
    applicationColumnId,
) {
    const { MenuItemId } = JSON.parse(source);
    return getMenuItemsById.request({ id: MenuItemId }).then((res) => {
        const foundFromSubSegments = [];
        if (applicationColumnId === SegmentFilterApplicationColumnId) {
            Data.Value.forEach((segmentId) => {
                if (keys(dynamicSegmentFiltersData).includes(segmentId)) {
                    foundFromSubSegments.push({
                        Id: dynamicSegmentFiltersData[segmentId]?.id,
                        Name: dynamicSegmentFiltersData[segmentId]?.name,
                    });
                }
            });
        }
        const foundFromMenuItems = res.Data.filter((val) => Data.Value.includes(val.Id));

        return [...foundFromMenuItems, ...foundFromSubSegments];
    });
}

function singleSelectAdditionalValue(data, source, getDataById) {
    const queryParams = `?id=${data.Data.Value}`;
    const url = JSON.parse(source).item;
    return getDataById.request({ url, queryParams });
}

function getFiltersAddDataView(
    { elementType, source, applicationColumnId },
    elementValues,
    viewType,
    getMenuItemsById,
    getDataById,
    t,
    dynamicSegmentFiltersData,
) {
    switch (elementType) {
        case 1:
            return singleSelectAdditionalValue(elementValues, source, getDataById);
        case 3:
            return staticSingleDataAdditionalData(elementValues, t);
        case 4:
            return fromToViewWithAdditionalData(elementValues, staticSingleDataAdditionalData, t);
        case 7:
            return valueLabelFilterAdditionalData(elementValues, source, viewType);
        case 12:
            return monthDayFormAdditionalData(elementValues, t);
        case 13:
            return monthDayFormFromToAdditionalData(elementValues, t);
        case 14:
            return multiSelectAdditionalValue(
                elementValues,
                source,
                getMenuItemsById,
                dynamicSegmentFiltersData,
                applicationColumnId,
            );
        case 18:
            return staticSingleDataAdditionalData(elementValues, t);
        case 19:
            return fromToViewWithAdditionalData(elementValues, staticSingleDataAdditionalData, t);
        default:
            return null;
    }
}

export {
    multiSelectAdditionalValue,
    getFiltersAddDataView,
    monthDayFormFromToAdditionalData,
    monthDayFormAdditionalData,
    fromToViewWithAdditionalData,
    staticSingleDataAdditionalData,
    staticSingleDataAdditionalDataTextCreate,
    valueLabelFilterAdditionalData,
    getMessageForDynamicCustomValues,
};
