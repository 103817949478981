import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
// Import Constants
import { ZeroTimeRemaining } from 'constants/common';

const useCountdownTimer = (endTime) => {
    const [currentRemainingTime, setCurrentRemainingTime] = useState('');

    const calcDuration = useCallback(
        (interval) => {
            const now = moment();
            const end = moment(endTime);
            const duration = moment.duration(end.diff(now));

            if (duration.asSeconds() <= 0) {
                interval && clearInterval(interval);
                setCurrentRemainingTime(ZeroTimeRemaining);
            } else {
                const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
                const minutes = String(duration.minutes()).padStart(2, '0');
                const seconds = String(duration.seconds()).padStart(2, '0');

                setCurrentRemainingTime(`${hours}:${minutes}:${seconds}`);
            }
        },
        [endTime],
    );

    useEffect(() => {
        calcDuration();

        const interval = setInterval(() => calcDuration(interval), 1000);

        return () => clearInterval(interval);
    }, [calcDuration, endTime]);

    return currentRemainingTime;
};

export default useCountdownTimer;
