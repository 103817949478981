import { CRMRequestService, getCancelableRequest, postCancelableRequest } from './http.config';

const ABTestingHttpService = {
    getPresets: () => {
        return getCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/PromotionTest/GetPresets',
        });
    },
    getSegmentStats: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/GetSegmentStats',
        });
    },
    calculateSegmentStats: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: () => '/Segment/CalculateSegmentStats',
        });
    },
    stopCampaignABTest: () => {
        return postCancelableRequest({
            requestService: CRMRequestService,
            endpointPath: ({ campaignId }) => `/PromotionTest/StopCampaignABTest?campaignId=${campaignId}`,
        });
    },
};

export default ABTestingHttpService;
