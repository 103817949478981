import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import UI Components
import { Paper } from '@geneui/components';
// Import Components
import { CustomTabs, DownloadOrSendToEmailToolbar, ActivityKPI } from 'components';
import CampaignActivityMetricsReceivedPlayers from './CampaignActivityMetricsReceivedPlayers';
import CampaignActivityMetricsPlayersWhoOpenedMail from './CampaignActivityMetricsPlayersWhoOpenedMail';
import CampaignActivityMetricsPlayersWhoClickedTheLink from './CampaignActivityMetricsPlayersWhoClickedTheLink';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { CampaignHttpService } from 'services/http';
// Import Constants
import {
    activityMetricsTabsKey as tabsKey,
    activityMetricsTabsKeyConfig as tabsKeyConfig,
    activityMetricsTabsActivityKPIs,
} from './config';
import { TemplateTypesLabels, l } from 'constants/common';

const CampaignActivityMetrics = ({
    campaignId,
    startDate,
    endDate,
    bonusCount,
    deliveryMethod,
    isLoadingSchedule,
    isActive: isActiveCampaignActivityMetrics,
}) => {
    const { t } = useTranslation();
    const { showToaster } = useToaster();

    const { doGetRequest, doPostRequest } = useRequest();
    const [campaignKPIs, setCampaignKPIs] = useState({});
    const [isLoadingCampaignKPI, setIsLoadingCampaignKPI] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [tabsDownloadDisabled, setTabsDownloadDisabled] = useState(() =>
        tabsKeyConfig.reduce((acc, val) => {
            acc[val] = true;
            return acc;
        }, {}),
    );

    const [tabActiveKey, setTabActiveKey] = useState(tabsKey.ReceivedPlayers);

    const tabChangeHandler = (key) => {
        setTabActiveKey(key);
    };

    const {
        getCampaignEmailTrackInfoRequest,
        downloadEmailClickReportRequest,
        downloadEmailReportRequest,
        downloadEmailOpenReportRequest,
    } = useMemo(
        () => ({
            getCampaignEmailTrackInfoRequest: CampaignHttpService.getCampaignEmailTrackInfo(),
            downloadEmailClickReportRequest: CampaignHttpService.downloadEmailClickReport(),
            downloadEmailReportRequest: CampaignHttpService.downloadEmailReport(),
            downloadEmailOpenReportRequest: CampaignHttpService.downloadEmailOpenReport(),
        }),
        [],
    );

    useEffect(() => {
        setIsLoadingCampaignKPI(true);
        doGetRequest(getCampaignEmailTrackInfoRequest.request, {
            queryString: { CampaignId: campaignId },
            successCallback: (Data) => setCampaignKPIs(Data),
        }).then(() => {
            setIsLoadingCampaignKPI(false);
        });
    }, [campaignId, doGetRequest, getCampaignEmailTrackInfoRequest]);

    const disableDownloadSetters = useMemo(() => {
        const tabSetter = (tabKey) => (value) => {
            setTabsDownloadDisabled((prev) => ({ ...prev, [tabKey]: value }));
        };

        return {
            [tabsKey.ReceivedPlayers]: tabSetter(tabsKey.ReceivedPlayers),
            [tabsKey.PlayersWhoOpenedMail]: tabSetter(tabsKey.PlayersWhoOpenedMail),
            [tabsKey.PlayersWhoClickedTheLink]: tabSetter(tabsKey.PlayersWhoClickedTheLink),
        };
    }, []);

    const getContent = useCallback(
        (tabKey) => {
            const isActive = isActiveCampaignActivityMetrics && tabKey === tabActiveKey;

            switch (tabKey) {
                case tabsKey.ReceivedPlayers:
                    return (
                        <CampaignActivityMetricsReceivedPlayers
                            campaignId={campaignId}
                            startDate={startDate}
                            endDate={endDate}
                            setIsDisableDownload={disableDownloadSetters[tabKey]}
                            isLoadingSchedule={isLoadingSchedule}
                            isActive={isActive}
                        />
                    );
                case tabsKey.PlayersWhoOpenedMail:
                    return (
                        <CampaignActivityMetricsPlayersWhoOpenedMail
                            campaignId={campaignId}
                            startDate={startDate}
                            endDate={endDate}
                            setIsDisableDownload={disableDownloadSetters[tabKey]}
                            isLoadingSchedule={isLoadingSchedule}
                            isActive={isActive}
                        />
                    );
                case tabsKey.PlayersWhoClickedTheLink:
                    return (
                        <CampaignActivityMetricsPlayersWhoClickedTheLink
                            campaignId={campaignId}
                            startDate={startDate}
                            endDate={endDate}
                            setIsDisableDownload={disableDownloadSetters[tabKey]}
                            isLoadingSchedule={isLoadingSchedule}
                            isActive={isActive}
                        />
                    );
                default:
                    return <></>;
            }
        },
        [
            isActiveCampaignActivityMetrics,
            tabActiveKey,
            campaignId,
            startDate,
            endDate,
            disableDownloadSetters,
            isLoadingSchedule,
        ],
    );

    const downloadAllOrSendEmailClickHandler = (isDownload) => {
        // eslint-disable-next-line default-case
        switch (tabActiveKey) {
            case tabsKey.ReceivedPlayers:
                return downloadAllOrSendEmail(downloadEmailReportRequest, isDownload);
            case tabsKey.PlayersWhoOpenedMail:
                return downloadAllOrSendEmail(downloadEmailOpenReportRequest, isDownload);
            case tabsKey.PlayersWhoClickedTheLink:
                return downloadAllOrSendEmail(downloadEmailClickReportRequest, isDownload);
        }
    };

    const downloadAllOrSendEmail = (request, isDownload) => {
        const requestBody = {
            CampaignId: campaignId,
            StartDate: startDate,
            EndDate: endDate,
            ReportType: 0,
        };
        setIsFileLoading(true);

        return doPostRequest(request.request, {
            requestBody,
            queryString: { isDownload },
            successCallback: (data) => {
                isDownload && window.open(data?.Url);
            },
        }).then(({ AlertType, AlertMessage }) => {
            showToaster(AlertType, AlertMessage);
            setIsFileLoading(false);
        });
    };

    const tabs = useMemo(() => {
        return tabsKeyConfig
            .filter((el) => campaignKPIs[activityMetricsTabsActivityKPIs[el]] > 0 || el === tabsKey.ReceivedPlayers)
            .map((item) => {
                const label = isNil(deliveryMethod) ? t(l.Mail) : `${t(`${TemplateTypesLabels[deliveryMethod]}Label`)}`;
                return {
                    tabKey: item,
                    tabTitle: (
                        <p>
                            {t(l[item], {
                                deliveryMethod: label,
                            })}
                        </p>
                    ),
                    tabContent: getContent(item),
                };
            });
    }, [campaignKPIs, deliveryMethod, getContent, t]);

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getCampaignEmailTrackInfoRequest.cancel('CampaignActivityMetrics:getCampaignEmailTrackInfoRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);
    return (
        <div className="activity-metrics-holder">
            <div className="activity-metrics-holder-inner">
                <ActivityKPI isLoading={isLoadingCampaignKPI} data={campaignKPIs} bonusCount={bonusCount} />
                <Paper className="activity-metrics-section">
                    <CustomTabs activeTabKey={tabActiveKey} tabsConfig={tabs} tabChangeHandler={tabChangeHandler} />
                    <ul className="module-header cr-position-radius p-top s-big">
                        <li className="right-content">
                            <DownloadOrSendToEmailToolbar
                                downloadAction={() => downloadAllOrSendEmailClickHandler(true)}
                                sendToEmailAction={() => downloadAllOrSendEmailClickHandler(false)}
                                isFileLoading={isFileLoading}
                                isDisabledDownload={tabsDownloadDisabled[tabActiveKey]}
                            />
                        </li>
                    </ul>
                </Paper>
            </div>
        </div>
    );
};

CampaignActivityMetrics.propTypes = {
    campaignId: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    bonusCount: PropTypes.string.isRequired,
    isLoadingSchedule: PropTypes.bool.isRequired,
    isActive: PropTypes.bool,
    deliveryMethod: PropTypes.number,
};

CampaignActivityMetrics.defaultProps = {
    isActive: true,
};

export default memo(CampaignActivityMetrics);
