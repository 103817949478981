import React, { memo, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useGACustomEvent, useGoToRoute, useQueryParams } from 'hooks';
// Import UI components
import { Tooltip } from '@geneui/components';
// Import Constants
import { GACategoryEvents } from 'constants/common';

interface Props {
    path?: { name: string; params?: object } | string;
    name: string;
    queryParams?: Record<string, unknown>;
    isClickable?: boolean;
    tooltipConfig?: object;
}

const { GADetailsCategory } = GACategoryEvents;

const TittleLink: React.FC<Props> = (props) => {
    const { path, name, queryParams, isClickable = true, tooltipConfig = { position: 'bottom' } } = props;
    const { goToRoute, getRoutePath } = useGoToRoute();
    const { search } = useLocation();
    const { logCustomEvent } = useGACustomEvent();
    const goToRouteQueryParams = useQueryParams();

    const buttonOnClick = useCallback(
        (e) => {
            e.preventDefault();
            logCustomEvent(
                GADetailsCategory.name,
                GADetailsCategory.events[`${(path as { name: string }).name}TitleDetails`],
                'open details from name',
                1,
            );
            goToRoute(path, queryParams, goToRouteQueryParams.encode({ backParams: search }));
        },
        [goToRoute, logCustomEvent, path, queryParams, goToRouteQueryParams, search],
    );

    const link = useMemo(
        () => (isClickable ? getRoutePath(path, queryParams, null) : null),
        [getRoutePath, path, queryParams, isClickable],
    );

    return (
        <Tooltip text={name} {...tooltipConfig}>
            {isClickable ? (
                <a className="tittle-text ellipsis-text" onClick={buttonOnClick} href={link || undefined}>
                    {name}
                </a>
            ) : (
                <span className="ellipsis-text">{name}</span>
            )}
        </Tooltip>
    );
};

export default memo(TittleLink);
