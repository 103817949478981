import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { noop, isNil } from 'lodash';
// Import UI components
import { BusyLoader } from '@geneui/components';
// Import components
import { FromToBlocks } from 'components';
// Import Constants
import { l } from 'constants/common';

const KPIChooser = forwardRef(({ leftKPIColumns, rightKPIColumns, isLoading, getUpdate }, ref) => {
    const { t } = useTranslation();

    const [leftBlockList, setLeftBlockList] = useState(null);
    const [rightBlockList, setRightBlockList] = useState(null);
    const fromToBlocksRef = useRef();

    useImperativeHandle(ref, () => ({
        reset: fromToBlocksRef.current?.reset,
        setInitialData(rightKPIColumns, leftKPIColumns) {
            if (!isNil(fromToBlocksRef.current?.setInitialBlocksData)) {
                fromToBlocksRef.current.setInitialBlocksData(
                    rightKPIColumns.map(mapToLocalModel),
                    leftKPIColumns.map(mapToLocalModel),
                );
            }
        },
    }));

    const mapToLocalModel = (item) => ({
        ...item,
        isVisible: true,
        isChecked: false,
        description: item.Description,
        childrenList: isNil(item.childrenList)
            ? []
            : item.childrenList.map((row) => ({ ...row, isChecked: false, isVisible: true })),
    });

    useEffect(() => {
        setLeftBlockList(leftKPIColumns.map(mapToLocalModel));
        setRightBlockList(rightKPIColumns.map(mapToLocalModel));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFromToBlockUpdates = ({ leftBlockList, rightBlockList }) => {
        getUpdate({ leftBlockList, rightBlockList });
    };

    return (
        <div className="crm-report-d-a-d-container">
            <BusyLoader
                isBusy={isLoading || isNil(leftBlockList) || isNil(rightBlockList)}
                type="spinner"
                loadingText={t(l.Loading)}
            >
                {!isLoading && !isNil(leftBlockList) && !isNil(rightBlockList) && (
                    <FromToBlocks
                        hasInfoTooltip={true}
                        leftBlockList={leftBlockList}
                        rightBlockList={rightBlockList}
                        leftBlockWithParent={true}
                        rightBlockWithParent={false}
                        getUpdate={getFromToBlockUpdates}
                        leftBlockWithPosition={false}
                        rightBlockWithPosition={true}
                        leftBlockTitle={t(l.NotSelected)}
                        rightBlockTitle={t(l.Selected)}
                        ref={fromToBlocksRef}
                    />
                )}
            </BusyLoader>
        </div>
    );
});

KPIChooser.displayName = 'KPIChooser';

KPIChooser.propTypes = {
    leftKPIColumns: PropTypes.array.isRequired,
    rightKPIColumns: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    getUpdate: PropTypes.func,
};

KPIChooser.defaultProps = {
    isLoading: false,
    getUpdate: noop,
};

export default KPIChooser;
