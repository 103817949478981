import React, { memo, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import UI Components
import { Alert, ExtendedInput, Paper, SkeletonLoader } from '@geneui/components';
//Import Components
import { ResetSave } from 'components';
// Import Services
import { ProviderHttpService } from 'services/http';
//Import Constants
import { l, AlertTypes } from 'constants/common';

const { success } = AlertTypes;

const TelegramSettingsSection = () => {
    const { t } = useTranslation();

    const { doPostRequest } = useRequest();
    const { showToaster } = useToaster();

    const [isTelegramSettingsLoading, setIsTelegramSettingsLoading] = useState(false);
    const [telegramBotUserName, setTelegramBotUserName] = useState('');

    const formik = useFormik({
        initialValues: {
            apiKey: '',
        },
        validationSchema: Yup.object({
            apiKey: Yup.string().required(t(l.CannotBeEmpty, { fieldName: t(l.TelegramApiKey) })),
        }),
        onSubmit: (values, formik) => {
            return doPostRequest(saveTelegramRequest.request, {
                requestBody: { apiKey: values.apiKey },
                successCallback: (data) => {
                    formik.resetForm({
                        values: {
                            apiKey: values.apiKey,
                        },
                    });
                    setTelegramBotUserName(data?.UserName ?? '');
                    showToaster(success, t(l.TelegramSettingsSavedSuccessfully));
                },
            });
        },
    });

    const { values, errors, dirty, isSubmitting, isValid, setFieldValue, submitForm, resetForm } = formik;

    const { getTelegramRequest, saveTelegramRequest } = useMemo(
        () => ({
            getTelegramRequest: ProviderHttpService.getTelegram(),
            saveTelegramRequest: ProviderHttpService.saveTelegram(),
        }),
        [],
    );

    const getTelegramSettings = () => {
        setIsTelegramSettingsLoading(true);
        doPostRequest(getTelegramRequest.request, {
            requestBody: {},
            successCallback: (data) => {
                resetForm({
                    values: { apiKey: data?.ApiKey ?? '' },
                });
                setTelegramBotUserName(data?.UserName ?? '');
            },
        }).then(() => {
            setIsTelegramSettingsLoading(false);
        });
    };

    const telegramApiKeyInputChangeHandler = (e) => {
        setFieldValue('apiKey', e.target.value);
    };

    const init = () => {
        getTelegramSettings();
    };

    const cleanUp = () => {
        return () => {
            getTelegramRequest.cancel('TelegramSettingsSection:getTelegramRequest');
            saveTelegramRequest.cancel('TelegramSettingsSection:saveTelegramRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <Paper className="telegram-provider-settings card" paperDirection="column">
            <ul className="module-header cr-position-radius p-top s-big">
                <li className="left-content">
                    <div className="left-inner">
                        <div className="module-title ellipsis-text" title="">
                            <div className="crm-statics-segment-data-table-name ellipsis-text">
                                {t(l.TelegramSettings)}
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div className="content">
                <Alert
                    closable={false}
                    title={
                        <>
                            {t(l.TelegramDescription)}
                            <a
                                href="https://core.telegram.org/bots#how-do-i-create-a-bot"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t(l.Here)}
                            </a>
                        </>
                    }
                    iconProps={{ type: 'bc-icon-info' }}
                />
                <div className="fields-btn-group">
                    <div className="input-container">
                        <SkeletonLoader isBusy={isTelegramSettingsLoading}>
                            <ExtendedInput
                                value={telegramBotUserName}
                                disabled={isSubmitting}
                                readOnly={true}
                                label={t(l.TelegramBotId)}
                                labelAppearance="swap"
                            />
                        </SkeletonLoader>
                    </div>

                    <div className="input-container">
                        <SkeletonLoader isBusy={isTelegramSettingsLoading}>
                            <ExtendedInput
                                value={values.apiKey}
                                onChange={telegramApiKeyInputChangeHandler}
                                disabled={isSubmitting}
                                placeholder={t(l.TelegramApiKey)}
                                isValid={!errors.apiKey}
                                errorText={errors.apiKey}
                                type="password"
                            />
                        </SkeletonLoader>
                    </div>
                    <div className="btn-group">
                        <ResetSave
                            hasAdditionalSettings={false}
                            resetButtonClickHandler={resetForm}
                            disableSave={!isValid || !dirty || isSubmitting}
                            disableReset={!dirty || isSubmitting}
                            saveButtonClickHandler={submitForm}
                            isLoadingSave={isSubmitting}
                        />
                    </div>
                </div>
            </div>
        </Paper>
    );
};

export default memo(TelegramSettingsSection);
