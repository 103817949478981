import React, { memo, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil } from 'lodash';
// Import Hooks
import { useRequest, useToaster } from 'hooks';
// Import Services
import { ClientTagHttpService } from 'services/http';
import { publish } from 'services/event';
// Import Components
import { ConfirmationModal } from 'components';
import ClientsFileUploader from './ClientsFileUploader';
import ManageTagList from './ManageTagList';
// Import UI Components
import { Modal, Button } from '@geneui/components';
// Import Constants
import { l, AlertTypes, ManageTagActionTypes, ActionsIcons, ModalsClassNames, GlobalEvents } from 'constants/common';
import { getManageTagDataTableColumnsConfig } from './configs';
// Import SCSS
import 'assets/scss/manageTagsModal.scss';

const { success } = AlertTypes;

const ManageTagModal = ({ isVisible, handleClose, clientIds, isFromFileMode }) => {
    const { t } = useTranslation();

    const [selectedManageTagAction, setSelectedManageTagAction] = useState(ManageTagActionTypes.SetTag);
    const [tagsListData, setTagsListData] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [deletableTagData, setDeletableTagData] = useState({});
    const [clientsCountData, setClientsCountData] = useState({});
    const [uploadId, setUploadId] = useState(null);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [isDeleteTagLoading, setIsDeleteTagLoading] = useState(false);
    const [isLoadingApply, setIsLoadingApply] = useState(false);
    const [isTagListLoading, setIsTagListLoading] = useState(false);

    const { showToaster } = useToaster();
    const { doPostRequest } = useRequest();

    const { getClientTagsListRequest, manageClientTags, deleteClientTagRequest } = useMemo(
        () => ({
            getClientTagsListRequest: ClientTagHttpService.getClientTagsList(),
            manageClientTags: ClientTagHttpService.manageClientTags(),
            deleteClientTagRequest: ClientTagHttpService.deleteClientTag(),
        }),
        [],
    );

    const configByAction = useMemo(
        () => ({
            [ManageTagActionTypes.SetTag]: {
                successMassage: t(l.ClientTagWasSuccessfullySet),
                okButtonText: t(l.SetTag),
            },
            [ManageTagActionTypes.RemoveTag]: {
                successMassage: t(l.ClientTagWasSuccessfullyRemoved),
                okButtonText: t(l.RemoveTag),
            },
            [ManageTagActionTypes.SetOnlyForSelection]: {
                successMassage: t(l.ClientTagWasSuccessfullySetOnlyForSelection),
                okButtonText: t(l.SetOnlyForSelection),
            },
        }),
        [t],
    );

    const getTags = () => {
        setIsTagListLoading(true);
        doPostRequest(getClientTagsListRequest.request, {
            requestBody: { clientIds: clientIds },
            successCallback: ({ Data }) => setTagsListData(sortTagList(Data)),
        }).then(() => setIsTagListLoading(false));
    };

    const deleteTagHandler = () => {
        setIsDeleteTagLoading(true);

        doPostRequest(deleteClientTagRequest.request, {
            queryString: { tagId: deletableTagData.TagId },
            successCallback: () => {
                publish(GlobalEvents.ClientTagsChange);
                setTagsListData((prev) => prev.filter((item) => deletableTagData.TagId !== item.TagId));
                setSelectedTags((prev) => prev.filter((item) => deletableTagData.TagId !== item.data.TagId));
                showToaster(success, l.ClientTagWasSuccessfullyDeleted);
            },
        }).then(() => {
            deleteModalVisibilityHandler();
            setIsDeleteTagLoading(false);
        });
    };

    const manageTagsHandler = () => {
        setIsLoadingApply(true);

        doPostRequest(manageClientTags.request, {
            requestBody: {
                TagIds: selectedTags.map((row) => row.data.TagId),
                Action: +selectedManageTagAction,
                ...(isFromFileMode ? { UploadId: uploadId } : { ClientIds: clientIds }),
            },
            successCallback: () => {
                showToaster(success, configByAction[selectedManageTagAction].successMassage);
                handleClose(true);
            },
        }).then(() => setIsLoadingApply(false));
    };

    const createTagHandler = (newTag) => {
        setTagsListData((prev) => [newTag, ...prev]);
    };

    const deleteModalVisibilityHandler = (data) => {
        if (!isOpenDeleteModal) {
            setDeletableTagData(data);
        }

        setIsOpenDeleteModal((prev) => !prev);
    };

    const onActionChange = (e) => {
        setSelectedManageTagAction(e.target.value);
    };

    const sortTagList = (list) => {
        return list.sort((a, b) => b.TagId - a.TagId).sort((a, b) => a.ClientsCount - b.ClientsCount);
    };

    const onFileUploaded = (data) => {
        setClientsCountData({
            total: data.Count,
            duplicate: data.Duplicate,
            invalid: data.Invalid,
            unique: data.Unique,
        });
        setTagsListData(sortTagList(data.Data));
        setUploadId(data.UploadId);
    };

    const tagsListDataByAction = useMemo(() => {
        switch (selectedManageTagAction) {
            case ManageTagActionTypes.SetTag:
                return tagsListData.filter(
                    (item) => item.ClientsCount !== (isFromFileMode ? clientsCountData.unique : clientIds.length),
                );
            case ManageTagActionTypes.RemoveTag:
                return tagsListData.filter((item) => item.ClientsCount > 0);
            default:
                return tagsListData;
        }
    }, [selectedManageTagAction, tagsListData, clientsCountData, clientIds, isFromFileMode]);

    const isDisabledActions = isFromFileMode ? isNil(uploadId) : isTagListLoading;

    const alert = useMemo(() => {
        if (isFromFileMode) {
            const text = isDisabledActions
                ? t(l.NoFileUploaded)
                : t(l.ManageClientTagFromFileHint, {
                      total: clientsCountData.total,
                      duplicate: clientsCountData.duplicate,
                      invalid: clientsCountData.invalid,
                      unique: clientsCountData.unique,
                  });

            return {
                title: text,
                type: isDisabledActions ? 'warning' : 'info',
            };
        }

        return {
            title: t(l.ManageClientTagHint, { selectedClientsCount: clientIds.length }),
            type: 'info',
        };
    }, [clientIds, clientsCountData, isDisabledActions, isFromFileMode, t]);

    const init = () => {
        if (!isFromFileMode) {
            getTags();
        }
    };

    const cleanUp = () => {
        return () => {
            getClientTagsListRequest.cancel('ManageTagsFromFileModal:getClientTagsListRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <>
            <Modal
                className="manage-tags-modal"
                title={t(isFromFileMode ? l.ManageTagFromFile : l.ManageTag)}
                closable={false}
                visible={isVisible}
                onCancel={handleClose}
                closeOnClickOutside={false}
                footer={
                    <>
                        <Button appearance="minimal" onClick={handleClose}>
                            {t(l.Cancel)}
                        </Button>
                        <Button
                            onClick={manageTagsHandler}
                            loading={isLoadingApply}
                            disabled={isEmpty(selectedTags) || isLoadingApply}
                        >
                            {configByAction[selectedManageTagAction].okButtonText}
                        </Button>
                    </>
                }
            >
                {isFromFileMode && (
                    <ClientsFileUploader onFileUploading={setIsTagListLoading} onFileUploaded={onFileUploaded} />
                )}
                <ManageTagList
                    tagsList={tagsListDataByAction}
                    isDisabledActions={isDisabledActions}
                    isTagListLoading={isTagListLoading}
                    columns={getManageTagDataTableColumnsConfig(t)}
                    onSelectedTagsChange={setSelectedTags}
                    onCreateTag={createTagHandler}
                    onDeleteTag={deleteModalVisibilityHandler}
                    onActionChange={onActionChange}
                    selectedTags={selectedTags}
                    action={selectedManageTagAction}
                    alowDisableRow={selectedManageTagAction !== ManageTagActionTypes.RemoveTag}
                    alert={alert}
                />
            </Modal>
            <ConfirmationModal
                isVisibleModal={isOpenDeleteModal}
                onOk={deleteTagHandler}
                onCancel={deleteModalVisibilityHandler}
                questionLabel={t(l.AreYouSureWantToDeleteTag, {
                    tagName: `${deletableTagData.Name}`,
                })}
                titleText={t(l.DeleteTag)}
                iconType={ActionsIcons.Delete}
                actionLabel={t(l.Delete)}
                className={ModalsClassNames.Delete}
                isLoading={isDeleteTagLoading}
            />
        </>
    );
};

ManageTagModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    clientIds: PropTypes.array,
    isFromFileMode: PropTypes.bool,
};

ManageTagModal.defaultProps = {
    clientIds: [],
    isFromFileMode: true,
};

export default memo(ManageTagModal);
