import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RoutesList } from 'routes';
// Import Hooks
import { useGoToRoute } from 'hooks';
//Import Components
import { ConfigurationWidget } from 'components';
//import Services
import { Helpers } from 'services';
//Import Constants
import { l, TemplateTypesLabels } from 'constants/common';

const { createTranslatableEventConfig } = Helpers;

const NotificationsGroup = ({ groupName, events }) => {
    const { t } = useTranslation();
    const getNotificationDescription = (Notifications) => {
        let activeNotifications = `${t(l.ActivatedChannels)}:`;
        const arrayOfActivatedChannels = [];
        Notifications.forEach((channel) => {
            if (channel.IsEnabled) {
                arrayOfActivatedChannels.push(t(TemplateTypesLabels[channel.Channel]));
            }
        });
        if (arrayOfActivatedChannels.length < 1) {
            return activeNotifications;
        } else if (arrayOfActivatedChannels.length === 1) {
            activeNotifications += ` ${arrayOfActivatedChannels[0]}`;
        } else {
            activeNotifications += ` ${arrayOfActivatedChannels.join(', ')}`;
        }
        return activeNotifications;
    };

    const widgetClickHandler = (eventId) => {
        return () => {
            goToRoute(NOTIFICATION, { eventId });
        };
    };

    const { NOTIFICATION } = RoutesList;
    const { goToRoute } = useGoToRoute();

    return (
        <div className="notifications-group-container">
            <h3>{groupName}</h3>
            <div className="notifications-events-wrapper">
                {events.map(({ EventName, EventId, Icon, IsEnabled, Notifications }, index) => {
                    return (
                        <ConfigurationWidget
                            key={index}
                            title={t(createTranslatableEventConfig(EventName, 'name'))}
                            small={true}
                            labelTag={{
                                name: IsEnabled ? t(l.Live) : t(l.Draft),
                                color: IsEnabled
                                    ? `var(${`--status-label-goal-color`})`
                                    : `var(${`--status-label-event-draft-color`})`,
                            }}
                            description={getNotificationDescription(Notifications)}
                            icon={Icon}
                            clickHandler={widgetClickHandler(EventId)}
                            infoIcon={t(createTranslatableEventConfig(EventName, 'description'))}
                        />
                    );
                })}
            </div>
        </div>
    );
};

NotificationsGroup.propTypes = {
    groupName: PropTypes.string,
    events: PropTypes.array,
};

export default memo(NotificationsGroup);
