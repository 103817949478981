/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { keys, isEmpty, values, noop, isNil } from 'lodash';
// Import UI Components
import { Tab, Tabs, BusyLoader, Button } from '@geneui/components';
// Import Components
import CommonTemplateContent from './CommonTemplateContent';
import { LanguageWithSearchModal, SetDefaultLanguageModal } from 'components';
// Import Hooks
import { useRequest, useValidator } from 'hooks';
// Import Services
import { UtilsHttpService, TemplateHttpService } from 'services/http';
import {
    TemplateType,
    createLanguageModalData,
    replaceOldPlaceholders,
    getTemplateValidationTranslatableErrorText,
    groupDynamicVariablesByDisplayName,
} from 'services/template';
// Import Actions
import { PartnerLanguageAction, TemplateActions } from 'actions';
// Import Constants
import { l, TemplateTypesLabels, TemplateInputTypes } from 'constants/common';

const { getPartnerLanguageList } = PartnerLanguageAction;
const { deleteTemplateLanguage, setTemplateValue, setTemplateAsDefault } = TemplateActions;

const CommonTemplate = ({
    isLoading,
    templateType,
    addValidationProperties,
    removeValidationProperties,
    maxSMSPartsCount,
    getAssets,
    addAsset,
    setTabActiveKey,
    tabActiveKey,
    withDynamicValues,
    eventId,
    shortenerAndTrackerSettings,
}) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();
    const dispatch = useDispatch();
    const templateData = useSelector((state) => state.template);
    const partnerLanguages = useSelector((state) => state.partnerLanguages);
    const pageValidation = useSelector((state) => state.pageValidation);
    const partnerSettings = useSelector((state) => state.partnerSettings);
    let isAllowViberSmsCascading;

    if (!isEmpty(partnerSettings)) {
        isAllowViberSmsCascading = !!partnerSettings.IsAllowViberSmsCascading;
    }

    const validator = useValidator(getTemplateValidationTranslatableErrorText(t));

    const [isTemplateDataModified, setIsTemplateDataModified] = useState(false);
    const [templateDynamicVariables, setTemplateDynamicVariables] = useState(null);
    const [partnerLanguageModalIsOpen, setPartnerLanguageModalIsOpen] = useState(false);
    const [defaultLanguageModalIsOpen, setDefaultLanguageModalIsOpen] = useState(false);

    const { getTemplateModels, getPartnerLanguage } = useMemo(
        () => ({
            getTemplateModels: TemplateHttpService.getTemplateModels(),
            getPartnerLanguage: UtilsHttpService.getPartnerLanguage(),
        }),
        [],
    );

    const fetchDynamicVariables = () => {
        const requestPromises = [];

        const mainRequestPromise = new Promise((resolve, reject) => {
            doGetRequest(getTemplateModels.request, {
                successCallback: (Data) => {
                    resolve({ type: templateType, variables: Data });
                },
                queryString: { channel: TemplateTypesLabels[templateType], eventId: eventId },
                errorCallback: reject,
            });
        });

        requestPromises.push(mainRequestPromise);
        if (templateType === TemplateType.Viber && isAllowViberSmsCascading) {
            const smsRequestPromise = new Promise((resolve, reject) => {
                doGetRequest(getTemplateModels.request, {
                    successCallback: (Data) => resolve({ type: TemplateType.SMS, variables: Data }),
                    queryString: { channel: TemplateTypesLabels[TemplateType.SMS], eventId: eventId },
                    errorCallback: reject,
                });
            });

            requestPromises.push(smsRequestPromise);
        }

        Promise.all(requestPromises).then((channelsData) => {
            const groupedDynamicVariables = groupDynamicVariablesByDisplayName(channelsData);
            setTemplateDynamicVariables(groupedDynamicVariables);
        });
    };

    function init() {
        if (isNil(isAllowViberSmsCascading)) return;

        if (withDynamicValues === true) {
            fetchDynamicVariables();
        }

        isEmpty(partnerLanguages) && getPartnerLanguageList(dispatch, getPartnerLanguage.request);
    }

    useEffect(() => {
        if (!isLoading && templateData) {
            if (withDynamicValues) {
                if (!isNil(templateDynamicVariables)) {
                    dispatch(
                        setTemplateValue(replaceOldPlaceholders(templateData, templateDynamicVariables, templateType)),
                    );
                    setIsTemplateDataModified(true);
                }
            } else {
                setIsTemplateDataModified(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, templateDynamicVariables]);

    function cleanUp() {
        return () => {
            getTemplateModels.cancel('CommonTemplate:getTemplateModels');
            getPartnerLanguage.cancel('CommonTemplate:getPartnerLanguage');
        };
    }

    function deleteTab(templateLangKey, index) {
        const fieldNames = keys(values(templateData)[index]).reduce((acc, key) => {
            if (key !== 'Language') {
                acc.push(templateLangKey + key);
            }
            return acc;
        }, []);

        removeValidationProperties(fieldNames);
        dispatch(deleteTemplateLanguage(templateLangKey));
    }

    const handleTabDelete = (templateLangKey, index) => (_e) => {
        deleteTab(templateLangKey, index);
        if (index === +tabActiveKey) {
            setTabActiveKey('0');
        } else if (index < +tabActiveKey) {
            setTabActiveKey(`${tabActiveKey - 1}`);
        }
    };

    function handleModalIsVisible() {
        setPartnerLanguageModalIsOpen(!partnerLanguageModalIsOpen);
    }

    function handleDefaultLanguageModalIsVisible() {
        setDefaultLanguageModalIsOpen(!defaultLanguageModalIsOpen);
    }

    function onConfirmSetDefaultLanguageModal(templateId, isDeletable) {
        dispatch(
            setTemplateAsDefault({
                targetId: 'defaultLang',
                template: templateData[templateId],
                type: templateType,
            }),
        );

        if (isDeletable) {
            const templateIndex = keys(templateData).findIndex((item) => item === templateId);

            deleteTab(templateId, templateIndex);
        }

        setTabActiveKey('0');
        setDefaultLanguageModalIsOpen(!defaultLanguageModalIsOpen);
    }

    const handleTabActiveKey = (index) => {
        const templateDataValues = values(templateData),
            templateDataKeys = keys(templateData);
        validator(
            keys(templateDataValues[tabActiveKey]).reduce((acc, key) => {
                if (key === TemplateInputTypes.ButtonUrl || key === TemplateInputTypes.ButtonText) {
                    acc[templateDataKeys[tabActiveKey] + key] = {
                        text: templateData[templateDataKeys[tabActiveKey]]?.ButtonText,
                        url: templateData[templateDataKeys[tabActiveKey]]?.ButtonUrl,
                    };
                } else {
                    acc[templateDataKeys[tabActiveKey] + key] = templateData[templateDataKeys[tabActiveKey]][key];
                }
                return acc;
            }, {}),
        );
        setTabActiveKey(index);
    };

    function isValidTab(templateLangKey) {
        let isValid = false;

        keys(templateData[templateLangKey]).forEach((key) => {
            if (pageValidation[templateLangKey + key]?.isValid === false) {
                isValid = true;
            }
        });
        return isValid;
    }

    function isDeletableTemplate(templateLangKey) {
        return templateLangKey !== 'defaultLang' && (templateData[templateLangKey]?.isDeletable ?? true);
    }

    function getDefaultLanguageModalData(allLanguages, tabLanguages) {
        return tabLanguages.reduce((acc, item) => {
            acc.push({
                label: allLanguages[item].Name,
                value: item,
            });
            return acc;
        }, []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, [isAllowViberSmsCascading]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    useEffect(() => {
        if (!isLoading && withDynamicValues && !isNil(templateDynamicVariables) && templateData) {
            validator({
                CrossValidation: {
                    dynamicValues: templateDynamicVariables,
                    data: templateData,
                    partnerLanguages: partnerLanguages,
                    shortenerAndTrackerSettings: shortenerAndTrackerSettings,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateData, isLoading, templateDynamicVariables]);

    return (
        <div className="crm-create-template-page-content">
            {defaultLanguageModalIsOpen && (
                <SetDefaultLanguageModal
                    titleLabel={t(l.SetDefaultLanguage)}
                    data={getDefaultLanguageModalData(
                        partnerLanguages,
                        keys(templateData).filter((key) => key !== 'defaultLang'),
                    )}
                    defaultValue={keys(templateData)[tabActiveKey]}
                    onCancel={handleDefaultLanguageModalIsVisible}
                    onOk={onConfirmSetDefaultLanguageModal}
                />
            )}
            {partnerLanguageModalIsOpen && (
                <LanguageWithSearchModal
                    onCancel={handleModalIsVisible}
                    onOk={handleModalIsVisible}
                    languageList={createLanguageModalData(
                        values(partnerLanguages),
                        keys(templateData).filter((key) => key !== 'defaultLang'),
                    )}
                    templateType={templateType}
                    addValidationProperties={addValidationProperties}
                    defaultTemplate={templateData?.defaultLang}
                />
            )}
            <BusyLoader
                isBusy={!isTemplateDataModified}
                type="spinner"
                spinnerSize="big"
                className="crm-loader-template-page-content"
            >
                {!isEmpty(templateData) && isTemplateDataModified && (
                    <Tabs
                        onChange={handleTabActiveKey}
                        actions={
                            <>
                                <Button
                                    disabled={+tabActiveKey === 0}
                                    appearance="minimal"
                                    className="set-default-language-btn"
                                    onClick={handleDefaultLanguageModalIsVisible}
                                >
                                    <span>{t(l.SetDefaultLanguage)}</span>
                                </Button>
                                <button className="add-translation-btn" onClick={handleModalIsVisible}>
                                    <i className={'bc-icon-profit'} />
                                    <span>{t(l.TemplateAddTranslation)}</span>
                                </button>
                            </>
                        }
                        type={'button'}
                        activeKey={`${tabActiveKey}`}
                    >
                        {keys(templateData).map((templateLangKey, i) => {
                            const language = partnerLanguages[templateLangKey];
                            const tabName = language
                                ? language.Id.toUpperCase() + (+tabActiveKey === i ? ` - ${language.Name}` : '')
                                : t(l.Default);
                            return (
                                <Tab
                                    key={i}
                                    className={`${+tabActiveKey === i ? 'active' : ''} ${
                                        isValidTab(templateLangKey) ? 'invalid-tab' : ''
                                    }`}
                                    title={<p>{tabName}</p>}
                                    {...(isDeletableTemplate(templateLangKey) && {
                                        onClose: handleTabDelete(templateLangKey, i),
                                    })}
                                >
                                    {isLoading || (withDynamicValues && isNil(templateDynamicVariables)) ? (
                                        <BusyLoader
                                            isBusy
                                            type="spinner"
                                            spinnerSize={'big'}
                                            className="crm-full-width crm-justify-content-center"
                                        />
                                    ) : (
                                        <CommonTemplateContent
                                            key={i}
                                            index={i}
                                            templateLangKey={templateLangKey}
                                            templateData={templateData[templateLangKey]}
                                            templateType={templateType}
                                            dynamicVariables={templateDynamicVariables}
                                            maxSMSPartsCount={maxSMSPartsCount}
                                            getAssets={getAssets}
                                            addAsset={addAsset}
                                            shortenerAndTrackerSettings={shortenerAndTrackerSettings}
                                        />
                                    )}
                                </Tab>
                            );
                        })}
                    </Tabs>
                )}
            </BusyLoader>
        </div>
    );
};

CommonTemplate.propTypes = {
    isLoading: PropTypes.bool,
    templateType: PropTypes.string,
    addValidationProperties: PropTypes.func,
    removeValidationProperties: PropTypes.func,
    maxSMSPartsCount: PropTypes.number,
    getAssets: PropTypes.func,
    addAsset: PropTypes.func,
    tabActiveKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setTabActiveKey: PropTypes.func,
    withDynamicValues: PropTypes.bool,
    eventId: PropTypes.number,
    templateInputRef: PropTypes.object,
    shortenerAndTrackerSettings: PropTypes.array,
};

CommonTemplate.defaultProps = {
    isLoading: false,
    templateType: TemplateType.SMS,
    addValidationProperties: noop,
    removeValidationProperties: noop,
    setTabActiveKey: noop,
    getAssets: noop,
    addAsset: noop,
    tabActiveKey: 0,
    withDynamicValues: true,
    eventId: 0,
    shortenerAndTrackerSettings: [],
};

export default CommonTemplate;
