import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
// Import UI Components
import { Button, Tooltip } from '@geneui/components';
// Import Components
import { AccessControl } from 'components';
// Import Constants
import { TemplateType } from 'services/template';
import { DefinitionTypes, l, PERMISSIONS, TemplateTypesLabels, ActionsIcons } from 'constants/common';

const TemplateActionsButtons = ({
    data,
    testTemplateAction,
    unarchiveTemplateAction,
    archiveTemplateAction,
    deleteTemplateAction,
    cloneTemplateAction,
    editTemplateAction,
    infoTemplateAction,
    isInfoVisible,
    isDeleteVisible,
    isEditVisible,
    isCloneVisible,
    isTestSendVisible,
    isUnArchiveVisible,
    isArchiveVisible,
    isViewMode,
}) => {
    const { IsUsed, DefinitionType, ArchivedDate } = data;

    const { t } = useTranslation();

    const isPredefined = DefinitionType === DefinitionTypes.predefined;
    const isArchive = !isNil(ArchivedDate);

    const isVisibleDelete = () => isDeleteVisible ?? (!isViewMode && !IsUsed && !isPredefined);
    const isVisibleArchive = () => isArchiveVisible ?? (!isViewMode && !isArchive && !isPredefined);
    const isVisibleEdit = () => isEditVisible ?? (!isViewMode && !isArchive && !isPredefined);
    const isVisibleUnArchive = () => isUnArchiveVisible ?? (!isViewMode && isArchive);
    const isVisibleClone = () => isCloneVisible ?? !isViewMode;
    const isVisibleTestSend = () =>
        isTestSendVisible ??
        (!isViewMode &&
            ![
                TemplateTypesLabels[TemplateType.PopUp],
                TemplateTypesLabels[TemplateType.CMSPopUp],
                TemplateTypesLabels[TemplateType.Hoory],
                TemplateTypesLabels[TemplateType.DynamicPopUp],
            ].includes(data.DeliveryMethod));

    return (
        <>
            {isVisibleDelete() && (
                <AccessControl permissions={PERMISSIONS.DeleteTemplate}>
                    <Tooltip text={t(l.DeleteTemplate)}>
                        <Button
                            icon={ActionsIcons.Delete}
                            appearance="minimal"
                            color="primary"
                            onClick={() => deleteTemplateAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleEdit() && (
                <AccessControl permissions={PERMISSIONS.EditTemplate}>
                    <Tooltip text={t(l.EditTemplate)}>
                        <Button
                            icon={ActionsIcons.Edit}
                            appearance="minimal"
                            color="primary"
                            onClick={() => editTemplateAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isVisibleUnArchive() && (
                <Tooltip text={t(l.UnArchiveTemplate)}>
                    <Button
                        icon={ActionsIcons.UnArchive}
                        appearance="minimal"
                        color="primary"
                        onClick={() => unarchiveTemplateAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleArchive() && (
                <Tooltip text={t(l.ArchiveTemplate)}>
                    <Button
                        icon={ActionsIcons.Archive}
                        appearance="minimal"
                        color="primary"
                        onClick={() => archiveTemplateAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleTestSend() && (
                <Tooltip text={t(l.TestTemplate)}>
                    <Button
                        icon={ActionsIcons.MessageClosed}
                        appearance="minimal"
                        color="primary"
                        onClick={() => testTemplateAction(data)}
                    />
                </Tooltip>
            )}
            {isVisibleClone() && (
                <AccessControl permissions={PERMISSIONS.CreateTemplate}>
                    <Tooltip text={t(l.CloneTemplate)}>
                        <Button
                            icon={ActionsIcons.Clone}
                            appearance="minimal"
                            color="primary"
                            onClick={() => cloneTemplateAction(data)}
                        />
                    </Tooltip>
                </AccessControl>
            )}
            {isInfoVisible && (
                <Tooltip text={t(l.TemplateInfo)}>
                    <Button
                        icon={ActionsIcons.Stop}
                        appearance="minimal"
                        color="primary"
                        onClick={() => infoTemplateAction(data)}
                    />
                </Tooltip>
            )}
        </>
    );
};

TemplateActionsButtons.propTypes = {
    data: PropTypes.object.isRequired,
    testTemplateAction: PropTypes.func,
    unarchiveTemplateAction: PropTypes.func,
    archiveTemplateAction: PropTypes.func,
    deleteTemplateAction: PropTypes.func,
    cloneTemplateAction: PropTypes.func,
    editTemplateAction: PropTypes.func,
    infoTemplateAction: PropTypes.func,
    isInfoVisible: PropTypes.bool,
    isViewMode: PropTypes.bool,
    isDeleteVisible: PropTypes.bool,
    isEditVisible: PropTypes.bool,
    isCloneVisible: PropTypes.bool,
    isTestSendVisible: PropTypes.bool,
    isUnArchiveVisible: PropTypes.bool,
    isArchiveVisible: PropTypes.bool,
};

TemplateActionsButtons.defaultProps = {
    testTemplateAction: noop,
    unarchiveTemplateAction: noop,
    archiveTemplateAction: noop,
    deleteTemplateAction: noop,
    cloneTemplateAction: noop,
    editTemplateAction: noop,
    infoTemplateAction: noop,
    isInfoVisible: false,
    isViewMode: false,
    isDeleteVisible: null,
    isEditVisible: null,
    isCloneVisible: null,
    isTestSendVisible: null,
    isUnArchiveVisible: null,
    isArchiveVisible: null,
};

export default memo(TemplateActionsButtons);
