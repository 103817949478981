import React, { memo, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
// Import Services
import { ReportHttpService } from 'services/http';
// Import Hooks
import { useRequest } from 'hooks';
// Import UI Components
import { BusyLoader, Title } from '@geneui/components';
// Import Components
import DynamicSegmentInfo from 'components/DynamicSegment/DynamicSegmentInfo';
import ColumnKPIs from './ColumnKPIs';
// Import Constants
import { l, ReportTypesLabels } from 'constants/common';
//Import SCSS
import 'assets/scss/reportInfoModal.scss';

const ColumnKPIsAndFiltersInfo = ({ reportType, reportInfo, segmentFiltersData }) => {
    const { t } = useTranslation();
    const { doGetRequest } = useRequest();
    const [columns, setColumns] = useState(null);

    const { getReportConfigurationColumns } = useMemo(
        () => ({
            getReportConfigurationColumns: ReportHttpService.getReportConfigurationColumns(),
        }),
        [],
    );

    const init = () => {
        doGetRequest(getReportConfigurationColumns.request, {
            successCallback: (Data) =>
                setColumns(
                    Data.reduce((acc, item) => {
                        acc = { ...acc, [item.Id]: item };
                        return acc;
                    }, {}),
                ),
        });
    };

    const cleanUp = () => {
        return () => {
            getReportConfigurationColumns.cancel('ReportInfoModal:getReportConfigurationColumns');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <>
            <div className="segment-modal-bottom segment-modal-head-bottom">
                <Title text={t(l.ColumnKPIs)} withLine />
                <BusyLoader isBusy={isNil(columns)} type="spinner" spinnerSize="medium">
                    {!isNil(columns) && (
                        <ColumnKPIs
                            kpis={reportInfo.QueryConfiguration.Columns.map((item) =>
                                isNil(columns[item.Id]) ? item : columns[item.Id],
                            )}
                        />
                    )}
                </BusyLoader>
            </div>
            {reportType === ReportTypesLabels.Report && (
                <div className="segment-modal-bottom">
                    <Title text={t(l.SegmentInfo)} withLine />
                    <div className="segment-modal-b-content c-s-b-content">
                        <DynamicSegmentInfo segmentFiltersData={segmentFiltersData} />
                    </div>
                </div>
            )}
        </>
    );
};

ColumnKPIsAndFiltersInfo.propTypes = {
    reportType: PropTypes.number.isRequired,
    reportInfo: PropTypes.object.isRequired,
    segmentFiltersData: PropTypes.shape({
        [PropTypes.string]: {
            id: PropTypes.number,
            isMain: PropTypes.bool,
            seqId: PropTypes.number,
            name: PropTypes.string,
            filters: PropTypes.shape({
                [PropTypes.string]: PropTypes.array,
            }),
        },
    }),
};

export default memo(ColumnKPIsAndFiltersInfo);
