import { has, values, isEmpty, cloneDeep, keys, head, isNil } from 'lodash';
// Import Services
import { SegmentHttpService } from 'services/http';
// Import Actions
import { DynamicSegmentActions, QueryConfigurationsActions } from 'actions';
//Import Constants
import { l } from 'constants/common';

const { setDefaultFilterList } = DynamicSegmentActions;
const { getQueryConfigurations } = QueryConfigurationsActions;

const createResultDataForSubSegments = (subSegmentFilters) => {
    return subSegmentFilters.map(({ filters, id, name }) => {
        return {
            Id: id,
            Name: name,
            QueryConfiguration: {
                Columns: null,
                Filters: [filters],
                Table: { Id: 4 },
            },
        };
    });
};

const createUIModelDataFromQueryConfigurations = (queryConfigurations, data, objectId, t) => {
    let resultFilterList = {};
    const mainSegmentFilterList = head(data.Filters);
    const subSegmentsFilterList = data?.SubSegments;
    const mainResultFilterList = createFilterListFromQueryConfigurations(queryConfigurations, mainSegmentFilterList);
    resultFilterList[objectId] = {
        filters: mainResultFilterList,
        id: objectId,
        isMain: true,
        seqId: 0,
        name: t(l.MainSegment),
    };

    const subSegmentsResultFilterList = isNil(subSegmentsFilterList)
        ? {}
        : subSegmentsFilterList.reduce((acc, val, index) => {
              acc[val.Id] = {
                  seqId: index + 1,
                  id: val.Id,
                  name: val.Name,
                  filters: val.QueryConfiguration.Filters.reduce((acc, val) => {
                      acc = createFilterListFromQueryConfigurations(queryConfigurations, val);
                      return acc;
                  }, {}),
              };
              return acc;
          }, {});

    resultFilterList = { ...resultFilterList, ...subSegmentsResultFilterList };

    return resultFilterList;
};

const createFilterListFromQueryConfigurations = (queryConfigurations, filterData) => {
    const resultFilterList = {};
    filterData.forEach((filter) => {
        const { Column, Comparision, Value } = filter;
        const comparison = cloneDeep(queryConfigurations[`comparison:${Column.Id}/${Comparision}`]);
        comparison.setValue(Value);
        if (has(resultFilterList, comparison.parent.hashKey)) {
            resultFilterList[comparison.parent.hashKey] = [...resultFilterList[comparison.parent.hashKey], comparison];
        } else {
            comparison.setAdditionalData(null);
            resultFilterList[comparison.parent.hashKey] = [comparison];
        }
    });
    return resultFilterList;
};

const createResultDataForDynamicSegment = (Name, Description, filterList, segmentId) => {
    const mainSegmentFilters = filterList.find((el) => el.isMain);
    const subSegmentFilters = filterList.filter((el) => !el.isMain);
    const resultData = {
        Name,
        Description,
        QueryConfiguration: {
            Columns: null,
            Filters: [mainSegmentFilters.filters],
            Table: { Id: 4 },
        },
    };
    if (segmentId) {
        resultData.SegmentId = +segmentId;
    }
    if (!isEmpty(subSegmentFilters)) {
        resultData.QueryConfiguration.SubSegments = createResultDataForSubSegments(subSegmentFilters);
    }
    return resultData;
};

const createQueryConfigurationsFromFilters = (filterList, Columns = null) => {
    const filters = createResultFilterListForDynamicSegment(filterList);
    const mainSegmentFilters = filters.find((el) => el.isMain);
    const subSegmentFilters = filters.filter((el) => !el.isMain);

    const resultData = {
        Columns,
        Table: { Id: 4 },
        Filters: [mainSegmentFilters.filters],
    };
    if (!isEmpty(subSegmentFilters)) {
        resultData.SubSegments = createResultDataForSubSegments(subSegmentFilters);
    }

    return resultData;
};

const createResultFilterListForDynamicSegment = (filterList) => {
    return keys(filterList).map((id) => {
        const filters = [];
        values(filterList[id]?.filters).forEach((filterGroup) => {
            filterGroup.forEach(({ comparison, Value }) => {
                filters.push({
                    Column: { Id: comparison.columnId },
                    Comparision: comparison.comparison,
                    Value,
                });
            });
        });
        return {
            filters: filters,
            id,
            name: filterList[id]?.name,
            seqId: filterList[id]?.seqId,
            isMain: filterList[id]?.isMain ?? false,
        };
    });
};

const getQueryConfigurationsList = () => {
    const getQueryConfigurationsListRequest = SegmentHttpService.getQueryConfigurationsList();
    return {
        request: (dispatch, queryConfigurations, objectInfo, action, t) => {
            if (isEmpty(queryConfigurations)) {
                getQueryConfigurations(dispatch, getQueryConfigurationsListRequest.request)
                    .then((res) => {
                        setFilterList(dispatch, res, objectInfo, action, t);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    });
            } else {
                setFilterList(dispatch, queryConfigurations, objectInfo, action, t);
            }
        },
        cancel: getQueryConfigurationsListRequest.cancel,
    };
};

function setFilterList(dispatch, queryConf, objectInfo, action, t) {
    const data = createUIModelDataFromQueryConfigurations(
        queryConf,
        objectInfo.QueryConfiguration,
        objectInfo.SegmentId ?? objectInfo.ReportId,
        t,
    );

    if (action?.shouldNotDispatch) {
        action.setData(data);
        return;
    }

    const dispatchAction = action || setDefaultFilterList;
    dispatch(dispatchAction(data));
}

export {
    createFilterListFromQueryConfigurations,
    createResultDataForDynamicSegment,
    createResultFilterListForDynamicSegment,
    createQueryConfigurationsFromFilters,
    getQueryConfigurationsList,
    createUIModelDataFromQueryConfigurations,
};
