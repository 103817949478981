import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { has, isEmpty, noop } from 'lodash';
// Import UI Components
import { ScheduleReportModal, ReportInfoModal, StopScheduleReportModal, ConfirmationModal } from 'components';
// Import Hooks
import { useRequest } from 'hooks';
// Import Services
import { ReportHttpService } from 'services/http';
import { Helpers } from 'services';
// Import Constants
import { ModalsActions, l, ActionsIcons, ModalsClassNames } from 'constants/common';

const ReportActionsModals = ({
    isDeleteModalOpenedState,
    isScheduleModalOpenedState,
    isStopScheduleModalOpenedState,
    isInfoModalOpenedState,
    isExecuteModalOpenedState,
    isUsedInConfirmationModalOpenedState,
    isArchiveModalOpenedState,
    isUnarchiveModalOpenedState,
    onCloseModalHandler,
    modalsStateSetDict,
    openedModalData,
    goToEditReport,
}) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const { doPostRequest, doDeleteRequest } = useRequest();

    const {
        deleteReportRequest,
        deleteReportsRequest,
        scheduleReportRequest,
        stopReportScheduleRequest,
        getReportScheduleRequest,
        executeReportRequest,
        archiveReportsRequest,
        unarchiveReportsRequest,
    } = useMemo(
        () => ({
            deleteReportRequest: ReportHttpService.deleteReport(),
            deleteReportsRequest: ReportHttpService.deleteReports(),
            scheduleReportRequest: ReportHttpService.scheduleReport(),
            stopReportScheduleRequest: ReportHttpService.stopReportSchedule(),
            getReportScheduleRequest: ReportHttpService.getReportSchedule(),
            executeReportRequest: ReportHttpService.executeReport(),
            archiveReportsRequest: ReportHttpService.archiveReports(),
            unarchiveReportsRequest: ReportHttpService.unarchiveReports(),
        }),
        [],
    );

    const { ReportId, ReportType, Name } = openedModalData;
    const { getUsedInConfirmationText } = Helpers;

    const deleteReport = () => {
        setIsLoading(true);
        doDeleteRequest(deleteReportRequest.request, {
            queryString: { reportId: ReportId, reportType: ReportType },
        }).then(({ AlertType, AlertMessage }) => {
            onCloseModalHandler(modalsStateSetDict.deleteReport.key, ModalsActions.DELETE, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const executeReport = () => {
        setIsLoading(true);
        doPostRequest(executeReportRequest.request, {
            requestBody: { CustomReportId: ReportId, Type: ReportType },
        }).then(({ AlertType, AlertMessage }) => {
            onCloseModalHandler(
                modalsStateSetDict.executeReport.key,
                ModalsActions.EXECUTE_REPORT,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const stopReportSchedule = () => {
        setIsLoading(true);
        doPostRequest(stopReportScheduleRequest.request, {
            requestBody: { ReportId: ReportId, Type: ReportType },
        }).then(({ AlertType, AlertMessage }) => {
            onCloseModalHandler(
                modalsStateSetDict.stopScheduleReport.key,
                ModalsActions.UNSCHEDULE_REPORT,
                AlertType,
                AlertMessage,
            );
            setIsLoading(false);
        });
    };

    const deleteReports = () => {
        setIsLoading(true);
        const { allowDeleteReports } = openedModalData;
        if (isEmpty(allowDeleteReports)) {
            return onCloseModalHandler(modalsStateSetDict.deleteReport.key);
        }
        const allowDeleteReportsIds = allowDeleteReports.map(({ reportId, reportType }) => {
            return { reportId, reportType };
        });
        doPostRequest(deleteReportsRequest.request, {
            requestBody: allowDeleteReportsIds,
        }).then(({ AlertType, AlertMessage }) => {
            onCloseModalHandler(
                modalsStateSetDict.deleteReport.key,
                ModalsActions.DELETE,
                AlertType,
                AlertMessage,
                allowDeleteReportsIds.length,
            );
            setIsLoading(false);
        });
    };

    const scheduleReport = (params) => {
        setIsLoading(true);
        doPostRequest(scheduleReportRequest.request, {
            requestBody: params,
        }).then(({ AlertType, AlertMessage }) => {
            setIsLoading(false);
            onCloseModalHandler(
                modalsStateSetDict.scheduleReport.key,
                ModalsActions.SCHEDULE_REPORT,
                AlertType,
                AlertMessage,
            );
        });
    };

    const archiveUnarchiveReport = (isArchive) => {
        setIsLoading(true);
        const requestObject = isArchive ? archiveReportsRequest.request : unarchiveReportsRequest.request;
        const modalKey = isArchive ? modalsStateSetDict.archiveReport.key : modalsStateSetDict.unarchiveReport.key;
        const modalAction = isArchive ? ModalsActions.ARCHIVE : ModalsActions.UNARCHIVE;
        const { ReportType: reportType, ReportId: reportId } = openedModalData;

        doPostRequest(requestObject, {
            requestBody: [{ reportId, reportType }],
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalKey, modalAction, AlertType, AlertMessage);
            setIsLoading(false);
        });
    };

    const archiveUnarchiveReports = (isArchive) => {
        setIsLoading(true);
        const requestObject = isArchive ? archiveReportsRequest.request : unarchiveReportsRequest.request;
        const modalKey = isArchive ? modalsStateSetDict.archiveReport.key : modalsStateSetDict.unarchiveReport.key;
        const modalAction = isArchive ? ModalsActions.ARCHIVE : ModalsActions.UNARCHIVE;
        const allowedReports = isArchive ? openedModalData.allowArchiveReports : openedModalData.allowUnarchiveReports;

        if (isEmpty(allowedReports)) {
            setIsLoading(false);
            return onCloseModalHandler(modalKey);
        }
        const allowReportsIdsTypes = allowedReports.map(({ reportId, reportType }) => {
            return { reportId, reportType };
        });
        doPostRequest(requestObject, {
            requestBody: allowReportsIdsTypes,
        }).then(({ AlertMessage, AlertType }) => {
            onCloseModalHandler(modalKey, modalAction, AlertType, AlertMessage, allowReportsIdsTypes.length);
            setIsLoading(false);
        });
    };

    const isBulkAction = () => {
        return (
            has(openedModalData, 'allowDeleteReports') ||
            has(openedModalData, 'allowArchiveReports') ||
            has(openedModalData, 'allowUnarchiveReports')
        );
    };

    const cleanUp = () => {
        return () => {
            deleteReportRequest.cancel('ReportListPage:deleteReportRequest');
            deleteReportsRequest.cancel('ReportListPage:deleteReportsRequest');
            scheduleReportRequest.cancel('ReportListPage:scheduleReportRequest');
            stopReportScheduleRequest.cancel('ReportListPage:stopReportScheduleRequest');
            getReportScheduleRequest.cancel('ReportListPage:getReportScheduleRequest');
            executeReportRequest.cancel('ReportListPage:executeReportRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        !isEmpty(openedModalData) && (
            <>
                {isDeleteModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? deleteReports : deleteReport}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowDeleteReports) : true}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.deleteReport.key)}
                        isVisibleModal={isDeleteModalOpenedState}
                        titleText={isBulkAction() ? t(l.DeleteSelectedReports) : t(l.DeleteReport)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Delete}
                        actionLabel={t(l.Delete)}
                        className={ModalsClassNames.Delete}
                        allowDataList={openedModalData.allowDeleteReports}
                        notAllowDataList={openedModalData.notAllowDeleteReports}
                        allowDataLabel={l.AllowDeleteSelectedReports}
                        notAllowDataLabel={l.NotAllowDeleteSelectedReports}
                        questionLabel={
                            isBulkAction()
                                ? l.DeleteSelectedReportsQuestion
                                : t(l.AreYouSureYouWantToDeleteReport, {
                                      reportName: `"${openedModalData.Name.Name}"`,
                                  })
                        }
                        isBulkAction={isBulkAction()}
                    />
                )}
                {isScheduleModalOpenedState && (
                    <ScheduleReportModal
                        onOk={scheduleReport}
                        isVisibleModal={isScheduleModalOpenedState}
                        titleText={t(l.ScheduleReport)}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.scheduleReport.key)}
                        reportId={ReportId}
                        reportType={ReportType}
                        isLoading={isLoading}
                    />
                )}
                {isStopScheduleModalOpenedState && (
                    <StopScheduleReportModal
                        onOk={() => stopReportSchedule()}
                        isVisibleModal={isStopScheduleModalOpenedState}
                        titleText={t(l.StopReportSchedule)}
                        confirmationText={t(l.DoYouWantToStopReportSchedule, {
                            reportName: `"${openedModalData.Name.Name}"`,
                        })}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.stopScheduleReport.key)}
                        isLoading={isLoading}
                    />
                )}
                {isInfoModalOpenedState && (
                    <ReportInfoModal
                        onOk={() => noop()}
                        isVisibleModal={isInfoModalOpenedState}
                        reportInfo={openedModalData}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.infoReport.key)}
                    />
                )}
                {isExecuteModalOpenedState && (
                    <ConfirmationModal
                        onOk={() => executeReport()}
                        isVisibleModal={isExecuteModalOpenedState}
                        titleText={t(l.ExecuteReport)}
                        questionLabel={t(l.DoYouWantToExecuteReport, { reportName: `"${openedModalData.Name.Name}"` })}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.executeReport.key)}
                        isLoading={isLoading}
                        iconType={ActionsIcons.Start}
                        actionLabel={t(l.Start)}
                        className={ModalsClassNames.Start}
                    />
                )}
                {isUsedInConfirmationModalOpenedState && (
                    <ConfirmationModal
                        onOk={() => goToEditReport(openedModalData.report)}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.editReport.key)}
                        isVisibleModal={isUsedInConfirmationModalOpenedState}
                        titleText={t(l.EditReport)}
                        questionLabel={getUsedInConfirmationText(
                            t,
                            l.Report,
                            openedModalData?.objects,
                            l.IsUsedInObjectsLabel,
                        )}
                        iconType={ActionsIcons.UsedIn}
                        actionLabel={t(l.Continue)}
                        className={ModalsClassNames.UsedIn}
                    />
                )}
                {isArchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={isBulkAction() ? () => archiveUnarchiveReports(true) : () => archiveUnarchiveReport(true)}
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.archiveReport.key)}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowArchiveReports) : true}
                        isVisibleModal={isArchiveModalOpenedState}
                        titleText={t(l.ArchiveReport)}
                        questionLabel={
                            isBulkAction()
                                ? l.ArchiveSelectedReportsQuestion
                                : t(l.AreYouSureYouWantToArchiveReport, {
                                      reportName: `"${Name?.Name}"`,
                                  })
                        }
                        isLoading={isLoading}
                        isBulkAction={isBulkAction()}
                        allowDataList={openedModalData.allowArchiveReports}
                        notAllowDataList={openedModalData.notAllowArchiveReports}
                        allowDataLabel={l.AllowArchiveSelectedReports}
                        notAllowDataLabel={l.NotAllowArchiveSelectedReports}
                        iconType={ActionsIcons.Archive}
                        actionLabel={t(l.Archive)}
                        className={ModalsClassNames.Archive}
                    />
                )}
                {isUnarchiveModalOpenedState && (
                    <ConfirmationModal
                        onOk={
                            isBulkAction() ? () => archiveUnarchiveReports(false) : () => archiveUnarchiveReport(false)
                        }
                        onCancel={() => onCloseModalHandler(modalsStateSetDict.unarchiveReport.key)}
                        isOkActive={isBulkAction() ? !isEmpty(openedModalData.allowUnarchiveReports) : true}
                        isVisibleModal={isUnarchiveModalOpenedState}
                        titleText={t(l.UnarchiveReport)}
                        questionLabel={
                            isBulkAction()
                                ? l.ArchiveSelectedReportsQuestion
                                : t(l.AreYouSureYouWantToArchiveReport, {
                                      reportName: `"${Name?.Name}"`,
                                  })
                        }
                        isLoading={isLoading}
                        isBulkAction={isBulkAction()}
                        allowDataList={openedModalData.allowUnarchiveReports}
                        notAllowDataList={openedModalData.notAllowUnarchiveReports}
                        allowDataLabel={l.AllowUnarchiveSelectedReports}
                        notAllowDataLabel={l.NotAllowUnarchiveSelectedReports}
                        iconType={ActionsIcons.UnArchive}
                        actionLabel={t(l.Unarchive)}
                        className={ModalsClassNames.UnArchive}
                    />
                )}
            </>
        )
    );
};

ReportActionsModals.propTypes = {
    isDeleteModalOpenedState: PropTypes.bool.isRequired,
    isScheduleModalOpenedState: PropTypes.bool.isRequired,
    isStopScheduleModalOpenedState: PropTypes.bool.isRequired,
    isInfoModalOpenedState: PropTypes.bool.isRequired,
    isExecuteModalOpenedState: PropTypes.bool.isRequired,
    isUsedInConfirmationModalOpenedState: PropTypes.bool.isRequired,
    isArchiveModalOpenedState: PropTypes.bool.isRequired,
    isUnarchiveModalOpenedState: PropTypes.bool.isRequired,
    onCloseModalHandler: PropTypes.func.isRequired,
    modalsStateSetDict: PropTypes.object.isRequired,
    openedModalData: PropTypes.any.isRequired,
    goToEditReport: PropTypes.func.isRequired,
};

export default memo(ReportActionsModals);
