import React, { useEffect, useImperativeHandle, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep, isNil, has, noop, isFunction, first, isArray } from 'lodash';
// Import Hooks
import { useQueryParams, useFilterFieldsState } from 'hooks';
// Import UI Components
import { Button } from '@geneui/components';
// Import Components
import { HolderWrapper, FilterFields } from 'components';
// Import Services
import { FilterHolderComponentsTypes, FiltersFieldsMap } from 'components/common/FilterFields/service';
// Import Constants
import { l } from 'constants/common';

/**
 * @component
 *
 * @namespace
 * @property {array}  defaultInputsData      - The default values for parties.
 * @property {object}  defaultInputsData.item  - Example of the defaultInputsData item.
 */
const FilterHolder = forwardRef(
    (
        {
            defaultInputsData,
            onApply,
            onReset,
            isDisabled,
            filterHolderKey,
            filterTagRef,
            withQueryParams,
            storeKey,
            ...restProps
        },
        filterHolderRef,
    ) => {
        const { t } = useTranslation();
        const pageState = useSelector((state) => state[storeKey]);
        const storedFilters = pageState?.tableData?.filters;
        const { filters, appliedFilters, changeFilters, applyFilters, resetFilters, isResetDisabled, isApplyDisabled } =
            useFilterFieldsState(defaultInputsData);

        const { decode, historyReplace } = useQueryParams(filterHolderKey);

        const changeFiltersHandler = (
            filters,
            isProcessSupportedValues = false,
            changeApplyBtnDisabledState = true,
        ) => {
            let tmpFilters = filters;
            if (isProcessSupportedValues) {
                tmpFilters = filters.map((filterField) => {
                    if (filterField.type === FilterHolderComponentsTypes.multiSelect.keyName) {
                        if (!isNil(filterField?.data) && isArray(filterField.data)) {
                            const supportedValues = filterField.data.reduce((acc, item) => [...acc, item.value], []);
                            filterField.value = filterField?.value.filter((item) => {
                                return supportedValues.includes(item);
                            });
                        } else {
                            filterField.value = [];
                        }
                    }

                    return filterField;
                });
            }
            changeFilters(tmpFilters, changeApplyBtnDisabledState);
            return tmpFilters;
        };

        const init = () => {
            const params = withQueryParams ? decode() : {};
            const hasSearchParamsValue = params ? filters.find((data) => has(params, data.valueFieldKey)) : -1;

            let resultFilterInputsData = filters;

            if (hasSearchParamsValue !== -1) {
                resultFilterInputsData = filters.map((filterInputData, index) => {
                    const { valueFieldKey, type } = filterInputData;
                    if (!isNil(params[valueFieldKey]) && params[valueFieldKey] !== '') {
                        const isMultiSelect = type === FilterHolderComponentsTypes.multiSelect.keyName;
                        if (type === FilterHolderComponentsTypes.rangeDate.keyName || isMultiSelect) {
                            filterInputData.value = params[valueFieldKey];

                            if (isMultiSelect) {
                                filterInputData.value = params[valueFieldKey].map((value) => {
                                    return +value;
                                });
                            }
                        } else if (type === FilterHolderComponentsTypes.checkbox.keyName) {
                            filterInputData.checked = params[valueFieldKey] === 'true';
                        } else {
                            filterInputData.value = params[valueFieldKey];
                        }
                    } else {
                        filterInputData.value = defaultInputsData[index].value;
                    }

                    return filterInputData;
                });
                changeFiltersHandler(resultFilterInputsData);
                applyFilters();
            }

            setParamsToUrl(false, resultFilterInputsData);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(init, [storedFilters]);

        const setParamsToUrl = (withApply, data = filters) => {
            const resultData = FiltersFieldsMap(data);

            if (withQueryParams) {
                historyReplace(resultData);
            }

            if (withApply) {
                applyFilters();
                onApply(resultData);
            }
        };

        const handleReset = () => {
            const resetList = cloneDeep(filters);

            resetList.forEach((filterField) => {
                if (has(filterField, 'value')) {
                    filterField.value = '';
                }
            });

            resetFilters();
            setParamsToUrl(false, resetList);
            isFunction(onReset) && onReset();
        };

        const handleApply = () => {
            setParamsToUrl(true);
        };

        const handleChange = (e, changeInputValue, customChange, index, filterInputData) => {
            const resultData = changeInputValue(e, customChange, cloneDeep(filters), index, filterInputData);
            changeFiltersHandler(resultData);
        };

        useEffect(() => {
            if (isDisabled === false) {
                const mergedData = filters.map((filterField) => {
                    filterField.data = first(
                        defaultInputsData.filter(({ valueFieldKey }) => {
                            return valueFieldKey === filterField.valueFieldKey;
                        }),
                    )?.data;

                    return filterField;
                });

                changeFiltersHandler(mergedData, true);
                applyFilters();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isDisabled]);

        useImperativeHandle(filterHolderRef, () => ({
            changeAndApplyFilters: (filters) => {
                const tmpFilters = changeFiltersHandler(filters, true, false);
                setParamsToUrl(true, tmpFilters);
            },
            filtersState: appliedFilters,
        }));

        useEffect(() => {
            if (isDisabled === false && isFunction(filterTagRef?.current?.changeFilters)) {
                filterTagRef.current.changeFilters(appliedFilters);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [appliedFilters]);

        return (
            <HolderWrapper
                isDisabled={isDisabled}
                title={t(l.Filters)}
                position="left"
                expandText={t(l.Expand)}
                collapseText={t(l.Collapse)}
                disableOnHover={true} // TODO: can be supported when UI lib will be deliver support for onMouseLeave functional
                footer={
                    <>
                        <Button
                            appearance="minimal"
                            color="default"
                            flexibility="full-width"
                            onClick={handleReset}
                            disabled={isResetDisabled}
                        >
                            {t(l.Reset)}
                        </Button>

                        <Button
                            color="confirm"
                            flexibility="full-width"
                            onClick={handleApply}
                            disabled={isApplyDisabled}
                        >
                            {t(l.Apply)}
                        </Button>
                    </>
                }
                {...restProps}
            >
                {!isDisabled && <FilterFields filterInputsData={filters} handleChange={handleChange} />}
            </HolderWrapper>
        );
    },
);

FilterHolder.displayName = 'FilterHolder';

const isRequiredDataField = ({ type, data }) => {
    return (
        (type === FilterHolderComponentsTypes.select.keyName && data) ||
        type !== FilterHolderComponentsTypes.select.keyName
    );
};

FilterHolder.defaultProps = {
    onReset: noop,
    isDisabled: false,
    withQueryParams: true,
};

FilterHolder.propTypes = {
    defaultInputsData: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            valueFieldKey: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            value: PropTypes.any,
            data: isRequiredDataField,
        }),
    ).isRequired,
    onApply: PropTypes.func.isRequired,
    filterHolderKey: PropTypes.string.isRequired,
    onReset: PropTypes.func,
    isDisabled: PropTypes.bool,
    filterTagRef: PropTypes.any,
    withQueryParams: PropTypes.bool,
    storeKey: PropTypes.string,
};

export default FilterHolder;
