import React, { memo, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep, isNil, isObject } from 'lodash';
// Import Hooks
import { useListBaseRequestBody, useQueryParams, useRequest } from 'hooks';
// Import Components
import { DataTableHeaderFilter } from 'components';
// Import Services
import { Helpers } from 'services';
import { CommunicationHttpService, TemplateHttpService, AwardHttpService } from 'services/http';
// Import Actions
import { ActionCampaignPageCommunicationListAction } from 'actions';
// Import Constants
import { CommunicationListFilterConfig, getCommunicationsRequestBody } from './config';
import { CommunicationList } from 'components/CommunicationList';
import { FilterComparisions, CommunicationPromotionTypeLabels } from 'constants/common';

const { getFilterHolderKey } = Helpers;
const listSelectorKey = 'actionCampaignPageCommunicationList';

const ActionCampaignCommunicationList = ({ workflowId }) => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const { doPostRequest } = useRequest();

    const filterHolderQueryParams = useQueryParams(getFilterHolderKey('dataTableCommunication'));

    const { tableData } = cloneDeep(useSelector((state) => state[listSelectorKey]));
    const { filters } = tableData;
    const { setCommunicationListTableFilters } = ActionCampaignPageCommunicationListAction;
    const [baseRequestBody, newFilterField, setFilters] = useListBaseRequestBody(filters, 0, 0);

    const defaultFilters = getCommunicationsRequestBody('PromotionId', [
        `${CommunicationPromotionTypeLabels.ActionCampaign} , ${workflowId}`,
    ]);

    const { defaultInputsData } = CommunicationListFilterConfig;
    const { defaultInputsDataList, defaultInputsDataIds } = defaultInputsData;

    const customerJourneyCommunicationListRef = useRef();

    const { getTemplatesRequest, getAwardsListRequest, getPromotionsRequest } = useMemo(
        () => ({
            getTemplatesRequest: TemplateHttpService.getTemplateList(),
            getAwardsListRequest: AwardHttpService.getAwardsList(),
            getPromotionsRequest: CommunicationHttpService.getPromotionList(),
        }),
        [],
    );

    const getRequestBody = (name, value) => {
        return {
            Filters: [{ Name: name, Comparision: FilterComparisions.In, Values: value }],
            Pageing: { PageSize: 100, PageNumber: 1 },
            Sorting: { Name: 'CreatedDate', Direction: 'desc' },
        };
    };

    const onFilterApply = (filtersFields, isInit = false) => {
        // Filter holder filters
        defaultInputsDataList.forEach(({ valueFieldKey, comparision }) => {
            filters.push(newFilterField(valueFieldKey, comparision, filtersFields[valueFieldKey]));
        });
        setFilters(filters);
        dispatch(setCommunicationListTableFilters(baseRequestBody.Filters));
        customerJourneyCommunicationListRef.current.Filters = baseRequestBody.Filters;

        if (!isInit) {
            customerJourneyCommunicationListRef.current.reset();
        }
    };

    const onResetFilters = () => {
        const filterFieldsKeys = defaultInputsDataList.map(({ valueFieldKey }) => valueFieldKey);
        setFilters(filters.filter(({ Name }) => !filterFieldsKeys.includes(Name)));
        dispatch(setCommunicationListTableFilters(baseRequestBody.Filters));
        customerJourneyCommunicationListRef.current.reset();
    };

    // Init part
    const getTemplates = (value) => {
        return new Promise((resolve, reject) => {
            if (!isNil(value)) {
                doPostRequest(getTemplatesRequest.request, {
                    requestBody: getRequestBody('TemplateId', value),
                    successCallback: (data) => {
                        defaultInputsDataList[defaultInputsDataIds.TemplateId].data = isObject(data)
                            ? data.Data.map((item) => ({ label: item.Name, value: item.TemplateId }))
                            : [];
                        resolve({ isTemplatesReady: true });
                    },
                }).then(() => reject({ isTemplatesReady: false }));
            } else {
                resolve({ isTemplatesReady: true });
            }
        });
    };

    const getAwards = (value) => {
        return new Promise((resolve, reject) => {
            if (!isNil(value)) {
                doPostRequest(getAwardsListRequest.request, {
                    requestBody: getRequestBody('BonusId', value),
                    successCallback: (data) => {
                        defaultInputsDataList[defaultInputsDataIds.BonusId].data = isObject(data)
                            ? data.Data.map((item) => ({ label: item.Name, value: item.Id }))
                            : [];
                        resolve({ isAwardsReady: true });
                    },
                    errorCallback: () => reject({ isAwardsReady: false }),
                }).then(() => reject({ isAwardsReady: false }));
            } else {
                resolve({ isAwardsReady: true });
            }
        });
    };

    const getPromotions = (value) => {
        return new Promise((resolve, reject) => {
            if (!isNil(value)) {
                doPostRequest(getPromotionsRequest.request, {
                    requestBody: getRequestBody('Id', [value]),
                    successCallback: (data) => {
                        defaultInputsDataList[defaultInputsDataIds.PromotionId].data = isObject(data)
                            ? data.Data.map((item) => ({
                                  value: item.Id,
                                  label: item.Name,
                                  type: item.Type,
                                  status: item.Status,
                              }))
                            : [];
                        resolve({ isAwardsReady: true });
                    },
                    errorCallback: () => reject({ isPromotionsReady: false }),
                }).then(() => {
                    reject({ isPromotionsReady: false });
                });
            } else {
                resolve({ isPromotionsReady: true });
            }
        });
    };

    const getHeaderActions = () => {
        return (
            <div className="table-header-filter-switcher-container">
                <div className="table-header-filter-container">
                    <DataTableHeaderFilter
                        defaultInputsData={defaultInputsDataList}
                        onApply={onFilterApply}
                        onReset={onResetFilters}
                    />
                </div>
            </div>
        );
    };

    const init = () => {
        let params = null;
        if (search) {
            params = filterHolderQueryParams.decode();
            if (isObject(params)) {
                onFilterApply(params, true);
            }
        } else {
            dispatch(setCommunicationListTableFilters(baseRequestBody.Filters));
        }

        Promise.all([getTemplates(params?.TemplateId), getAwards(params?.BonusId), getPromotions(params?.PromotionId)]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    const cleanUp = () => {
        return () => {
            // Cancel all async processes
            getTemplatesRequest.cancel('CustomerJourneyCommunicationList:getTemplatesRequest');
            getAwardsListRequest.cancel('CustomerJourneyCommunicationList:getAwardsListRequest');
            getPromotionsRequest.cancel('CustomerJourneyCommunicationList:getPromotionsRequest');
        };
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(cleanUp, []);

    return (
        <div className="crm-segment-list-page-wrapper crm-report-list-page-wrapper report-list-page-c communication-list-wrapper">
            <div className="crm-section crm-section-full-height">
                <CommunicationList
                    listAction={ActionCampaignPageCommunicationListAction}
                    getHeaderActions={getHeaderActions}
                    isShowVerticalDots={false}
                    isShowColumnChooser={true}
                    ref={customerJourneyCommunicationListRef}
                    listSelectorKey={listSelectorKey}
                    defaultFilter={defaultFilters}
                />
            </div>
        </div>
    );
};

ActionCampaignCommunicationList.displayName = 'ActionCampaignCommunicationList';

ActionCampaignCommunicationList.propTypes = {
    listSelectorKey: PropTypes.string,
    workflowId: PropTypes.number.isRequired,
};

export default memo(ActionCampaignCommunicationList);
