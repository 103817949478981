import React from 'react';
import { isObject } from 'lodash';
// Import Services
import { Helpers } from 'services';
import { TemplateHttpService, BonusHttpService } from 'services/http';
// Import Constants
import {
    l,
    DateTimeFormat,
    CommunicationType,
    CommunicationStatus,
    PERMISSIONS,
    AlertTypes,
    FilterComparisions,
    FilterHolderFields,
} from 'constants/common';

const { Equals, Between, In, Like } = FilterComparisions;
const { textInput, rangeDate, multiSelect, multiSelectAutocomplete } = FilterHolderFields;

const { customMomentWithoutTimezoneConversion } = Helpers;

const getRequestBody = (searchValue, sortColumn) => {
    return {
        Filters: [{ Name: 'Name', Comparision: Like, Values: [searchValue] }],
        Pageing: { PageSize: 100, PageNumber: 1 },
        Sorting: { Name: sortColumn, Direction: 'desc' },
    };
};

const PlayersColumns = (t) => {
    return {
        columns: [
            {
                text: t(l.ActionDate),
                dataKey: 'ActionDate',
                sortable: false,
                formatter: (ActionDate) => {
                    return ActionDate && customMomentWithoutTimezoneConversion(ActionDate).format(DateTimeFormat);
                },
            },
            {
                text: t(l.Country),
                dataKey: 'Country',
                sortable: false,
            },
            {
                text: t(l.Currency),
                dataKey: 'Currency',
                sortable: false,
            },
            {
                text: t(l.ClientId),
                dataKey: 'ClientId',
                sortable: false,
            },
            {
                text: t(l.Email),
                dataKey: 'Email',
                sortable: false,
            },
            {
                text: t(l.Gender),
                dataKey: 'Gender',
                sortable: false,
            },
            {
                text: t(l.FirstName),
                dataKey: 'FirstName',
                sortable: false,
            },
            {
                text: t(l.LastName),
                dataKey: 'LastName',
                sortable: false,
            },
        ],
    };
};

const TrackedLinksColumnsConfigs = (t) => {
    return {
        columns: [
            {
                text: t(l.Url),
                dataKey: 'Url',
                sortable: false,
                // eslint-disable-next-line react/prop-types
                colRenderer: (Url) => (
                    <a href={Url} target="_blank" rel="noopener noreferrer">
                        <span>{Url}</span>
                    </a>
                ),
            },
            {
                text: t(l.ClickedCount),
                dataKey: 'ClickedCount',
                sortable: false,
            },
        ],
    };
};

const ModalTypes = {
    Open: 'Open',
    Click: 'Click',
    UnsubscribeLink: 'UnsubscribeLink',
    TrackedLink: 'TrackedLink',
};

const ModalTitleLabels = {
    Open: l.ClientsWhoOpenMessage,
    Click: l.ClientsWhoClickMessage,
    UnsubscribeLink: l.ClientsWhoUnsubscribeLinkMessage,
    TrackedLink: l.ClientsWhoTrackedLinkMessage,
};

const CommunicationListFilterConfig = {
    defaultInputsData: {
        defaultInputsDataIds: {
            TemplateId: 4,
            BonusId: 5,
        },
        defaultInputsDataList: [
            {
                type: textInput.type,
                labelAppearance: 'swap',
                valueFieldKey: 'ClientId',
                comparision: Equals,
                displayName: l.PlayerId,
                value: '',
            },
            {
                type: rangeDate.type,
                labelAppearance: 'swap',
                valueFieldKey: 'SentDate',
                comparision: Between,
                popoverAlign: 'start',
                displayName: l.SentDate,
                value: [null, null],
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Channel',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Channel,
                value: [],
                data: CommunicationType,
            },
            {
                type: multiSelect.type,
                labelAppearance: 'swap',
                valueFieldKey: 'Status',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Status,
                value: [],
                data: CommunicationStatus,
            },
            {
                type: multiSelectAutocomplete.type,
                labelAppearance: 'swap',
                valueFieldKey: 'TemplateId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Template,
                value: [],
                data: [],
                getRequest: TemplateHttpService.getTemplateList,
                getData(requestObj, searchValue) {
                    return new Promise((resolve, reject) => {
                        requestObj
                            .request(getRequestBody(searchValue, 'CreatedDate'))
                            .then((response) => {
                                const { HasError, AlertMessage, Data } = response;
                                if (!HasError) {
                                    resolve(
                                        isObject(Data)
                                            ? Data.Data.map((item) => ({ label: item.Name, value: item.TemplateId }))
                                            : [],
                                    );
                                } else {
                                    reject({ AlertType: AlertTypes.error, AlertMessage: AlertMessage });
                                }
                            })
                            .catch((error) => {
                                reject({ AlertType: AlertTypes.danger, AlertMessage: error });
                            });
                    });
                },
            },
            {
                type: multiSelectAutocomplete.type,
                labelAppearance: 'swap',
                valueFieldKey: 'BonusId',
                isMultiSelect: true,
                hasSearch: true,
                comparision: In,
                displayName: l.Award,
                value: [],
                data: [],
                permission: PERMISSIONS.ViewAwards,
                getRequest: BonusHttpService.getBonusList,
                getData(requestObj, searchValue) {
                    return new Promise((resolve, reject) => {
                        requestObj
                            .request(getRequestBody(searchValue, 'Id'), {
                                isIncludeExpired: true,
                            })
                            .then((response) => {
                                const { HasError, AlertMessage, Data } = response;
                                if (!HasError) {
                                    resolve(
                                        isObject(Data)
                                            ? Data.Data.map((item) => ({ label: item.Name, value: item.Id }))
                                            : [],
                                    );
                                } else {
                                    reject({ AlertType: AlertTypes.error, AlertMessage: AlertMessage });
                                }
                            })
                            .catch((error) => {
                                reject({ AlertType: AlertTypes.danger, AlertMessage: error });
                            });
                    });
                },
            },
        ],
    },
};

const getCommunicationsRequestBody = (name, value) => {
    return {
        Filters: [{ Name: name, Comparision: FilterComparisions.In, Values: value }],
        Pageing: { PageSize: 20, PageNumber: 1 },
        Sorting: { Name: 'SentDate', Direction: 'desc' },
    };
};

export {
    PlayersColumns,
    TrackedLinksColumnsConfigs,
    ModalTypes,
    ModalTitleLabels,
    CommunicationListFilterConfig,
    getCommunicationsRequestBody,
};
