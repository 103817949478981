import { keys, has, isFunction } from 'lodash';

/**
 * @example
 * {
 *    EventName1: {
 *        Source1: (data) => {},
 *        Source2: (data) => {},
 *    },
 *    EventName2: {
 *        Source1: (data) => {},
 *        Source3: (data) => {},
 *    },
 * }
 */
const subscribedEvents = {};

/**
 * Subscribe listener to custom event with source.
 * And every event with source has one listener
 * @param eventName Event name
 * @param source Subscriber source name
 * @param listener Listener function with one argument
 * @throws {Error} When listener is not a function
 */
const subscribe = (eventName, source, listener) => {
    if (!isFunction(listener)) {
        throw new Error('`listener` must be a function');
    }

    if (!has(subscribedEvents, eventName)) {
        subscribedEvents[eventName] = {};
    }
    subscribedEvents[eventName][source] = listener;
};

/**
 * Unsubscribe custom event with source.
 * @param eventName Event name
 * @param source Subscriber source name
 */
const unsubscribe = (eventName, source) => {
    if (has(subscribedEvents, eventName)) {
        const subscribers = subscribedEvents[eventName];
        if (has(subscribers, source)) {
            delete subscribers[source];
        }
    }
};

/**
 * Publish custom event
 * @param eventName Event name
 * @param data Event publish data
 */
const publish = (eventName, data) => {
    if (has(subscribedEvents, eventName)) {
        const subscribers = subscribedEvents[eventName];
        keys(subscribers).forEach((key) => {
            try {
                subscribers[key](data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
            }
        });
    }
};

export { subscribe, unsubscribe, publish };
