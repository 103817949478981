import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { has, isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// Import Constants
import { formsTypes } from '../config';
import { l, CJModelRelationScopes, CJArgumentValuesTypes } from 'constants/common';
// Import UI Components
import { ExtendedInput } from '@geneui/components';
// Import Services
import { getFormContent, newNodeNameGenerator, getModalCollectedProperties } from 'services/customerJourney';
// Import Components
import ReferenceWrapper from './ReferenceWrapper';
// Import SCSS
import 'assets/scss/customerJourneyForms.scss';

const EditForm = ({ block, elements }) => {
    const { t } = useTranslation();
    const options = block?.data?.metaData?.Options;
    const [blockName, setBlockName] = useState(block?.data?.name);

    useEffect(() => {
        block.data.name = blockName;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blockName]);

    const handleUpdate = (option, formConfig, argumentInType = CJArgumentValuesTypes.StaticInArgument) => {
        return (value, paramArgumentInType = argumentInType) => {
            if (!isNil(value?.isValid)) {
                return;
            }

            const apiModel = formConfig.mapToApiModel(value, paramArgumentInType, option);
            if (!isNil(apiModel)) {
                block.data.apiModel[option.Name] = apiModel;
            } else if (!isNil(block.data.apiModel[option.Name])) {
                delete block.data.apiModel[option.Name];
            }
        };
    };

    const updateDependency = (optionType) => {
        return (data) => {
            const { shouldRemoveDependency, ...dependencyData } = data;
            const dependencyType = formsTypes[optionType]?.dependencyType;
            if (!isNil(dependencyType)) {
                if (isNil(block.data.dependencies)) {
                    block.data.dependencies = {};
                }

                if (shouldRemoveDependency && !isNil(block.data.dependencies[dependencyType])) {
                    delete block.data.dependencies[dependencyType];
                    return;
                }

                if (isNil(block.data.dependencies[dependencyType])) {
                    block.data.dependencies[dependencyType] = [];
                }
                block.data.dependencies[dependencyType] = block.data.dependencies[dependencyType].filter(
                    (item) => item.dependencyItemId !== data.dependencyItemId,
                );
                block.data.dependencies[dependencyType].push(dependencyData);
            }
        };
    };

    return (
        <>
            <ExtendedInput
                type="text"
                label={t(l.Name)}
                labelAppearance="swap"
                defaultValue={blockName}
                value={blockName}
                onChange={(e) => {
                    setBlockName(e.target.value);
                }}
                onBlur={() => {
                    setBlockName(newNodeNameGenerator(t, blockName, elements, block));
                }}
            />

            {options.map((option, index) => {
                const { Hidden, ModelRelationScope, BaseTypes, Name, CustomAttributes } = option;
                const { DisplayName } = CustomAttributes;
                if (!Hidden) {
                    const formConfig = getFormContent(BaseTypes, formsTypes);
                    if (!isNil(formConfig?.Component)) {
                        const { Component, optionType } = formConfig;
                        const dynamicProps = {};

                        dynamicProps['collectedProperties'] = getModalCollectedProperties(
                            ModelRelationScope,
                            block,
                            option,
                        );
                        dynamicProps['updateDependency'] = updateDependency(optionType);

                        if (!isNil(block?.data?.apiModel[Name])) {
                            dynamicProps['defaultValue'] = formConfig.mapToUIModel(block.data.apiModel[Name]);
                        }

                        const isRightSideOption = !has(formConfig, 'isLabelVisible');

                        return (
                            <div
                                key={index}
                                className={classNames('crm-cj-modal-option', {
                                    'right-side-option': isRightSideOption,
                                })}
                            >
                                {!isRightSideOption && (
                                    <div className="crm-cj-edit-modal-cmp-head">
                                        {formConfig.isLabelVisible && <span>{t(DisplayName)}</span>}
                                    </div>
                                )}
                                <div className="crm-cj-edit-modal-cmp-body">
                                    {ModelRelationScope !== CJModelRelationScopes.None &&
                                    formConfig?.onlyPrimitive !== true ? (
                                        <ReferenceWrapper
                                            optionType={optionType}
                                            Component={Component}
                                            getUpdate={handleUpdate(option, formConfig)}
                                            elements={elements}
                                            option={option}
                                            node={block}
                                            {...dynamicProps}
                                        />
                                    ) : (
                                        <Component
                                            getUpdate={handleUpdate(option, formConfig)}
                                            option={option}
                                            node={block}
                                            elements={elements}
                                            {...dynamicProps}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    }
                }
                return <></>;
            })}
        </>
    );
};

EditForm.propTypes = {
    block: PropTypes.object.isRequired, // TODO: refactor to shape of block object
    elements: PropTypes.array.isRequired,
};

export default memo(EditForm);
