// @ts-nocheck

import React, { useState, memo, useEffect, useCallback, FC } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
// Import UI components
import { Button } from '@geneui/components';
// Import Components
import { Scheduler } from 'components';
// Import Hooks
import { useValidator } from 'hooks';
// Import Constants
import { ScheduleModalViews, l, PageTypes } from 'constants/common';
//Import SCSS
import 'assets/scss/scheduler.scss';
// Import Services
import { resultScheduleDataCreator, isSchedulerUnitPairsInvalid } from 'services/schedular';

const { mainView, dailyView, weeklyView, monthlyView, annuallyView, notRepeatView } = ScheduleModalViews;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SchedulerWithModal: FC<any> = ({
    startDate,
    endDate,
    startTime,
    setStartDate,
    setEndDate,
    setStartTime,
    getRecCount,
    selectedDateSelector,
    setSelectedDateSelector,
    datesDict,
    setDatesDict,
    isVisibleRecCount,
    setIsVisibleRecCount,
    recurrenceData,
    isLoaded,
    pageType,
    campaignStatus,
    timeZone,
    setTimeZone,
    isActiveSchedule,
    setIsActiveSchedule,
    hasImmediately,
    withSwitcher,
    schedulerOffInfoMessage,
}) => {
    const { t } = useTranslation();
    const [modalView, setModalView] = useState(mainView);
    const validator = useValidator();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pageValidation = useSelector((state: { pageValidation: any }) => state.pageValidation);
    const [modalValidationErrorText, setModalValidationErrorText] = useState('');
    const [localDictData, setLocalDictData] = useState(datesDict);
    const cancelHandler = () => {
        setModalView(mainView);
        setSelectedDateSelector(datesDict.lastValidView || notRepeatView);
    };

    const startDateChangeHandler = (value) => {
        let endDateValue = endDate;
        if (selectedDateSelector === notRepeatView) {
            endDateValue = value;
            setEndDate(value);
        }
        const Schedule = resultScheduleDataCreator(
            startTime,
            value,
            endDateValue,
            selectedDateSelector,
            datesDict,
            timeZone,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
        setStartDate(value);
        validator({
            SchedulerStartDate: {
                fromDate: value,
                fromTime: startTime,
                toDate: Schedule.EndDate,
                timeZone,
                pageType,
                campaignStatus,
            },
            SchedulerEndDate: { fromDate: Schedule.StartDate, fromTime: startTime, toDate: Schedule.EndDate },
        });
    };

    const startTimeChangeHandler = (e) => {
        const { value } = e.target;

        const match = value.match(/[0-9]?[0-9]?:?[0-9]?[0-9]?/g);
        let matchedValue = match[0] || match[1] || '';

        if (matchedValue.length === 2) {
            matchedValue += ':';
        }

        const Schedule = resultScheduleDataCreator(
            matchedValue,
            startDate,
            endDate,
            selectedDateSelector,
            datesDict,
            timeZone,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
        setStartTime(matchedValue);

        validator({
            SchedulerStartDate: {
                fromDate: startDate,
                fromTime: matchedValue,
                toDate: endDate,
                timeZone,
                pageType,
                campaignStatus,
            },
            SchedulerEndDate: { fromDate: startDate, fromTime: matchedValue, toDate: endDate },
            SchedulerStartTime: matchedValue,
        });
    };

    const endDateChangeHandler = (value) => {
        const Schedule = resultScheduleDataCreator(
            startTime,
            startDate,
            value,
            selectedDateSelector,
            datesDict,
            timeZone,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
        setEndDate(value);
        validator({
            SchedulerStartDate: {
                fromDate: startDate,
                fromTime: startTime,
                toDate: value,
                timeZone,
                pageType,
                campaignStatus,
            },
            SchedulerEndDate: { fromDate: startDate, fromTime: startTime, toDate: value },
        });
    };

    const timeZoneChangeHandler = (value) => {
        setTimeZone(value);
        const Schedule = resultScheduleDataCreator(
            startTime,
            startDate,
            endDate,
            selectedDateSelector,
            datesDict,
            value,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
        validator({
            SchedulerStartDate: {
                fromDate: startDate,
                fromTime: startTime,
                toDate: endDate,
                timeZone: value,
                pageType,
                campaignStatus,
            },
            SchedulerEndDate: { fromDate: startDate, fromTime: startTime, toDate: endDate },
        });
    };

    const doneHandler = () => {
        const isInvalid = isSchedulerUnitPairsInvalid(localDictData[modalView]);

        if (!isInvalid) {
            const Schedule = resultScheduleDataCreator(
                startTime,
                startDate,
                endDate,
                selectedDateSelector,
                localDictData,
                timeZone,
            );
            getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
            setSelectedDateSelector(modalView);
            setModalView(mainView);
            setIsVisibleRecCount(true);
            setDatesDict({ ...localDictData, lastValidView: modalView });
            setModalValidationErrorText('');
        } else {
            setModalValidationErrorText(l.SelectAtLeastOneUnit);
        }
    };

    const reset = () => {
        const dict = {
            dailyView: datesDict[dailyView],
            weeklyView: datesDict[weeklyView],
            monthlyView: datesDict[monthlyView],
            annuallyView: datesDict[annuallyView],
        };

        dict[modalView] = {
            days: [],
            weeks: [],
            months: [],
            years: [],
            day: '',
            month: '',
            year: '',
            isActivePicer: false,
            viewType: modalView,
        };
        setLocalDictData(dict);
        setModalValidationErrorText('');
    };

    const localDictDataChangeHandler = (data, viewType) => {
        setLocalDictData({
            ...localDictData,
            [viewType]: data,
        });
    };

    const onViewChange = (view) => {
        const toDate = view === notRepeatView ? startDate : endDate;

        setLocalDictData({ ...datesDict });
        setModalValidationErrorText('');
        setModalView(view);
        validator({
            SchedulerStartDate: {
                fromDate: startDate,
                fromTime: startTime,
                toDate,
                timeZone: timeZone,
                pageType,
                campaignStatus,
            },
            SchedulerEndDate: { fromDate: startDate, fromTime: startTime, toDate },
        });
    };

    const calculateRecCount = useCallback(() => {
        const Schedule = resultScheduleDataCreator(
            startTime,
            startDate,
            endDate,
            selectedDateSelector,
            datesDict,
            timeZone,
        );
        getRecCount(Schedule.CronSchedule, Schedule.StartDate, Schedule.EndDate, Schedule.TimeZone);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datesDict, endDate, selectedDateSelector, startDate, startTime, timeZone, modalView]);

    useEffect(() => {
        if (!datesDict.lastValidView) {
            const isInvalid = isSchedulerUnitPairsInvalid(datesDict[selectedDateSelector]);
            const lastValidView = isInvalid ? notRepeatView : selectedDateSelector;

            setDatesDict({ ...datesDict, lastValidView });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datesDict]);

    const init = () => {
        calculateRecCount();
        setIsVisibleRecCount(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, []);

    useEffect(() => {
        if (isLoaded && pageType !== PageTypes.create) {
            calculateRecCount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoaded]);

    const getFooter = () => {
        return (
            <>
                <Button appearance="minimal" onClick={cancelHandler}>
                    {t(l.Close)}
                </Button>
                <Button appearance="minimal" onClick={reset}>
                    {t(l.Reset)}
                </Button>
                <Button onClick={doneHandler}>{t(l.Apply)}</Button>
            </>
        );
    };

    return (
        <div className={classNames('scheduler-modal-wrap', { 'disable-scheduler': !isActiveSchedule })}>
            <Scheduler
                dictData={localDictData}
                view={modalView}
                startDate={startDate}
                endDate={endDate}
                startTime={startTime}
                setView={onViewChange}
                setDictData={localDictDataChangeHandler}
                selectedDateSelector={selectedDateSelector}
                viewMode="withModal"
                getFooter={getFooter}
                recurrenceData={recurrenceData}
                isVisibleRecCount={isVisibleRecCount}
                setStartTime={startTimeChangeHandler}
                setEndDate={endDateChangeHandler}
                setStartDate={startDateChangeHandler}
                startDateErrorText={t(pageValidation?.SchedulerStartDate?.errorText)}
                endDateErrorText={t(pageValidation?.SchedulerEndDate?.errorText)}
                startTimeErrorText={t(pageValidation?.SchedulerStartTime?.errorText)}
                campaignStatus={campaignStatus}
                pageType={pageType}
                timeZone={timeZone}
                setTimeZone={timeZoneChangeHandler}
                setSelectedDateSelector={setSelectedDateSelector}
                withSwitcher={withSwitcher}
                isActiveSchedule={isActiveSchedule}
                setIsActiveSchedule={setIsActiveSchedule}
                hasImmediately={hasImmediately}
                schedulerOffInfoMessage={schedulerOffInfoMessage}
                schedulerValidationErrorText={modalValidationErrorText}
                isModalView={true}
                isHourlyEnabled={false}
            />
        </div>
    );
};

SchedulerWithModal.propTypes = {
    selectedDateSelector: PropTypes.string,
    setSelectedDateSelector: PropTypes.func,
    datesDict: PropTypes.object,
    setDatesDict: PropTypes.func,
    isVisibleRecCount: PropTypes.bool,
    setIsVisibleRecCount: PropTypes.func,
    recurrenceData: PropTypes.object,
    isLoaded: PropTypes.bool,
    schedule: PropTypes.object,
    setSchedule: PropTypes.func,
    getRecCount: PropTypes.func,
    startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    startTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    setStartDate: PropTypes.func,
    setEndDate: PropTypes.func,
    setStartTime: PropTypes.func,
    pageType: PropTypes.string,
    campaignStatus: PropTypes.number,
    timeZone: PropTypes.number,
    setTimeZone: PropTypes.func,
    isActiveSchedule: PropTypes.bool,
    setIsActiveSchedule: PropTypes.func,
    hasImmediately: PropTypes.bool,
    withSwitcher: PropTypes.bool,
    schedulerOffInfoMessage: PropTypes.string,
};

SchedulerWithModal.defaultProps = {
    campaignStatus: null,
    hasImmediately: true,
    withSwitcher: true,
};
export default memo(SchedulerWithModal);
